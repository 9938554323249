import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//Components
import { CurricularPlan } from "@uaveiro/ui";
import SEO from "../SEO";
import { hasHash } from "../../services/constants";
//API
import { getCourseCurricularPlanById } from "../../api/requests";

function CurricularPlanTemplate({ match, history, ...props }) {
  const [state, setState] = useState({
    isLoading: true,
    hasError: false,
    content: {},
    tables: [],
    staticTables: [],
    filters: []
  });

  const onFetchData = async () => {
    try {
      const { id, lang } = match.params;
      const res = await getCourseCurricularPlanById({ id, lang, history });
      const { filters, tables, ...rest } = res.data;

      let arr = []; 
      tables.forEach(t =>
        t.table.forEach(
          table =>
            (arr = [...arr, ...table.columns
              .filter(col => col.type === 'link')
              .map(col => col.id)])
        )
      )
      if(arr.length == 1)
      {
        const path = `/${lang}/uc/${arr[0]}`;
        history.replace(path);
      }
      else{
        setState(prevState => {
          return {
            ...prevState,
            content: { ...rest },
            tables,
            staticTables: tables,
            filters,
            isLoading: false
          };
        });
      }
    } catch (error) {
      setState(prevState => {
        return { ...prevState, isLoading: false, hasError: true };
      });
    }
  };

  const onChangeFilter = option => {
    if (option !== null) {
      const tables = state.staticTables.map(res => {
        const cols = res.table.filter(item => {
          return item.minor === option.value || item.minor === null;
        });

        return {
          ...res,
          table: [...cols]
        };
      });

      setState(prevState => {
        return {
          ...prevState,
          tables
        };
      });
    } else {
      setState(prevState => {
        return {
          ...prevState,
          tables: prevState.staticTables
        };
      });
    }
  };

  useEffect(() => {
    onFetchData();
  }, [history.location]);

  const curricularPlanProps = {
    ...state,
    hasHash,
    onFilter: onChangeFilter,
    lang: match.params.lang,
    ...props
  };

  return (
    <Fragment>
      {!state.isLoading && (
        <SEO title={`Plano Curricular: ${state.content.title}`} />
      )}
      <CurricularPlan {...curricularPlanProps} />
    </Fragment>
  );
}

CurricularPlanTemplate.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object
};

export default withRouter(CurricularPlanTemplate);
