import React, { memo, useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withTheme } from "styled-components";
//Components
import { Section, BreadcrumbsLoading, Breadcrumbs } from "@uaveiro/ui";
//Utils
import { compose } from "../../services/utilities";
import { flagBread } from "../../services/constants";
//Hooks
import { usePageContent } from "../../hooks";
import { injectIntl } from "react-intl";
//context
import { ContentContext } from "../../context/ContentContext";
import { StoreContext } from "../../context/StoreContext";
//API
import { getSubNavigation } from "../../api/requests";

const BreadcrumbsTemplate = memo(function ArticleTemplate({
  data,
  match,
  hasLargerMdPaddingTop,
  hasSideNavColumnOffset,
  customColumn,
  hasHighContrastBorderTop,
  isLocal,
  ...props
}) {
  const context = useContext(ContentContext);
  const store = useContext(StoreContext);
  const [state, setState] = useState({
    loading: true,
    error: false,
    anchors: []
  });
  const [breadcrumbsData, setBreadcrumbsData] = useState([]);

  const path = match.url;
  const redirect = false;

  const { data: pageContent, loading: pageContentLoading } = usePageContent(
    path,
    redirect
  );

  const { lang } = match.params;
  const content = data[lang] || data;

  const sideNavColumnOffset =
    "col-xl-6 offset-xl-3 col-lg-8 offset-lg-4 col-12 offset-0";

  const getSubWebNavData = async () => {
    try {
      const { id: subWebNavId } = store.state.subWebNav;

      const data = await getSubNavigation({
        path: subWebNavId,
        lang,
        history: props.history
      });

      const mainBreadcrumbs = store.state.isExternal
        ? [{ text: subWebNavId, url: `/${subWebNavId}` }]
        : [
            { text: "UA", url: "/" },
            { text: subWebNavId, url: `/${subWebNavId}` }
          ];

      const [breadcrumbs] = data.tabs
        .map(({ links, ...tab }) => {
          let [link] = links.filter(item => `/${lang}${item.url}` === path);
          if (link)
            return [
              ...mainBreadcrumbs,
              {
                text: tab.title,
                type: "text"
              },
              { text: link.text, url: `/${link.url}` }
            ];
        })
        .filter(tabs => tabs !== undefined && tabs.length > 0);
      const items =
        breadcrumbs !== undefined
          ? breadcrumbs
          : [...content, ...context.state.breadcrumbs];

      setBreadcrumbsData(items);
    } catch (e) {
      setBreadcrumbsData([...content, ...context.state.breadcrumbs]);
    }
  };

  const onRenderAnchors = data => {
    const content = data.split("\n");
    return content
      .filter(res => res.includes("##") && !res.includes("###"))
      .map(items => {
        let text = items
          .split("(")[0]
          .replace(/(<([^>]+)>)/gi, "")
          .replace(/##\s?|([[\]])/g, "")
          .replace(/([\/\*\&])/g, "")
          .replace(/nbsp;/gi, "")
          .trim();

        return {
          text,
          elementType: "anchor"
        };
      });
  };

  const onFilterAnchors = () => {
    const articles = pageContent.filter(res => res.name === "Article");

    const anchors = [];

    if (articles.length > 0) {
      articles.map(item => {
        anchors.push(...onRenderAnchors(item.data.content));
        return true;
      });
    }

    setState(prevState => {
      return { ...prevState, anchors, loading: false };
    });
  };

  useEffect(() => {
    if (!pageContentLoading) {
      if (store.state.isSubWeb) {
        getSubWebNavData();
      } else {
        setBreadcrumbsData([...content, ...context.state.breadcrumbs]);
      }

      onFilterAnchors();
    }
  }, [pageContentLoading]);

  useEffect(() => {
    if (props.history.location.hash) {
      const hash = props.history.location.hash.split(".");
      const elementType = hash[0].replace("#", "");
      const text = decodeURIComponent(hash[1]).replace(/(-)/g, " ");

      goToScrollPosition({
        text,
        href: text,
        elementType
      });
    }
  }, []);

  const goToScrollPosition = item => {
    setTimeout(() => {
      let xpath = `//${item.elementType}[contains(text(),'${item.text}')]`;

      let element = document.evaluate(
        xpath,
        document,
        null,
        XPathResult.FIRST_ORDERED_NODE_TYPE,
        null
      ).singleNodeValue;

      if (element !== null) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "start"
        });

        if (window.history.replaceState) {
          window.history.replaceState(
            "",
            "",
            `#${item.elementType}.${item.text.replace(/ /g, "-")}`
          );
        }
      }
    }, 100);
  };

  const breadcrumbsProps = {
    breadcrumbs: breadcrumbsData,
    anchors: state.anchors,
    anchorOnClick: goToScrollPosition,
    lang,
    ...props
  };

  if (!flagBread) {
    return null;
  }

  return (
    <Section
      autoFocus
      hasHighContrastBorderTop={hasHighContrastBorderTop}
      hasBottomSpacing={false}
      mdPaddingTop={!store.state.isSubWeb && hasLargerMdPaddingTop && "142px"}
    >
      <div className="container">
        <div className="row no-gutters">
          <div
            className={`${
              hasSideNavColumnOffset ? sideNavColumnOffset : customColumn
            }`}
          >
            {state.loading ? (
              <BreadcrumbsLoading />
            ) : (
              <Breadcrumbs {...breadcrumbsProps} />
            )}
          </div>
        </div>
      </div>
    </Section>
  );
});

BreadcrumbsTemplate.defaultProps = {
  hasLargerMdPaddingTop: true,
  hasSideNavColumnOffset: true,
  hasHighContrastBorderTop: false
};

BreadcrumbsTemplate.propTypes = {
  match: PropTypes.object,
  shouldStretch: PropTypes.bool
};

export default compose(withRouter, withTheme, injectIntl)(BreadcrumbsTemplate);
