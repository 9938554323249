import React, { useContext } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//Components
import { Gallery } from "@uaveiro/ui";
//Context
import { StoreContext } from "../../context/StoreContext";
import { size } from "polished";

const GalleryTemplate = ({ match, size, ...props }) => {
  const { lang } = match.params;

  const context = useContext(StoreContext);
  const galleryProps = {
    ...props,
    activeColor: context.state.activeColor,
    isMobile: size ? size.width <= 991 : false
  };
  return <Gallery {...galleryProps} />;
};

GalleryTemplate.propTypes = {
  match: PropTypes.object
};

export default withRouter(GalleryTemplate);
