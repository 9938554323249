import React, { memo, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//Components
import {
  Section,
  ContentContainer,
  MarkdownRenderer,
  Line,
  Text
} from "@uaveiro/ui";
import { compose } from "../../../services/utilities";
import { withTheme } from "styled-components";

const PeopleArticle = memo(function Presentation({
  className,
  hasLine,
  activeColor,
  data,
  match,
  showLangMessage,
  theme
}) {
  const { lang } = match.params;
  var hasContentLang = true;
  var content;

  //******** option 1 ***** filtragem por lingua e não conteudo */
  /* content = data[lang];
  if (content === undefined || content.filter(item => item.name).length === 0) {
    content = data["pt"] || data;
    hasContentLang = data["pt"] ? false : true;
  }*/

  //******** option 2 ***** fazer filtragem de lingua e conteudo, permite ter alguns em pt e outros em en */
  if (data[lang] === undefined && Array.isArray(data)) {
    content = data.filter(item => item.name === match.params.name);
  } else {
    if (data[lang] !== undefined)
      content = data[lang].filter(item => item.name === match.params.name);
    if (data[lang] === undefined || content.length === 0) {
      content = data["pt"].filter(item => item.name === match.params.name);
      hasContentLang = false;
    }
  }

  return (
    <Section
      className={className}
      backgroundColor={theme.components.peopleArticle.backgroundColor}
      hasHighContrastBorderTop={false}
    >
      <div className="container">
        <div className="row no-gutters">
          <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-4 col-12 offset-0">
            {!hasContentLang && showLangMessage && (
              <ContentContainer marginBottom="40px">
                <Text color={activeColor}>
                  We are working in the translation of this content to English.
                  The original version of the content is presented below.{" "}
                </Text>
              </ContentContainer>
            )}
            {content
              .filter(item => item.name === match.params.name)
              .map((item, index) => {
                return (
                  <Fragment key={index}>
                    {item.subtitle && (
                      <Text
                        as="h2"
                        size="small"
                        text={item.subtitle}
                        firstLetter="capitalize"
                        fontSize="14px"
                        fontWeight="400"
                      />
                    )}
                    <Text
                      as="h1"
                      size="xLarge"
                      text={item.title}
                      fontWeight="500"
                      marginTop="5px"
                      marginBottom="15px"
                      firstLetter="capitalize"
                      lineHeight="1"
                    />
                    {hasLine && (
                      <Line
                        color={theme.components.peopleArticle.lineColor}
                        marginBottom="25px"
                      />
                    )}
                    <ContentContainer marginTop="40px">
                      <MarkdownRenderer
                        content={item.content}
                        activeColor={activeColor}
                      />
                    </ContentContainer>
                  </Fragment>
                );
              })}
          </div>
        </div>
      </div>
    </Section>
  );
});

PeopleArticle.defaultProps = {
  hasSubTitle: false,
  hasLine: false,
  hasSEO: false,
  showLangMessage: false,
  alternativeStyle: true
};

PeopleArticle.propTypes = {
  data: PropTypes.array,
  className: PropTypes.string,
  hasSubTitle: PropTypes.bool,
  hasLine: PropTypes.bool,
  showLangMessage: PropTypes.bool,
  hasSEO: PropTypes.bool
};
export default compose(withRouter, withTheme)(PeopleArticle);
