export default [
  {
    name: "SEO",
    title: {
      pt: "Departamentos e Escolas",
      en: "Departments and schools"
    },
    description:
      "Existem 16 departamentos, que se interrelacionam consoante a interdisciplinaridade dos cursos que integram ou das áreas de investigação que partilham.",
    keywords: []
  },
  {
    name: "Departments",
    title: "Departamentos e Escolas",
    subTitle: "Departamentos",
    polytechnics: "Escolas Politécnicas",
    resource: {
      title: "app.departments.list.title",
      subTitle: "app.departments.list.subTitle",
      description: "app.departments.list.description",
      polytechnics: "app.departments.polytechnics.title"
    },
    titleColor: "#000",
    titleSize: "22px",
    titleWeight: "400",
    textColor: "#000",
    textWeight: "300",
    textSize: "14px",
    textLineHeight: "1.6",
    description:
      "A Universidade de Aveiro é constituída por 16 departamentos, que se inter-relacionam consoante a interdisciplinaridade dos cursos que integram ou das áreas de investigação que partilham.",
    departments: {
      pt: [
        {
          text: "Ambiente e Ordenamento",
          url: "dao"
        },
        {
          text: "Biologia",
          url: "dbio"
        },
        {
          text: " Ciências Médicas",
          url: "dcm"
        },
        {
          text: "Ciências Sociais, Políticas e do Território",
          url: "dcspt"
        },
        {
          text: "Comunicação e Arte",
          url: "deca"
        },
        {
          text: "Economia, Gestão, Engenharia Industrial e Turismo",
          url: "degeit"
        },
        {
          text: "Educação e Psicologia",
          url: "dep"
        },
        {
          text: "Eletrónica, Telecomunicações e Informática",
          url: "deti"
        },
        {
          text: "Engenharia de Materiais e Cerâmica",
          url: "demac"
        },
        {
          text: "Engenharia Civil",
          url: "decivil"
        },
        {
          text: "Engenharia Mecânica",
          url: "dem"
        },
        {
          text: "Física",
          url: "fis"
        },
        {
          text: "Geociências",
          url: "geo"
        },
        {
          text: "Línguas e Culturas",
          url: "dlc"
        },
        {
          text: "Matemática",
          url: "dmat"
        },
        {
          text: "Química",
          url: "dqua"
        }
      ],
      en: [
        {
          text: "Biology",
          url: "dbio"
        },
        {
          text: "Chemistry",
          url: "dqua"
        },
        {
          text: "Civil Engineering",
          url: "decivil"
        },
        {
          text: "Communication and Art",
          url: "deca"
        },
        {
          text: "Economics, Management, Industrial Engineering and Tourism",
          url: "degeit"
        },
        {
          text: "Education and Psychology",
          url: "dep"
        },
        {
          text: "Electronics, Telecommunications and Informatics",
          url: "deti"
        },
        {
          text: "Environment and Planning",
          url: "dao"
        },
        {
          text: "Geosciences",
          url: "geo"
        },
        {
          text: "Languages and Cultures",
          url: "dlc"
        },
        {
          text: "Materials and Ceramic Engineering",
          url: "demac"
        },
        {
          text: "Mathematics",
          url: "dmat"
        },
        {
          text: "Mechanical Engineering",
          url: "dem"
        },
        {
          text: "Medical Sciences",
          url: "dcm"
        },
        {
          text: "Physics",
          url: "fis"
        },
        {
          text: "Social, Political and Territorial Sciences",
          url: "dcspt"
        }
      ]
    },
    politec: {
      pt: {
        description: [
          "A Universidade de Aveiro oferece um conjunto de cursos de natureza politécnica da responsabilidade das suas quatro escolas politécnicas que se encontram distribuídas pelo distrito, de forma a servir e satisfazer as necessidades da região em termos de cursos de caráter profissionalizante."
        ],
        data: [
          {
            text:
              "Escola Superior de Design, Gestão e Tecnologias da Produção Aveiro-Norte (ESAN)",
            url: "esan"
          },
          {
            text: "Escola Superior de Saúde da Universidade de Aveiro (ESSUA)",
            url: "essua"
          },
          {
            text: "Escola Superior de Tecnologia e Gestão de Águeda (ESTGA)",
            url: "estga"
          },
          {
            text:
              "Instituto Superior de Contabilidade e Administração da Universidade de Aveiro (ISCA)",
            url: "isca"
          }
        ]
      },
      en: {
        description: [
          "The University of Aveiro also offers a range of degrees in its four polytechnic schools. These schools are located in 3 different cities in the Aveiro district and serve the needs of the region in terms of professionally-oriented study programmes."
        ],
        data: [
          {
            text: "Águeda School of Technology and Management (ESTGA)",
            url: "estga"
          },
          {
            text: "Aveiro Institute of Accounting and Administration (ISCA)",
            url: "isca"
          },
          {
            text: "School of Health Sciences (ESSUA)",
            url: "essua"
          },
          {
            text:
              "School of Design, Management and Production Technologies (ESAN)",
            url: "esan"
          }
        ]
      }
    }
  }
];
