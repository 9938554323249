//Containers
import Search from "../containers/Navigation/Search";
import RouteSelector from "../containers/RouteSelector";
import LocalSelector from "../containers/LocalSelector";
import { views } from "./../services/constants";

const { index, investigation } = views;

// this is a seperate variable because
// we need this values in the navigation to handle searchToggle behaviour
// if we are on the global search page we dont want the nav search to open
export const SearchRoute = {
  path: ["/search/:type", "/pesquisa/:type"],
  component: Search,
  props: {
    activeView: index,
    sideNav: false,
    isNews: false
  }
};

const GlobalRoutes = {
  routes: [
    SearchRoute,
    {
      path: ["/search", "/pesquisa"],
      component: Search,
      props: {
        activeView: index,
        sideNav: false,
        isNews: false
      }
    },

    {
      path: ["/agenda", "/agenda"],
      component: LocalSelector,
      props: {
        activeView: index,
        document: "agenda",
        sideNav: false
      }
    },
    {
      path: ["/news/contacts", "/noticias/contactos"],
      component: LocalSelector,
      props: {
        activeView: index,
        document: "journalContacts",
        sideNav: false,
        isNews: true
      }
    },
    {
      path: ["/news/about", "/noticias/sobre"],
      component: LocalSelector,
      props: {
        activeView: index,
        document: "journalAbout",
        sideNav: false,
        isNews: true
      }
    },
    {
      path: ["/news/dossiers", "/noticias/dossies"],
      component: LocalSelector,
      props: {
        activeView: index,
        document: "journalDossiers",
        sideNav: false,
        isNews: true
      }
    },
    {
      path: ["/news/dossier/:dossierId", "/noticias/dossie/:dossierId"],
      component: LocalSelector,
      props: {
        activeView: index,
        document: "journalDossiersDetails",
        sideNav: false,
        isNews: true
      }
    },
    {
      path: ["/news/:categoryId/:newsId", "/noticias/:categoryId/:newsId"],
      component: LocalSelector,
      props: {
        activeView: index,
        document: "journalDetails",
        sideNav: false,
        isNews: true
      }
    },
    {
      path: ["/news/:categoryId", "/noticias/:categoryId"],
      component: LocalSelector,
      props: {
        activeView: index,
        document: "journalList",
        sideNav: false,
        isNews: true
      }
    },
    // {
    //   path: ["/news", "/noticias"],
    //   component: LocalSelector,
    //   props: {
    //     activeView: index,
    //     document: "journal",
    //     sideNav: false,
    //     isNews: true
    //   }
    // },
    {
      path: ["/playground", "/playground"],
      component: LocalSelector,
      props: {
        activeView: index,
        document: "playground",
        sideNav: false
      }
    },
    {
      path: ["/demo/mark/:id", "/demo/mark/:id"],
      component: LocalSelector,
      props: {
        activeView: index,
        document: "demoMark",
        sideNav: false
      }
    },
    {
      path: ["/demo/projects", "/demo/projetos"],
      component: LocalSelector,
      props: {
        activeView: investigation,
        document: "projects"
      }
    },
    {
      path: ["/demo/home", "/demo/home"],
      component: LocalSelector,
      props: {
        activeView: index,
        document: "demoHome",
        sideNav: false
      }
    },
    {
      path: ["/demo/people", "/demo/people"],
      component: LocalSelector,
      props: {
        activeView: index,
        document: "demoPeople",
        isSubWeb: true,
        sideNav: false
      }
    },
    {
      path: ["/demo/courses", "/demo/courses"],
      component: LocalSelector,
      props: {
        activeView: index,
        document: "demoCourses",
        isSubWeb: true,
        sideNav: false
      }
    },
    {
      path: ["/demo", "/demo"],
      component: LocalSelector,
      props: {
        activeView: index,
        document: "demoArticle",
        isSubWeb: true,
        sideNav: false
      }
    },
    {
      path: ["/politica-de-cookies", "/cookies-policy"],
      component: LocalSelector,
      props: {
        activeView: index,
        document: "cookiesPolicy",
        sideNav: false
      }
    },
    {
      path: ["/login", "/login"],
      component: LocalSelector,
      props: {
        activeView: index,
        document: "authenticationCallback",
        isSubWeb: false,
        sideNav: false
      }
    },
    {
      path: ["/services", "/servicos"],
      component: LocalSelector,
      props: {
        activeView: index,
        document: "ourServices",
        isSubWeb: false,
        sideNav: false
      }
    }
  ]
};

export default GlobalRoutes;
