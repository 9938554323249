import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { OneFact } from "@uaveiro/ui";

const OneFactTemplate = ({ data, match, ...props }) => {
  const { lang } = match.params;
  const content = data[lang] || data;

  return <OneFact data={content} {...props} />;
};

OneFactTemplate.propTypes = {
  content: PropTypes.arrayOf(PropTypes.string).isRequired,
  match: PropTypes.object
};

export default withRouter(OneFactTemplate);
