import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { About } from "@uaveiro/ui";
//Utils
import { lang } from "../../translations";

const Testimonials = ({ data, ...props }) => {
  const content = useMemo(() => data[lang()] || data, [data]);
  return <About data={content} {...props} />;
};

Testimonials.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export default Testimonials;
