import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//Components
import { ContentList } from "@uaveiro/ui";
//Utils
import { portalAPI, hasHash } from "../../services/constants";
import { compose } from "../../services/utilities";

const ContentListTemplate = ({ match, ...props }) => {
  const [state, setState] = useState({
    loading: true,
    error: false,
    data: {}
  });

  const { lang } = match.params;

  const onFetchData = async () => {
    try {
      const url = `${portalAPI}${props.endpoint}?language=${lang}`;
      const res = await fetch(url);
      const { data } = await res.json();

      setState(prevState => {
        return { ...prevState, data, loading: false };
      });
    } catch (e) {
      setState(prevState => {
        return { ...prevState, loading: false, error: true };
      });
    }
  };

  useEffect(() => {
    onFetchData();
  }, []);

  const contentListProps = {
    hasHash,
    lang,
    ...props
  };

  return (
    <ContentList
      data={state.data}
      loading={state.loading}
      hasError={state.error}
      {...contentListProps}
    />
  );
};

ContentListTemplate.propTypes = {
  match: PropTypes.object.isRequired
};

export default compose(withRouter)(ContentListTemplate);
