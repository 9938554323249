import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//Components
import Loading from "./Loading";
import { Link } from "../../components/atoms";

import {
  Text,
  Pagination,
  Section,
  ContentContainer,
  HorizontalList,
  Error
} from "@uaveiro/ui";

//Utils
import moment from "moment";
import { portalAPI } from "../../services/constants";
import { compose, getParameterByName } from "../../services/utilities";
//Locales
import { FormattedMessage } from "react-intl";
import { withTheme } from "styled-components";

function Directory({
  className,
  backgroundColor,
  endpoint,
  match,
  history,
  theme
}) {
  const [isLoading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [hasError, setError] = useState(false);
  const [content, setContent] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const { lang } = match.params;

  const onFetchData = async (search = "", currentPage = 1) => {
    try {
      const searchQuery = search && `&searchString=${search}`;

      if (!searchQuery) {
        setContent({
          data: {
            listNews: []
          }
        });
        setLoading(false);
        return;
      }

      const res = await fetch(
        `${portalAPI}${endpoint}?language=${lang}${searchQuery}&currentPage=${currentPage}`
      );

      const content = await res.json();

      setContent(content);
      setLoading(false);
    } catch (error) {
      setContent({
        data: {
          listNews: []
        }
      });
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    setLoading(true);
    const value = getParameterByName("q");

    setSearchValue(value);
    onFetchData(value);
  }, [history, match]);

  const onChangePage = page => {
    setCurrentPage(page);
    setLoading(true);
    onFetchData(searchValue, page, "A");
  };

  return (
    <Section
      as="div"
      className={className}
      backgroundColor={
        theme.components.globalSearch.backgroundColor || backgroundColor
      }
      hasHighContrastBorderTop={false}
      paddingTop="10px"
      mdPaddingTop="10px"
    >
      <div className="container">
        {isLoading ? (
          <Loading color="#000" />
        ) : hasError ? (
          <Error />
        ) : (
          <Fragment>
            <div className="row">
              <div className="col-12">
                {Object.keys(content).length <= 0 ? (
                  <div>
                    <FormattedMessage
                      id="app.search.empty"
                      defaultMessage="Sem conteúdos para a pesquisa"
                    />
                  </div>
                ) : Object.keys(content.data.listNews).length <= 0 ? (
                  <div>
                    <br />
                    <br />
                    <p>
                      <FormattedMessage
                        id="app.search.empty"
                        defaultMessage="Sem conteúdos para a pesquisa"
                      />
                    </p>
                  </div>
                ) : (
                  <Fragment>
                    <Text marginBottom="30px">
                      {`Foram encontrados ${content.data.pagination.totalRecords} resultados`}
                    </Text>

                    {content.data.listNews.map(value => {
                      return (
                        <ContentContainer key={value.id} marginBottom="30px">
                          <Link
                            text={value.title}
                            aria-label={value.name}
                            href={`/${lang}/${value.url}`}
                            color={theme.components.globalSearch.news.linkColor}
                            hoverColor={
                              theme.components.globalSearch.news.linkHoverColor
                            }
                            fontSize="16px"
                            fontWeight="400"
                            textDecoration="underline"
                            lineHeight="1"
                            native={value.hasLink}
                          />
                          <Text
                            text={moment(value.startDate).format(
                              "DD MMMM YYYY"
                            )}
                            fontSize="14px"
                            color={theme.components.globalSearch.news.dateColor}
                            fontWeight="300"
                            lineHeight="1.4"
                          />
                          <Text
                            text={value.pretitle}
                            fontSize="14px"
                            color={theme.components.globalSearch.news.textColor}
                            fontWeight="300"
                            lineHeight="1.6"
                          />
                        </ContentContainer>
                      );
                    })}
                  </Fragment>
                )}
              </div>
            </div>

            {content.data !== null &&
              (Object.keys(content.data.listNews).length ? (
                <div className="row">
                  <div className="col-12">
                    <HorizontalList
                      display="inline-block"
                      position="relative"
                      left="-5px"
                    >
                      <Pagination
                        onChange={onChangePage}
                        current={currentPage}
                        total={content.data.pagination.totalRecords}
                        pageSize={content.data.pagination.recordsPerPage}
                      />
                    </HorizontalList>
                  </div>
                </div>
              ) : null)}
          </Fragment>
        )}
      </div>
    </Section>
  );
}

Directory.propTypes = {
  className: PropTypes.string,
  backgroundColor: PropTypes.string,
  endpoint: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default compose(withTheme, withRouter)(Directory);
