import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { SearchRoute } from "./../../routes/GlobalRoutes";
import { withContext } from "./../../hocs";
//Components
import { MobileNavbar, WebNavbar } from "../../templates";
import { NavigationLoading } from "@uaveiro/ui";

import SubWebNavigation from "../../templates/SubWebs/Navigation";
//Data
import data from "../../data/navigation/navigation";
import { StoreContext } from "../../context/StoreContext";
//Utils
import { compose } from "../../services/utilities";
import { withTheme } from "styled-components";
import { lang } from "../../translations";
import { portalAPI, authConfig } from "../../services/constants";
import { getSideNavByPage } from "../../api/requests";

const publicLinksData = {
  pt: [
    { text: "Futuros estudantes", href: `/pt/publico-futuros-estudantes` },
    { text: "Estudantes UA", href: `/pt/publico-estudantes` },
    {
      text: "Estudantes internacionais",
      href: `/pt/publico-estudantes-internacionais`
    },
    { text: "Alumni", href: `/pt/publico-alumni` },
    { text: "Pessoas UA", href: `/pt/publico-pessoas` },
    { text: "Sociedade", href: `/pt/publico-sociedade` }
  ],
  en: [
    { text: "Prospective Students", href: `/en/public-prospective-students` },
    { text: "UA Students", href: `/en/public-students` },
    {
      text: "International Students",
      href: `/en/public-internacional-student`
    },
    { text: "Alumni", href: `/en/public-alumni` },
    { text: "UA People", href: `/en/public-people` },
    { text: "Society", href: `/en/public-society` }
  ]
};

const getPublicLinks = (lang = "pt", hasHighContrast = false) => {
  const links = publicLinksData[lang].map(link => {
    return {
      text: link.text,
      href: `${link.href}${hasHighContrast ? "?highContrast=true" : ""}`
    };
  });

  return links;
};

class Navigation extends React.Component {
  static contextType = StoreContext;
  state = {
    isLogin: false,
    movedScroll: false,
    isSearchOpen: false,
    navHeight: 105,
    journalNavigationHeight: 139,
    journalMobileNavigationHeight: 72,
    journalNavigationShadow: false,
    subWebNavigationShadow: false,
    hideSubWebLogoDescription: false,
    innerWidth: null,
    pathname: null,
    searchRoutes: [],
    isLoading: true,
    tabs: null
  };
  componentDidMount() {
    this.onChangeInnerWidth();
    this.setState({ pathname: this.props.location.pathname });
    this.computeSearchRoutes();
    this.getTabs();
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.onChangeInnerWidth);

    /*setTimeout(() => {
      this.setState({ isLoading: false });
    }, 500);*/
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.onChangeInnerWidth);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({
        pathname: this.props.location.pathname,
        isSearchOpen: false
      });
      return true;
    }
  }

  computeSearchRoutes = () => {
    const possibleSearchRoutes = SearchRoute.path.map(
      path => path.split("/")[1]
    );
    this.setState({ searchRoutes: possibleSearchRoutes });
  };

  getTabs = async () => {

        const data = await getSideNavByPage({
          activeView: "ua",
          redirect: false,
          history,
          lang: lang()
        });
        this.setState({ tabs: data.tabs });
        setTimeout(() => {
          this.setState({ isLoading: false });
        }, 200);
  };

  handleScroll = () => {
    this.setState({
      movedScroll: window.scrollY
    });
    if (window.scrollY >= 150) {
      this.setState({
        navHeight: 80,
        journalNavigationHeight: 71,
        journalMobileNavigationHeight: 60,
        hideSubWebLogoDescription: true
      });
    }
    if (window.scrollY <= 40) {
      this.setState({
        navHeight: 105,
        journalNavigationHeight: 139,
        journalMobileNavigationHeight: 72,
        journalNavigationShadow: false,
        subWebNavigationShadow: false,
        hideSubWebLogoDescription: false
      });
    }
    if (window.scrollY >= 40) {
      this.setState({
        journalNavigationShadow: true,
        subWebNavigationShadow: true
      });
    }
  };
  onChangeInnerWidth = () => {
    this.setState({ innerWidth: window.innerWidth });
  };

  _loginUser = e => {
    e.preventDefault();
    this.setState(prevState => ({
      isLogin: !prevState.isLogin
    }));
  };
  _onSearchToggle = e => {
    e.preventDefault();
    const {
      location,
      context: { toggleSearchFocus }
    } = this.props;
    const activeRouteName = location.pathname.split("/")[2];
    const isSearchRoute = this.state.searchRoutes.indexOf(activeRouteName) > -1;
    // only toggle nav search when the user is NOT in the search page
    if (!isSearchRoute) {
      this.setState(prevState => ({
        isSearchOpen: !prevState.isSearchOpen
      }));
    } else toggleSearchFocus();
  };
  render() {
    const { accessibilityValue } = this.context.state;

    const {
      movedScroll,
      isSearchOpen,
      innerWidth,
      pathname,
      isLoading
    } = this.state;

    if (this.context.state.isExternal) {
      return (
        <SubWebNavigation
          hideSubWebLogoDescription={this.state.hideSubWebLogoDescription}
          subWebNavigationShadow={this.state.subWebNavigationShadow}
        />
      );
    }

    if (isLoading) {
      return (
        <Fragment>
          <NavigationLoading height="40px" mdHeight="40px" />
          <NavigationLoading
            height="105px"
            mdHeight="80px"
            backgroundColor={this.props.theme.colors.black}
          />
        </Fragment>
      );
    }

    var token = "";
    try {
      token = localStorage.getItem("sb-ua-auth-id-token");
    } catch (error) {}
    return (
      <Fragment>
        <ua-systems-bar
          publicLinks={JSON.stringify(
            getPublicLinks(lang(), accessibilityValue == 1)
          )}
          authConfig={JSON.stringify({
            login: authConfig.login,
            logout: authConfig.logout,
            clientId: authConfig.clientId,
            idToken: token,
            callbackUri: authConfig.callbackUri
          })}
          lang={lang()}
          apiURL={portalAPI}
          setHighContrast={accessibilityValue == 1 ? true : null}
        ></ua-systems-bar>
        <Fragment>
          {innerWidth <= 991 && innerWidth !== null ? (
            <MobileNavbar
              pathname={pathname}
              isSearchOpen={isSearchOpen}
              //height={isSubWeb ? 60 : this.state.navHeight}
              height={this.state.navHeight}
              journalMobileNavigationHeight={
                this.state.journalMobileNavigationHeight
              }
              journalNavigationShadow={this.state.journalNavigationShadow}
              searchToggle={this._onSearchToggle}
              movedPosition={movedScroll}
              //hasInstituteLogo={!isSubWeb}
              hasInstituteLogo={true}
              //hasEmbeddedSearch={!isSubWeb}
              hasEmbeddedSearch={true}
              //accessibilityDropdownPadding={isSubWeb ? "6px 15px" : ""}
              subWebNavigationShadow={this.state.subWebNavigationShadow}
              hideSubWebLogoDescription={this.state.hideSubWebLogoDescription}
              {...data.topNavbar}
              {...this.props}
            />
          ) : (
            <WebNavbar
              isSearchOpen={isSearchOpen}
              //height={isSubWeb ? 40 : this.state.navHeight}
              height={this.state.navHeight}
              journalNavigationHeight={this.state.journalNavigationHeight}
              journalNavigationShadow={this.state.journalNavigationShadow}
              searchToggle={this._onSearchToggle}
              movedPosition={movedScroll}
              pathname={pathname}
              //hasInstituteLogo={!isSubWeb}
              hasInstituteLogo={true}
              //hasEmbeddedSearch={!isSubWeb}
              hasEmbeddedSearch={true}
              //hasLinksBottomAdjustments={!isSubWeb}
              hasLinksBottomAdjustments={true}
              subWebNavigationShadow={this.state.subWebNavigationShadow}
              hideSubWebLogoDescription={this.state.hideSubWebLogoDescription}
              tabs={this.state.tabs}
              // {...data.topNavbar}
              {...this.props}
            />
          )}
        </Fragment>
      </Fragment>
    );
  }
}

Navigation.propTypes = {
  location: PropTypes.object.isRequired,
  context: PropTypes.object.isRequired
};

export default compose(withRouter, withContext, withTheme)(Navigation);
