import React, { useEffect, useContext } from "react";
//Router
import { useHistory } from "react-router-dom";
//Components
import { Loading } from "@uaveiro/ui";
//utils
import queryString from "query-string";
import { lang } from "../../translations";
// Context
import { ContentContext } from "../../context/ContentContext";

const AuthenticationCallback = () => {
  const history = useHistory();
  const {
    state: { disableLocalStorage }
  } = useContext(ContentContext);

  const getUserAuthToken = async (access_token, id_token, lastPath) => {
    try {
      const res = await fetch(
        `${process.env.RAZZLE_PORTAL_API}/authentication/wso2/portal/login/${access_token}?local=true`,
        {
          credentials: "include"
        }
      );

      const { token } = await res.json();

      if (!disableLocalStorage) {
        localStorage.setItem("sb-ua-auth-token", token);
        localStorage.setItem("sb-ua-auth-id-token", id_token);
      }

      window.location.href = lastPath ? lastPath : `/${lang()}/`;
    } catch (error) {
      window.location.href = `/${lang()}/404`;
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      const { access_token, id_token } = queryString.parse(
        history.location.hash
      );
      const lastPath = localStorage.getItem("portalLastLocationPath");
      // const lastPath = window.location.pathname;
      if (access_token === undefined) {
        window.location.href = lastPath ? lastPath : `/${lang()}/`;
      } else {
        getUserAuthToken(access_token, id_token, lastPath);
      }
    }
  }, []);
  return <Loading />;
};

export default AuthenticationCallback;
