import React, { useState, Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { lighten } from "polished";
//Styles
import styled, { withTheme } from "styled-components";
//components
import { HorizontalList, ListItem, Error, Text, Icon } from "@uaveiro/ui";
import { Link } from "./../../../atoms";
import { withSize } from "react-sizeme";
import Loading from "./Loading";
//Locales
import { FormattedMessage } from "react-intl";
import { compose } from "../../../../services/utilities";

const classes = {
  initial: "closed-menu",
  close: "close-menu",
  open: "open-menu"
};

const heightTransitionTime = "0.4s";

const Navigation = ({
  className,
  size,
  data,
  loading,
  hasError,
  titleUrl,
  categoryId,
  theme,
  magazineLink,
  magazineTitle,
  ...props
}) => {
  const { initial, close, open } = classes;
  const [isOpen, setOpen] = useState(false);
  const [menuClass, setMenuClass] = useState(initial);

  useEffect(() => {
    setOpen(false);
  }, [props.location.pathname]);

  const onHandleAnimation = () => {
    if (isOpen) {
      setMenuClass(close);
      setTimeout(() => {
        setOpen(false);
      }, 600);
      return;
    }
    setMenuClass(open);
    setOpen(true);
  };
  return (
    <div className={className}>
      {loading ? (
        <Loading
          navigationLoadingHeight={props.journalNavigationHeight}
          mobileNavigationLoadingHeight={props.journalMobileNavigationHeight}
        />
      ) : hasError ? (
        <Error />
      ) : (
        <Fragment>
          <div className={`nav-container ${isOpen ? "remove-border" : ""}`}>
            <div
              className="container"
              onClick={size.width < 1200 ? onHandleAnimation : null}
            >
              <div className="row no-gutters align-items-center">
                <div className="col-xl-3 col-lg col">
                  <div className="link-container">
                    <Link
                      href={titleUrl}
                      display="inline-block"
                      color={theme.components.journalNavigation.titleColor}
                    >
                      <Text
                        as="h2"
                        size="xLarge"
                        fontWeight="500"
                        color="inherit"
                      >
                        {props.resource ? (
                          <FormattedMessage
                            id={props.resource}
                            defaultMessage={props.title}
                          />
                        ) : (
                          props.title
                        )}
                      </Text>
                    </Link>
                  </div>
                </div>

                <div className="col-xl col-auto">
                  {size.width <= 1199 ? (
                    <button
                      aria-label={isOpen ? "Fechar menu" : "Abrir menu"}
                      onClick={onHandleAnimation}
                    >
                      <Icon
                        name="fal fa-ellipsis-v"
                        color={theme.components.journalNavigation.menuIconColor}
                        size="large"
                      />
                    </button>
                  ) : (
                    <HorizontalList display="inline-block">
                      {data.map(item => {
                        return (
                          <ListItem key={item.id}>
                            <Link
                              href={item.url}
                              fontWeight="400"
                              textDecoration={
                                item.id === categoryId ? "underline" : "none"
                              }
                              fontSize="13px"
                              color={
                                theme.components.journalNavigation.linksColor
                              }
                            >
                              {item.name}
                            </Link>
                          </ListItem>
                        );
                      })}
                      <ListItem>
                        <Link
                          href={magazineLink}
                          fontWeight="500"
                          fontSize="13px"
                          color={theme.components.journalNavigation.linksColor}
                        >
                          {magazineTitle}
                        </Link>
                      </ListItem>
                    </HorizontalList>
                  )}
                </div>
              </div>
            </div>
          </div>

          {size.width <= 1199 && (
            <div className={`menu-list ${menuClass}`}>
              {isOpen && (
                <div className="list-container">
                  <div className="container">
                    <HorizontalList display="block">
                      {data.map(item => {
                        return (
                          <ListItem key={item.id}>
                            <Link
                              href={item.url}
                              fontWeight={
                                item.id === categoryId ? "500" : "400"
                              }
                              fontSize="16px"
                              color={
                                theme.components.journalNavigation.linksColor
                              }
                            >
                              {item.name}
                            </Link>
                          </ListItem>
                        );
                      })}
                      <ListItem>
                        <Link
                          href={magazineLink}
                          fontWeight="500"
                          fontSize="16px"
                          color={theme.components.journalNavigation.linksColor}
                        >
                          {magazineTitle}
                        </Link>
                      </ListItem>
                    </HorizontalList>
                  </div>
                </div>
              )}
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};

Navigation.propTypes = {
  className: PropTypes.string,
  resource: PropTypes.string,
  title: PropTypes.string,
  titleUrl: PropTypes.string,
  route: PropTypes.string,
  size: PropTypes.object,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  loading: PropTypes.bool,
  hasError: PropTypes.bool,
  categoryId: PropTypes.number,
  journalNavigationHeight: PropTypes.string,
  journalMobileNavigationHeight: PropTypes.string,
  magazineLink: PropTypes.string,
  magazineTitle: PropTypes.string
};

const NavigationWithStyles = styled(Navigation)`
  background-color: ${props =>
    props.theme.highContrast.backgroundColor ||
    props.backgroundColor ||
    props.theme.components.journalNavigation.backgroundColor};
  z-index: 98;
  // Add Shadow on Journal Navigation After 40px scroll
  ${props =>
    props.journalNavigationShadow &&
    `
    box-shadow:
    ${props.theme.components.journalNavigation.boxShadow};
    -moz-box-shadow:
    ${props.theme.components.journalNavigation.boxShadow};
    -webkit-box-shadow:
    ${props.theme.components.journalNavigation.boxShadow};
    -o-box-shadow:
    ${props.theme.components.journalNavigation.boxShadow};
  `}

  .nav-container {
    height: auto;
    .link-container {
      min-width: 125px;
      margin-right: 30px;
    }
    .row {
      transition: height 0.4s ease;
      -webkit-transition: height 0.4s ease;
      height: ${props => props.journalNavigationHeight};
    }
    li {
      margin-right: 18px;
      &:last-child {
        margin-right: 0;
      }
    }
    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      &:hover {
        i {
          color: ${lighten(0.5, "#000")};
        }
      }
    }
  }
  // Medium devices (tablets, less than 1200px)
  @media (max-width: 1199px) {
    .nav-container {
      position: relative;
      height: auto;
      z-index: 3;

      .row {
        height: ${props => props.journalMobileNavigationHeight};
        transition: height 0.2s ease;
        -webkit-transition: height 0.2s ease;
      }

      ${props =>
        props.hasMobileMdBorderBottom &&
        `  border-bottom: 1px solid
            ${props.theme.components.journalNavigation.mobileBorderBottomColor};
     `}
    }

    .nav-container,
    .menu-list {
      background-color: ${props =>
        props.theme.highContrast.backgroundColor ||
        props.mobileBackgroundColor ||
        props.theme.components.journalNavigation.backgroundColor};
    }

    /*Menu List*/
    .menu-list {
      z-index: 2;
      position: absolute;
      top: calc(${props => props.journalMobileNavigationHeight} - 20px);
      width: 100%;
      overflow: hidden;
      background-color: ${props =>
        props.theme.highContrast.backgroundColor ||
        props.backgroundColor ||
        props.theme.components.journalNavigation.backgroundColor};
      .list-container {
        padding: 20px 0;
      }
      li {
        margin-right: 0;
        margin-bottom: 5px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      a {
        display: block;
        padding: 10px 0;
      }
      box-shadow: ${props =>
        props.theme.components.journalNavigation.boxShadow};
      -moz-box-shadow: ${props =>
        props.theme.components.journalNavigation.boxShadow};
      -webkit-box-shadow: ${props =>
        props.theme.components.journalNavigation.boxShadow};
      -o-box-shadow: ${props =>
        props.theme.components.journalNavigation.boxShadow};
    }

    /*Initial state*/
    .closed-menu {
      max-height: 0;
      height: 0;
      visibility: hidden;
    }

    /*Animation*/
    .open-menu {
      transition: max-height ${heightTransitionTime} ease-in;
      visibility: visible;
      max-height: 440px;
      a {
        -webkit-animation: fade-in-jn-items 0.6s ease-in;
        -moz-animation: fade-in-jn-items 0.6s ease-in;
        animation: fade-in-jn-items 0.6s ease-in;
      }
    }

    .close-menu {
      transition: all ${heightTransitionTime} ease-out;
      visibility: hidden;
      max-height: 0;
      a {
        -webkit-animation: fade-out-jn-items 0.2s ease-out forwards;
        -moz-animation: fade-out-jn-items 0.2s ease-out forwards;
        animation: fade-out-jn-items 0.2s ease-out forwards;
      }
    }

    /*Fade-in Fade-out*/
    @keyframes fade-out-jn-items {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

    @keyframes fade-in-jn-items {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767px) {
    .nav-container {
      ${props =>
        props.hasMobileBorderBottom &&
        `
        border-bottom: 1px solid
          ${props.theme.components.journalNavigation.mobileBorderBottomColor};
      `}
    }
  }

  //Remove border by giving the same background color to maintain space
  .nav-container.remove-border {
    border-bottom-color: ${props =>
      props.backgroundColor ||
      props.theme.components.journalNavigation.backgroundColor};
  }
`;

NavigationWithStyles.defaultProps = {
  journalNavigationHeight: "139px",
  journalMobileNavigationHeight: "72px",
  hasMobileBorderBottom: false,
  hasMobileMdBorderBottom: false
};

export default compose(withSize(), withTheme)(NavigationWithStyles);
