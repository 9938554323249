import React, { Fragment, useContext } from "react";
import PropTypes from "prop-types";
import { AnimatedBackground, ContentContainer } from "@uaveiro/ui";
import { StoreContext } from "../../../context/StoreContext";
import { withTheme } from "styled-components";

const Mask = props => {
  return (
    <ContentContainer
      backgroundColor={props.theme.loadings.maskBgColor}
      position="absolute"
      {...props}
    />
  );
};

const LoadingCard = ({ ...props }) => {
  const GlobalStore = useContext(StoreContext);

  const {
    state: { activeColor }
  } = GlobalStore;

  return (
    <Fragment>
      <ContentContainer
        padding="26px 0 14px 0"
        mdPadding="27px 0"
        backgroundColor={
          props.theme.highContrast.lightBackgroundColor || activeColor
        }
        width="100%"
        borderBottom={props.theme.highContrast.border}
      >
        <div className="container">
          <ContentContainer width="100px">
            <AnimatedBackground height="18px" />
          </ContentContainer>
        </div>
      </ContentContainer>
      {window.scrollY >= 150 ? (
        ""
      ) : (
        <ContentContainer
          padding="25px 0"
          backgroundColor={props.theme.loadings.cardBgColor}
          mobileMdDisplay="none"
        >
          <div className="container">
            <AnimatedBackground height="112px">
              <Mask
                height="16px"
                right="0"
                width="30%"
                mobileMdWidth="70%"
                {...props}
              />
              <Mask height="16px" top="16px" width="100%" {...props} />
              <Mask
                height="16px"
                top="32px"
                right="0"
                width="35%"
                mobileMdWidth="75%"
                {...props}
              />
              <Mask height="16px" top="48px" width="100%" {...props} />
              <Mask
                height="16px"
                top="64px"
                right="0"
                width="40%"
                mobileMdWidth="80%"
                {...props}
              />
              <Mask height="16px" top="80px" width="100%" {...props} />
              <Mask
                height="16px"
                top="96px"
                right="0"
                width="25%"
                mobileMdWidth="80%"
                {...props}
              />
            </AnimatedBackground>
          </div>
        </ContentContainer>
      )}
    </Fragment>
  );
};

const Loading = ({ className, ...props }) => {
  return (
    <div className={className}>
      <LoadingCard {...props} />
    </div>
  );
};

Loading.propTypes = {
  className: PropTypes.string
};

export default withTheme(Loading);
