export default [
  {
    name: "SEO",
    title: {
      pt: "Agenda",
      en: "Events"
    },
    description: "Agenda da Universidade de Aveiro",
    keywords: []
  },
  {
    name: "Events",
    title: "Agenda",
    textColor: "#000",
    endpoint: "/events"
  }
];
