import React, { memo, useContext, useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
//Components
import { Agenda } from "@uaveiro/ui";
//Utils
import { portalAPI } from "../../services/constants";
import moment from "moment";
//locales
import { lang } from "../../translations";
import { StoreContext } from "../../context/StoreContext";

const AgendaTemplate = memo(function AgendaTemplate({
  backgroundColor,
  href,
  ...props
}) {
  const [state, setState] = useState({
    loading: true,
    error: false,
    data: {}
  });

  const {
    state: { accessibilityValue, isSubWeb, activeColor }
  } = useContext(StoreContext);
  const hasAccessibility = useMemo(() => Number(accessibilityValue) === 1, [
    accessibilityValue
  ]);

  const onFetchData = async () => {
    try {
      const url = `${portalAPI}${props.endpoint}&startDate=${moment(
        new Date()
      ).format("DD-MM-YYYY")}&language=${lang()}`;
      const res = await fetch(url);
      const { data } = await res.json();

      setState(prevState => {
        return { ...prevState, data, loading: false };
      });
    } catch (e) {
      setState(prevState => {
        return { ...prevState, loading: false, error: true };
      });
    }
  };

  useEffect(() => {
    onFetchData();
  }, []);

  return (
    <Agenda
      data={state.data}
      loading={state.loading}
      hasError={state.error}
      hasAccessibility={hasAccessibility}
      backgroundColor={isSubWeb ? activeColor : backgroundColor}
      href={href.includes("pt") ? href : `/${lang()}/${href}`}
      {...props}
    />
  );
});

AgendaTemplate.propTypes = {
  backgroundColor: PropTypes.string,
  href: PropTypes.string
};

export default AgendaTemplate;
