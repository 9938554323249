import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//Components
//Engine
import ComponentSelector from "../../containers/ComponentSelector";
import { PresentationLoading, Section } from "@uaveiro/ui";
//API
import { getCourseInfoById } from "../../api/requests";
//Hooks
import { useActiveNavigation } from "../../hooks";

function CourseInfoTemplate({ match, location, history, ...props }) {
  useActiveNavigation(props.activeView, props.sideNav, props.isNews);
  const [state, setState] = useState({
    loading: true,
    error: false,
    data: []
  });

  const { id, lang } = match.params;

  const onFetchContent = async () => {
    try {
      const data = await getCourseInfoById({
        id,
        lang,
        history
      });

      setState(prevState => {
        return {
          ...prevState,
          data,
          loading: false
        };
      });
    } catch (error) {
      history.push(`/${lang}/404`);
    }
  };

  useEffect(() => {
    onFetchContent();
  }, [location]);

  if (state.loading)
    return (
      <Section>
        <div className="container">
          <PresentationLoading />
        </div>
      </Section>
    );
  if (state.data === null || state.data === undefined) return null;

  return state.data.map((component, index) => {
    const { name, ...rest } = component;
    return (
      <ComponentSelector
        key={index}
        name={name}
        index={index}
        length={Object.keys(state.data).length}
        {...rest}
        {...props}
      />
    );
  });
}

CourseInfoTemplate.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(CourseInfoTemplate);
