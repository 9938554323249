export default [
  {
    name: "Breadcrumbs",
    data: {
      pt: [
        {
          text: "UA",
          url: "/"
        },
        {
          text: "estudar",
          url: "/estudar"
        },
        {
          text: "candidaturas",
          url: "/candidaturas"
        },
        {
          text: "candidaturas a pós-graduação",
          url: "/candidaturas-pos-grad"
        },
        {
          text: "editais",
          url: "/editais"
        }
      ],
      en: [
        {
          text: "UA",
          url: "/"
        },
        {
          text: "studying",
          url: "/study"
        },
        {
          text: "applications",
          url: "/applications"
        },
        {
          text: "postgraduate applications",
          url: "/post-grad-applications"
        },
        {
          text: "application calls",
          url: "/edicts"
        }
      ]
    }
  },
  {
    name: "Edicts",
    title: "Editais de abertura",
    endpoint: "/edicts",
    selects: [
      {
        title: "1ª Fase",
        options: [
          {
            value: "percurso: Inglês, Alemão e Árabe",
            label: "percurso: Inglês, Alemão e Árabe"
          },
          {
            value: "percurso: Inglês, Alemão e Chinês",
            label: "percurso: Inglês, Alemão e Chinês"
          },
          {
            value: "percurso: Inglês, Alemão e Português",
            label: "percurso: Inglês, Alemão e Português"
          },
          {
            value: "percurso: Inglês, Alemão e Russo",
            label: "percurso: Inglês, Alemão e Russo"
          }
        ]
      }
    ],
    data: [
      {
        table: [
          {
            isHeader: true,
            cols: [
              {
                col: "col",
                text: "curso"
              },
              {
                col: "col-auto",
                text: "período de candidatura",
                width: "165px"
              },
              {
                col: "col-auto",
                text: "edital",
                width: "35px"
              },
              {
                col: "col-auto",
                text: "ata",
                width: "30px"
              }
            ]
          },
          {
            cols: [
              {
                col: "col",
                text: "biologia",
                type: "link"
              },
              {
                col: "col-auto",
                text: "período de candidatura",
                width: "165px"
              },
              {
                col: "col-auto",
                text: "far fa-file",
                width: "35px",
                type: "icon"
              },
              {
                col: "col-auto",
                text: "far fa-file",
                width: "30px",
                type: "icon"
              }
            ]
          },
          {
            cols: [
              {
                col: "col",
                text: "biologia e geologia",
                type: "link"
              },
              {
                col: "col-auto",
                text: "período de candidatura",
                width: "165px"
              },
              {
                col: "col-auto",
                text: "far fa-file",
                width: "35px",
                type: "icon"
              },
              {
                col: "col-auto",
                text: "far fa-file",
                width: "30px",
                type: "icon"
              }
            ]
          },
          {
            cols: [
              {
                col: "col",
                text: "bioquímica",
                type: "link"
              },
              {
                col: "col-auto",
                text: "período de candidatura",
                width: "165px"
              },
              {
                col: "col-auto",
                text: "far fa-file",
                width: "35px",
                type: "icon"
              },
              {
                col: "col-auto",
                text: "far fa-file",
                width: "30px",
                type: "icon"
              }
            ]
          },
          {
            cols: [
              {
                col: "col",
                text: "biotecnologia",
                type: "link"
              },
              {
                col: "col-auto",
                text: "período de candidatura",
                width: "165px"
              },
              {
                col: "col-auto",
                text: "far fa-file",
                width: "35px",
                type: "icon"
              },
              {
                col: "col-auto",
                text: "far fa-file",
                width: "30px",
                type: "icon"
              }
            ]
          },
          {
            cols: [
              {
                col: "col",
                text: "ciências biomédicas",
                type: "link"
              },
              {
                col: "col-auto",
                text: "período de candidatura",
                width: "165px"
              },
              {
                col: "col-auto",
                text: "far fa-file",
                width: "35px",
                type: "icon"
              },
              {
                col: "col-auto",
                text: "far fa-file",
                width: "30px",
                type: "icon"
              }
            ]
          },
          {
            cols: [
              {
                col: "col",
                text: "ciências do mar",
                type: "link"
              },
              {
                col: "col-auto",
                text: "período de candidatura",
                width: "165px"
              },
              {
                col: "col-auto",
                text: "far fa-file",
                width: "35px",
                type: "icon"
              },
              {
                col: "col-auto",
                text: "far fa-file",
                width: "30px",
                type: "icon"
              }
            ]
          },
          {
            cols: [
              {
                col: "col",
                text: "contabilidade",
                type: "link"
              },
              {
                col: "col-auto",
                text: "período de candidatura",
                width: "165px"
              },
              {
                col: "col-auto",
                text: "far fa-file",
                width: "35px",
                type: "icon"
              },
              {
                col: "col-auto",
                text: "far fa-file",
                width: "30px",
                type: "icon"
              }
            ]
          },
          {
            cols: [
              {
                col: "col",
                text: "contabilidade à distância",
                type: "link"
              },
              {
                col: "col-auto",
                text: "período de candidatura",
                width: "165px"
              },
              {
                col: "col-auto",
                text: "far fa-file",
                width: "35px",
                type: "icon"
              },
              {
                col: "col-auto",
                text: "far fa-file",
                width: "30px",
                type: "icon"
              }
            ]
          },
          {
            cols: [
              {
                col: "col",
                text: "contabilidade pós-laboral",
                type: "link"
              },
              {
                col: "col-auto",
                text: "período de candidatura",
                width: "165px"
              },
              {
                col: "col-auto",
                text: "far fa-file",
                width: "35px",
                type: "icon"
              },
              {
                col: "col-auto",
                text: "far fa-file",
                width: "30px",
                type: "icon"
              }
            ]
          },
          {
            cols: [
              {
                col: "col",
                text: "design",
                type: "link"
              },
              {
                col: "col-auto",
                text: "período de candidatura",
                width: "165px"
              },
              {
                col: "col-auto",
                text: "far fa-file",
                width: "35px",
                type: "icon"
              },
              {
                col: "col-auto",
                text: "far fa-file",
                width: "30px",
                type: "icon"
              }
            ]
          },
          {
            cols: [
              {
                col: "col",
                text: "design de produto e tecnologia",
                type: "link"
              },
              {
                col: "col-auto",
                text: "período de candidatura",
                width: "165px"
              },
              {
                col: "col-auto",
                text: "far fa-file",
                width: "35px",
                type: "icon"
              },
              {
                col: "col-auto",
                text: "far fa-file",
                width: "30px",
                type: "icon"
              }
            ]
          },
          {
            cols: [
              {
                col: "col",
                text: "economia",
                type: "link"
              },
              {
                col: "col-auto",
                text: "período de candidatura",
                width: "165px"
              },
              {
                col: "col-auto",
                text: "far fa-file",
                width: "35px",
                type: "icon"
              },
              {
                col: "col-auto",
                text: "far fa-file",
                width: "30px",
                type: "icon"
              }
            ]
          },
          {
            cols: [
              {
                col: "col",
                text: "educação básica",
                type: "link"
              },
              {
                col: "col-auto",
                text: "período de candidatura",
                width: "165px"
              },
              {
                col: "col-auto",
                text: "far fa-file",
                width: "35px",
                type: "icon"
              },
              {
                col: "col-auto",
                text: "far fa-file",
                width: "30px",
                type: "icon"
              }
            ]
          },
          {
            cols: [
              {
                col: "col",
                text: "enfermagem",
                type: "link"
              },
              {
                col: "col-auto",
                text: "período de candidatura",
                width: "165px"
              },
              {
                col: "col-auto",
                text: "far fa-file",
                width: "35px",
                type: "icon"
              },
              {
                col: "col-auto",
                text: "far fa-file",
                width: "30px",
                type: "icon"
              }
            ]
          },
          {
            cols: [
              {
                col: "col",
                text: "engenharia biomédica mestrado integrado",
                type: "link"
              },
              {
                col: "col-auto",
                text: "período de candidatura",
                width: "165px"
              },
              {
                col: "col-auto",
                text: "far fa-file",
                width: "35px",
                type: "icon"
              },
              {
                col: "col-auto",
                text: "far fa-file",
                width: "30px",
                type: "icon"
              }
            ]
          }
        ]
      }
    ]
  }
];
