import React, { memo, useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//Components
import { Profile } from "@uaveiro/ui";
import SEO from "../SEO";
//Utils
import { portalAPI, hasHash } from "../../services/constants";
import { compose } from "../../services/utilities";
//API
import {
  getProfileExternalInformation,
  getProfileExternalPublications,
  getProfilePublications,
  getProfile,
  getProfilePhoto
} from "../../api/requests";

const ProfileTemplate = memo(function ProfileTemplate({
  match,
  history,
  ...props
}) {
  const [content, setContent] = useState({
    loading: true,
    error: false,
    showPhoto: false,
    isLoadingPerson: true,
    photoContent: {},
    data: {}
  });

  const [pubsContent, setPubsContent] = useState({
    loading: true,
    error: false,
    data: {}
  });

  const [externalContent, setExternalContent] = useState({
    loading: true,
    error: false,
    data: []
  });

  const [externalPubsContent, setExternalPubsContent] = useState({
    loading: true,
    error: false,
    title: "",
    data: null
  });

  const { id: userId, lang } = match.params;

  const onFetchExternalInformation = async () => {
    try {
      const { data } = await getProfileExternalInformation({
        userId,
        lang,
        history: props.history
      });

      setExternalContent(prevState => {
        return { ...prevState, loading: false, data };
      });
    } catch (e) {
      setExternalContent(prevState => {
        return { ...prevState, loading: false, error: true };
      });
    }
  };

  const onFetchExternalPublications = async () => {
    try {
      const { data, title } = await getProfileExternalPublications({
        userId,
        lang,
        history: props.history
      });

      setExternalPubsContent(prevState => {
        return { ...prevState, loading: false, title, data };
      });
    } catch (e) {
      setExternalPubsContent(prevState => {
        return { ...prevState, loading: false, error: true };
      });
    }
  };

  const onFetchPublications = async () => {
    try {
      const publications = await getProfilePublications({
        userId,
        lang,
        history: props.history
      });

      setPubsContent(prevState => {
        return {
          ...prevState,
          loading: false,
          data: {
            publications: publications.data
          }
        };
      });
    } catch (error) {
      setPubsContent(prevState => {
        return { ...prevState, loading: false, error: true };
      });
    }
  };

  const onFetchData = async () => {
    try {
      const { data: personData } = await getProfile({
        userId,
        lang,
        history: props.history
      });

      const imageSrc = await getProfilePhoto({ userId });

      setContent(prevState => {
        return {
          ...prevState,
          loading: false,
          data: { ...personData },
          showPhoto: personData.resume.showPhoto,
          photoContent: {
            image: {
              src: imageSrc || `${portalAPI}/person/${userId}/photo`,
              emails: personData.resume.email,
              links: personData.resume.links
            }
          },
          isLoadingPerson: false
        };
      });

      onFetchExternalInformation();
      onFetchExternalPublications();
      onFetchPublications(personData);
    } catch (error) {
      setContent(prevState => {
        return {
          ...prevState,
          loading: false,
          error: true,
          isLoadingPerson: false
        };
      });
      setPubsContent(prevState => {
        return { ...prevState, loading: false };
      });

      history.push(`/${match.params.lang}/404`);
    }
  };

  useEffect(() => {
    onFetchData();
  }, [match]);

  const onSetShowPhoto = value => {
    setContent(prevState => {
      return {
        ...prevState,
        showPhoto: value
      };
    });
  };

  const profileProps = {
    showPhoto: content.showPhoto,
    pubsContent: pubsContent.data,
    photoContent: content.photoContent,
    content: content.data,
    isLoadingPublications: pubsContent.loading,
    isLoadingPerson: content.isLoadingPerson,
    externalContent,
    externalPubsContent,
    hasError: content.error,
    hasHash,
    setShowPhoto: onSetShowPhoto,
    lang,
    ...props
  };

  return (
    <Fragment>
      {!content.isLoadingPerson && !pubsContent.loading && (
        <SEO title={content.data.name} description={content.data.name} />
      )}
      <Profile {...profileProps} />
    </Fragment>
  );
});

ProfileTemplate.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default compose(withRouter)(ProfileTemplate);
