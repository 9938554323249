import React, { useMemo } from "react";
import { Image, Link } from "@uaveiro/ui";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

const AccessibilityLogo = ({ location }) => {
  const imgProps = useMemo(() => {
    const { pathname } = location;
    // triple A on index
    if (pathname === "/")
      return {
        src: "/imgs/logos/logo_ta.jpg",
        alt: "Em conformidade com o nível 'triplo-A' das WCAG 2.0 do W3C",
        href: "https://www.w3.org/WAI/WCAG2AAA-Conformance.html"
      };

    // other pages double A
    return {
      src: "/imgs/logos/logo_da.jpg",
      alt: "Em conformidade com o nível 'duplo-A' das WCAG 2.0 do W3C",
      href: "https://www.w3.org/WAI/WCAG2AA-Conformance.html"
    };
  }, [location.pathname]);
  const { src, alt, href } = imgProps;
  return (
    <Link fontDecoration="none" href={href} target="_blank" native>
      <Image height="25px" src={src} alt={alt} />
    </Link>
  );
};

AccessibilityLogo.propTypes = {
  location: PropTypes.shape({}).isRequired
};

AccessibilityLogo.defaultProps = {
  location: {}
};

export default withRouter(AccessibilityLogo);
