import React from "react";
import { Timely } from '@uaveiro/ui';
import { withRouter } from "react-router-dom";
import { withTheme } from "styled-components";
import { injectIntl } from "react-intl";

const EventsTemplate = ({ match, ...props }) => {
  const { lang } = match.params;

  return  (
    <Timely {...props} identifier="2p89s104" background="#4D93FF" lang={lang}/>
  );
};

export default withRouter(withTheme(injectIntl(EventsTemplate)));
