import React, { memo, useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//Components
import { ProjectsList } from "../../../components/organisms";
//Utils
import { compose } from "../../../services/utilities";
//API
import { getProjectsList } from "../../../api/requests";
import { StoreContext } from "../../../context/StoreContext";
//Hooks
import { useQueryBuilder } from "../../../hooks";

const ProjectsListTemplate = memo(function ProjectsListTemplate({
  match,
  data,
  ...props
}) {
  const context = useContext(StoreContext);
  const [state, setState] = useState({
    loading: true,
    hasError: false,
    data: {},
    currentPage: 1
  });

  //Search input value state
  const [searchValue, setSearchValue] = useState("");

  //Filter value selected by the user state
  const [filterValue, setFilterValue] = useState({
    value: "",
    type: ""
  });

  //Dates selects value states
  const [dates, setDates] = useState({
    startDateValue: "",
    endDateValue: ""
  });

  //Filters data states
  const [filters, setFilters] = useState({
    loading: true,
    error: false,
    data: []
  });

  const [
    urlParams,
    setUrlParams,
    searchParams,
    remoteUrlParam
  ] = useQueryBuilder(match.url, props.history.location.search);

  const { lang } = match.params;

  const content = data[lang] || data;
  const hasContent =
    content && content.content && content.content.trim() !== "";
  const hasTitle = !!(content && content.title && content.title.trim() !== "");
  const defaultContent = data[lang] || data;
  const hasTitleDefault = !!(
    defaultContent &&
    defaultContent.title &&
    defaultContent.title.trim() !== ""
  );

  //Gets the filters from the API
  const onGetFilters = async () => {
    try {
      const res = await getProjectsList({ lang });

      setFilters(prevState => {
        return {
          ...prevState,
          loading: false,
          data: res.data.filter
        };
      });
    } catch (error) {
      setFilters(prevState => {
        return { ...prevState, loading: false, hasError: true };
      });
    }
  };

  //Gets the content from the API
  const onFetchContent = async ({
    search = "",
    recordsPerPage = 10,
    filter = "",
    currentPage = 1,
    startDate = "",
    endDate = "",
    filterType = "fundingProgram"
  }) => {
    try {
      const res = await getProjectsList({
        lang,
        recordsPerPage,
        search,
        filter,
        filterType,
        currentPage,
        startDate,
        endDate
      });

      setState(prevState => {
        return {
          ...prevState,
          data: res.data,
          filter: res.data.filter,
          loading: false
        };
      });
    } catch (error) {
      setState(prevState => {
        return { ...prevState, loading: false, hasError: true };
      });
    }
  };

  useEffect(() => {
    if (Object.keys(searchParams).length > 0) {
      onFetchContent(searchParams);
      setSearchValue(searchParams.search);
      setFilterValue(prevState => {
        return {
          ...prevState,
          value: searchParams.filter,
          type: searchParams.filterType
        };
      });
      setDates(prevState => {
        return {
          ...prevState,
          startDateValue: searchParams.startDate,
          endDateValue: searchParams.endDate
        };
      });
    } else {
      //Fetches the content
      onFetchContent({});
    }

    //Fetches and only updates the state with the filters, this makes less render and search is used
    onGetFilters();
  }, []);

  const onChangePage = page => {
    //Changes the page number in the state
    setState(prevState => {
      return { ...prevState, currentPage: page };
    });

    //Gets the new content based on the page number and previous state values
    onFetchContent({
      search: searchValue,
      filter: filterValue.value,
      currentPage: page
    });
  };

  const onClearSearch = () => {
    //Clear the state value of the state
    setSearchValue("");

    //Removes "search" param from url
    remoteUrlParam("search");

    //Fetches the new content without the value from the search value
    //Maintains the current state from the previous state
    onFetchContent({
      search: "",
      filter: filterValue.value,
      filterType: filterValue.type,
      startDate: dates.startDateValue,
      endDate: dates.endDateValue
    });
  };

  const onChangeSearchValue = e => {
    //Listens to new values on input change
    setSearchValue(e.target.value);
  };

  const onHandleSearch = () => {
    //Check for empty values and trigger new content fetch based on state value
    if (
      searchValue ||
      filterValue.value ||
      dates.startDateValue ||
      dates.endDateValue
    ) {
      onFetchContent({
        search: searchValue,
        filter: filterValue.value,
        filterType: filterValue.type,
        startDate: dates.startDateValue,
        endDate: dates.endDateValue
      });
      if (searchValue) {
        setUrlParams({
          ...urlParams,
          search: searchValue
        });
      }
    } else {
      //Clears the search
      onClearSearch();
      window.history.pushState(null, null, `${match.url}`);
    }
  };

  const onEnterSearch = e => {
    //empty search
    if (!searchValue) return;
    if (e.key === "Enter") {
      onHandleSearch();
    }
  };

  const onChangeFilter = (option, filterType = "fundingProgram") => {
    //Reset the content if the filter is empty or removed by the user
    if (option !== null) {
      setFilterValue(prevState => {
        return { ...prevState, value: option.value, type: filterType };
      });

      setUrlParams({
        ...urlParams,
        filterType: filterType,
        filter: option.value
      });
      return;
    }

    //Removes filter type param from url
    remoteUrlParam(["filterType", "filter"]);

    //Clear the filter on the state
    setFilterValue(prevState => {
      return { ...prevState, value: "", type: "" };
    });
    //Resets the content and gets the default content
    onFetchContent({});
  };

  const onChangeStartDate = value => {
    //Resets the value if removed by the user and gets the new content based on the previous state values
    if (value === null) {
      //Removes "startDate" type param from url
      remoteUrlParam("startDate");

      onFetchContent({
        search: searchValue,
        filter: filterValue.value,
        filterType: filterValue.type,
        startDate: "",
        endDate: dates.endDateValue
      });

      return;
    }
    //Update the state with the new date value
    setDates(prevState => {
      return { ...prevState, startDateValue: value };
    });

    setUrlParams({
      ...urlParams,
      startDate: value
    });
  };

  const onChangeEndDate = value => {
    //Resets the value if removed by the user and gets the new content based on the previous state values
    if (value === null) {
      //Removes "startDate" type param from url
      remoteUrlParam("endDate");

      onFetchContent({
        search: searchValue,
        filter: filterValue.value,
        filterType: filterValue.type,
        startDate: dates.startDateValue,
        endDate: ""
      });

      return;
    }
    //Update the state with the new date value
    setDates(prevState => {
      return { ...prevState, endDateValue: value };
    });
    setUrlParams({
      ...urlParams,
      endDate: value
    });
  };

  const onResetFilters = () => {
    //Resets the value state from search input
    setSearchValue("");
    //resets the filters state
    setFilterValue(prevState => {
      return { ...prevState, value: "", type: "" };
    });
    //Resets and clears the dates value from state
    setDates(prevState => {
      return { ...prevState, startDateValue: "", endDateValue: "" };
    });
    //Gets the default content
    onFetchContent({});
    //Gets and resets the default content for filters
    onGetFilters();
  };

  const projectsListProps = {
    hasTitleDefault,
    defaultContent,
    hasContent,
    hasTitle,
    lang,
    onChangePage,
    onChangeSearchValue,
    onHandleSearch,
    searchValue,
    onClearSearch,
    onChangeFilter,
    startDateValue: dates.startDateValue,
    onChangeStartDate,
    endDateValue: dates.endDateValue,
    onChangeEndDate,
    onResetFilters,
    activeColor: context.state.activeColor,
    onEnterSearch,
    ...props
  };

  return <ProjectsList {...state} {...projectsListProps} filters={filters} />;
});

ProjectsListTemplate.propTypes = {
  data: PropTypes.array,
  match: PropTypes.object
};

export default compose(withRouter)(ProjectsListTemplate);
