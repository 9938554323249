import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
//Components
import { Streaming } from "@uaveiro/ui";

const VideoTemplate = ({ match, ...props }) => {
  const {
    params: { lang }
  } = match;

  const videoProps = {
    lang,
    ...props
  };

  return <Streaming {...videoProps} />;
};

VideoTemplate.propTypes = {
  match: PropTypes.object
};

export default withRouter(VideoTemplate);
