import React, { useState, Fragment, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
//Styles
import styled, { withTheme } from "styled-components";
//Data
import data from "../../../../data/navigation/navigation";
//components
import { Link } from "./../../../atoms";
import {
  Icon,
  Image,
  Text,
  NavigationLoading,
  HorizontalList,
  ListItem,
  Error,
  Dropdown,
  DropdownList,
  ContentContainer,
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionTitle,
  AccordionItemBody,
  VerticalList
} from "@uaveiro/ui";

import { withSize } from "react-sizeme";
//Locales
import { compose } from "../../../../services/utilities";
import AccessibilitySelector from "../../../../containers/AccessibilitySelector";

const {
  topNavbar: { institute }
} = data;

const classes = {
  initial: "closed-menu",
  close: "close-menu",
  open: "open-menu"
};

const heightTransitionTime = "0.4s";

const LogoContainer = styled(Link)`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-decoration: none;
  margin-right: 30px;
  min-width: 125px;
  max-width: 240px;
  overflow: hidden;
  .logo-description {
    margin-top: 2px;

    &.filter {
      filter: invert(91%) sepia(11%) saturate(2651%) hue-rotate(355deg)
        brightness(105%) contrast(100%);
    }
  }
  &:hover {
    text-decoration: none;
    span {
      color: ${props => props.hoverColor};
    }
  }
`;

const MenuButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 32px;
  width: 24px;
  &:hover {
    i {
      color: ${props =>
        props.theme.components.subWebNavigation.menuIconColor}!important;
    }
  }
`;

const Navigation = ({
  accessibilityValue,
  activeColor,
  className,
  content,
  data,
  hasError,
  hasEmbeddedSearch,
  hideSubWebLogoDescription,
  isDropdownOpen,
  isFirefox,
  isWhite,
  lang,
  loading,
  location,
  onChangeAccessibilityFilter,
  onChangeZoom,
  onChangeLang,
  size,
  setDropdownOpen,
  searchToggle,
  theme,
  zoomValue,
  isExternal
}) => {
  const { initial, close, open } = classes;
  const [isOpen, setOpen] = useState(false);
  const [menuClass, setMenuClass] = useState(initial);
  const hideLogoDesc = useMemo(
    () => (content.logoType === "service" ? false : hideSubWebLogoDescription),
    [content.logoType, hideSubWebLogoDescription]
  );

  useEffect(() => {
    setOpen(false);
  }, []);

  const onHandleAnimation = () => {
    if (isOpen) {
      setMenuClass(close);
      setTimeout(() => {
        setOpen(false);
      }, 600);
      return;
    }
    setMenuClass(open);
    setOpen(true);
  };

  // close sidenav on route change
  useEffect(() => {
    setOpen(false);
    setMenuClass(initial);
  }, [location.pathname]);

  return (
    <div className={className}>
      {loading ? (
        <NavigationLoading
          backgroundColor={
            activeColor || theme.components.subWebNavigation.backgroundColor
          }
          linksGutter="40px"
          hasIcons={false}
          mobileMenuIconOnly={true}
          height="69px"
          mdHeight="69px"
        />
      ) : hasError ? (
        <Error />
      ) : (
        <Fragment>
          <ContentContainer
            backgroundColor={
              theme.highContrast.backgroundColor ||
              activeColor ||
              theme.components.subWebNavigation.backgroundColor
            }
            position="relative"
            zIndex="2"
            padding="10px 0"
          >
            <div
              className="container"
              onClick={size.width < 992 ? onHandleAnimation : null}
            >
              <div
                className="row no-gutters"

                /*
                 className={`row no-gutters ${
                  size.width < 992 || content.logoType !== "default"
                    ? "align-items-center"
                    : !hideLogoDesc
                    ? "align-items-start"
                    : "align-items-center"
                }`}
                */
              >
                <div className="col-xl-3 col-lg-auto col d-flex">
                  <LogoContainer
                    href={`/${lang}/${content.id}`}
                    aria-label={
                      lang === "pt"
                        ? "página de entrada de "
                        : "entry page of " + content.description
                    }
                    hoverColor={
                      isWhite
                        ? theme.colors.black
                        : theme.components.subWebNavigation.linksColor
                    }
                  >
                    {content.logoType !== "text" ? (
                      <Fragment>
                        {content.logoType !== "service" && (
                          <Image
                            src={content.imageBig}
                            objectFit="initial"
                            alt=" "
                            height="25px"
                          />
                        )}

                        {!hideLogoDesc && (
                          <Image
                            src={content.imageSmall}
                            objectFit="initial"
                            height="auto"
                            alt=" "
                            width="auto"
                            className={
                              content.logoType !== "service"
                                ? "logo-description"
                                : accessibilityValue === 1 && isExternal
                                ? "logo-description filter"
                                : ""
                            }
                          />
                        )}
                      </Fragment>
                    ) : (
                      <Text
                        as="span"
                        lineHeight="1.1"
                        fontWeight="500"
                        size="mediumSmall"
                        color={
                          isWhite ? theme.colors.black : theme.colors.white
                        }
                      >
                        {content.description}
                      </Text>
                    )}
                  </LogoContainer>
                </div>
                <div className="col-xl col-lg col-auto">
                  {size.width <= 949 ? (
                    isExternal ? (
                      <div className="col-auto mobile-icons-container">
                        <HorizontalList className="d-flex align-items-center">
                          <AccessibilitySelector
                            accessibilityValue={accessibilityValue}
                            isDropdownOpen={isDropdownOpen}
                            setDropdownOpen={setDropdownOpen}
                            isFirefox={isFirefox}
                            onChangeAccessibilityFilter={
                              onChangeAccessibilityFilter
                            }
                            onChangeLang={onChangeLang}
                            onChangeZoom={onChangeZoom}
                            zoomValue={zoomValue}
                            lang={lang}
                            theme={theme}
                            hasEmbeddedSearch={hasEmbeddedSearch}
                            searchToggle={searchToggle}
                            isSearchable={false}
                            isInternal={!isExternal}
                          />
                          <MenuButton
                            aria-label={isOpen ? "Fechar menu" : "Abrir menu"}
                            onClick={onHandleAnimation}
                          >
                            <Icon
                              name="fal fa-ellipsis-v"
                              color={
                                isWhite && accessibilityValue !== 1
                                  ? "#000"
                                  : theme.components.subWebNavigation
                                      .menuIconColor
                              }
                              size="large"
                            />
                          </MenuButton>
                        </HorizontalList>
                      </div>
                    ) : (
                      <MenuButton
                        aria-label={isOpen ? "Fechar menu" : "Abrir menu"}
                        onClick={onHandleAnimation}
                      >
                        <Icon
                          name="fal fa-ellipsis-v"
                          color={
                            isWhite && accessibilityValue !== 1
                              ? "#000"
                              : theme.components.subWebNavigation.menuIconColor
                          }
                          size="large"
                        />
                      </MenuButton>
                    )
                  ) : (
                    <ContentContainer
                      height={
                        !hideLogoDesc && content.logoType === "default"
                          ? "25px"
                          : "auto"
                      }
                      display="flex"
                      alignItems="flex-end"
                    >
                      <HorizontalList display="inline-block">
                        {data.length > 0 &&
                          data
                            .filter((_, index) => index + 1 <= 6)
                            .map((row, i) => {
                              let emptyLink = !row.url;
                              let isLastChild = i + 1 === data.length;
                              let isExternalLink = row.url.includes("http");

                              return (
                                <ListItem
                                  marginRight={!isLastChild && "40px"}
                                  key={i}
                                >
                                  <Dropdown
                                    paddingBottom="20px"
                                    marginTop="15px"
                                    withUnderlineAnimation={!emptyLink}
                                    onMouseEnter={() => setDropdownOpen(true)}
                                    onMouseLeave={() => setDropdownOpen(false)}
                                    onClick={() => setDropdownOpen(false)}
                                  >
                                    <Link
                                      as={emptyLink && "span"}
                                      textTransform="lowercase"
                                      href={
                                        isExternalLink
                                          ? row.url
                                          : `/${lang}${row.url}`
                                      }
                                      fontWeight="300"
                                      fontDecoration="none"
                                      fontSize="16px"
                                      cursor={emptyLink ? "default" : "pointer"}
                                      color={
                                        isWhite && accessibilityValue !== 1
                                          ? theme.colors.black
                                          : theme.components.subWebNavigation
                                              .linksColor
                                      }
                                      lineHeight={
                                        content.logoType !== "text"
                                          ? "1"
                                          : "1.5"
                                      }
                                      tabIndex="0"
                                      onFocus={() => setDropdownOpen(true)}
                                      native={isExternalLink}
                                    >
                                      {row.title}
                                    </Link>

                                    {row.links.length > 0 && isDropdownOpen && (
                                      <DropdownList
                                        fontWeight="400"
                                        hasMaxHeight={false}
                                      >
                                        {row.links.map((child, i) => {
                                          const external =
                                            child.url !== undefined
                                              ? child.url.includes("http") ||
                                                child.url.includes("file")
                                              : false;
                                          const broken =
                                            child.url !== undefined
                                              ? !child.url.includes("/")
                                              : false;

                                          const hasAsp =
                                            child.url !== undefined
                                              ? child.url.includes("aspx")
                                              : false;

                                          return (
                                            <Link
                                              textTransform="lowercase"
                                              style={{
                                                color: `${
                                                  broken
                                                    ? "red"
                                                    : external
                                                    ? ""
                                                    : hasAsp
                                                    ? "orange"
                                                    : ""
                                                }`
                                              }}
                                              key={i}
                                              href={
                                                external
                                                  ? child.url
                                                  : `/${lang}${child.url}`
                                              }
                                              fontWeight="300"
                                              fontDecoration="none"
                                              onClick={() =>
                                                setDropdownOpen(false)
                                              }
                                              native={external}
                                            >
                                              {child.text}
                                            </Link>
                                          );
                                        })}
                                      </DropdownList>
                                    )}
                                  </Dropdown>
                                </ListItem>
                              );
                            })}
                      </HorizontalList>
                      {isExternal ? (
                        <div className="col-auto icons-container">
                          <HorizontalList display="inline-block">
                            <AccessibilitySelector
                              accessibilityValue={accessibilityValue}
                              isDropdownOpen={isDropdownOpen}
                              setDropdownOpen={setDropdownOpen}
                              isFirefox={isFirefox}
                              onChangeAccessibilityFilter={
                                onChangeAccessibilityFilter
                              }
                              onChangeLang={onChangeLang}
                              onChangeZoom={onChangeZoom}
                              zoomValue={zoomValue}
                              lang={lang}
                              theme={theme}
                              hasEmbeddedSearch={hasEmbeddedSearch}
                              searchToggle={searchToggle}
                              isSearchable={false}
                              isInternal={!isExternal}
                            />
                          </HorizontalList>
                        </div>
                      ) : null}
                    </ContentContainer>
                  )}
                </div>
              </div>
            </div>
          </ContentContainer>

          {size.width <= 991 && (
            <div className={`menu-list ${menuClass}`}>
              {isOpen && (
                <ContentContainer
                  padding="20px 0"
                  backgroundColor={
                    theme.highContrast.backgroundColor ||
                    activeColor ||
                    theme.components.subWebNavigation.backgroundColor
                  }
                >
                  <div className="container">
                    <Accordion>
                      {data.length > 0 &&
                        data.map((row, i) => {
                          let emptyLink = !row.url;
                          return (
                            <AccordionItem key={i}>
                              <AccordionItemTitle>
                                <AccordionTitle
                                  hasLinks={row.links.length > 0 && true}
                                  accordionTitleHasLink={!emptyLink}
                                  color={
                                    isWhite && accessibilityValue !== 1
                                      ? theme.colors.black
                                      : theme.components.subWebNavigation
                                          .linksColor
                                  }
                                  baseLinkUrl={`/${lang}${row.url}`}
                                >
                                  {row.title}
                                </AccordionTitle>
                              </AccordionItemTitle>
                              {row.links.length > 0 && (
                                <AccordionItemBody>
                                  <VerticalList>
                                    {row.links.map((child, i) => {
                                      const external =
                                        child.url !== undefined
                                          ? child.url.includes("http")
                                          : false;
                                      const broken =
                                        child.url !== undefined
                                          ? !child.url.includes("/")
                                          : false;

                                      const hasAsp =
                                        child.url !== undefined
                                          ? child.url.includes("aspx")
                                          : false;

                                      return (
                                        <ListItem>
                                          <Link
                                            fontDecoration="none"
                                            display="block"
                                            padding="6px 0"
                                            lighten
                                            color={
                                              isWhite &&
                                              accessibilityValue !== 1
                                                ? theme.colors.black
                                                : theme.components
                                                    .subWebNavigation.linksColor
                                            }
                                            native={external}
                                            style={{
                                              color: `${
                                                broken
                                                  ? "red"
                                                  : external
                                                  ? ""
                                                  : hasAsp
                                                  ? "orange"
                                                  : ""
                                              }`
                                            }}
                                            key={i}
                                            href={
                                              external
                                                ? child.url
                                                : `/${lang}${child.url}`
                                            }
                                          >
                                            {child.text}
                                          </Link>
                                        </ListItem>
                                      );
                                    })}
                                  </VerticalList>
                                </AccordionItemBody>
                              )}
                            </AccordionItem>
                          );
                        })}
                    </Accordion>
                  </div>
                </ContentContainer>
              )}
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};

Navigation.propTypes = {
  accessibilityValue: PropTypes.number.isRequired,
  activeColor: PropTypes.string,
  className: PropTypes.string,
  content: PropTypes.object,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  hasError: PropTypes.bool,
  hasEmbeddedSearch: PropTypes.bool,
  isDropdownOpen: PropTypes.bool,
  isFirefox: PropTypes.bool,
  lang: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  location: PropTypes.object,
  onChangeZoom: PropTypes.func.isRequired,
  onChangeAccessibilityFilter: PropTypes.func.isRequired,
  onChangeLang: PropTypes.func.isRequired,
  size: PropTypes.object,
  searchToggle: PropTypes.func,
  setDropdownOpen: PropTypes.func.isRequired,
  theme: PropTypes.object,
  zoomValue: PropTypes.string.isRequired,
  isWhite: PropTypes.bool
};

Navigation.defaultProps = {
  loading: false,
  lang: "pt",
  hasEmbeddedSearch: true
};

const NavigationWithStyles = styled(Navigation)`
  background-color: ${props =>
    props.activeColor ||
    props.theme.components.subWebNavigation.backgroundColor};
  z-index: 98;
  ${props =>
    props.isExternal &&
    `position: sticky;
  top: 0;`};

.mobile-icons-container{
  vertical-align: top;
}
  // Medium devices (tablets, less than 992px)
  @media (max-width: 991px) {
    /*
    -- Menu List
    */
    .menu-list {
      z-index: 1;
      overflow-y: scroll;
      position: absolute;
      //Get the Navigation Height with sizeMe
      //top: ${props => props.size.height + "px"};
      width: 100%;
      background-color: ${props =>
        props.activeColor ||
        props.theme.components.subWebNavigation.backgroundColor};
      //Accordion Styles
      .accordion__item {
        border: none;
        .accordion__arrow {
          &:before,
          &:after {
            background-color: ${props =>
              props.theme.components.subWebNavigation.menuIconColor};
          }
        }
        &:last-child {
          .accordion__title {
            margin-bottom: 0;
          }
        }
        .accordion__body {
          li:first-child {
            a {
              padding-top: 0;
            }
          }
        }
      }
      // Add Shadow on SubWeb Menu List
      box-shadow: ${props => props.theme.components.subWebNavigation.boxShadow};
      -moz-box-shadow: ${props =>
        props.theme.components.subWebNavigation.boxShadow};
      -webkit-box-shadow: ${props =>
        props.theme.components.subWebNavigation.boxShadow};
      -o-box-shadow: ${props =>
        props.theme.components.subWebNavigation.boxShadow};
    }

    /*Initial state*/
    .closed-menu {
      max-height: 0;
      height: 0;
      visibility: hidden;
    }

    /*Animation*/
    .open-menu {
      transition: max-height ${heightTransitionTime} ease-in;
      visibility: visible;
      max-height: 400px;
      .accordion__item {
        -webkit-animation: fade-in-swn-items 0.6s ease-in;
        -moz-animation: fade-in-swn-items 0.6s ease-in;
        animation: fade-in-swn-items 0.6s ease-in;
      }
    }

    .close-menu {
      transition: all ${heightTransitionTime} ease-out;
      visibility: hidden;
      max-height: 0;
      .accordion__item {
        -webkit-animation: fade-out-swn-items 0.2s ease-out forwards;
        -moz-animation: fade-out-swn-items 0.2s ease-out forwards;
        animation: fade-out-swn-items 0.2s ease-out forwards;
      }
    }

    /*Fade-in Fade-out*/
    @keyframes fade-out-swn-items {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

    @keyframes fade-in-swn-items {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  // Add Shadow on SubWeb Navigation After 40px scroll
  ${props =>
    props.subWebNavigationShadow &&
    `
    box-shadow:
    ${props.theme.components.journalNavigation.boxShadow};
    -moz-box-shadow:
    ${props.theme.components.journalNavigation.boxShadow};
    -webkit-box-shadow:
    ${props.theme.components.journalNavigation.boxShadow};
    -o-box-shadow:
    ${props.theme.components.journalNavigation.boxShadow};
  `}
`;

NavigationWithStyles.defaultProps = {
  hasHighContrastBorderBottom: true,
  subWebNavigationShadow: false
};

export default compose(
  withSize({ monitorHeight: true }),
  withTheme
)(NavigationWithStyles);
