import EducationRoutes from "./EducationRoutes";
import CoursesRoutes from "./CoursesRoutes";
import PersonRoutes from "./PersonRoutes";
import DepartmentRoutes from "./DepartmentRoutes";
import ResearchRoutes from "./ResearchRoutes";
import InternalRoutes from "./InternalRoutes";
import GlobalRoutes from "./GlobalRoutes";
import DevRoutes from "./DevRoutes";

const Routes = {
  routes: [
    ...EducationRoutes.routes,
    ...CoursesRoutes.routes,
    ...PersonRoutes.routes,
    ...DepartmentRoutes.routes,
    ...ResearchRoutes.routes,
    ...InternalRoutes.routes,
    ...GlobalRoutes.routes
  ]
};

// add dev routes such as markdown documentation for development environment
if (process.env.NODE_ENV === "development")
  Routes.routes = [...Routes.routes, ...DevRoutes.routes];

export default Routes;
