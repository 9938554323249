import React, { Fragment } from "react";
import PropTypes from "prop-types";
//Components
import {
  Section,
  SectionHeader,
  AgendaCard,
  Error,
  ScreenReaderText,
  Text
} from "@uaveiro/ui";
import theme from "../../../../services/theme.semantic";
import moment from "moment";
import Loading from "./Loading";

const Related = ({
  className,
  backgroundColor,
  titleProps,
  sectionTitle,
  data,
  loading,
  hasError
}) => {
  const isSameDay = moment(data.startDate).isSame(data.endDate, "day");
  return (
    <Section
      className={className}
      backgroundColor={backgroundColor}
      style={{ position: "relative" }}
    >
      <ScreenReaderText>
        <Text as="h2" text="Relacionados" />
      </ScreenReaderText>
      <div className="container">
        {loading ? (
          <Loading />
        ) : hasError ? (
          <Error />
        ) : (
          <Fragment>
            <SectionHeader
              hasContainer={false}
              textColor="#000"
              {...titleProps}
              title={sectionTitle}
            />
            <div className="row">
              <div className="col-xl-6 col-lg-6 col-12">
                <AgendaCard
                  category={data.category}
                  title={data.title}
                  titleColor="#fff"
                  hasText={false}
                  textColorHover={backgroundColor}
                  href={data.url}
                  iconColor="#fff"
                  iconColorHover={backgroundColor}
                  date={
                    isSameDay
                      ? `${moment(data.startDate).format("DD MMMM YYYY")}`
                      : `${moment(data.startDate).format("DD MMMM")} - ${moment(
                          data.endDate
                        ).format("DD MMMM YYYY")}`
                  }
                  hasDateSlider={!isSameDay}
                >
                  {!isSameDay ? (
                    <Fragment>
                      {/* Se tiver slider */}
                      <div className="initial-date">
                        <Text
                          fontSize="28px"
                          text={moment(data.startDate, "YYYY/MM/DD").format(
                            "DD"
                          )}
                          color="#fff"
                          fontWeight="900"
                          lineHeight="1"
                        />
                        <Text
                          fontSize="18px"
                          text={moment(data.startDate, "YYYY/MM/DD").format(
                            "MMM"
                          )}
                          color="#fff"
                          textTransform="uppercase"
                          lineHeight="1"
                        />
                      </div>
                      <div className="today">
                        <Text
                          fontSize="28px"
                          text={moment(new Date(), "YYYY/MM/DD").format("DD")}
                          color="#fff"
                          fontWeight="900"
                          lineHeight="1"
                        />
                        <Text
                          fontSize="18px"
                          text={moment(new Date(), "YYYY/MM/DD").format("MMM")}
                          color="#fff"
                          textTransform="uppercase"
                          lineHeight="1"
                        />
                      </div>
                      <div className="end-date">
                        <Text
                          fontSize="28px"
                          text={moment(data.endDate, "YYYY/MM/DD").format("DD")}
                          color="#fff"
                          fontWeight="900"
                          lineHeight="1"
                        />
                        <Text
                          fontSize="18px"
                          text={moment(data.endDate, "YYYY/MM/DD").format(
                            "MMM"
                          )}
                          color="#fff"
                          textTransform="uppercase"
                          lineHeight="1"
                        />
                      </div>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div className="no-slider">
                        <Text
                          fontSize="28px"
                          text={moment(data.startDate, "YYYY/MM/DD").format(
                            "DD"
                          )}
                          color="#fff"
                          fontWeight="900"
                          lineHeight="1"
                        />
                        <Text
                          fontSize="18px"
                          text={moment(data.startDate, "YYYY/MM/DD").format(
                            "MMM"
                          )}
                          color="#fff"
                          textTransform="uppercase"
                          lineHeight="1"
                        />
                      </div>
                    </Fragment>
                  )}
                </AgendaCard>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </Section>
  );
};

Related.propTypes = {
  title: PropTypes.string,
  sectionTitle: PropTypes.string,
  className: PropTypes.string,
  backgroundColor: PropTypes.string,
  titleProps: PropTypes.object,
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  hasError: PropTypes.bool
};

Related.defaultProps = {
  backgroundColor: theme.components.journalRelated.backgroundColor
};

export default Related;
