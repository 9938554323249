import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//Components
import { SubWebCourses } from "@uaveiro/ui";
//API
import { getCourseTypes } from "../../api/requests";
//Context
import { StoreContext } from "../../context/StoreContext";

const CoursesTemplate = ({ match, data, isLocal, ...props }) => {
  const context = useContext(StoreContext);
  const [state, setState] = useState({
    isLoading: true,
    hasError: false,
    data: []
  });

  const { id: department } = context.state.subWebNav;

  const {
    params: { lang }
  } = match;

  const getData = async () => {
    try {
      const res = await getCourseTypes({
        lang,
        history: props.history,
        department
      });

      setState(prevState => {
        return {
          ...prevState,
          data: res.data.list,
          isLoading: false
        };
      });
    } catch (error) {
      setState(prevState => {
        return { ...prevState, isLoading: false, hasError: true };
      });
    }
  };

  useEffect(() => {
    if (isLocal && data !== undefined && data.length > 0) {
      return setState(prevState => {
        return {
          ...prevState,
          data,
          isLoading: false
        };
      });
    }
    getData();
  }, [department]);

  const coursesProps = {
    lang,
    activeColor: context.state.activeColor,
    href: `/${lang}/${department}/cursos`,
    ...state,
    ...props
  };

  return <SubWebCourses {...coursesProps} />;
};

CoursesTemplate.defaultProps = {
  isLocal: false
};

CoursesTemplate.propTypes = {
  match: PropTypes.object,
  isLocal: PropTypes.string
};

export default withRouter(CoursesTemplate);
