//Containers
import LocalSelector from "../containers/LocalSelector";
import { views } from "./../services/constants";

const { education } = views;

const PersonRoutes = {
  routes: [
    {
      path: ["/p/:id", "/p/:id"],
      component: LocalSelector,
      props: {
        activeView: education,
        document: "profile",
        sideNav: false
      }
    }
  ]
};

export default PersonRoutes;
