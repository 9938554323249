import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//Components
import { JournalMoreNews } from "@uaveiro/ui";
//Hocs
import {
  compose,
  facebookUrl,
  twitterUrl,
  linkedinUrl
} from "../../services/utilities";
import { baseUrl } from "../../services/constants";
//API
import { getNewsRecentAndPopular } from "../../api/requests";

function JournalMoreNewsTemplate({ match, ...props }) {
  const [state, setState] = useState({
    loading: true,
    error: false,
    data: {}
  });

  const { lang } = match.params;

  const onAddUrl = data => {
    const route = lang === "pt" ? "noticias" : "news";
    const popular = data.popular.listNews.map(item => {
      let url = `${lang}/${route}/${item.categoryID}/${item.id}`;
      return {
        ...item,
        categoryUrl: `/${lang}/${route}/${item.categoryID}`,
        url: `/${url}`,
        share: {
          facebook: facebookUrl(`${baseUrl}/${url}`),
          twitter: twitterUrl(`${baseUrl}/${url}`, item.title),
          linkedin: linkedinUrl(`${baseUrl}/${url}`),
          url: `${baseUrl}/${url}`
        }
      };
    });

    const recent = data.recent.listNews.map(item => {
      let url = `${lang}/${route}/${item.categoryID}/${item.id}`;
      return {
        ...item,
        categoryUrl: `/${lang}/${route}/${item.categoryID}`,
        url: `/${url}`,
        share: {
          facebook: facebookUrl(`${baseUrl}/${url}`),
          twitter: twitterUrl(`${baseUrl}/${url}`, item.title),
          linkedin: linkedinUrl(`${baseUrl}/${url}`),
          url: `${baseUrl}/${url}`
        }
      };
    });

    return {
      popular: {
        listNews: popular,
        pagination: data.popular.pagination
      },
      recent: {
        listNews: recent,
        pagination: data.recent.pagination
      }
    };
  };

  const onFetchData = async () => {
    try {
      //const categories = categoryId !== undefined ? Number(categoryId) : null;
      const res = await getNewsRecentAndPopular({
        lang,
        categories: 0,
        history: props.history
      });

      const data = onAddUrl(res.data);

      setState(prevState => {
        return { ...prevState, data, loading: false };
      });
    } catch (error) {
      setState(prevState => {
        return { ...prevState, loading: false, error: true };
      });
    }
  };

  useEffect(() => {
    onFetchData();
  }, []);

  const openInNewTab = (e, url) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(url, "_blank");
  };

  const onHandleEnter = (e, url) => {
    if (e.key === "Enter") {
      props.history.push(url);
    }
  };

  const NewsProps = {
    onAction: openInNewTab,
    onEnter: onHandleEnter,
    ...props
  };

  return (
    <JournalMoreNews
      data={state.data}
      loading={state.loading}
      hasError={state.error}
      {...NewsProps}
    />
  );
}

JournalMoreNewsTemplate.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default compose(withRouter)(JournalMoreNewsTemplate);
