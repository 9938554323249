import React from "react";
//Context
import { StoreContext } from "../context/StoreContext";

export default function withContext(Component) {
  return function WrapperComponent(props) {
    return (
      <StoreContext.Consumer>
        {state => <Component {...props} context={state} />}
      </StoreContext.Consumer>
    );
  };
}
