import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
//Components
import { JournalListing } from "@uaveiro/ui";
//Hocs
import {
  compose,
  getParameterByName,
  facebookUrl,
  twitterUrl,
  linkedinUrl
} from "../../services/utilities";
import { hasHash, baseUrl } from "../../services/constants";
//API
import { getNewsDossierList } from "../../api/requests";
//Context
import { ContentContext } from "../../context/ContentContext";

function JournalDossiersDetails({ match, ...props }) {
  const [state, setState] = useState({
    id: -1,
    loading: true,
    error: false,
    nextPageToken: "",
    data: {
      categoryTitle: "",
      listNews: [],
      pagination: {
        totalResults: 0       
      }
    }
  });
  const [refetch, setrefetch] = useState(false);
  const context = useContext(ContentContext);
  const { lang, dossierId } = match.params;
  const id = dossierId !== undefined ? Number(dossierId) : 1;
  let resDossie = undefined;

  const onAddUrl = (data) => {
        let items = undefined;
        const route = lang === "pt" ? "noticias" : "news";
        const routeDossier = lang === "pt" ? "dossie" : "dossier";
        items = data.news.map(item => {
        let url = `${lang}/${route}/${routeDossier}/${item.id}`;
        return {
            ...item,
            url: item.url,
            // url: `/${url}`,
            categoryUrl: `/${lang}/${route}/${item.categoryId}`,
            share: {
                facebook: facebookUrl(`${baseUrl}${hasHash}/${url}`),
                twitter: twitterUrl(`${baseUrl}${hasHash}/${url}`, item.title),
                linkedin: linkedinUrl(`${baseUrl}${hasHash}/${url}`),
                url: `${baseUrl}${hasHash}/${url}`
            }
        };
    });
    return {
      listNews: items,
      pagination: data.pagination
    };
  };

  const onFetchData = async (
    nextPageToken = "",
    hasDelay = true,
    recordsPerPage = 8
  ) => {
    try {
      const isTag = Boolean(getParameterByName("tag")); //?tag=true
      const name = getParameterByName("n");
      let res = await getNewsDossierList({
        lang,
        name,
        id: isTag ? `tag=${id}` : id,
        nextPageToken,
        recordsPerPage
      })

      if(res.data === null){
        setrefetch(true)
        const routeaux = lang === "pt" ? "en" : "pt";
        console.log('routeaux', routeaux)
        resDossie = await getNewsDossierList({
          lang:routeaux,
          name,
          id: isTag ? `tag=${id}` : id,
          nextPageToken,
          recordsPerPage
        })
        res = resDossie;
      }

      const data = onAddUrl(res.data);
      const categoryTitle = res.data.dossier.title;

      setState(prevState => {
        return {
          ...prevState,
          id,
          data: {
            ...res.data,
            categoryTitle,
            listNews: [...prevState.data.listNews, ...data.listNews]
          },
          totalResults: res.data.pagination.totalResults,
          nextPageToken: res.data.pagination.nextPageToken,
          loading: false
        };
      });
    } catch (error) {
      setState(prevState => {
        return { ...prevState, loading: false, error: true };
      });
    }
  };

  useEffect(() => {
    //The State context is not empty and has the same id, get news from state
    //Checks if the browser action is back
    //Checks the current data on the Store and its is the same id
    if (
      props.history.action === "POP" &&
      Object.keys(context.state.journalList.data.listNews).length > 0 &&
      context.state.journalList.id === id
    ) {
      //Loads the content from Store
      setState(prevState => {
        return {
          ...prevState,
          ...context.state.journalList
        };
      });

      return;
    }
    //If the state navigation is new reset the data from the Store
    if (props.history.action === "PUSH" && !state.loading) {
      //Clears the store, resets the journal navigation fetch
      context.dispatch({
        type: "update-journal-list",
        payload: {
          id: -1,
          loading: true,
          error: false,
          currentPage: 1,
          totalPages: null,
          data: {
            categoryTitle: "",
            listNews: []
          }
        }
      });
    }

    onFetchData();
  }, []);

  const openInNewTab = (e, url) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(url, "_blank");
  };

  const onHandleEnter = (e, url) => {
    //Updates the context state with current list
    context.dispatch({
      type: "update-journal-list",
      payload: {
        ...state
      }
    });

    if (e.key === "Enter") {
      openInNewTab(e, url);
    }
  };

  const onloadMore = () => {
    const { nextPageToken } = state;
    if (nextPageToken!== null) {
      onFetchData(nextPageToken, false, 6);
    }
  };

  const NewsProps = {
    onAction: openInNewTab,
    onEnter: onHandleEnter,
    onLoad: onloadMore,
    currentPage: state.currentPage,
    totalPages: state.totalPages,
    dossierId,
    ...props
  };

  return (
      <JournalListing
        data={state.data}
        loading={state.loading}
        hasError={state.error}
        translation={refetch}
        {...NewsProps}
      />
  );
}

JournalDossiersDetails.propTypes = {
  match: PropTypes.object.isRequired
};

const JournalDossiersDetailsWithStyles = styled(JournalDossiersDetails)`
  .teste {
      background-color: green;
    }
`

export default compose(withRouter)(JournalDossiersDetailsWithStyles);
