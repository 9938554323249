export default [
  {
    name: "SEO",
    title: {
      pt: "Jornal",
      en: "Journal"
    },
    description: "Jornal da Universidade de Aveiro. Um jornal que pensa.",
    keywords: ["jornal", "journal", "notícias", "news"]
  },
  {
    name: "JournalListing"
  },
  {
    name: "JournalMoreNews",
    sectionTitle: "Mais lidas",
    rightSectionTitle: "Últimas",
    backgroundColor: "#C7C9CB",
    resource: "app.journal.news.mostRead.title",
    rightResource: "app.journal.news.latest.title"
  }
];
