import React, { Fragment, useEffect, useState, memo } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//Components
import Loading from "./Loading";
import { Link } from "../../components/atoms";
import { Text, Section, ContentContainer, Error } from "@uaveiro/ui";
//Utils
import { portalAPI } from "../../services/constants";
import { compose } from "../../services/utilities";
//Locales
import { FormattedMessage } from "react-intl";
import { withTheme } from "styled-components";

const Directory = memo(function Directory({
  className,
  backgroundColor,
  endpoint,
  match,
  history,
  theme
}) {
  const [isLoading, setLoading] = useState(true);
  const [hasError, setError] = useState(false);
  const [content, setContent] = useState({});
  const { lang } = match.params;

  const onFetchData = async (search = "") => {
    try {
      const searchQuery = search && `&searchString=${search}`;
      const res = await fetch(
        `${portalAPI}${endpoint}?language=${lang}${searchQuery}`
      );
      const content = await res.json();

      setContent(content);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    setLoading(true);
    const search = history.location.search.split("=")[1];
    onFetchData(search);
  }, [history, match]);

  return (
    <Section
      as="div"
      className={className}
      backgroundColor={
        theme.components.globalSearch.backgroundColor || backgroundColor
      }
      hasHighContrastBorderTop={false}
      paddingTop="10px"
      mdPaddingTop="10px"
    >
      <div className="container">
        {isLoading ? (
          <Loading color="#000" />
        ) : hasError ? (
          <Error />
        ) : (
          <Fragment>
            <div className="row">
              <div className="col-12">
                {Object.keys(content.data).length <= 0 ||
                content.data === null ? (
                  <div>
                    <br />
                    <br />
                    <p>
                      <FormattedMessage
                        id="app.search.empty"
                        defaultMessage="Sem conteúdos para a pesquisa"
                      />
                    </p>
                  </div>
                ) : (
                  <Fragment>
                    <Text marginBottom="30px">
                      {`Foram encontrados ${
                        Object.keys(content.data).length
                      } resultados`}
                    </Text>

                    {content.data.map((value, index) => {
                      return (
                        <ContentContainer key={index} marginBottom="30px">
                          <Link
                            as={!value.hasLink && "p"}
                            text={value.name}
                            aria-label={value.name}
                            href={value.link}
                            color={
                              theme.components.globalSearch.places.linkColor
                            }
                            hoverColor={
                              theme.components.globalSearch.places
                                .linkHoverColor
                            }
                            fontSize="16px"
                            fontWeight="400"
                            textDecoration="underline"
                            lineHeight="1"
                            native={value.hasLink}
                          />
                          <Text
                            text={`${value.locationDescription}`}
                            fontSize="14px"
                            color={
                              theme.components.globalSearch.places.textColor
                            }
                            fontWeight="300"
                            lineHeight="1.6"
                          />

                          <Text
                            fontSize="14px"
                            color={
                              theme.components.globalSearch.places.textColor
                            }
                            fontWeight="300"
                            lineHeight="1.6"
                          >
                            {`${value.number != null ? value.number : ""}
                             ${
                               value.number != null && value.email != null
                                 ? "|"
                                 : ""
                             }
                            ${value.email != null ? value.email : ""}`}
                          </Text>
                          <Link
                            href={`https://maps.google.com/maps?q=loc:${value.coordenates}`}
                            target="_blank"
                            fontSize="14px"
                            color={
                              theme.components.globalSearch.places.mapLinkColor
                            }
                            fontWeight="300"
                            lineHeight="1.4"
                            native
                          >
                            <FormattedMessage
                              id="app.search.map"
                              defaultMessage="ver no mapa"
                            />
                          </Link>
                        </ContentContainer>
                      );
                    })}
                  </Fragment>
                )}
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </Section>
  );
});

Directory.propTypes = {
  className: PropTypes.string,
  backgroundColor: PropTypes.string,
  localFetch: PropTypes.func.isRequired,
  endpoint: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default compose(withTheme, withRouter)(Directory);
