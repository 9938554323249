import React, { Fragment, memo, useState } from "react";
import PropTypes from "prop-types";
//Components
import Loading from "./Loading";
import { Link } from "../../atoms";
import {
  ContentContainer,
  HorizontalList,
  ListItem,
  Error,
  ClickAwayListener,
  Pagination,
  Text
} from "@uaveiro/ui";
//Locales
import { FormattedMessage } from "react-intl";
import { injectIntl } from "react-intl";
import { withTheme } from "styled-components";

const Directory = memo(function Directory({
  className,
  backgroundColor,
  hasHash,
  isLoading,
  hasError,
  content,
  onChangeLetter,
  onChangePage,
  alphabet,
  letter,
  lang,
  currentPage,
  intl,
  theme
}) {
  const [alphabetNavigation, setAlphabetNavigation] = useState(0);
  const onHandleAlphabetNavigation = e => {
    if (e.key === "Enter") {
      setAlphabetNavigation(1);
    }
  };
  const onHandleTabNavigation = e => {
    if (e.shiftKey && e.keyCode === 9) {
      setAlphabetNavigation(alphabetNavigation - 1);
    } else if (e.key === "Tab") {
      setAlphabetNavigation(alphabetNavigation + 1);
    }
  };
  return (
    <ContentContainer
      className={className}
      backgroundColor={theme.highContrast.backgroundColor || backgroundColor}
    >
      <div className="container">
        {!hasError && (
          <Text marginBottom="30px">{`Foram encontrados ${
            isLoading ? 0 : content.pagination.total
          } resultados`}</Text>
        )}

        <ClickAwayListener onChange={() => setAlphabetNavigation(0)}>
          <ContentContainer
            display="inline-block"
            marginBottom="20px"
            boxShadow={
              alphabetNavigation !== 0 && alphabetNavigation <= 26
                ? theme.components.directory.alphabetBoxSelectedShadow
                : backgroundColor
            }
            tabIndex="0"
            aria-label={intl.formatMessage({
              id: "app.journal.directory.navigate.alphabet",
              defaultMessage: "Clicar no enter"
            })}
            onKeyPress={onHandleAlphabetNavigation}
          >
            <HorizontalList display="inline-block">
              {alphabet.map((value, index) => {
                return (
                  <ListItem key={index} marginRight="2px">
                    <Link
                      text={value}
                      tabIndex={
                        alphabetNavigation !== 0 && alphabetNavigation <= 26
                          ? "0"
                          : "-1"
                      }
                      aria-label={value}
                      color={
                        value === letter
                          ? theme.components.directory.alphabetActiveLetter
                          : theme.components.directory.alphabetColor
                      }
                      onClick={e => onChangeLetter(e, value)}
                      onKeyDown={onHandleTabNavigation}
                      lineHeight="1.4"
                      display="inherit"
                      padding="5px"
                      lighten
                    />
                  </ListItem>
                );
              })}
            </HorizontalList>
          </ContentContainer>
        </ClickAwayListener>

        {isLoading ? (
          <Loading color="#000" />
        ) : hasError ? (
          <Error />
        ) : (
          <Fragment>
            {content.data && Object.keys(content.data).length <= 0 ? (
              <div>
                <br />
                <br />
                <p>
                  <FormattedMessage
                    id="app.search.empty"
                    defaultMessage="Sem conteúdos para a pesquisa"
                  />
                </p>
              </div>
            ) : (
              <Fragment>
                {/*
                <Text marginBottom="30px">
                  {`Foram encontrados ${content.pagination.total} resultados`}
                </Text>

                */}

                {content.data &&
                  content.data.map(value => {
                    return (
                      <ContentContainer key={value.id} marginTop="30px">
                        <Link
                          text={value.name}
                          aria-label={value.name}
                          href={`${hasHash}/${lang}/p/${value.id}`}
                          color={theme.components.directory.linkColor}
                          hoverColor={theme.components.directory.linkHoverColor}
                          fontSize="16px"
                          fontWeight="400"
                          textDecoration="underline"
                          lineHeight="1"
                        />
                        <Text
                          text={value.unitsList}
                          fontSize="14px"
                          color={theme.components.directory.textColor}
                          fontWeight="300"
                          lineHeight="1.5"
                        />
                        <Text
                          text={
                            value.contactsList !== ""
                              ? `${value.contactsList} | ${value.email[0]}`
                              : `${value.email[0]}`
                          }
                          fontSize="14px"
                          fontWeight="300"
                          lineHeight="1.4"
                        />
                      </ContentContainer>
                    );
                  })}
              </Fragment>
            )}

            {Object.keys(content.data).length > 0 && (
              <ContentContainer
                position="relative"
                left="-10px"
                className="d-flex"
                marginTop="30px"
              >
                <Pagination
                  onChange={onChangePage}
                  current={currentPage}
                  total={content.pagination ? content.pagination.total : 0}
                />
              </ContentContainer>
            )}
          </Fragment>
        )}
      </div>
    </ContentContainer>
  );
});

Directory.defaultProps = {
  hasHash: false,
  content: {},
  onChangeLetter: () => null,
  onChangePage: () => null,
  alphabet: [],
  letter: "A",
  lang: "pt",
  currentPage: 1
};

Directory.propTypes = {
  className: PropTypes.string,
  backgroundColor: PropTypes.string,
  hasHash: PropTypes.bool,
  isLoading: PropTypes.bool,
  hasError: PropTypes.bool,
  content: PropTypes.object.isRequired,
  onChangeLetter: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  alphabet: PropTypes.array.isRequired,
  letter: PropTypes.string,
  lang: PropTypes.string,
  currentPage: PropTypes.string
};

export default withTheme(injectIntl(Directory));
