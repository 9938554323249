import React, { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { StoreContext } from "../../context/StoreContext";
import { MultiColumns } from "@uaveiro/ui";

const MultiColumnsTemplate = ({ content, data, match, title, ...props }) => {
  const GlobalStore = useContext(StoreContext);
  const titleColor = useMemo(() => (props.isThemeReverse ? "#FFF" : "#000"), [
    props.isThemeReverse
  ]);
  const hasLine = useMemo(() => props.href !== null && props.href !== "", [
    props.href
  ]);

  const { lang } = match.params;
  const contentLang = content && (content[lang] || content);
  const dataLang = data && (data[lang] || data);

  const titleLang = title === undefined ? "" : title[lang] || title;

  const { state } = GlobalStore;

  const multiColumnsProps = {
    titleColor,
    hasLine,
    content: contentLang,
    data: dataLang,
    title: titleLang,
    lang,
    activeColor: props.activeColor || state.activeColor,
    ...props
  };

  return <MultiColumns {...multiColumnsProps} />;
};

MultiColumnsTemplate.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  match: PropTypes.object,
  activeColor: PropTypes.string,
  isThemeReverse: PropTypes.bool,
  title: PropTypes.string,
  href: PropTypes.string
};

export default withRouter(MultiColumnsTemplate);
