import React, { useEffect, useState } from "react";
//Router
import { withRouter } from "react-router-dom";
//components
import { Services } from "@uaveiro/ui";
//Utils
import { groupArrayOfObjects } from "../../services/utilities";
//Hooks
import { useAuthentication } from "../../hooks";

const ServicesTemplate = props => {
  const {
    setFavorite,
    removeFavorite,
    services,
    loading,
    error
  } = useAuthentication(props.location);

  const [data, setData] = useState([]);

  useEffect(() => {
    if (!loading) {
      const groups = groupArrayOfObjects(services.original, "group");

      let data = [];
      for (let key in groups) {
        let list = groups[key].map(item => {
          return {
            id: item.id,
            favorite: item.isFavourite,
            text: item.shortName,
            icon: item.image,
            href: item.url
          };
        });

        data.push({
          title: key,
          list
        });

        setData(data);
      }
    }
  }, [loading, services]);

  const servicesProps = {
    setFavorite,
    removeFavorite,
    loading,
    error,
    data,
    ...props
  };

  return <Services {...servicesProps} />;
};

export default withRouter(ServicesTemplate);
