import React, { memo, useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//Components
import { ProjectDetails } from "../../../components/organisms";
//Utils
import { compose } from "../../../services/utilities";
import { portalAPI } from "../../../services/constants";
//API
import { getProjectDetails } from "../../../api/requests";

const ProjectDetailsTemplate = memo(function ProjectDetailsTemplate({
  match,
  ...props
}) {
  const [state, setState] = useState({
    loading: true,
    error: false,
    data: {}
  });

  const { lang } = match.params;

  useEffect(() => {
    const onFetchContent = async () => {
      try {
        const { id } = match.params;
        const res = await getProjectDetails({
          id,
          lang,
          history: props.history
        });

        setState(prevState => {
          return {
            ...prevState,
            data: {
              ...res,
              logos: `${portalAPI}/projects/${id}/logo?language=${lang}`
            },
            loading: false
          };
        });
      } catch (error) {
        setState(prevState => {
          return { ...prevState, loading: false, error: true };
        });
      }
    };

    onFetchContent();
  }, []);

  const projectDetailsProps = {
    lang,
    ...props
  };

  return (
    <ProjectDetails
      data={state.data}
      loading={state.loading}
      hasError={state.error}
      {...projectDetailsProps}
    />
  );
});

ProjectDetailsTemplate.propTypes = {
  data: PropTypes.array,
  match: PropTypes.object
};

export default compose(withRouter)(ProjectDetailsTemplate);
