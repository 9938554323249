//Styles
import { createGlobalStyle, withTheme } from "styled-components";
import { normalize } from "polished";

// Global style
// eslint-disable-next-line
const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&display=swap');

  * {
    margin: 0;
    padding: 0;
  }

  html {
    height: 100%;
    scroll-behavior: smooth;
  }

  body {
    font-family: ${props => props.theme.type.base};
    display: flex;
    flex-direction: column;
    min-height: 100%;
    zoom: ${props => props.zoom || "100%"};
    background-color: ${props => props.theme.globalStyle.body.backgroundColor};
  }

  #root {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  &:focus {
    ${props => props.theme.mixins.focus()};
  }
  
  //Button Go to Top
  .go-to-top {
    position: fixed;
    bottom: 100px;
    right: 30px;
    z-index: 1000;
    background-color: ${props =>
      props.theme.components.goToTopButton.backgroundColor};
    height: 50px;
    width: 50px;
    border-radius: 50%;
    box-shadow: ${props => props.theme.components.goToTopButton.boxShadow};
    cursor: pointer;
    outline: none;
    border: none;
  }
  .go-to-top.hidden {
    visibility: hidden;
  }
  .go-to-top.visible {
    visibility: visible;    
  }

  /* override styles when printing */
  @media print {
    @page {
      margin: 2cm;
    }
    html, body {height: 100%; margin: 0;}
    header, footer, aside, nav, form, iframe, .tags-container, .journal-footer {
      display: none!important;
    }
    
    @-moz-document url-prefix() {
      body, picture, img, div {
        display: block!important;
      }
    }
  }
`;

export default withTheme(GlobalStyle);
