import { useState, useEffect, useContext } from "react";
import { getPageContent } from "../api/requests";
//Context
import { StoreContext } from "../context/StoreContext";
//utils
import { lang } from "../translations";

function usePageContent(path, redirect) {
  const context = useContext(StoreContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({ error: null, message: null });
  const [data, setData] = useState([]);

  const updatePageDate = data => {
    const [seo] = data.filter(res => res.name === "SEO");
    context.updatePageLastModified(seo.lastModified);
  };

  //activeView, document
  const onFetchPage = async () => {
    try {
      const data = await getPageContent({ path, lang: lang(), redirect });

      setData(data);
      setLoading(false);
      updatePageDate(data);
    } catch (error) {
      setLoading(false);
      setError({
        error: true,
        message: error
      });
    }
  };

  useEffect(() => {
    onFetchPage();
  }, []);

  return {
    data,
    loading,
    error
  };
}

export default usePageContent;
