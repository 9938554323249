import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
//components
import { MobileNavbar } from "../../components/organisms";
//Context
import { StoreContext } from "../../context/StoreContext";
import { withRouter } from "react-router-dom";
//Locales
import { changeLanguage, lang } from "../../translations";
//Utilities
import { clearHashRoute } from "../../services/utilities";
import { hasHash } from "../../services/constants";
import { getZoom } from "../../services/utilities";
// Context
import { ContentContext } from "../../context/ContentContext";

const classes = {
  initial: "state-close",
  close: "close",
  open: "open"
};

function MobileNavbarTemplate({ pathname, history, ...props }) {
  const { initial, close, open } = classes;
  const [isOpen, setOpen] = useState(false);
  const [menuClass, setMenuClass] = useState(initial);
  const isFirefox = typeof InstallTrigger !== "undefined";
  const GlobalStore = useContext(StoreContext);
  const [isFocus, setFocus] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const {
    state: { disableLocalStorage }
  } = useContext(ContentContext);

  useEffect(() => {
    setMenuClass(initial);
    setOpen(false);
  }, [pathname]);

  const onHandleAnimation = e => {
    e.preventDefault();
    if (isOpen) {
      setMenuClass(close);
      setTimeout(() => {
        setOpen(false);
      }, 400);
      return;
    }
    setMenuClass(open);
    setOpen(true);
  };

  const onChangeLang = e => {
    e.preventDefault();
    e.stopPropagation();
    const language = lang() === "pt" ? "en" : "pt";
    let url = history.location.pathname.replace(lang(), language);

    const isHome = disableLocalStorage && url === "/";
    history.replace(isHome ? `/${language}/` : url);

    changeLanguage(language);
  };

  const onHandleSearch = e => {
    if (e.key === "Enter") {
      const route =
        lang() === "pt" ? `/${lang()}/pesquisa/a/` : `/${lang()}/search/a/`;
      const query = `?#gsc.tab=0&gsc.q=${e.target.value}&gsc.sort=`;
      history.push(`${route}${query}`);
    }
  };

  // focus search input
  useEffect(() => {
    if (props.isSearchOpen) setFocus(true);
  }, [props.isSearchOpen]);

  const onChangeAccessibilityFilter = e => {
    e.preventDefault();
    e.stopPropagation();
    const value = Number(GlobalStore.state.accessibilityValue) === 1 ? 0 : 1;
    GlobalStore.updateAccessibility(value, disableLocalStorage);
    setDropdownOpen(false);
  };

  const onChangeZoom = e => {
    e.preventDefault();
    e.stopPropagation();
    const value =
      GlobalStore.state.zoomValue === "100%"
        ? "125%"
        : getZoom() === "125%"
        ? "150%"
        : "100%";
    GlobalStore.updateZoom(value, disableLocalStorage);
  };

  const mobileNavbarProps = {
    onHandleAnimation,
    onChangeLang,
    clearHashRoute,
    onHandleSearch,
    onChangeZoom,
    onChangeAccessibilityFilter,
    hasHash,
    isFirefox,
    isFocus,
    menuClass,
    isOpen,
    history,
    lang: lang(),
    accessibilityValue: Number(GlobalStore.state.accessibilityValue),
    zoomValue: GlobalStore.state.zoomValue,
    GlobalStore,
    isDropdownOpen,
    setDropdownOpen,
    ...props
  };

  return <MobileNavbar {...mobileNavbarProps} />;
}

MobileNavbarTemplate.propTypes = {
  pathname: PropTypes.string,
  history: PropTypes.object.isRequired,
  isSearchOpen: PropTypes.bool
};

export default withRouter(MobileNavbarTemplate);
