//Utils
import { portalAPI } from "../services/constants";
import { getAuthUserInformation } from "../services/utilities";

const endpoints = {
  courses: {
    list: "/courses"
  }
};

const token =
  getAuthUserInformation() !== undefined && getAuthUserInformation().token;

export const withAuthorizationToken = {
  credentials: "include",
  headers: {
    Authorization: `Bearer ${token} `
  }
};

/**
 * GLOBAL UTILS
 */

const delay = promiseToDelay =>
  new Promise((resolve, reject) => {
    setTimeout(async () => {
      try {
        const data = await promiseToDelay();
        resolve(data);
      } catch (err) {
        reject(err);
      }
    }, 1000);
  });

async function onFetch(
  url,
  {
    hasDelay = false,
    lang = "pt",
    redirect = true,
    options = {},
    redirectErrors = true,
    ...props
  }
) {
  const res = hasDelay
    ? await delay(() => fetch(url, options))
    : await fetch(url, options);
  const hasHistory = props.history !== undefined;

  //THIS IS FOR TESTING ONLY TO GET MORE INFO OF PAGE ERROR HANDLING
  const location = typeof window !== "undefined" && document.location.href;
  const isCoursePage =
    location.includes("curso") || location.includes("course");

  /*if (res.status === 500) {
    throw new Error("Internal server error");
  }*/

  if (res.status === 204) {
    throw new Error("No Content");
  }

  if (res.status === 302) {
    const redirectUrl = await res.text();
    window.location.replace(redirectUrl);
  }

  /** ------- */

  if (redirectErrors && res.status !== 200 && !isCoursePage) {
    if (!redirect) throw new Error();
    if (hasHistory) {
      switch (res.status) {
        case 404:
          props.history.push({
            pathname: `/${lang}/404`,
            state: {
              endpoint: url,
              location: props.history.location.pathname,
              errorCode: 404
            }
          });
          break;
        default:
          props.history.push({
            pathname: `/${lang}/error`,
            state: {
              endpoint: url,
              location: props.history.location.pathname,
              errorCode: res.status
            }
          });
          break;
      }
    } else {
      switch (res.status) {
        case 404:
          window.location.replace(`/${lang}/404`);
          localStorage.setItem("portalErrorCode", res.status);
          break;
        default:
          window.location.replace(`/${lang}/error`);
          localStorage.setItem("portalErrorCode", res.status);
          break;
      }
    }

    throw new Error(res.status);
  }
  return await res.json();
}

/**
 * DEBUG REQUESTS
 */

export async function sendToTeams({ page = "", info = "", code = 404 }) {
  try {
    await fetch(
      `${portalAPI}/teams/message?page=${page}&info=${info}&code=${code}`,
      {
        method: "POST"
      }
    );
  } catch (error) {
    throw error;
  }
}

/**
 * NAVIGATION REQUESTS
 */

//Gets sidenav based on the ActiveView
export function getSideNavByPage({
  activeView = "root",
  lang = "pt",
  ...props
}) {
  try {
    return onFetch(
      `${portalAPI}/page/sidenav?path=${activeView}&language=${lang}`,
      {
        lang,
        ...props
      }
    );
  } catch (error) {
    throw error;
  }
}

/**
 * GLOBAL CONTENT REQUESTS
 */

//Gets the page seo
export function getPageSEO({ path = "", lang = "pt", ...props }) {
  try {
    return onFetch(`${portalAPI}/page/seo?path=${path}&language=${lang}`, {
      lang,
      ...props
    });
  } catch (error) {
    throw error;
  }
}

//Gets the page content and structure
export function getPageContent({
  path = "",
  lang = "pt",
  redirect = true,
  ...props
}) {
  try {
    return onFetch(`${portalAPI}/page/slug?path=${path}&language=${lang}`, {
      lang,
      redirect,
      ...props
    });
  } catch (error) {
    throw error;
  }
}

/**
 * PROFILE REQUESTS
 */

export function getProfileExternalInformation({
  userId,
  type,
  lang = "pt",
  ...props
}) {
  try {
    return onFetch(
      `${portalAPI}/person/${userId}/externalinformation?language=${lang}`,
      {
        lang,
        options: withAuthorizationToken,
        ...props
      }
    );
  } catch (error) {
    throw error;
  }
}

export function getProfilePublications({
  userId,
  type,
  lang = "pt",
  ...props
}) {
  try {
    return onFetch(
      `${portalAPI}/person/${userId}/publications?visibility=Public&language=${lang}&bypassCache=false`,
      {
        lang,
        options: withAuthorizationToken,
        ...props
      }
    );
  } catch (error) {
    throw error;
  }
}

export function getProfileExternalPublications({
  userId,
  type,
  lang = "pt",
  ...props
}) {
  try {
    return onFetch(
      `${portalAPI}/person/${userId}/publications/external?language=${lang}`,
      {
        lang,
        options: withAuthorizationToken,
        ...props
      }
    );
  } catch (error) {
    throw error;
  }
}

export function getProfile({ userId, type, lang = "pt", ...props }) {
  try {
    return onFetch(`${portalAPI}/person/${userId}?language=${lang}`, {
      lang,
      options: withAuthorizationToken,
      ...props
    });
  } catch (error) {
    throw error;
  }
}

export async function getProfilePhoto({ userId, lang = "pt" }) {
  try {
    const image = await fetch(
      `${portalAPI}/person/${userId}/photo`,
      withAuthorizationToken
    );

    const src = await image.blob();

    const urlCreator = window.URL || window.webkitURL;
    return urlCreator.createObjectURL(src);
  } catch (error) {
    throw error;
  }
}

//Get multiples photos from various users
export function getProfilesPhotos({ ids, lang = "pt", ...props }) {
  try {
    return onFetch(`${portalAPI}/person/photo?${ids}&language=${lang}`, {
      lang,
      options: withAuthorizationToken,
      redirectErrors: false,
      ...props
    });
  } catch (error) {
    throw error;
  }
}

//Get current auth status for the user
export function getAuthStatus({ token = "", ...props }) {
  try {
    return onFetch(`${portalAPI}/authentication/status`, {
      options: {
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token} `
        }
      },
      redirectErrors: false,
      ...props
    });
  } catch (error) {
    throw error;
  }
}

export async function getAuthUserPhoto({
  id = "",
  lang = "pt",
  token = "",
  getDefault = true
}) {
  try {
    const image = await fetch(
      `${portalAPI}/person/${id}/photo?language=${lang}&getdefault=${getDefault}`,
      {
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token} `
        }
      }
    );

    const src = await image.blob();
    const urlCreator = window.URL || window.webkitURL;
    return urlCreator.createObjectURL(src);
  } catch (error) {
    throw error;
  }
}

/**
 * COURSES REQUESTS
 */

//localhost:44321

export function getCourseInfoById({ id, lang = "pt", ...props }) {
  try {
    return onFetch(`${portalAPI}/courses/${id}/page?language=${lang}`, {
      lang,
      ...props
    });
  } catch (error) {
    throw error;
  }
}

export function getCoursesListById({
  id,
  type,
  lang = "pt",
  slug = "",
  ...props
}) {
  try {
    const filter = type === "c" ? `cycle=${id}` : `degree=${id}`;
    return onFetch(
      `${portalAPI}${endpoints.courses.list}?${filter}&slug=${slug}&language=${lang}`,
      { lang, ...props }
    );
  } catch (error) {
    throw error;
  }
}

export function getCourseCurricularById({ id, lang = "pt", ...props }) {
  try {
    return onFetch(
      `${portalAPI}${endpoints.courses.list}/${id}/short-info?language=${lang}`,
      {
        lang,
        ...props
      }
    );
  } catch (error) {
    throw error;
  }
}

export function getCoursePresentationById({ id, lang = "pt", ...props }) {
  try {
    return onFetch(
      `${portalAPI}${endpoints.courses.list}/${id}/presentation?language=${lang}`,
      {
        lang,
        ...props
      }
    );
  } catch (error) {
    throw error;
  }
}

export function getCourseAdmissionById({ id, lang = "pt", ...props }) {
  try {
    return onFetch(
      `${portalAPI}${endpoints.courses.list}/${id}/admission?language=${lang}`,
      {
        lang,
        ...props
      }
    );
  } catch (error) {
    throw error;
  }
}

export function getCourseGraduateProfilesById({ id, lang = "pt", ...props }) {
  try {
    return onFetch(
      `${portalAPI}${endpoints.courses.list}/${id}/graduate-profiles?language=${lang}`,
      {
        lang,
        ...props
      }
    );
  } catch (error) {
    throw error;
  }
}

export function getCoursePlanSummaryById({ id, lang = "pt", ...props }) {
  try {
    return onFetch(
      `${portalAPI}${endpoints.courses.list}/${id}/plan-summary?language=${lang}`,
      {
        lang,
        ...props
      }
    );
  } catch (error) {
    throw error;
  }
}

export function getCourseAccreditationsById({ id, lang = "pt", ...props }) {
  try {
    return onFetch(
      `${portalAPI}${endpoints.courses.list}/${id}/accreditations?language=${lang}`,
      {
        lang,
        ...props
      }
    );
  } catch (error) {
    throw error;
  }
}

export function getCourseCurricularPlanById({ id, lang = "pt", ...props }) {
  try {
    return onFetch(
      `${portalAPI}${endpoints.courses.list}/${id}/plan?language=${lang}`,
      {
        lang,
        ...props
      }
    );
  } catch (error) {
    throw error;
  }
}

export function getCourseInfo({ lang = "pt", ...props }) {
  try {
    return onFetch(
      `${portalAPI}${endpoints.courses.list}/info?language=${lang}`,
      {
        lang,
        ...props
      }
    );
  } catch (error) {
    throw error;
  }
}

/**
 * SITEMAP REQUESTS
 */

export function getSiteMap({
  lang = "pt",
  isSubWeb = false,
  department = "",
  extended = false,
  ...props
}) {
  const subWeb = isSubWeb ? `&subweb=${department}` : "";
  try {
    return onFetch(
      `${portalAPI}/page/sitemap?language=${lang}${subWeb}&extended=${extended}`,
      {
        ...props
      }
    );
  } catch (error) {
    throw error;
  }
}

export function getAllSideNavs(props) {
  try {
    return onFetch(`${portalAPI}/contents/sidemap`, {
      ...props
    });
  } catch (error) {
    throw error;
  }
}

/**
 * CONTESTS REQUESTS
 */

export function getContests({
  lang = "pt",
  query,
  ...props
}) {
  try {
    return onFetch(
      `${portalAPI}/contests/list?${query}`,
      {
        lang,
        ...props
      }
    );
  } catch (error) {
    throw error;
  }
};


/**
 * JOURNAL REQUESTS
 */

export function getNews({
  lang = "pt",
  id = 1,
  nextPageToken = "",
  recordsPerPage = 6,
  channel = 0,
  featured = false,
  isTag = false,
  isDossier = false,
  multiCategories = false,
  multiCategoriesIDs = [],
  notInTopFeatured = false,
  ...props
}) {
  try {
    const contentType = isTag
      ? `${id}`
      : isDossier
      ? `dossier=${id}`
      : id !== null
      ? `categories=${id}`
      : multiCategories
      ? multiCategoriesIDs
      : "";

    return onFetch(
      `${portalAPI}/news?${contentType}&language=${lang}&recordsPerPage=${recordsPerPage}&channel=${channel}&featured=${featured}&notInTopFeatured=${notInTopFeatured}&pageToken=${nextPageToken}`,
      {
        lang,
        ...props
      }
    );
  } catch (error) {
    throw error;
  }
}

export function getAllCategories({ lang = "pt", ...props }) {
  try {
    return onFetch(`${portalAPI}/news/categories?language=${lang}`, {
      lang,
      ...props
    });
  } catch (error) {
    throw error;
  }
}

export function getNewsFeatured({ lang = "pt", recordsPerPage = 3, ...props }) {
  try {
    return onFetch(
      `${portalAPI}/news/featured?language=${lang}&recordsPerPage=${recordsPerPage}`,
      {
        lang,
        ...props
      }
    );
  } catch (error) {
    throw error;
  }
}

export function getNewsRecentAndPopular({
  lang = "pt",
  channel = 0,
  categories = null,
  ...props
}) {
  try {
    const setCategory = categories !== null ? `&categories=${categories}` : "";
    return onFetch(
      `${portalAPI}/news/recent-and-popular?channel=${channel}&language=${lang}${setCategory}`,
      {
        lang,
        ...props
      }
    );
  } catch (error) {
    throw error;
  }
}

export function getNewsDetails({
  lang = "pt",
  newsId = 0,
  relatedNews = true,
  ...props
}) {
  try {
    return onFetch(
      `${portalAPI}/news/${newsId}?language=${lang}&relatedNews=${relatedNews}`,
      {
        lang,
        ...props
      }
    );
  } catch (error) {
    throw error;
  }
}

export function getNewsDossier({ lang = "pt", id = 34, ...props }) {
  try {
    return onFetch(`${portalAPI}/news/dossier/${id}?language=${lang}`, {
      lang,
      ...props
    });
  } catch (error) {
    throw error;
  }
}

export function getDossiersList({ lang = "pt", ...props }) {
  try {
    return onFetch(`${portalAPI}/news/dossier?language=${lang}`, {
      lang,
      ...props
    });
  } catch (error) {
    throw error;
  }
}
export function getNewsDossierList({
  lang = "pt",
  name,
  id,
  nextPageToken,
  recordsPerPage,
  ...props
}) {
  try {
    return onFetch(
      `${portalAPI}/news/dossier/${id}?language=${lang}&recordsPerPage=${recordsPerPage}&pageToken=${nextPageToken}`,
      {
        lang,
        ...props
      }
    );
  } catch (error) {
    throw error;
  }
}

/**
 * GLOBAL SEARCH REQUESTS
 */

export function getSearchDirectory({
  search = "",
  offset = 0,
  letter = "A",
  lang = "pt",
  endpoint = "",
  ...props
}) {
  try {
    return onFetch(
      `${portalAPI}${endpoint}?language=${lang}${search}&offset=${offset}&firstLetterFilter=${letter}`,
      {
        lang,
        ...props
      }
    );
  } catch (error) {
    throw error;
  }
}

/**
 * PROJECTS REQUESTS
 */

export function getProjectsList({
  lang = "pt",
  recordsPerPage = 10,
  search = "",
  filter = "",
  currentPage = 1,
  startDate = "",
  endDate = "",
  filterType = "fundingProgram",
  ...props
}) {
  try {
    const hasSearch = search ? `&search=${search}` : "";
    const hasFilter = filter
      ? `&${filterType}=${encodeURIComponent(filter)}`
      : "";
    const hasStartDate = startDate ? `&startDate=${startDate}` : "";
    const hasEndDate = endDate ? `&endDate=${endDate}` : "";

    return onFetch(
      `${portalAPI}/projects/listpaginated?recordsPerPage=${recordsPerPage}&currentPage=${currentPage}&language=${lang}${hasSearch}${hasFilter}${hasStartDate}${hasEndDate}`,
      {
        lang,
        ...props
      }
    );
  } catch (error) {
    throw error;
  }
}

export function getProjectDetails({ lang = "pt", id = null, ...props }) {
  try {
    return onFetch(`${portalAPI}/projects/${id}?language=${lang}`, {
      lang,
      ...props
    });
  } catch (error) {
    throw error;
  }
}

export function getProjectSigef({ lang = "pt", id = null, ...props }) {
  try {
    return onFetch(`${portalAPI}/projects/sigef/${id}`, {
      lang,
      ...props
    });
  } catch (error) {
    throw error;
  }
}
/**
 * SUBWEB REQUESTS
 */

export function getDepartmentPeople({
  lang = "pt",
  department = "",
  type = "",
  ...props
}) {
  try {
    const hasType = type ? `&type=${type}` : "";
    return onFetch(
      `${portalAPI}/subwebs/${department}/people?language=${lang}${hasType}`,
      {
        lang,
        redirectErrors: false,
        options: {
          ...withAuthorizationToken
        },

        ...props
      }
    );
  } catch (error) {
    throw error;
  }
}

export function getDepartmentManagement({
  lang = "pt",
  department = "",
  ...props
}) {
  try {
    return onFetch(
      `${portalAPI}/subwebs/${department}/management?language=${lang}`,
      {
        lang,
        redirectErrors: false,
        options: {
          ...withAuthorizationToken
        },
        ...props
      }
    );
  } catch (error) {
    throw error;
  }
}

export function getCourseTypes({ lang = "pt", department = "", ...props }) {
  try {
    return onFetch(`${portalAPI}/coursestypes/${department}?language=${lang}`, {
      lang,
      ...props
    });
  } catch (error) {
    throw error;
  }
}

export function getCourseTypesList({ lang = "pt", department = "", ...props }) {
  try {
    return onFetch(`${portalAPI}/courses/${department}/list?language=${lang}`, {
      lang,
      ...props
    });
  } catch (error) {
    throw error;
  }
}

export function getDepartmentInfo({ lang = "pt", department = "", ...props }) {
  try {
    return onFetch(
      `${portalAPI}/subwebs/${department}/information?language=${lang}`,
      {
        lang,
        ...props
      }
    );
  } catch (error) {
    throw error;
  }
}

//Gets the content from a dynamic route path
export function getDynamicPage({ lang = "pt", path = "", ...props }) {
  try {
    return onFetch(`${portalAPI}/page/slug?path=${path}&language=${lang}`, {
      lang,
      ...props
    });
  } catch (error) {
    throw error;
  }
}

//Gets the nav bar from a route path
export function getSubNavigation({ lang = "pt", path = "", ...props }) {
  try {
    return onFetch(`${portalAPI}/page/sidenav?path=${path}&language=${lang}`, {
      lang,
      ...props
    });
  } catch (error) {
    throw error;
  }
}

/**
 *  SERVICES
 */

export function getServices({ lang = "pt", token = "", ...props }) {
  try {
    return onFetch(`${portalAPI}/systems?profiles=Docente&language=${lang}`, {
      lang,
      options: {
        credentials: "include",
        headers: {
          Authorization: `Bearer ${token} `
        }
      },
      ...props
    });
  } catch (error) {
    throw error;
  }
}

export function setServiceFavorite({ id, lang = "pt", ...props }) {
  try {
    return onFetch(`${portalAPI}/systems/set-favourite?id=${id}`, {
      lang,
      options: {
        method: "POST",
        ...withAuthorizationToken
      },
      ...props
    });
  } catch (error) {
    throw error;
  }
}

export function removeServiceFavorite({ id, lang = "pt", ...props }) {
  try {
    return onFetch(`${portalAPI}/systems/remove-favourite?id=${id}`, {
      lang,
      options: {
        method: "POST",
        ...withAuthorizationToken
      },
      ...props
    });
  } catch (error) {
    throw error;
  }
}

/**
 *  DEMO TESTS
 */

export function getMarkdownDemo({ lang = "pt", id = "", ...props }) {
  try {
    return onFetch(
      `${portalAPI}/migration/testmarkdown/${id}?language=${lang}`,
      {
        lang,
        ...props
      }
    );
  } catch (error) {
    throw error;
  }
}
