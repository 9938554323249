import React, { Fragment, useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//Components
import { Xperimenta } from "@uaveiro/ui";
import ErrorTemplate from "../Error/index";
//API
import { getCourseInfo } from "../../api/requests";

import { useQueryBuilder } from "../../hooks";
import { get } from "lodash";

const generateNewFilter = (value, initialFilter) => {
  // handle delete all
  if (value === null) return [];

  const newFilter = [...initialFilter];
  // handle add one
  if (!newFilter.includes(value)) {
    newFilter.push(value);
  } else {
    // handle delete one
    newFilter.splice(newFilter.indexOf(value), 1);
  }
  return newFilter;
};
// parse array search parameter
const parseArrayParam = (searchParams, paramKey) => {
  if (paramKey in searchParams) {
    if (typeof searchParams[paramKey] === "string") {
      return searchParams[paramKey].includes(",")
        ? searchParams[paramKey].split(",")
        : [searchParams[paramKey]];
    }
    if (Array.isArray(searchParams[paramKey])) return searchParams[paramKey];
  }
  return [];
};

const XperimentaTemplate = ({ match, sessions, ...props }) => {
  const [state, setState] = useState({
    isLoading: true,
    hasError: false,
    filters: [],
    data: [],
    buttons: [],
    staticData: [],
    sessions: []
  });

  // currently applied filters
  const [activeFilters, setActiveFilters] = useState({
    type: [],
    knowledgeAreas: []
  });

  const [urlParams, setUrlParams, searchParams] = useQueryBuilder(
    match.url,
    props.history.location.search
  );

  const {
    params: { lang }
  } = match;

  //order like this : TESP > Licenciaturas > Mestrados
  const orderByDegree = degrees => {
    return degrees.sort((firstElement, secondElement) => {
      if (
        firstElement.value
          ? firstElement.value === "10"
          : firstElement.type.toString() === "10"
      ) {
        return -1;
      }
      if (
        secondElement.value
          ? secondElement.value === "2"
          : secondElement.type.toString() === "2"
      ) {
        return 1;
      }
      return 0;
    });
  };

  const getData = async () => {
    try {
      const res = await getCourseInfo({
        lang,
        history: props.history
      });

      //order buttons like TESP > Licenciaturas > Mestrados
      const orderedButtons = res.data.buttons.map(button => {
        return { ...button, options: orderByDegree(button.options) };
      });

      const knowledgeAreasLabel = res.data.filters[0].options.filter(opt => {
        const parsedSearchParams = parseArrayParam(
          searchParams,
          "knowledgeAreas"
        );
        return parsedSearchParams.includes(opt.value);
      });

      const degreeAreasLabel = res.data.buttons[0].options.filter(opt => {
        const parsedSearchParams = parseArrayParam(searchParams, "type");
        return parsedSearchParams.includes(opt.value);
      });

      setState(prevState => {
        return {
          ...prevState,
          title: lang === "pt" ? "Cursos" : "Courses",
          data: res.data.list,
          buttons: orderedButtons,
          staticData: res.data.list,
          defaultValues: {
            degree: degreeAreasLabel,
            knowledgeAreas: knowledgeAreasLabel
          },
          filters: res.data.filters,
          isLoading: false
        };
      });
    } catch (error) {
      setState(prevState => {
        return {
          ...prevState,
          isLoading: false,
          hasError: true,
          errorCode: parseInt(error.message)
        };
      });
    }
  };

  // set filters from search params in initial load
  const onChangeSearchParams = searchParams => {
    const newFilters = {
      type: parseArrayParam(searchParams, "type"),
      knowledgeAreas: parseArrayParam(searchParams, "knowledgeAreas")
    };

    const shouldUpdateFilters =
      activeFilters.type.length === 0 &&
      activeFilters.knowledgeAreas.length === 0;
    if (shouldUpdateFilters) setActiveFilters(newFilters);
  };

  const onChangeFilter = (option, field) => {
    setActiveFilters({
      ...activeFilters,
      [field]: generateNewFilter(
        get(option, "value", null),
        activeFilters[field]
      )
    });
  };

  const handleFilterData = filters => {
    const { type, knowledgeAreas } = filters;

    //order the data rendered with no filters
    let filteredData = orderByDegree(state.staticData);

    // filter degrees
    if (type.length > 0) {
      filteredData = filteredData.filter(degreeData => {
        return type.find(deg => degreeData.type.toString() === deg);
      });
    }

    // filter knowledge areas
    if (knowledgeAreas.length > 0) {
      filteredData = filteredData.map(degree => {
        const filteredList = degree.list.reduce((acc, area) => {
          // no knowleged area, so skip
          if (area.knowledgeAreas.length === 0) return acc;

          // check if knowleged area matches filter and add
          const knowledgeAreasMatch = knowledgeAreas.filter(
            knowledgeAreaFilter =>
              area.knowledgeAreas.includes(knowledgeAreaFilter)
          );
          if (knowledgeAreasMatch.length > 0) return acc.concat(area);

          // otherwise skip
          return acc;
        }, []);

        return {
          ...degree,
          list: filteredList
        };
      });
    }
    setState({ ...state, data: filteredData });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (Object.keys(searchParams).length) {
      onChangeSearchParams(searchParams);
    }
  }, []);

  useEffect(() => {
    const newUrlParams = {
      ...urlParams,
      ...activeFilters
    };

    // remove empty string filters (params)
    const urlParamsClean = Object.entries(newUrlParams).reduce(
      (acc, [key, value]) => {
        return !value.length ? acc : ((acc[key] = value), acc);
      },
      {}
    );

    setUrlParams(urlParamsClean);
    handleFilterData(activeFilters);
  }, [activeFilters, state.staticData]);

  const {
    isLoading,
    hasError,
    data,
    buttons,
    defaultValues,
    filters,
    title
  } = state;

  const xperimentaProps = {
    isLoading,
    hasError,
    data,
    buttons,
    defaultValues,
    filters,
    onChangeFilter,
    title,
    sessions,
    lang
  };
  return <Xperimenta {...xperimentaProps} />;
};

export default withRouter(XperimentaTemplate);
