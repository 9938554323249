export default [
  {
    name: "Article",
    hasSubTitle: false,
    hasLine: false,
    showLangMessage: false,
    data: {
      pt: {
        title: "Contactos",
        content:
          "\nPara contactar a equipa editorial da Área de Notícias da Universidade de Aveiro pode enviar um e-mail para [noticias@ua.pt](mailto:noticias@ua.pt). \n\n---\n\n **Serviços de Comunicação, Imagem e Relações Públicas**\n\nUniversidade de Aveiro<br/>Campus Universitário de Santiago<br/> 3810-193 Aveiro<br/> Portugal<br/> Telefone: + 351 234 370072\n\n"
      },
      en: {
        title: "Contacts",
        content:
          "To contact the editorial team of the jornal@UA you can send an e-mail to [noticias@ua.pt](mailto:noticias@ua.pt).\n\n---\n\n **Communication, image and public relations services**\n\nUniversidade de Aveiro<br/>Campus Universitário de Santiago<br/> 3810-193 Aveiro<br/> Portugal<br/> Telefone: + 351 234 370072\n\n"
      }
    }
  }
];
