import React, { memo, useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
//Components
import { Header, HeaderLoading, SubWebHeaderLoading } from "@uaveiro/ui";
//Utils
import { lang } from "../../translations";
//Context
import { StoreContext } from "../../context/StoreContext";

const Slider = memo(function Slider({ data, ...props }) {
  const context = useContext(StoreContext);
  const [video, setVideo] = useState({
    default: true
  });
  const [autoplay, setAutoplay] = useState(true);
  const [loading, setLoading] = useState(true);
  const scrollMoreRef = React.createRef();
  const defaultLang = lang() || "pt";

  const isSubWeb = context.state.isSubWeb;

  const LoadingComponent = isSubWeb ? SubWebHeaderLoading : HeaderLoading;

  const content =
    data[defaultLang] !== undefined ? data[defaultLang].data : data;

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  const onChangeVideo = () => {
    setVideo(prevState => {
      return { ...prevState, default: !prevState.default };
    });
  };

  const scrollHeight = window.innerHeight - (isSubWeb ? 175 : 102);

  const scrollDown = () => {
    window.scrollTo(0, scrollHeight);
  };

  const onVideoPlay = () => {
    setAutoplay(false);
  };

  const onVideoEnd = () => {
    setAutoplay(true);
  };

  const SliderProps = {
    //urlExtension: defaultLang === "pt" ? "/pt/noticias" : "/en/news",
    urlExtension: "",
    lang: defaultLang,
    onChangeVideo,
    video,
    isSubWeb,
    scrollMoreRef,
    scrollDown,
    onVideoPlay,
    onVideoEnd,
    autoplay,
    ...props
  };

  return loading ? (
    <LoadingComponent isVideo={props.onlyVideo} />
  ) : (
    <Header data={content} loading={false} {...SliderProps} />
  );
});

Slider.propTypes = {
  data: PropTypes.object
};

export default Slider;
