import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//Components
import JournalNavigation from "../../components/organisms/Journal/Navigation";
//Hocs
import { compose } from "../../services/utilities";
import { hasHash } from "../../services/constants";
//API
import { getAllCategories } from "../../api/requests";
//Context
import { lang as localeLang } from "../../translations";

function JournalNavigationTemplate({ ...props }) {
  const [state, setState] = useState({
    loading: true,
    error: false,
    data: {}
  });
  const [categorySplitLength, setCategorySplitLength] = useState(-1);
  const [categoryId, setCategoryId] = useState(-1);

  const handleNewsCategory = () => {
    let categorySplit = props.location.pathname.split("/");
    categorySplit = [...new Set(categorySplit)];

    let _categoryId = NaN;
    if(!categorySplit.includes('dossie') || !categorySplit.includes('dossier')) {
      if(categorySplit.length === 5)
      _categoryId = Number(categorySplit[4]);
      else
      _categoryId = Number(categorySplit[3]);
    } 
  
    if(categorySplit.length === 5) {
      if(!isNaN(Number(categorySplit[3])))
      _categoryId = NaN
    }

    setCategorySplitLength(categorySplit.length);
    setCategoryId(_categoryId);
  };

  const lang = localeLang();

  const journalInfo =
    lang === "pt"
      ? {
          route: "noticias",
          magazineLink: `${hasHash}/${lang}/revista-linhas`
        }
      : {
          route: "news",
          magazineLink: `${hasHash}/${lang}/linhas-magazine`
        };

  const onAddUrl = data => {
    let items = data.map(item => {
      return {
        ...item,
        url: `${hasHash}/${lang}/${journalInfo.route}/${item.id}`
      };
    });

    return items;
  };

  const onFetchData = async () => {
    try {
      const res = await getAllCategories({ lang, history: props.history });
      const data = onAddUrl(res.data);
      
      setState(prevState => {
        return { ...prevState, data, loading: false };
      });
    } catch (error) {
      setState(prevState => {
        return { ...prevState, loading: false, error: true };
      });
    }
  };

  useEffect(() => {
    onFetchData();
  }, []);

  useEffect(() => {
    handleNewsCategory();
  }, [props.location.pathname]);

  const journalNavigationProps = {
    titleUrl: `${hasHash}/${lang}/${journalInfo.route}`,
    route: journalInfo.route,
    categoryId: categoryId !== "NaN" ? categoryId : 0,
    ...props
  };

  const mobileProps =
    !isNaN(categoryId)
      ? {
          backgroundColor: "#E7E8E9",
          mobileBackgroundColor: "#fff"
        }
      : {};

  return (
    <JournalNavigation
      data={state.data}
      loading={state.loading}
      hasError={state.error}
      magazineLink={journalInfo.magazineLink}
      magazineTitle="Linhas"
      {...mobileProps}
      {...journalNavigationProps}
      hasMobileMdBorderBottom={!isNaN(categoryId) && categorySplitLength >= 4}
      hasMobileBorderBottom={!isNaN(categoryId) && categorySplitLength >= 4}
    />
  );
}

JournalNavigationTemplate.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default compose(withRouter)(JournalNavigationTemplate);
