/* eslint-disable no-new-func */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
//Locales
import { FormattedMessage } from "react-intl";
import { getCookie } from "../../services/utilities";
import { Link } from "../../components/atoms";
import { Text} from "@uaveiro/ui";
const Search = ({ className, history, match }) => {
  const [value, setValue] = useState("");
  const [cache, setCache] = useState(false);
  const { lang } = match.params;
  useEffect(() => {
    const apiKey = lang === "en" ? ":kzmogez4xns" : ":v8aziouiucg";
    let embedcode = `
    (function() {
      var cx = '017278274143886982091${apiKey}';
      var gcse = document.createElement('script');
      gcse.type = 'text/javascript';
      gcse.async = true;
      gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(gcse, s);
    })();
  `;
    new Function(embedcode)();

    if (history.location.hash) {
      const search = history.location.hash.split("&")[1].split("=")[1];
      setValue(search);
    }
     const oneTrustCookie = getCookie("OptanonConsent");

      setCache(false)
      if (oneTrustCookie) {
        try {
          const getGroups = oneTrustCookie
            .split("&")
            .find(item => item.includes("groups="))
            .replace("groups=", "")
            .split(",");
  
          const disableLocalStorage = getGroups.find(item =>
            item.includes("C0004:1")
          );
          if(disableLocalStorage){
            setCache(true)
          }
        } catch (e) {
        }
      }

  }, [history, match]);

  return (
    <div className="container">
      <div className={`${className} row`}>
        <div className="col-12">
          {
          !cache? 
          (
            <div>
              <Text
               fontSize = "14px">
                <FormattedMessage
                  id="app.search.cookies"
                  defaultMessage="Cookies desativadas"
                />

                <Link
                  href={`/${lang}/politica-de-cookies`}
                  target="_blank"
                  native
                >
                  <FormattedMessage
                  id="app.global.cookiePolicy"
                  defaultMessage="Politica de cookies"
                />
                </Link>
              </Text>

            </div>
          ):(
          !value ? (
            <div>
              <FormattedMessage
                id="app.search.empty"
                defaultMessage="Sem conteúdos para a pesquisa"
              />
            </div>
          ) : (
            <form>
              <div
                id="googleSearch"
                dangerouslySetInnerHTML={{
                  __html: `<gcse:search></gcse:search>`
                }}
              />
            </form>
          )
          )}
        </div>
      </div>
    </div>
  );
};

Search.propTypes = {
  className: PropTypes.string,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

const SearchWithStyles = styled(Search)`
  text-decoration: none !important;
  padding-bottom: 0;
  font-weight: ${props => props.theme.weight.light};

  form.gsc-search-box {
    display: none !important;
  }

  .gsc-option-selector:before {
    content: "\f078";
  }

  .gsc-option-selector {
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    -webkit-font-smoothing: antialiased;
    display: table;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    left: 130px !important;
    background: none !important;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      height: 13px;
      width: 1px;
      background-color: black;
      left: -4px;
      top: -1px;
    }
  }

  .gsc-control-cse .gsc-table-result {
    font-size: 14px !important;
  }

  .gsc-url-top {
    padding-top: 2px;
  }

  #googleSearch,
  .gs-title,
  .gs-snippet,
  .gsc-control-cse {
    font-family: "Roboto", sans-serif !important;
  }

  div.gs-title {
    font-weight: ${props => props.theme.weight.xbase};
  }

  .gsc-webResult.gsc-result,
  .gsc-webResult.gsc-result:hover {
    border-left: none !important;
  }
  .gsc-control-cse .gsc-option-menu {
    position: absolute;
    top: 40px;
    min-width: 161px;
    right: 16px;
    background-color: ${props =>
      props.theme.components.googleSearch.optionMenuBgColor};
    border: ${props => props.theme.components.googleSearch.optionMenuBorder};
  }
  @media only screen and (max-width: 767px) {
    .gsc-control-cse .gsc-option-menu {
      top: 66px;
    }
  }
  .gsc-option-menu-item {
    padding: 0 30px 0 17px;
  }
  .gcsc-branding-text.gcsc-branding-text-name {
    color: ${props =>
      props.theme.components.googleSearch.brandingTextNameColor};
  }
  .gcsc-branding-text {
    color: ${props => props.theme.components.googleSearch.brandingTextColor};
  }
  .gs-richsnippet-box {
    display: none !important;
  }
  .gsc-search-button {
    float: left;
  }
  .gsc-search-button-v2,
  .gsc-search-button-v2:hover,
  .gsc-search-button .gsc-search-button-v2,
  .gsc-search-button .gsc-search-button-v2:hover {
    background-color: ${props =>
      props.theme.components.googleSearch.searchButtonBgColor};
    padding: 0;
  }
  .gsc-search-button .gsc-search-button-v2 svg {
    cursor: pointer;
    height: 20px;
    width: 20px;
    fill: ${props => props.theme.components.googleSearch.searchButtonSvgFill};
    padding-bottom: 2px;
  }

  .gsc-control-cse div {
    position: static;
    border: none;
  }

  .gsst_a .gscb_a {
    color: black !important;
  }

  .gs-no-results-result .gs-snippet,
  .gs-error-result .gs-snippet {
    background-color: transparent !important;
  }

  .gsc-selected-option-container {
    background: ${props =>
      props.theme.components.googleSearch.selectOptionBgColor} !important;
    padding-right: 116px !important;
    width: 180px;
    padding-top: 3px;
    padding-bottom: 29px;
    border: ${props =>
      props.theme.components.googleSearch.selectOptionBorder} !important;
    cursor: pointer !important;
    font-weight: ${props => props.theme.weight.xbase};
    color: ${props => props.theme.components.googleSearch.selectOptionColor};
    border-radius: 0;
  }

  .gsc-selected-option-container > .gsc-selected-option {
    padding-left: 4px;
    text-align: left;
  }
  .gsc-orderby-label {
    color: ${props =>
      props.theme.components.googleSearch.selectLabelColor} !important;
    font-weight: ${props => props.theme.weight.xbase};
  }

  table.gsc-search-box td.gsc-input {
    line-height: 21px;
    float: right;
    width: calc(100% - 30px);
    padding-right: 0;
  }

  .gsc-control-cse .gsc-option-selector {
    position: relative;
    left: 134px;
    top: -15px;
  }
  .gsc-control-cse {
    margin-bottom: 70px;
  }
  .gs-webResult.gs-result a.gs-title:visited,
  .gs-webResult.gs-result a.gs-title:visited b,
  .gs-imageResult a.gs-title:visited,
  .gs-imageResult a.gs-title:visited b,
  .gs-webResult.gs-result a.gs-title:link,
  .gs-webResult.gs-result a.gs-title:link b,
  .gs-imageResult a.gs-title:link,
  .gs-imageResult a.gs-title:link b {
    color: ${props => props.theme.components.googleSearch.linkColor};
  }

  .gs-webResult.gs-result a.gs-title:hover,
  .gs-webResult.gs-result a.gs-title:hover b,
  .gs-imageResult a.gs-title:hover,
  .gs-imageResult a.gs-title:hover b {
    color: ${props =>
      props.theme.components.googleSearch.linkHoverColor}!important;
  }

  .gsc-option-menu-item.gsc-option-menu-item-highlighted {
    background: ${props =>
      props.theme.components.googleSearch.gscOptionMenuBgColor};
    color: ${props => props.theme.components.googleSearch.gscOptionMenuColor};
  }
  .gsc-result .gs-title {
    height: 1.4em;
  }
  .gsc-option-menu-item.gsc-option-menu-item-highlighted:hover {
    background: ${props =>
      props.theme.components.googleSearch.gscActiveMenuItemHoverBgColor};
    color: ${props =>
      props.theme.components.googleSearch.gscActiveMenuItemHoverColor};
  }
  .gsc-option-menu-item:hover {
    background: ${props =>
      props.theme.components.googleSearch.gscMenuItemHoverBgColor};
    color: ${props =>
      props.theme.components.googleSearch.gscMenuItemHoverColor};
  }
  .gsc-option-menu-item {
    color: ${props => props.theme.components.googleSearch.gscMenuItemColor};
  }

  .gsc-search-box.gsc-search-box-tools tr {
    border-bottom: 1px solid #999999;
  }
  .gsc-cursor-page.gsc-cursor-current-page {
    color: black;
  }
  .gsc-results .gsc-cursor-box .gsc-cursor-current-page {
    color: ${props =>
      props.theme.components.googleSearch.currentPageColor} !important;
  }
  .gsc-results .gsc-cursor-box .gsc-cursor-page {
    color: ${props => props.theme.components.googleSearch.pageNumberColor};
    font-size: 1.3em;
    background-color: ${props =>
      props.theme.components.googleSearch.backgroundColor};
  }
  .gsc-cursor-box.gs-bidi-start-align {
    margin-left: 0;
    margin-top: 50px;
  }
  .gsc-webResult.gsc-result {
    margin-bottom: 15px;
  }
  .gsc-above-wrapper-area {
    margin-bottom: ${props => props.theme.spacing.m};
    display: block !important;
  }

  #gsc-i-id1 {
    font-size: 1.4em;
    font-weight: ${props => props.theme.weight.xbase};
  }
  #gsc-i-id1::placeholder,
  #gsc-i-id1::-webkit-input-placeholder,
  #gsc-i-id1:-moz-placeholder,
  #gsc-i-id1::-moz-placeholder,
  #gsc-i-id1:-ms-input-placeholder {
    font-size: 1.1em !important;
    font-weight: normal !important;
    color: #999999 !important;
  }

  .gsc-control-cse.gsc-control-cse-pt-PT {
    padding: 0;
  }

  b {
    font-weight: ${props => props.theme.weight.base} !important;
  }

  .gsc-control-cse,
  .gsc-webResult.gsc-result,
  .gsc-webResult.gsc-result:hover {
    border-color: ${props =>
      props.theme.components.googleSearch.backgroundColor};
    background-color: ${props =>
      props.theme.components.googleSearch.backgroundColor};
  }

  .gsc-result-info {
    color: ${props => props.theme.components.googleSearch.gscResultInfo};
  }

  .gs-webResult div.gs-visibleUrl {
    color: ${props => props.theme.components.googleSearch.gscResultVisibleUrl};
  }

  .gs-webResult .gs-snippet {
    color: ${props => props.theme.components.googleSearch.gsSnippet};
  }

  .gcsc-branding-clickable {
    background-color: ${props =>
      props.theme.components.googleSearch.gcscBrandingClickable};
  }

  .gcsc-find-more-on-google {
    color: ${props => props.theme.components.googleSearch.findMoreMagnifier};
    .gcsc-find-more-on-google-magnifier {
      fill: ${props => props.theme.components.googleSearch.findMoreMagnifier};
    }
  }
`;

export default withRouter(SearchWithStyles);
