import React from "react";
import PropTypes from "prop-types";
//Components
import { Section, SectionHeader, ContentContainer, Select } from "@uaveiro/ui";

const CoursesSelects = ({
  className,
  selects,
  titleProps,
  textColor,
  title,
  showHeader
}) => {
  return (
    <Section
      className={className}
      paddingTop="120px"
      paddingBottom="120px"
      backgroundColor="#4AB0BB"
    >
      {showHeader ? (
        <SectionHeader
          title={title}
          textColor={textColor}
          marginBottom="50px"
          {...titleProps}
        />
      ) : null}
      <div className="container">
        <div className="row">
          {selects.map((item, index) => {
            return (
              <div key={index} className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <ContentContainer smMarginbottom="20px">
                  <Select
                    variant="white"
                    options={item.options}
                    placeholder={item.title}
                    label={item.title}
                    isClearable
                  />
                </ContentContainer>
              </div>
            );
          })}
        </div>
      </div>
    </Section>
  );
};

CoursesSelects.propTypes = {
  selects: [],
  title: "Title"
};

CoursesSelects.propTypes = {
  className: PropTypes.string,
  selects: PropTypes.array.isRequired,
  textColor: PropTypes.string,
  title: PropTypes.string,
  showHeader: PropTypes.bool,
  titleProps: PropTypes.object
};

export default CoursesSelects;
