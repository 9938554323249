import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { ImageWrapper } from "@uaveiro/ui";
//Utils
import { portalAPI } from "../../services/constants";
import { compose } from "../../services/utilities";

const ImageWrapperTemplate = ({ history, data, ...props }) => {
  const { lang } = props.match.params;

  const content = data[lang] || data;

  const imageWrapperProps = {
    url: portalAPI,
    data: content,
    history,
    ...props
  };

  return <ImageWrapper {...imageWrapperProps} />;
};

ImageWrapperTemplate.propTypes = {
  history: PropTypes.object
};

export default compose(withRouter)(ImageWrapperTemplate);
