export default [
  {
    name: "Article",
    hasSubTitle: false,
    hasLine: false,
    showLangMessage: true,
    data: {
      pt: {
        title: "Sobre as notícias",
        content:
        "\n##Estatuto editorial\n\nA Área de Notícias da Universidade de Aveiro é uma plataforma de informação e divulgação, onde se publicam as notícias de diversos campos de atividade da Universidade, numa tentativa de corresponder aos interesses da comunidade académica e do seu público. \n\nOs principais objetivos da área de notícias deste portal são dar expressão ao dever de informar e ao direito de ser informado, promovendo, simultaneamente, o conhecimento sobre o que é e sobre o que se faz na Universidade. Este depende essencialmente da comunidade em que se insere e, como tal, encontra-se aberto à participação responsável de todos os membros da comunidade UA. \n\nEsta plataforma de notícias assume o direito à liberdade de criação e expressão, num compromisso de estreito respeito pelos Estatutos da Universidade de Aveiro e seus valores, encontrando-se aberto à partilha e difusão dos seus conteúdos, desde que efetuadas com indicação da respetiva fonte. \n\nA Área de Notícias da Universidade tenta explorar as potencialidades do meio eletrónico, de forma criativa, numa permanente interação com a comunidade e com os seus leitores.  \n\n##Ficha Técnica\n\n###Serviços de Comunicação Imagem e Relações Públicas\n\n###Coordenação\n\n* [Constança Mendonça](https://www.ua.pt/pt/p/10313869)\n\n###Edição e Revisão\n\n* [Elsa Santos](https://www.ua.pt/pt/p/80649498)\n* [João Afonso Correia](https://www.ua.pt/pt/p/10341092)\n* [Mariana Pires da Rosa](https://www.ua.pt/pt/p/80519221)\n* [Pedro Farias](https://www.ua.pt/pt/p/10399045)\n\n###Outros editores\n\nPivots de unidades orgânicas, unidades de investigação, serviços e outras estruturas da UA\n\n"
          // "\n##Estatuto editorial\n\nA Área de Notícias da Universidade de Aveiro é uma plataforma de informação e divulgação, onde se publicam as notícias de diversos campos de atividade da Universidade, numa tentativa de corresponder aos interesses da comunidade académica e do seu público. \n\nOs principais objetivos da área de notícias deste portal são dar expressão ao dever de informar e ao direito de ser informado, promovendo, simultaneamente, o conhecimento sobre o que é e sobre o que se faz na Universidade. Este depende essencialmente da comunidade em que se insere e, como tal, encontra-se aberto à participação responsável de todos os membros da comunidade UA. \n\nEsta plataforma de notícias assume o direito à liberdade de criação e expressão, num compromisso de estreito respeito pelos Estatutos da Universidade de Aveiro e seus valores, encontrando-se aberto à partilha e difusão dos seus conteúdos, desde que efetuadas com indicação da respetiva fonte. \n\nA Área de Notícias da Universidade tenta explorar as potencialidades do meio eletrónico, de forma criativa, numa permanente interação com a comunidade e com os seus leitores.  \n\n##Ficha Técnica\n\n###Serviços de Comunicação Imagem e Relações Públicas\n\n###Coordenação\n\n* [Paula Rocha](https://www.ua.pt/pt/p/80574479)\n\n###Edição e Revisão\n\n* [Constança Mendonça](https://www.ua.pt/pt/p/10313869)\n* [João Afonso Correia](https://www.ua.pt/pt/p/10341092)\n* [Pedro Farias](https://www.ua.pt/pt/p/10399045)\n\n###Outros editores\n\nPivots de unidades orgânicas, unidades de investigação, serviços e outras estruturas da UA\n\n"
      },
      en: {
        title: "About us",
        content:
        "\n##Editorial Statute \n\nThe news area of the University of Aveiro  is an information and dissemination platform, where news from various fields of activity of the University are published, aimed at corresponding to the needs of the academic community and its publics.\n\nThe main objectives of this news area concern the duty to inform and the right to be informed, simultaneously promoting the University among the readers. Its maintenance depends mainly on the Aveiro academic community, therefore it’s open to the participation of all its the members. \n\nThis space feels the right to the freedom of creativity and expression, in a compromise of close respect for the Statutes of The University of Aveiro and for the values of the academy.\n\nThis news portal it´s open to share and disseminate its contents, since the respective source is mentioned. The news area will try to explore the technical potentialities of this new electronic means, in a creative way, in a continuous interaction between the university community and the readers. \n\n##Communication, image and public relations services \n\n###Coordination\n\n* [Constança Mendonça](https://www.ua.pt/en/p/10313869)\n\n###Editing and review\n\n* [Elsa Santos](https://www.ua.pt/en/p/80649498)\n* [João Afonso Correia](https://www.ua.pt/en/p/10341092)\n* [Mariana Pires da Rosa](https://www.ua.pt/en/p/80519221) \n* [Pedro Farias](https://www.ua.pt/en/p/10399045)\n\n###Other editors \n\nPivots of organic units, research units, services and other UA structures\n\n"
          // "\n##Editorial Statute \n\nThe news area of the University of Aveiro  is an information and dissemination platform, where news from various fields of activity of the University are published, aimed at corresponding to the needs of the academic community and its publics.\n\nThe main objectives of this news area concern the duty to inform and the right to be informed, simultaneously promoting the University among the readers. Its maintenance depends mainly on the Aveiro academic community, therefore it’s open to the participation of all its the members. \n\nThis space feels the right to the freedom of creativity and expression, in a compromise of close respect for the Statutes of The University of Aveiro and for the values of the academy.\n\nThis news portal it´s open to share and disseminate its contents, since the respective source is mentioned. The news area will try to explore the technical potentialities of this new electronic means, in a creative way, in a continuous interaction between the university community and the readers. \n\n##Communication, image and public relations services \n\n###Coordination\n\n* [Paula Rocha](https://www.ua.pt/en/p/80574479)\n\n###Editing and review\n\n* [Constança Mendonça](https://www.ua.pt/en/p/10313869)\n* [João Afonso Correia](https://www.ua.pt/en/p/10341092)\n* [Pedro Farias](https://www.ua.pt/en/p/10399045)\n\n###Other editors \n\nPivots of organic units, research units, services and other UA structures\n\n"
      }
    }
  }
];
