import { useEffect } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

const Portal = ({ children }) => {
  const el = document.createElement("div");
  const portalRoot = document.getElementById("portal");

  useEffect(() => {
    portalRoot.appendChild(el);
    return () => portalRoot.removeChild(el);
  }, [children]);

  return ReactDOM.createPortal(children, el);
};

Portal.propTypes = {
  children: PropTypes.node.isRequired
};

export default Portal;
