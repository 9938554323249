export default [
  {
    name: "SEO",
    title: {
      pt: "Cursos",
      en: "Courses"
    },
    description: "",
    keywords: []
  },
  {
    name: "ContentList",
    title: "Cursos",
    resource: "app.nav.education.item.courses",
    titleColor: "#000",
    textColor: "#000",
    textWeight: "300",
    textSize: "14px",
    textLineHeight: "1.6",
    hasLine: false,
    endpoint: "/coursestypes"
  }
];
