import React, { Fragment } from "react";
import PropTypes from "prop-types";
//Components
import { Link } from "../../../atoms";
import { InstituteSearch } from "../../../molecules";
import {
  Section,
  SectionHeader,
  ContentContainer,
  Select,
  Table,
  TableLoading,
  MarkdownRenderer,
  Error,
  DatePicker,
  BorderedCard,
  Button,
  Pagination,
  Text
} from "@uaveiro/ui";

import { withTheme } from "styled-components";
import { FormattedMessage, injectIntl } from "react-intl";
import moment from "moment";
import { withSize } from "react-sizeme";
import { compose } from "../../../../services/utilities";

const Card = ({
  link,
  href,
  ariaLabel,
  theme,
  activeColor,
  endDate,
  startDate
}) => {
  return (
    <BorderedCard
      borderColor={theme.components.projects.projectList.card.borderColor}
      cardIsLink={false}
    >
      <Link
        color={theme.components.projects.projectList.card.linkColor}
        fontSize="15px"
        lineHeight="1.6"
        fontWeight="300"
        aria-label={ariaLabel}
        href={href}
        default={true}
        textDecorationColor={theme.highContrast.link || activeColor}
      >
        {link}
      </Link>
      <Text
        size="article"
        color={theme.components.projects.projectList.card.textColor}
        marginTop="10px"
      >
        {startDate}
        {" a "}
        {endDate}
      </Text>
    </BorderedCard>
  );
};

Card.defaultProps = {
  link: "Texto",
  startDate: "Texto",
  endDate: "Texto"
};

const ProjectList = ({
  className,
  theme,
  intl,
  activeColor,
  defaultContent,
  data,
  filters,
  loading,
  hasError,
  lang,
  onChangePage,
  currentPage,
  onChangeSearchValue,
  onHandleSearch,
  searchValue,
  onClearSearch,
  onChangeFilter,
  onChangeStartDate,
  onChangeEndDate,
  startDateValue,
  endDateValue,
  onEnterSearch,
  size
}) => {
  const onRenderSelectOption = data =>
    data.map(item => {
      return {
        value: item,
        label: item
      };
    });

  return (
    <Section
      className={className}
      paddingTop="70px"
      paddingBottom="70px"
      hasHighContrastBorderTop={false}
    >
      <div className="container">
        <div className="row no-gutters">
          <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-4 col-12 offset-0">
            <SectionHeader
              hasContainer={false}
              hasLink={false}
              hasLine={false}
              textColor={theme.components.projects.projectList.titleColor}
            >
              {defaultContent.title}
            </SectionHeader>

            <Text size="small" marginBottom="50px">
              <MarkdownRenderer
                content={defaultContent.content}
                activeColor={activeColor}
              />
            </Text>

            <Text
              size="large"
              marginBottom="30px"
              fontWeight="400"
              color={theme.highContrast.text || activeColor}
            >
              <FormattedMessage
                id="app.projects.projectsList.search.title"
                defaultMessage="Pesquisar Projetos"
              />
            </Text>

            <InstituteSearch
              onChange={onChangeSearchValue}
              onKeyPress={onEnterSearch}
              value={searchValue}
              clear={onClearSearch}
              removeLeftIcon={true}
              showClear={searchValue}
              type="projects"
              underlineAnimationColor={theme.highContrast.action || activeColor}
              isFocus={false}
            />

            <div className="row">
              <div className="col-md-6 col-12">
                <ContentContainer marginTop="20px" smMargintop="15px">
                  <DatePicker
                    value={startDateValue}
                    calendarPrimaryColor={activeColor}
                    placeholder={intl.formatMessage({
                      id: "app.projects.projectsList.filter.startDate",
                      defaultMessage: "data de início"
                    })}
                    onChange={onChangeStartDate}
                  />
                </ContentContainer>
              </div>
              <div className="col-md-6 col-12">
                <ContentContainer marginTop="20px" smMargintop="15px">
                  <DatePicker
                    value={endDateValue}
                    calendarPrimaryColor={activeColor}
                    placeholder={intl.formatMessage({
                      id: "app.projects.projectsList.filter.endDate",
                      defaultMessage: "data de fim"
                    })}
                    onChange={onChangeEndDate}
                  />
                </ContentContainer>
              </div>

              {/**
                 <div className="col-md-5 col-sm-12">
                  <ContentContainer smMargintop="15px">
                    <Select
                      variant="black"
                      isLoading={loading}
                      // options={filter}
                      placeholder={intl.formatMessage({
                        id: "app.projects.projectsList.filter.entity",
                        defaultMessage: "entidate financiadora"
                      })}
                      label="entidate financiadora"
                      isClearable
                    />
                  </ContentContainer>
                </div>
               */}

              {filters.data.length > 0 &&
                filters.data.map((item, index) => {
                  return (
                    <div key={index} className="col-md-6 col-12">
                      <ContentContainer marginTop="20px" smMargintop="15px">
                        <Select
                          variant="black"
                          options={onRenderSelectOption(item.values)}
                          onChange={option => onChangeFilter(option, item.name)}
                          placeholder={item.title}
                          label={item.title}
                          inputProps={{ "aria-label": item.title }}
                          isClearable
                        />
                      </ContentContainer>
                    </div>
                  );
                })}
            </div>

            <ContentContainer marginTop="30px">
              <Button
                onClick={onHandleSearch}
                variant="default"
                border={`1px solid ${theme.highContrast.action || activeColor}`}
                contentColor={theme.highContrast.action || activeColor}
                hoverBackgroundColor={theme.highContrast.action || activeColor}
              >
                <FormattedMessage
                  id="app.projects.projectsList.search.button"
                  defaultMessage="Pesquisar"
                />
              </Button>
            </ContentContainer>

            {loading ? (
              <TableLoading />
            ) : hasError ? (
              <Error />
            ) : (
              <Fragment>
                {size.width >= 575 ? (
                  <Table
                    marginTop="20px"
                    col2Size="120px"
                    col3Size="90px"
                    col2SizeMobile="105px"
                    col3SizeMobile="75px"
                    borders={true}
                  >
                    <thead>
                      <tr>
                        <th>
                          <Text
                            size="article"
                            lineHeight="1.6"
                            fontWeight="400"
                          >
                            <FormattedMessage
                              id="app.projects.projectsList.table.title"
                              defaultMessage="título"
                            />
                          </Text>
                        </th>
                        <th>
                          <Text
                            size="article"
                            lineHeight="1.6"
                            fontWeight="400"
                          >
                            <FormattedMessage
                              id="app.projects.projectsList.filter.startDate"
                              defaultMessage="data de início"
                            />
                          </Text>
                        </th>
                        <th>
                          <Text
                            size="article"
                            lineHeight="1.6"
                            fontWeight="400"
                          >
                            <FormattedMessage
                              id="app.projects.projectsList.filter.endDate"
                              defaultMessage="data de conclusão"
                            />
                          </Text>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.list && Object.keys(data.list).length <= 0 ? (
                        <tr>
                          <FormattedMessage
                            id="app.search.empty"
                            defaultMessage="Sem conteúdos para a pesquisa"
                          />
                        </tr>
                      ) : (
                        data.list.map(item => {
                          let url = `${lang}/${
                            lang === "pt" ? "projetos-id" : "id-projects"
                          }`;

                          return (
                            <tr key={item.id}>
                              <td>
                                <Link
                                  fontSize="15px"
                                  lineHeight="1.6"
                                  fontWeight="300"
                                  aria-label={item.title}
                                  href={`/${url}/${item.id}`}
                                  default={true}
                                  color={
                                    theme.components.projects.projectList.table
                                      .linkColor
                                  }
                                  textDecorationColor={
                                    theme.highContrast.link || activeColor
                                  }
                                >
                                  {item.title}
                                </Link>
                              </td>
                              <td>
                                <Text
                                  size="article"
                                  lineHeight="1.6"
                                  fontWeight="300"
                                >
                                  {moment(item.startDate).format("DD/MM/YY")}
                                </Text>
                              </td>
                              <td>
                                <Text
                                  size="article"
                                  lineHeight="1.6"
                                  fontWeight="300"
                                >
                                  {moment(item.endDate).format("DD/MM/YY")}
                                </Text>
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </Table>
                ) : (
                  <ContentContainer marginTop="30px">
                    {data.list && Object.keys(data.list).length <= 0 ? (
                      <FormattedMessage
                        id="app.search.empty"
                        defaultMessage="Sem conteúdos para a pesquisa"
                      />
                    ) : (
                      data.list.map(item => {
                        let url = `${lang}/${
                          lang === "pt" ? "projetos-id" : "id-projects"
                        }`;

                        return (
                          <Card
                            key={item.id}
                            link={item.title}
                            startDate={moment(item.startDate).format(
                              "DD/MM/YY"
                            )}
                            endDate={moment(item.endDate).format("DD/MM/YY")}
                            ariaLabel={item.title}
                            href={`/${url}/${item.id}`}
                            theme={theme}
                            activeColor={activeColor}
                          />
                        );
                      })
                    )}
                  </ContentContainer>
                )}
              </Fragment>
            )}

            {Object.keys(data).length > 0 && (
              <ContentContainer
                position="relative"
                left="-10px"
                className="d-flex"
                marginTop="30px"
              >
                <Pagination
                  mainColor={activeColor}
                  onChange={onChangePage}
                  current={currentPage}
                  total={data.pagination ? data.pagination.totalRecords : 0}
                />
              </ContentContainer>
            )}
          </div>
        </div>
      </div>
    </Section>
  );
};

ProjectList.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.object,
  intl: PropTypes.object,
  activeColor: PropTypes.string.isRequired,
  defaultContent: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  lang: PropTypes.string.isRequired,
  onChangePage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  onChangeSearchValue: PropTypes.func.isRequired,
  onHandleSearch: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  onClearSearch: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onChangeStartDate: PropTypes.func.isRequired,
  onChangeEndDate: PropTypes.func.isRequired,
  onResetFilters: PropTypes.func.isRequired,
  startDateValue: PropTypes.string.isRequired,
  endDateValue: PropTypes.string.isRequired
};

export default compose(withTheme, withSize())(injectIntl(ProjectList));
