import React, { useState, useEffect, useContext, Fragment } from "react";
import PropTypes from "prop-types";
//Styles
import styled, { withTheme } from "styled-components";
import { darken } from "polished";
//Components
import { Link } from "../../atoms/index";

import {
  Title,
  Text,
  Line,
  Icon,
  ScreenReaderText,
  ContentContainer,
  ListItem,
  VerticalList,
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody,
  AccordionTitle
} from "@uaveiro/ui";

import Loading from "./Loading";
import { withRouter } from "react-router-dom";
//Context
import { StoreContext } from "../../../context/StoreContext";
//locales
import { FormattedMessage } from "react-intl";
import { isIE } from "../../../services/utilities";
import { lang } from "../../../translations";
//API
import { getSideNavByPage } from "../../../api/requests";

const classes = {
  initial: "closed-side-nav",
  close: "close-side-nav",
  open: "open-side-nav"
};

const heightTransitionTime = "0.4s";

const SideNav = withRouter(
  ({ backgroundColor, className, location, theme, history }) => {
    const { initial, close, open } = classes;
    const [isOpen, setOpen] = useState(false);
    const [isVisible, setVisible] = useState(false);
    const [menuClass, setMenuClass] = useState(initial);

    const GlobalStore = useContext(StoreContext);

    const [state, setState] = useState({
      loading: false,
      error: false,
      content: {},
      hasNavigation: false
    });

    const {
      state: { activeColor, activeView, showSideNav }
    } = GlobalStore;

    const onHandleAnimation = e => {
      e.preventDefault();
      if (isOpen) {
        setMenuClass(close);
        setTimeout(() => {
          setOpen(false);
        }, 600);
        return;
      }
      setMenuClass(open);
      setOpen(true);
    };

    const handleAnimationStart = () => {
      if (!isVisible) setVisible(isOpen);
    };

    const handleAnimationEnd = () => {
      setVisible(isOpen);
    };

    const handleScroll = () => {
      if (window.scrollY >= 150 && window.innerWidth >= 992) {
        setOpen(false);
        setMenuClass(initial);
      }
    };

    // close sidenav on route change
    useEffect(() => {
      setOpen(false);
      setMenuClass(initial);
    }, [location.pathname]);

    useEffect(() => {
      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    //If the sidenav changes reset open state
    useEffect(() => {
      setOpen(false);
    }, [state.content.tabs]);

    const onFetchSideNav = async (activeView = "aboutua") => {
      try {
        const data = await getSideNavByPage({
          activeView,
          redirect: false,
          history,
          lang: lang()
        });
        setState(prevState => {
          return { ...prevState, content: data, loading: false };
        });
      } catch (error) {
        setState(prevState => {
          return { ...prevState, loading: false, error: true };
        });
      }
    };

    useEffect(() => {
      const blacklist = [
        `/${lang()}/pesquisa/a/`,
        `/${lang()}/search/a/`,
        `/${lang()}/pesquisa/p/`,
        `/${lang()}/search/p/`,
        `/${lang()}/pesquisa/n/`,
        `/${lang()}/search/n/`,
        `/${lang()}/pesquisa/l/`,
        `/${lang()}/search/l/`,
        `/${lang()}/404`
      ];

      if (blacklist.indexOf(location.pathname) > -1) {
        setState(prevState => {
          return {
            ...prevState,
            hasNavigation: false
          };
        });
      }
    }, [location]);

    useEffect(() => {
      if (activeView !== "internal") {
        setState(prevState => {
          return {
            ...prevState,
            content: {},
            loading: true,
            hasNavigation: showSideNav
          };
        });

        if (showSideNav) {
          onFetchSideNav(activeView);
        }
      }
    }, [activeView, showSideNav]);

    if (!state.hasNavigation) return null;

    const highContrastTextColor = theme.highContrast.text;
    const highContrastLinkColor = theme.highContrast.link;
    const highContrastActionColor = theme.highContrast.action;

    return (
      <nav className={className}>
        <ScreenReaderText>
          <Title text="Navegação Lateral" />
        </ScreenReaderText>
        {state.loading ? (
          <Loading />
        ) : state.error ? (
          <div>
            <p>Ocorreu um erro ao carregar a navegação lateral</p>
          </div>
        ) : (
          <Fragment>
            <div className="side-nav-container">
              <ContentContainer
                padding="20px 0 10px 0"
                mdPadding="10px 0 10px 0"
                backgroundColor={
                  theme.highContrast.lightBackgroundColor || activeColor
                }
                onClick={onHandleAnimation}
              >
                <div className="container">
                  <div className="row align-items-center no-gutters">
                    <div className="col">
                      <Title
                        fontSize="20px"
                        color={
                          highContrastTextColor || state.content.titleColor
                        }
                        marginBottom="0"
                      >
                        {state.content.resource ? (
                          <FormattedMessage
                            id={state.content.resource}
                            defaultMessage={state.content.title}
                          />
                        ) : (
                          state.content.title
                        )}
                      </Title>
                      {state.content.hasSubTitle && (
                        <Text
                          fontSize="18px"
                          color={
                            highContrastTextColor || state.content.subTitleColor
                          }
                          text={state.content.subTitle}
                        />
                      )}
                    </div>
                    <div className="col-auto sn-mobile-menu">
                      <button
                        onClick={onHandleAnimation}
                        aria-label={isOpen ? "Fechar Submenu" : "Abrir Submenu"}
                      >
                        <Icon
                          name="fal fa-ellipsis-v"
                          color={
                            highContrastActionColor || state.content.titleColor
                          }
                          size="large"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </ContentContainer>
            </div>
            <div
              className={`side-nav-dropdown ${menuClass}`}
              onAnimationStart={() => handleAnimationStart()}
              onAnimationEnd={() => handleAnimationEnd()}
            >
              <div className="container">
                <div className="row">
                  <div className="col">
                    <Accordion key={activeView}>
                      {state.content !== undefined &&
                        state.content.tabs.map((res, index) => {
                          let isResPath =
                            `${location.pathname}` === `/${lang()}/${res.url}`;
                          let resColor = "#000";

                          let isExternal = res.url.includes("http");

                          return (
                            <AccordionItem key={index}>
                              <AccordionItemTitle tabIndex={-1}>
                                <AccordionTitle
                                  name={`tab-${res.title}`}
                                  hasLinks={res.hasLinks}
                                  baseLinkUrl={
                                    isExternal
                                      ? res.url
                                      : `/${lang()}/${res.url}`
                                  }
                                  color={
                                    isResPath
                                      ? highContrastTextColor || activeColor
                                      : highContrastLinkColor || resColor
                                  }
                                  fontSize="16px"
                                  fontWeight={400}
                                  hoverColor={activeColor}
                                  native={isExternal}
                                >
                                  {res.resource ? (
                                    <FormattedMessage
                                      id={res.resource}
                                      defaultMessage={res.title}
                                    />
                                  ) : (
                                    res.title
                                  )}
                                </AccordionTitle>
                              </AccordionItemTitle>
                              {res.hasLinks && (
                                <AccordionItemBody>
                                  <VerticalList>
                                    {res.links.map((item, index) => {
                                      let isChildPath =
                                        `${location.pathname}` ===
                                        `/${lang()}/${item.url}`;

                                      let childColor = "#000";

                                      let isChildExternal = item.url.includes(
                                        "http"
                                      );

                                      return (
                                        <ListItem
                                          key={index}
                                          paddingTop="6px"
                                          paddingBottom="6px"
                                        >
                                          <Link
                                            tabIndex={0}
                                            key={index}
                                            href={`/${lang()}/${item.url}`}
                                            aria-label={item.text}
                                            tabItem={isVisible ? "0" : "-1"}
                                            fontDecoration={
                                              backgroundColor !== "#fff"
                                                ? "none"
                                                : "underline"
                                            }
                                            native={isChildExternal}
                                          >
                                            <Text
                                              className="active"
                                              color={
                                                isChildPath
                                                  ? highContrastTextColor ||
                                                    activeColor
                                                  : highContrastLinkColor ||
                                                    childColor
                                              }
                                              fontSize="14px"
                                              fontWeight={300}
                                              hoverColor={activeColor}
                                              hasHover={
                                                backgroundColor !== "#fff"
                                              }
                                            >
                                              {item.resource ? (
                                                <FormattedMessage
                                                  id={item.resource}
                                                  defaultMessage={item.text}
                                                />
                                              ) : (
                                                item.text
                                              )}
                                            </Text>
                                          </Link>
                                        </ListItem>
                                      );
                                    })}
                                  </VerticalList>
                                </AccordionItemBody>
                              )}
                            </AccordionItem>
                          );
                        })}
                    </Accordion>
                  </div>
                </div>
              </div>
              {state.content.hasExtraLinks && (
                <ContentContainer padding="0 0 12px 0">
                  <Line
                    width="100%"
                    height="1px"
                    color="#d1d2d4"
                    marginTop="0"
                    marginBottom="12px"
                  />
                  <div className="container">
                    <Accordion>
                      <AccordionItem>
                        <AccordionItemTitle>
                          <AccordionTitle
                            name={`tab-${state.content.links.title}`}
                            {...state.content.links.styles}
                          >
                            {state.content.links.title}
                          </AccordionTitle>
                        </AccordionItemTitle>
                        <AccordionItemBody>
                          <VerticalList>
                            {state.content.links.data.map((item, index) => {
                              return (
                                <ListItem key={index} marginBottom="5px">
                                  <Link
                                    href={item.url}
                                    aria-label={item.text}
                                    {...item.styles}
                                  >
                                    {item.text}
                                  </Link>
                                </ListItem>
                              );
                            })}
                          </VerticalList>
                        </AccordionItemBody>
                      </AccordionItem>
                    </Accordion>
                  </div>
                </ContentContainer>
              )}
            </div>
          </Fragment>
        )}
      </nav>
    );
  }
);

SideNav.defaultProps = {
  location: {
    pathname: null
  }
};

SideNav.propTypes = {
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
};

const SideNavWithStyles = styled(SideNav)`
  width: 240px;
  position: ${isIE() ? "absolute" : "sticky"};
  z-index: 9;
  cursor: pointer;
  box-shadow: ${props => props.theme.components.sideNav.desktopBoxShadow};
  -moz-box-shadow: ${props => props.theme.components.sideNav.desktopBoxShadow};
  -webkit-box-shadow: ${props =>
    props.theme.components.sideNav.desktopBoxShadow};
  -o-box-shadow: ${props => props.theme.components.sideNav.desktopBoxShadow};

  // Large devices (desktops, 992px and down)
  @media (max-width: 992px) {
    box-shadow: ${props => props.theme.components.sideNav.mobileBoxShadow};
    -moz-box-shadow: ${props => props.theme.components.sideNav.mobileBoxShadow};
    -webkit-box-shadow: ${props =>
      props.theme.components.sideNav.mobileBoxShadow};
    -o-box-shadow: ${props => props.theme.components.sideNav.mobileBoxShadow};
  }

  button {
    background-color: transparent;
    border: none;
    :hover {
      i {
        color: ${props =>
          darken(0.5, props.theme.components.sideNav.iconColor)};
      }
    }
  }

  .side-nav-container {
    width: 240px;
    //highContrast only border
    border-bottom: ${props => props.theme.highContrast.border};
  }

  .side-nav-item {
    margin-bottom: 10px;
    :last-child {
      margin-bottom: 0;
    }
  }

  .sn-mobile-menu {
    display: ${props => (props.scrollPostion >= 41 ? "block" : "none")};
  }

  ${props =>
    props.scrollPostion >= 41 &&
    `.closed-side-nav {
      display: none;
    };`}

  .accordion__item {
    border: none !important;
  }

  .accordion__body li:first-child {
    padding-top: 0;
  }

  .side-nav-dropdown {
    background-color: ${props =>
      props.theme.components.sideNav.backgroundColor};
    overflow: hidden;
    .container {
      padding-top: 20px;
      padding-bottom: 10px;
    }
  }

  // Medium devices (tablets, more than 992px)
  @media (min-width: 991px) {
    .side-nav-container {
      cursor: ${props => (props.scrollPostion >= 41 ? "pointer" : "default")};
    }
    .sn-mobile-menu {
      visibility: hidden;
    }
    .close-side-nav {
      ${props =>
        props.scrollPostion >= 41 ? "display: none;" : "display: block"}
    }
  }

  // Medium devices (tablets, less than 992px)
  @media (max-width: 991px) {
    top: calc(165px - 20px);
    width: 100%;

    .side-nav-container {
      width: 100%;
    }

    .side-nav-dropdown {
      overflow: hidden;
    }

    /*Open Icon*/
    .sn-mobile-menu {
      display: block;
    }

    .closed-side-nav {
      max-height: 0;
      height: 0;
      visibility: hidden;
    }

    /*Animation*/
    .open-side-nav {
      transition: all ${heightTransitionTime} ease-in;
      visibility: visible;
      max-height: 500px;
      .accordion__item {
        -webkit-animation: fade-in-sn-items 0.6s ease-in;
        -moz-animation: fade-in-sn-items 0.6s ease-in;
        animation: fade-in-sn-items 0.6s ease-in;
      }
    }

    .close-side-nav {
      transition: all ${heightTransitionTime} ease-out;
      visibility: hidden;
      max-height: 0;
      .accordion__item {
        -webkit-animation: fade-out-sn-items 0.2s ease-out forwards;
        -moz-animation: fade-out-sn-items 0.2s ease-out forwards;
        animation: fade-out-sn-items 0.2s ease-out forwards;
      }
    }

    /*Fade items*/
    @keyframes fade-out-sn-items {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }

    @keyframes fade-in-sn-items {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
`;

export default withTheme(SideNavWithStyles);
