import React, { useEffect, useState, useContext, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//Components
import { Courses } from "@uaveiro/ui";
import SEO from "../SEO";
//Hocs
import { compose } from "../../services/utilities";
//Context
import { ContentContext } from "../../context/ContentContext";
//API
import { getCoursesListById } from "../../api/requests";
//Utils
import { hasHash } from "../../services/constants";

function CoursesTemplate({ match, location, hasStaticData, data, ...props }) {
  const context = useContext(ContentContext);

  const [filters, setFilters] = useState([]);
  const [filterValue, setFilterValue] = useState({});
  const [list, setList] = useState([]);
  const [staticList, setStaticList] = useState([]);

  const [state, setState] = useState({
    loading: true,
    error: false,
    content: {},
    filters: []
  });

  useEffect(() => {
    onFetchData();
  }, [match]);

  const onFetchData = async () => {
    try {
      const { lang, id, type } = match.params;
      //const hasSlug = location.state !== undefined && location.state.slug;
      //const type = hasSlug ? location.state.type : match.params.type;
      //const id = hasSlug ? location.state.value : match.params.id;

      let onHandleData = {};
      const { coursesList } = context.state; //local store
      //checks if the data is already loaded on local store
      if (coursesList.id === id && coursesList.type === type) {
        onHandleData = coursesList.data;
      } else {
        //if the data is static
        if (hasStaticData) {
          onHandleData = data.data;
        } else {
          const res = await getCoursesListById({
            id: isNaN(id) ? 0 : id,
            type,
            lang,
            slug: isNaN(id) ? id : "",
            history: props.history
          });

          //updates the store with the new courses list
          context.dispatch({
            type: "update-courses-list",
            payload: { id, type, ...res }
          });
          onHandleData = res.data;
        }
      }
      const { list, filters, ...rest } = onHandleData;

      setFilters(filters);
      setList(list);
      setStaticList(list);

      setState(prevState => {
        return { ...prevState, content: { ...rest }, loading: false };
      });
    } catch (error) {
      setState(prevState => {
        return { ...prevState, loading: false, error: true };
      });
    }
  };

  const onChangeFilter = (option, jsonField) => {
    const filterObj = {
      ...filterValue
    };
    filterObj[jsonField] = option || {
      value: ""
    };

    const filters = Object.keys(filterObj);
    const filteredData = filters.reduce((acc, filterKey) => {
      const filterValue = filterObj[filterKey].value;
      const isUnfiltered = filterValue === "";
      const filteredList = acc.filter(
        course => isUnfiltered || course[filterKey].includes(filterValue)
      );

      return filteredList;
    }, staticList);

    setFilterValue(filterObj);
    setList(filteredData);
  };

  const coursesProps = {
    onFilter: onChangeFilter,
    filters,
    lang: match.params.lang,
    hasHash,
    ...props
  };

  return (
    <Fragment>
      {!state.loading && <SEO title={state.content.title} />}
      <Courses
        content={state.content}
        data={list}
        loading={state.loading}
        {...coursesProps}
      />
    </Fragment>
  );
}

CoursesTemplate.propTypes = {
  match: PropTypes.object.isRequired,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  hasStaticData: PropTypes.bool
};

export default compose(withRouter)(CoursesTemplate);
