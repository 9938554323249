export default [
  {
    name: "Breadcrumbs",
    data: {
      pt: [
        { text: "UA", url: "/" },
        { text: "investigar", url: "/investigacao" },
        { text: "atividade científica", url: "/atividade-cientifica" },
        { text: "projetos i&d", url: "/projetos-id" }
      ],
      en: [
        { text: "UA", url: "/" },
        { text: "research", url: "/uaresearch" },
        { text: "scientific activity", url: "/scientific-activity" },
        { text: "r&d projetos", url: "/id-projects" }
      ]
    }
  },
  {
    name: "ProjectsList",
    data: {
      pt: {
        title: "Projetos I&D",
        content:
          "\nProdutos e soluções inovadoras são desenvolvidos na Universidade de Aveiro para contribuir para o avanço da ciência e tecnologia.  A Universidade é um parceiro privilegiado de empresas e outras organizações nacionais e internacionais com as quais coopera em numerosos projetos e para os quais presta serviços importantes.\n\nDurante 2021, 664 projetos nacionais e internacionais de investigação e desenvolvimento e transferência de tecnologia estiveram ativos na UA, dos quais 51 financiados pelo programa ERASMUS + e 57 pelo programa Horizonte 2020. A UA é ​​instituição de acolhimento de 10 bolsas ERC e 1 ERAChair, coordena 2 projetos FET Open e um projeto TWINNING, entre outros projetos europeus estratégicos.\n\nConsulte os projetos de investigação financiados por fundos europeus com a coordenação ou participação de investigadores da Universidade de Aveiro.\n\n Esta lista encontra-se em atualização."
      },
      en: {
        title: "R&D projects",
        content:
          "\nInnovative products and solutions are developed at the University of Aveiro to contribute to the advancement of science and technology. The University is a privileged partner of companies and other national and international organizations with whom it cooperates in numerous projects and for which it provides important services.\n\nDuring 2021, 664 national and international research and technology transfer projects have been active in UAVR, of which 51 funded by ERASMUS+ and 57 by Horizon 2020. The UAVR is the host institution of 10 ERC Grants and 1 ERA Chair, coordinates 2 FET Open and one TWINNING projects, among other relevant and strategic European projects.\n\n See  projects unded by European funds with the coordination or participation of researchers from the University of Aveiro.\n\n The project list is still being updated.\n\n"
      }
    }
  }
];
