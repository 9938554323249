import React, { useEffect, useState, memo } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//Components
import { Directory } from "../../components/organisms";
//Utils
import { portalAPI, hasHash } from "../../services/constants";
import { compose } from "../../services/utilities";
import queryString from "query-string";
//API
import { getSearchDirectory } from "../../api/requests";

const alphabet = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z"
];

const DirectoryTemplate = memo(function DirectoryTemplate({
  endpoint,
  match,
  history,
  ...props
}) {
  const [isLoading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [hasError, setError] = useState(false);
  const [content, setContent] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [letter, setLetter] = useState("");

  const { lang } = match.params;

  const onFetchData = async (search = "", offset = 0, letter = "A") => {
    try {
      const searchQuery = search && `&searchString=${search}`;
      const data = await getSearchDirectory({
        search: searchQuery,
        endpoint,
        offset,
        letter,
        lang,
        history
      });

      setContent(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    setLoading(true);
    const values = queryString.parse(history.location.search);
    const letter = values.l || "";
    const page = Number(values.o) || 0;
    const offset = (page - 1) * 10;

    setSearchValue(values.q);
    setCurrentPage(page === 0 ? 1 : page);
    setLetter(letter.toUpperCase());
    onFetchData(values.q, offset, letter);
  }, [history, match]);

  const onReplaceUrl = (letter = "A", offset = 1) => {
    const url = `${history.location.pathname}?q=${searchValue}&l=${letter}&o=${offset}`;
    history.replace(url);
  };

  const onChangePage = page => {
    onReplaceUrl(letter, page);
    setCurrentPage(page);
    setLoading(true);
    onFetchData(searchValue, (page - 1) * 10, letter);
  };

  const onChangeLetter = (e, letter) => {
    e.preventDefault();
    e.stopPropagation();
    setLetter(letter);
    setCurrentPage(1);
    setLoading(true);
    onReplaceUrl(letter, currentPage);
  };

  const directoryProps = {
    hasHash,
    isLoading,
    hasError,
    content,
    onChangeLetter,
    onChangePage,
    alphabet,
    letter,
    currentPage,
    lang,
    ...props
  };

  return <Directory {...directoryProps} />;
});

DirectoryTemplate.propTypes = {
  endpoint: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default compose(withRouter)(DirectoryTemplate);
