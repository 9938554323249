import React, { Fragment, useRef, useEffect, useContext } from "react";
import PropTypes from "prop-types";
//Containers
import Navigation from "../Navigation";
//Pages Navigation
import { withRouter } from "react-router-dom";
import Router from "../../routes/Router";
//Styles
import GlobalStyle from "../../services/GlobalStyle";
//Components
import { Footer } from "../../components/organisms";
import { ScreenReaderText, Icon } from "@uaveiro/ui";
import { SEO } from "../../templates";
import Portal from "../Portal";
// Utils
import data from "../../data/footer";
import { StoreContext } from "../../context/StoreContext";
import { compose } from "../../services/utilities";
import { lang, changeLanguage } from "../../translations";
import { withTheme } from "styled-components";
// Context
import { ContentContext } from "../../context/ContentContext";

function App({ location, history, theme }) {
  const content = useRef(null);
  const goUpButton = useRef(null);
  const {
    state: { disableLocalStorage }
  } = useContext(ContentContext);

  useEffect(() => {
    const langValue = location.pathname.includes("/pt/") ? "pt" : "en";

    if (typeof window !== "undefined") {
      const domainUrl = window.location.href;
      const lastLocation = ["login", "error", "404"];
      const isIncluded = lastLocation.some(location =>
        domainUrl.includes(location)
      );

      try {
        if (!isIncluded && !disableLocalStorage) {
          localStorage.setItem("portalLastLocationPath", location.pathname);
        }
      } catch (error) {}
      if (!domainUrl.includes("#gsc.tab") && domainUrl.includes("#")) {
        window.location.replace(domainUrl.replace("/#", ""));
      }

      if (location.pathname.includes("styles")) {
        history.push(`/pt/404`);
      }

      //not a supported language
      if (location.pathname !== "/") {
        if (
          !location.pathname.includes("pt/") &&
          !location.pathname.includes("en/")
        ) {
          history.replace(`${lang()}${location.pathname}`);
        }
      }
    }

    const pathnameTimer = setTimeout(() => {
      //change language based on url pathname

      if (location.pathname !== "/" && !disableLocalStorage) {
        if (langValue !== lang()) {
          changeLanguage(langValue);
        }
      }
    }, 250);

    content.current.focus();

    return () => {
      clearTimeout(pathnameTimer);
    };
  }, [location.pathname, disableLocalStorage]);

  /*
  -- Scroll To Top
  */

  const checkPosition = () => {
    let windowY = window.scrollY;
    if (windowY >= 155) {
      // Scrolled more than 1536px
      goUpButton.current.classList.add("visible");
      goUpButton.current.classList.remove("hidden");
    } else {
      // Scrolled less than 1536px
      goUpButton.current.classList.add("hidden");
      goUpButton.current.classList.remove("visible");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkPosition);
    return () => {
      window.removeEventListener("scroll", checkPosition);
    };
  }, []);

  const backToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <Fragment>
      <SEO />
      {location.pathname === "/" && (
        <ScreenReaderText>
          <h1>Página inicial</h1>
        </ScreenReaderText>
      )}
      <div>
        <Navigation />
        <div>
          <span tabIndex="-1" ref={content} />
          <Router />
        </div>
      </div>
      <Footer key="footer" {...data.footer} />

      <StoreContext.Consumer>
        {store => (
          <GlobalStyle
            zoom={store.state.zoomValue}
            filter={store.state.accessibilityValue}
          />
        )}
      </StoreContext.Consumer>

      {/*Back to top*/}
      {typeof window !== "undefined" && (
        <Portal>
          <button
            ref={goUpButton}
            className="go-to-top hidden"
            onClick={backToTop}
            title="Go to top"
          >
            <Icon
              name={theme.icons.arrow_up}
              color={theme.components.goToTopButton.iconColor}
              size="small"
            />
          </button>
        </Portal>
      )}
    </Fragment>
  );
}

App.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default compose(withRouter, withTheme)(App);
