const authSecret = "@Portal_17_Web17";
const apiUrl = "https://portalua-server-qa6yq4mpm.now.sh/api";
const baseUrl = "https://www.ua.pt";
const hasHash = "";

const isModeDev = process.env.NODE_ENV !== "production";

const flagBread = true;

const views = {
  root: "aboutua",
  cooperate: "cooperate",
  education: "education",
  public: "ua",
  index: "ua",
  internal: "ua",
  international: "international",
  live: "live",
  investigation: "investigation"
};

//PORTAL UA MICRO SERVICES APIS URLS
const portalAPI = "https://api-portal.ua.pt/api/v1";
const portalWWW = "https://www.ua.pt";
const imageAPI = "https://api-assets.ua.pt/v1";

const authUrl = `${portalAPI}/authentication/login?url=portal&local=${isModeDev}`;

const authLogoutUrl = `${portalAPI}/authentication/logout`;

const authConfig = {
  login: process.env.RAZZLE_AUTH_URL_LOGIN,
  logout: process.env.RAZZLE_AUTH_URL_LOGOUT,
  clientId: process.env.RAZZLE_AUTH_CLIENT_ID,
  callbackUri: process.env.RAZZLE_AUTH_CALLBACK_URI
};

export {
  authConfig,
  authUrl,
  authLogoutUrl,
  authSecret,
  apiUrl,
  portalAPI,
  baseUrl,
  hasHash,
  views,
  portalWWW,
  imageAPI,
  flagBread,
  isModeDev
};
