import React, { useState, useRef, useEffect } from "react";
//Route
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
//Styles
import styled, { withTheme } from "styled-components";
//components
import { Link } from "../../atoms";
//Locales
import { FormattedMessage } from "react-intl";
import {
  IconCard,
  ContentContainer,
  Dropdown,
  DropdownList,
  HorizontalList,
  ListItem,
  VerticalList,
  ClickAwayListener,
  Icon,
  ScreenReaderText,
  Title,
  Picture,
  Image
} from "@uaveiro/ui";
import { withSize } from "react-sizeme";
import { compose } from "../../../services/utilities";

//Container for Avatar
const AvatarContainer = styled.button`
  height: 20px;
  width: 20px;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

//Container for Bento Icon
const MenuIconContainer = styled.button`
  height: 20px;
  width: 20px;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

const MoreLink = styled(RouterLink)`
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props =>
    props.theme.components.publicNavbar.bentoMenu.moreLinkBgColor};
  color: ${props =>
    props.theme.components.publicNavbar.bentoMenu.moreLinkColor};
  font-size: 12px;
  font-weight: 300;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const MobilePublicNavbar = ({
  className,
  isLogin,
  links,
  history,
  theme,
  size,
  user,
  authUrl,
  portalAPI,
  logout,
  servicesUrl,
  services,
  ...props
}) => {
  const [isOpen, setOpen] = useState(false);
  const openMenuRef = useRef(null);
  const [showBentoMenu, setShowBentoMenu] = useState(false);
  const [showUserMenu, setShowUserMenu] = useState(false);

  useEffect(() => {
    openMenuRef.current.classList.add("close");
    openMenuRef.current.classList.remove("open");
    setOpen(false);
  }, [history.location.pathname]);

  const onHandleAnimation = e => {
    e.preventDefault();
    if (isOpen) {
      openMenuRef.current.classList.add("close");
      openMenuRef.current.classList.remove("open");
      setTimeout(() => {
        setOpen(false);
      }, 400);
      return;
    }
    openMenuRef.current.classList.add("open");
    openMenuRef.current.classList.remove("close");
    openMenuRef.current.classList.remove("state-close");
    setOpen(true);
  };

  return (
    <nav className={className}>
      <ScreenReaderText>
        <Title text="Navegação de Públicos" />
      </ScreenReaderText>
      <div className="container">
        <div className="row no-gutters align-items-center">
          <div className="col">
            <HorizontalList
              display="inline-flex"
              spacing="0"
              className="public-social-links"
            >
              <ListItem lineHeight="18px" height="18px">
                <Link
                  href="/pt/noticias"
                  aria-label="Notícias"
                  fontSize="12px"
                  color={theme.components.mobilePublicNavBar.linksColor}
                >
                  <FormattedMessage
                    id="app.nav.public.social.news"
                    defaultMessage="Notícias"
                  />
                </Link>
              </ListItem>
              <ListItem lineHeight="18px" height="18px">
                <Link
                  href="/pt/agenda"
                  aria-label="Agenda"
                  fontSize="12px"
                  color={theme.components.mobilePublicNavBar.linksColor}
                >
                  <FormattedMessage
                    id="app.nav.public.social.agenda"
                    defaultMessage="Agenda"
                  />
                </Link>
              </ListItem>
            </HorizontalList>
          </div>

          <div className="col-auto">
            <ContentContainer
              paddingRight="12px"
              marginRight="12px"
              borderRight={`1px solid ${theme.components.publicNavbar.borderColor}`}
            >
              <Link
                className="d-flex align-items-center"
                fontSize="12px"
                onClick={onHandleAnimation}
                aria-label="Quem sou?"
                height="18px"
                color={theme.components.mobilePublicNavBar.linksColor}
              >
                <FormattedMessage
                  id="app.nav.public.who"
                  defaultMessage="Quem sou?"
                />

                <Icon
                  name={`${isOpen ? "fa fa-angle-up" : "fa fa-angle-down"} `}
                  cursor="pointer"
                  fontSize="12px"
                  marginLeft="5px"
                  color={theme.components.mobilePublicNavBar.iconColor}
                />
              </Link>
            </ContentContainer>
          </div>

          <div className="col-auto d-flex">
            {user.authenticated ? (
              <HorizontalList
                display="inline-flex"
                className="d-flex"
                spacing="0"
              >
                <ListItem marginRight="10px">
                  <Dropdown
                    display="flex"
                    top="40px"
                    right="-10px"
                    zIndex="130"
                    openMenuOnHover={false}
                  >
                    <AvatarContainer onClick={() => setShowUserMenu(true)}>
                      <Image
                        src={user.photo}
                        alt="Imagem sem alt"
                        height="100%"
                      />
                    </AvatarContainer>
                    {showUserMenu && (
                      <ClickAwayListener
                        onChange={() => setShowUserMenu(false)}
                      >
                        <DropdownList
                          color={
                            theme.components.publicNavbar.avatar.linksColor
                          }
                          fontWeight="300"
                        >
                          <VerticalList>
                            <ListItem>
                              <Link
                                size="small"
                                fontDecoration="none"
                                href="https://my.ua.pt/"
                                target="_blank"
                                native
                              >
                                My.ua
                              </Link>
                            </ListItem>
                            <ListItem>
                              <Link
                                size="small"
                                fontDecoration="none"
                                href="https://id.ua.pt/"
                                target="_blank"
                                native
                              >
                                ID.ua
                              </Link>
                            </ListItem>
                            <ListItem>
                              <Link
                                size="small"
                                fontDecoration="none"
                                href="https://www.ua.pt/"
                                target="_blank"
                                native
                              >
                                Portal UA
                              </Link>
                            </ListItem>
                            <ListItem>
                              <Link
                                as="button"
                                size="small"
                                fontDecoration="none"
                                onClick={() => logout()}
                              >
                                Sair
                              </Link>
                            </ListItem>
                          </VerticalList>
                        </DropdownList>
                      </ClickAwayListener>
                    )}
                  </Dropdown>
                </ListItem>
                <ListItem>
                  <Dropdown
                    display="flex"
                    top="40px"
                    right={size.width < 575 ? "-15px" : "-10px"}
                    minContentWidth={size.width < 575 ? "100vw" : "360px"}
                    maxContentWidth={size.width < 575 ? "100vw" : "360px"}
                    zIndex="130"
                    padding="0"
                    openMenuOnHover={false}
                  >
                    <MenuIconContainer onClick={() => setShowBentoMenu(true)}>
                      <Image
                        height="20px"
                        width="20px"
                        objectFit="initial"
                        src={theme.components.publicNavbar.bentoMenu.icon}
                      />
                    </MenuIconContainer>
                    {showBentoMenu && (
                      <ClickAwayListener
                        onChange={() => setShowBentoMenu(false)}
                      >
                        <div className="dropdown-menu">
                          <ContentContainer
                            className="d-flex flex-wrap"
                            padding="10px 4px"
                          >
                            {services.favorites.map((item, index) => {
                              return (
                                <IconCard
                                  key={index}
                                  linkText={item.text}
                                  iconName={item.icon}
                                  margin="6px 12px"
                                  linkProps={{
                                    href: item.href,
                                    native: true,
                                    target: "_blank"
                                  }}
                                />
                              );
                            })}
                          </ContentContainer>
                          <MoreLink
                            to={servicesUrl}
                            onClick={() => setShowBentoMenu(false)}
                          >
                            ver mais
                          </MoreLink>
                        </div>
                      </ClickAwayListener>
                    )}
                  </Dropdown>
                </ListItem>
              </HorizontalList>
            ) : (
              <Link
                aria-label="Entrar em sessão"
                href={authUrl}
                {...props.auth.styles}
                color={theme.components.publicNavbar.myUAColor}
                hasIconHover={false}
                className="d-flex align-items-center"
                native
              >
                <Icon {...props.auth.iconLogIn} color="inherit" />
              </Link>
            )}
          </div>
        </div>

        <div ref={openMenuRef} className="public-links state-close">
          {isOpen ? (
            <div className="container">
              <HorizontalList display="block">
                {links.map((item, index) => {
                  return (
                    <ListItem key={index}>
                      <Link
                        href={item.url}
                        target={item.target}
                        marginBottom="4px"
                        fontSize="12px"
                        aria-label={item.title}
                        fontWeight="300"
                        padding="12px 0"
                        lineHeight="1"
                        display="block"
                        color={theme.components.mobilePublicNavBar.linksColor}
                      >
                        <FormattedMessage
                          id={item.resource}
                          defaultMessage={item.title}
                        />
                      </Link>
                    </ListItem>
                  );
                })}
              </HorizontalList>
            </div>
          ) : null}
        </div>
      </div>
    </nav>
  );
};

MobilePublicNavbar.propTypes = {
  className: PropTypes.string,
  isLogin: PropTypes.bool.isRequired,
  loginClick: PropTypes.func.isRequired,
  links: PropTypes.array.isRequired,
  social: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  auth: PropTypes.object,
  services: PropTypes.shape({
    favorites: PropTypes.array.isRequired,
    all: PropTypes.array.isRequired
  })
};

MobilePublicNavbar.defaultProps = {
  name: "fa-info",
  cursor: "default",
  services: {
    favorites: [],
    all: []
  }
};

const MobilePublicNavBarWithStyles = styled(MobilePublicNavbar)`
  width: 100%;
  min-height: 40px;
  background: ${props =>
    props.theme.components.mobilePublicNavBar.backgroundColor};
  position: relative;

  .row {
    min-height: 40px;
  }

  /*
    DropDown
  */
  .dropdown {
    height: 40px;
    align-items: center;
    //Styles Avatar List
    .dropdown-content {
      li {
        display: block;
      }
    }
  }

  /*Left Social Links*/
  .public-social-links {
    li {
      padding-right: 12px;
      padding-left: 12px;
      border-left: 1px solid
        ${props => props.theme.components.publicNavbar.borderColor};
      :first-child {
        padding-left: 0;
        border-left: none;
      }
      :last-child {
        padding-right: 0;
      }
    }
  }

  /*Dropdown starts here*/
  .public-links {
    background-color: ${props =>
      props.theme.components.mobilePublicNavBar.backgroundColor};
    z-index: 1004;
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 250px;
    overflow: hidden;
    box-shadow: ${props => props.theme.components.mobilePublicNavBar.boxShadow};
    -moz-box-shadow: ${props =>
      props.theme.components.mobilePublicNavBar.boxShadow};
    -webkit-box-shadow: ${props =>
      props.theme.components.mobilePublicNavBar.boxShadow};
    -o-box-shadow: ${props =>
      props.theme.components.mobilePublicNavBar.boxShadow};
  }

  .state-close {
    display: none;
  }

  .open {
    display: block;
    -webkit-animation: open-mobile-public-nav-bar 0.3s ease-in;
    -moz-animation: open-mobile-public-nav-bar 0.3s ease-in;
    animation: open-mobile-public-nav-bar 0.3s ease-in;
    ul li {
      -webkit-animation: fade-in-mpnb-links 0.6s ease-in;
      -moz-animation: fade-in-mpnb-links 0.6s ease-in;
      animation: fade-in-mpnb-links 0.6s ease-in;
    }
  }

  .close {
    -webkit-animation: close-mobile-public-nav-bar 0.4s ease-out forwards;
    -moz-animation: close-mobile-public-nav-bar 0.4s ease-out forwards;
    animation: close-mobile-public-nav-bar 0.4s ease-out forwards;
    ul li {
      -webkit-animation: fade-out-mpnb-links 0.2s ease-out forwards;
      -moz-animation: fade-out-mpnb-links 0.2s ease-out forwards;
      animation: fade-out-mpnb-links 0.2s ease-out forwards;
    }
  }

  /*Open and Fade in links*/
  @keyframes open-mobile-public-nav-bar {
    0% {
      max-height: 0;
    }
    100% {
      max-height: 300px;
    }
  }

  @keyframes fade-in-mpnb-links {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  /*Close and Fade out links*/
  @keyframes close-mobile-public-nav-bar {
    0% {
      max-height: 300px;
    }
    100% {
      max-height: 0;
    }
  }

  @keyframes fade-out-mpnb-links {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default compose(withSize(), withTheme)(MobilePublicNavBarWithStyles);
