// todo default props
import PropTypes from "prop-types";
//Styles
import styled from "styled-components";
import { darken } from "polished";

const ContentContainer = styled.div`
  background-color: ${props => props.backgroundColor};
  border: ${props => props.border};
  border-radius: ${props => props.borderRadius};
  border-top: ${props => props.borderTop};
  border-bottom: ${props => props.borderBottom};
  border-left: ${props => props.borderLeft};
  margin: ${props => props.margin};
  margin-top: ${props => props.marginTop};
  margin-right: ${props => props.marginRight};
  margin-bottom: ${props => props.marginBottom};
  margin-left: ${props => props.marginLeft};
  padding: ${props => props.padding};
  text-align: ${props => props.textAlign};
  transform: ${props => props.transform};

  display: ${props => props.display};
  justify-content: ${props => props.justifyContent};
  flex-direction: ${props => props.flexDirection};
  align-items: ${props => props.alignItems};

  color: ${props => props.color};

  padding-top: ${props => props.paddingTop};
  padding-bottom: ${props => props.paddingBottom};
  padding-right: ${props => props.paddingRight};
  padding-left: ${props => props.paddingLeft};

  height: ${props => props.height};
  min-height: ${props => props.minHeight};
  max-height: ${props => props.maxHeight};
  z-index: ${props => props.zIndex};

  width: ${props => props.width};
  position: ${props => props.position};
  bottom: ${props => props.bottom};

  overflow: ${props => props.overflow};
  overflow-x: ${props => props.overflowX};
  text-align: ${props => props.textAlign};

  top: ${props => props.top};
  left: ${props => props.left};
  right: ${props => props.right};

  float: ${props => props.float};
  clear: ${props => props.clear};
  cursor: ${props => props.cursor};

  box-shadow: ${props => props.boxShadow};

  ${props =>
    props.verticalAlign ? "display: flex; align-items: center;" : null};

  ${props =>
    props.hasLink &&
    `:hover {
    ${props.lighten && "opacity: 0.5;"};
    ${props.pointer && "cursor: pointer;"};
  }`}

  ${props =>
    props.behavior === "button" &&
    `&:hover {
    cursor: pointer;
    background-color: ${darken(0.1, props.backgroundColor)};
  }`}

  :last-child {
    border-bottom: ${props => props.lastChildBorderBottom};
  }

  ${props =>
    props.underlineWithAnimation &&
    `:after {
      content: "";
      display: block;
      position: absolute;
      width: ${props.active ? "100%" : "0"};
      height: 3px;
      background-color: ${
        props.active ? props.underlineAnimationColor || "#000" : "transparent"
      };
      transition: width 0.4s ease;
      -webkit-transition: width 0.4s ease;
      bottom: 0px;
    }
    :hover {
      :after {
        background-color: ${props.underlineAnimationColor || "#000"};
        width: 100%;
      }
    }`};

  // Large devices (desktops, less than 1200px)
  @media (max-width: 1199px) {
    margin-top: ${props => props.lgMargintop};
    margin-right: ${props => props.mobileLgMarginRight};
    margin-bottom: ${props => props.lgMarginBottom};
    margin-left: ${props => props.mobileLgMarginLeft};

    border-bottom: ${props => props.mobileLgBorderBottom};
    border-top: ${props => props.mobileLgBorderTop};

    padding-top: ${props => props.mobileLgPaddingTop};
    padding-right: ${props => props.mobileLgPaddingRight};
    padding-bottom: ${props => props.mobileLgPaddingBottom};
    padding-left: ${props => props.mobileLgPaddingLeft};

    display: ${props => props.mobileXlDisplay};
    width: ${props => props.mobileXlWidth};
  }

  // Medium devices (tablets, less than 992px)
  @media (max-width: 991px) {
    height: ${props => props.mdHeight};
    background-color: ${props => props.mobileMdBgColor};
    display: ${props => props.mobileMdDisplay};
    top: ${props => props.mobileMdTop};

    margin-top: ${props => props.mdMargintop};
    margin-right: ${props => props.mobileMdMarginRight};
    margin-bottom: ${props => props.mdMarginBottom};
    margin-left: ${props => props.mobileMdMarginLeft};

    width: ${props => props.mobileMdWidth};

    border-bottom: ${props => props.mobileMdBorderBottom};
    border-top: ${props => props.mobileMdBorderTop};

    padding: ${props => props.mdPadding};
    padding-top: ${props => props.mobileMdPaddingTop};
    padding-bottom: ${props => props.mobileMdPaddingBottom};
  }

  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767px) {
    height: ${props => props.smHeight};
    top: ${props => props.smTop};

    margin-top: ${props => props.smMargintop};
    margin-right: ${props => props.mobileSmMarginRight};
    margin-bottom: ${props => props.smMarginbottom};
    margin-left: ${props => props.mobileSmMarginLeft};

    padding-left: ${props => props.mobileSmPaddingLeft};
    padding-right: ${props => props.mobileSmPaddingRight};
    padding-top: ${props => props.mobileSmPaddingTop};

    width: ${props => props.mobileSmWidth};
  }

  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575px) {
    width: ${props => props.mobileXsWidth};
    height: ${props => props.mobileXsHeight};
    top: ${props => props.xsTop};

    margin-top: ${props => props.mobileXsMarginTop};
    margin-right: ${props => props.mobileXsMarginRight};
    margin-left: ${props => props.mobileXsMarginLeft};
    margin-bottom: ${props => props.mobileXsMarginBottom};

    padding-top: ${props => props.mobileXsPaddingTop};
    padding-bottom: ${props => props.mobileXsPaddingBottom};

    border-bottom: ${props => props.mobileXsBorderBottom};
    border-top: ${props => props.mobileXsBorderTop};

    display: ${props => props.mobileXsDisplay};
  }
`;

ContentContainer.propTypes = {
  backgroundColor: PropTypes.string,
  border: PropTypes.string,

  marginTop: PropTypes.string,
  lgMargintop: PropTypes.string,
  mdMargintop: PropTypes.string,
  smMargintop: PropTypes.string,
  mobileXsMarginTop: PropTypes.string,

  marginBottom: PropTypes.string,
  smMarginbottom: PropTypes.string,

  marginLeft: PropTypes.string,
  mobileLgMarginLeft: PropTypes.string,
  mobileMdMarginLeft: PropTypes.string,
  mobileSmMarginLeft: PropTypes.string,
  mobileXsMarginLeft: PropTypes.string,

  marginRight: PropTypes.string,
  mobileSmMarginRight: PropTypes.string,
  mobileXsMarginRight: PropTypes.string,

  padding: PropTypes.string,
  paddingRight: PropTypes.string,
  mobileSmPaddingRight: PropTypes.string,
  paddingLeft: PropTypes.string,
  mobileSmPaddingLeft: PropTypes.string,

  height: PropTypes.string,
  minHeight: PropTypes.string,
  maxHeight: PropTypes.string,
  zIndex: PropTypes.string,
  width: PropTypes.string,
  position: PropTypes.string,
  bottom: PropTypes.string,
  overflow: PropTypes.string,
  textAlign: PropTypes.string,
  verticalAlign: PropTypes.bool,
  lighten: PropTypes.bool,
  pointer: PropTypes.bool,
  hasLink: PropTypes.bool
};

ContentContainer.defaultProps = {
  hasLink: true
};

export default ContentContainer;
