import React from "react";
import PropTypes from "prop-types";
import { AnimatedBackground, ContentContainer } from "@uaveiro/ui";
import { withTheme } from "styled-components";

const Mask = props => {
  return (
    <ContentContainer
      backgroundColor={props.theme.loadings.bgColor}
      position="absolute"
      {...props}
    />
  );
};

const LoadingCard = ({ marginTop, ...props }) => {
  return (
    <ContentContainer height="54px" marginTop={marginTop}>
      <AnimatedBackground height="54px">
        <Mask height="16px" right="0" width="40%" {...props} />
        <Mask height="5px" top="16px" width="100%" {...props} />
        <Mask height="14px" top="21px" right="0" width="30%" {...props} />
        <Mask height="5px" top="35px" width="100%" {...props} />
        <Mask height="14px" top="40px" right="0" width="35%" {...props} />
      </AnimatedBackground>
    </ContentContainer>
  );
};

LoadingCard.propTypes = {
  marginTop: PropTypes.string
};

const Loading = ({ className, ...props }) => {
  return (
    <div className={className}>
      <div className="row">
        <div className="col-xl-6 col-lg-7 col-md-7 col-sm-10 col-11">
          <LoadingCard {...props} marginTop="30px" />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-5 col-lg-6 col-md-7 col-sm-8 col-9">
          <LoadingCard {...props} marginTop="35px" />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-5 col-lg-6 col-md-6 col-sm-9 col-10">
          <LoadingCard {...props} marginTop="35px" />
        </div>
      </div>
    </div>
  );
};

Loading.propTypes = {
  className: PropTypes.string
};

export default withTheme(Loading);
