import React from "react";
import styled, { withTheme } from "styled-components";
import PropTypes from "prop-types";
import { AnimatedBackground, Icon, ContentContainer } from "@uaveiro/ui";
import { withSize } from "react-sizeme";
import { compose } from "../../../../services/utilities";

const Mask = props => {
  return (
    <ContentContainer
      backgroundColor={props.theme.loadings.bgColor}
      position="absolute"
      {...props}
    />
  );
};

const LoadingCard = ({ size, ...props }) => {
  return (
    <div className="row align-items-center no-gutters">
      <div className="col-xl-3 col-lg-auto col">
        <ContentContainer width="125px" mobileLgMarginRight="30px">
          <ContentContainer width="104px">
            <AnimatedBackground height="20px" />
          </ContentContainer>
        </ContentContainer>
      </div>
      <div className="col-xl col-lg col-auto">
        {size.width >= 991 ? (
          <ContentContainer width="635px">
            <AnimatedBackground height="15px">
              <Mask height="15px" left="52px" width="20px" {...props} />
              <Mask height="15px" left="112px" width="20px" {...props} />
              <Mask height="15px" left="184px" width="20px" {...props} />
              <Mask height="15px" left="279px" width="20px" {...props} />
              <Mask height="15px" left="423px" width="20px" {...props} />
              <Mask height="15px" left="502px" width="20px" {...props} />
            </AnimatedBackground>
          </ContentContainer>
        ) : (
          <Icon
            name="fal fa-ellipsis-v"
            color={props.theme.loadings.journalNavigation.iconColor}
            size="large"
          />
        )}
      </div>
    </div>
  );
};

LoadingCard.propTypes = {
  size: PropTypes.object
};

const Loading = ({ className, ...props }) => {
  return (
    <div className={className}>
      <div className="container">
        <LoadingCard {...props} />
      </div>
    </div>
  );
};

Loading.propTypes = {
  className: PropTypes.string
};

const LoadingWithStyles = styled(Loading)`
  background-color: ${props => props.theme.loadings.bgColor};
  height: ${props => props.navigationLoadingHeight || "139px"};
  .row {
    height: ${props => props.navigationLoadingHeight || "139px"};
  }

  // Medium devices (tablets, less than 992px)
  @media (max-width: 991px) {
    height: ${props => props.mobileNavigationLoadingHeight || "72px"};
    .row {
      height: ${props => props.mobileNavigationLoadingHeight || "72px"};
    }
  }
`;

Loading.propTypes = {
  navigationLoadingHeight: PropTypes.string,
  mobileNavigationLoadingHeight: PropTypes.string
};

export default compose(withSize(), withTheme)(LoadingWithStyles);
