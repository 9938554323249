import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//components
import { Curricular } from "@uaveiro/ui";
//API
import { getCourseCurricularById } from "../../api/requests";

function CurricularTemplate({ history, match, ...props }) {
  const [state, setState] = useState({
    loading: true,
    error: false,
    content: {}
  });

  const onFetchContent = async () => {
    try {
      const { lang, id } = match.params;
      const data = await getCourseCurricularById({
        id,
        lang,
        hasDelay: true,
        history
      });

      setState(prevState => {
        return { ...prevState, content: { ...data }, loading: false };
      });
    } catch (error) {
      setState(prevState => {
        return { ...prevState, loading: false, error: true };
      });
    }
  };

  //If the user changes the url update the content
  useEffect(() => {
    onFetchContent();
  }, [history.location]);

  if (!state.loading && state.content.hideModule) return <Fragment></Fragment>;

  return (
    <Curricular
      content={state.content}
      loading={state.loading}
      hasError={state.error}
      {...props}
    />
  );
}

CurricularTemplate.propTypes = {
  backgroundColor: PropTypes.string,
  history: PropTypes.object,
  match: PropTypes.object,
  styles: PropTypes.object.isRequired
};

export default withRouter(CurricularTemplate);
