import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//Components
import { JournalCards } from "@uaveiro/ui";
//Hocs
import {
  compose,
  facebookUrl,
  twitterUrl,
  linkedinUrl
} from "../../services/utilities";
import { hasHash, baseUrl } from "../../services/constants";

//API
import { getNews } from "../../api/requests";

function JournalCardsTemplate({ match, categoryId, isTag = false, ...props }) {
  const [state, setState] = useState({
    loading: true,
    error: false,
    data: {}
  });
  const { lang } = match.params;
  const route = lang === "pt" ? "noticias" : "news";

  const onAddUrl = data => {
    const items = data.listNews.map(item => {
      let url = `${lang}/${route}/${item.categoryID}/${item.id}`;
      return {
        ...item,
        url: `/${url}`,
        share: {
          facebook: facebookUrl(`${baseUrl}/${url}`),
          twitter: twitterUrl(`${baseUrl}/${url}`, item.title),
          linkedin: linkedinUrl(`${baseUrl}/${url}`),
          url: `${baseUrl}/${url}`
        }
      };
    });

    return {
      listNews: items,
      pagination: data.pagination
    };
  };

  const onFetchData = async () => {
    try {
      const fetchProps = isTag ? { id: categoryId, isTag } : { id: categoryId };
      const recordsPerPage = props.isSlider ? 9 : 3;

      const res = await getNews({
        ...fetchProps,
        lang,
        isTag,
        recordsPerPage,
        notInTopFeatured: true,
        history: props.history
      });

      const data = onAddUrl(res.data);

      setState(prevState => {
        return { ...prevState, data, loading: false };
      });
    } catch (error) {
      setState(prevState => {
        return { ...prevState, loading: false, error: true };
      });
    }
  };

  useEffect(() => {
    onFetchData();
  }, []);

  const openInNewTab = (e, url) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(url, "_blank");
  };

  const onHandleEnter = (e, url) => {
    if (e.key === "Enter") {
      openInNewTab(e, url);
    }
  };

  const NewsProps = {
    onAction: openInNewTab,
    onEnter: onHandleEnter,
    categoryUrl: `${hasHash}/${lang}/${route}/${categoryId}${
      isTag ? "?tag=true" : ""
    }`,
    hasHash,
    ...props
  };

  return (
    <JournalCards
      data={state.data}
      loading={state.loading}
      hasError={state.error}
      {...NewsProps}
    />
  );
}

JournalCardsTemplate.propTypes = {
  match: PropTypes.object.isRequired,
  categoryId: PropTypes.number.isRequired,
  isTag: PropTypes.bool
};

export default compose(withRouter)(JournalCardsTemplate);
