import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//Components
import { SubWebEvents } from "@uaveiro/ui";

const EventsTemplate = ({ match, ...props }) => {
  const {
    params: { lang }
  } = match;

  const eventsProps = {
    lang,
    ...props
  };

  return <SubWebEvents {...eventsProps} />;
};

EventsTemplate.propTypes = {
  match: PropTypes.object
};

export default withRouter(EventsTemplate);
