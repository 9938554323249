import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//Components
import { Edicts } from "@uaveiro/ui";
import SEO from "../SEO";
//Utils
import { portalAPI, hasHash } from "../../services/constants";

const EdictsTemplate = ({ endpoint, match, history, ...props }) => {
  const [state, setState] = useState({
    isLoading: true,
    hasError: false,
    tables: [],
    staticTables: [],
    filters: [],
    faseFilterValue: "",
    degreeFilterValue: ""
  });

  const onFetchData = async () => {
    try {
      const degree = match.params.degree || 0;

      const res = await fetch(
        `${portalAPI}${endpoint}?language=${match.params.lang}&degree=${degree}`
      );

      const {
        data: { filters, rows: tables }
      } = await res.json();

      setState(prevState => {
        return {
          ...prevState,
          tables,
          staticTables: tables,
          filters,
          isLoading: false
        };
      });
    } catch (error) {
      setState(prevState => {
        return {
          ...prevState,
          isLoading: false,
          hasError: true
        };
      });
    }
  };
  useEffect(() => {
    onFetchData();
  }, [history.location]);

  const onFilter = ({ jsonField, fase = {}, degree = {} }) => {
    const data = [];

    state.staticTables.map((items, index) => {
      if (index === 0) data.push(items);

      if (fase && degree) {
        if (
          items["fase"] === Number(fase.value) &&
          items["degree"] === degree.value
        ) {
          data.push(items);
        }
      } else {
        switch (jsonField) {
          case "fase":
            if (items[jsonField] === Number(fase.value)) data.push(items);
            break;
          case "degree":
            if (items[jsonField] === degree.value) data.push(items);
            break;
          default:
            data.push(items);
        }
      }

      return true;
    });

    return data;
  };

  const onChangeFaseFilter = (option, jsonField) => {
    if (option !== null) {
      const data = onFilter({
        jsonField,
        fase: option,
        degree: state.degreeFilterValue
      });

      setState(prevState => {
        return {
          ...prevState,
          faseFilterValue: option,
          tables: data
        };
      });

      return;
    }

    setState(prevState => {
      return {
        ...prevState,
        faseFilterValue: ""
      };
    });

    if (option === null && !state.degreeFilterValue) {
      setState(prevState => {
        return {
          ...prevState,
          tables: prevState.staticTables
        };
      });
    } else {
      setState(prevState => {
        return {
          ...prevState,
          tables: prevState.tables
        };
      });
    }
  };

  const onChangeDegreeFilter = (option, jsonField) => {
    if (option !== null) {
      const data = onFilter({
        jsonField,
        fase: state.faseFilterValue,
        degree: option
      });

      setState(prevState => {
        return {
          ...prevState,
          degreeFilterValue: option,
          tables: data
        };
      });

      return;
    }
    setState(prevState => {
      return {
        ...prevState,
        degreeFilterValue: ""
      };
    });

    if (option === null && !state.faseFilterValue) {
      setState(prevState => {
        return {
          ...prevState,
          tables: prevState.staticTables
        };
      });
    } else {
      setState(prevState => {
        return {
          ...prevState,
          tables: prevState.tables
        };
      });
    }
  };

  const edictsProps = {
    lang: match.params.lang,
    hasHash,
    ...state,
    onChangeFaseFilter,
    onChangeDegreeFilter,
    ...props
  };

  return (
    <Fragment>
      <SEO title={props.title} />
      <Edicts {...edictsProps} />
    </Fragment>
  );
};

EdictsTemplate.propTypes = {
  endpoint: PropTypes.string,
  match: PropTypes.object.isRequired,
  history: PropTypes.object,
  title: PropTypes.string
};

export default withRouter(EdictsTemplate);
