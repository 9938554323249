import React, { useContext, useMemo, useEffect, useState } from "react";
import PropTypes from "prop-types";
//Locales
import { StoreContext } from "../../context/StoreContext";
import { lang as utilLang } from "../../translations";
//Components
import { Campos as Campus } from "@uaveiro/ui";

const CampusTemplate = ({ data, ...props }) => {
  const { state } = useContext(StoreContext);
  const content = useMemo(() => data[utilLang()] || data, [data]);
  const [supportsGrid, setSupportsGrid] = useState(true);

  useEffect(() => {
    const el = document.createElement("div");
    const supportsGrid = typeof el.style.grid === "string";
    setSupportsGrid(supportsGrid);
  }, []);

  return (
    <Campus
      data={content}
      supportsCssGrid={supportsGrid}
      {...props}
      state={state}
      lang={utilLang()}
      activeColor={props.activeColor || state.activeColor}
    />
  );
};

CampusTemplate.propTypes = {
  className: PropTypes.string,
  textColor: PropTypes.string,
  title: PropTypes.string,
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        backgroundPosition: null
      })
    ),
    PropTypes.object
  ]),
  showHeader: PropTypes.bool,
  size: PropTypes.object,
  titleProps: PropTypes.object,
  showPlusButton: PropTypes.bool,
  isMarkdown: PropTypes.bool,
  contentMaxHeight: PropTypes.string,
  height: PropTypes.string,
  activeColor: PropTypes.string,
  backgroundPosition: PropTypes.string,
  isLocal: PropTypes.bool
};

CampusTemplate.defaultProps = {
  showPlusButton: true,
  isMarkdown: false,
  contentMaxHeight: "190px",
  height: "350px",
  activeColor: null,
  isLocal: true
};

export default CampusTemplate;
