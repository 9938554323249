import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//Components
import { JournalDetails } from "@uaveiro/ui";
//Hocs
import {
  compose,
  facebookUrl,
  twitterUrl,
  linkedinUrl
} from "../../services/utilities";
import { baseUrl, hasHash } from "../../services/constants";
//API
import { getNewsDetails } from "../../api/requests";

function JournalDetailsTemplate({ match, ...props }) {
  const [state, setState] = useState({
    loading: true,
    error: false,
    data: {}
  });
  const { lang, newsId } = match.params;

  const onFetchData = async () => {
    try {
      const res = await getNewsDetails({
        lang,
        newsId,
        history: props.history
      });
      const categoryId = res.data.categoryID;
      const route = lang === "pt" ? "noticias" : "news";
      const url = `${baseUrl}${hasHash}/${lang}/${route}/${categoryId}/${newsId}`;
      const categoryUrl = `/${lang}/${route}/${categoryId}`;

      const facebook = facebookUrl(url);
      const twitter = twitterUrl(url, res.data.title);
      const linkedin = linkedinUrl(url);

      const tagUrl = `${hasHash}/${lang}/${route}`;

      setState(prevState => {
        return {
          ...prevState,
          data: {
            ...res.data,
            facebook,
            twitter,
            linkedin,
            tagUrl,
            categoryUrl
          },
          loading: false
        };
      });
    } catch (error) {
      setState(prevState => {
        return { ...prevState, loading: false, error: true };
      });
    }
  };

  useEffect(() => {
    onFetchData();
  }, []);

  const JournalDetailsProps = {
    lang,
    route: lang === "pt" ? "noticias" : "news",
    hasHash,
    ...props
  };

  return (
    <JournalDetails
      data={state.data}
      loading={state.loading}
      hasError={state.error}
      {...JournalDetailsProps}
    />
  );
}

JournalDetailsTemplate.propTypes = {
  match: PropTypes.object.isRequired
};

export default compose(withRouter)(JournalDetailsTemplate);
