import React, { memo, useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//Components
import { ProjectRedirect } from "../../../components/organisms";
//Utils
import { compose } from "../../../services/utilities";
//API
import { getProjectSigef } from "../../../api/requests";

const ProjectRedirectTemplate = memo(function ProjectRedirectTemplate({
  match,
  ...props
}) {
  const [state, setState] = useState({
    loading: true,
    error: false,
    data: {}
  });

  const { lang } = match.params;

  useEffect(() => {
    const onFetchContent = async () => {
      const { id } = match.params;
      const res = await getProjectSigef({
        id,
        history: props.history
      });
      const langPath = lang == "en" ? "id-projects/" : "projetos-id/";
      const path = res.url.replace(
        "https://www.ua.pt/pt/projetos-id/",
        "../" + langPath
      );
      window.location.href = path;

      setState(prevState => {
        return { ...prevState, loading: true, error: true };
      });
    };

    onFetchContent();
  }, []);

  const projectRedirectProps = {
    lang,
    ...props
  };

  return (
    <ProjectRedirect
      data={state.data}
      loading={state.loading}
      hasError={state.error}
      {...projectRedirectProps}
    />
  );
});

ProjectRedirectTemplate.propTypes = {
  data: PropTypes.array,
  match: PropTypes.object
};

export default compose(withRouter)(ProjectRedirectTemplate);
