import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//Components
import { JournalSlides } from "@uaveiro/ui";
//Hocs
import {
  compose,
  facebookUrl,
  twitterUrl,
  linkedinUrl
} from "../../services/utilities";
import { hasHash, baseUrl } from "../../services/constants";
//API
import { getNewsDossier } from "../../api/requests";

function JournalSlidesTemplate({ match, id, ...props }) {
  const [state, setState] = useState({
    loading: true,
    error: false,
    data: {}
  });
  const { lang } = match.params;
  const route = lang === "pt" ? "noticias" : "news";
  const dossiersRoute =
    lang === "pt" ? `${route}/dossies` : `${route}/dossiers`;

  const onAddUrl = data => {
    let items = data.news
      .filter((_, index) => index <= 8)
      .map(item => {
        let url = `${lang}/${route}/${item.categoryId}/${item.id}`;
        return {
          ...item,
          url: `/${url}`,
          categoryUrl: `${route}/${item.categoryId}`,
          share: {
            facebook: facebookUrl(`${baseUrl}${hasHash}/${url}`),
            twitter: twitterUrl(`${baseUrl}${hasHash}/${url}`, item.title),
            linkedin: linkedinUrl(`${baseUrl}${hasHash}/${url}`),
            url: `${baseUrl}${hasHash}/${url}`
          }
        };
      });

    return {
      news: items,
      dossier: data.dossier
    };
  };

  const onFetchData = async () => {
    try {
      const res = await getNewsDossier({
        lang,
        id,
        history: props.history
      });
      //Checks the content from the server
      if (res.data === null) {
        setState(prevState => {
          return { ...prevState, data: null, loading: false };
        });
        return;
      }

      const data = onAddUrl(res.data);

      setState(prevState => {
        return { ...prevState, data, loading: false };
      });
    } catch (error) {
      setState(prevState => {
        return { ...prevState, loading: false, error: true };
      });
    }
  };

  useEffect(() => {
    onFetchData();
  }, []);

  const openInNewTab = (e, url) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(url, "_blank");
  };

  const onHandleEnter = (e, url) => {
    if (e.key === "Enter") {
      props.history.push(url);
    }
  };

  const NewsProps = {
    onAction: openInNewTab,
    onEnter: onHandleEnter,
    rightTitle: "Ver todos os dossiês",
    rightHref: `${hasHash}/${lang}/${dossiersRoute}`,
    ...props
  };

  //Removes the component from the page if there is no content
  if (!state.loading && state.data === null) return null;

  return (
    <JournalSlides
      data={state.data}
      loading={state.loading}
      hasError={state.error}
      {...NewsProps}
    />
  );
}

JournalSlidesTemplate.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired
};

export default compose(withRouter)(JournalSlidesTemplate);
