import React, { useEffect, useState, Fragment, useContext, memo } from "react";
import PropTypes from "prop-types";
//Components
import { SiteMap } from "../../components/organisms";
import SEO from "../SEO";
import { lang } from "../../translations";
import { getSiteMap } from "../../api/requests";
//Context
import { StoreContext } from "../../context/StoreContext";

const SiteMapTemplate = ({ ...props }) => {
  const context = useContext(StoreContext);
  const [state, setState] = useState({
    loading: true,
    error: false,
    data: []
  });

  const getData = async () => {
    try {
      const data = await getSiteMap({
        history: props.history,
        isSubWeb: context.state.isSubWeb,
        department: context.state.subWebNav.id,
        lang: lang()
      });

      setState(prevState => {
        return { ...prevState, data, loading: false };
      });
    } catch (error) {
      setState(prevState => {
        return { ...prevState, loading: false, error: true };
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const siteMapProps = {
    loading: state.loading,
    hasError: state.error,
    data: state.data,
    lang: lang(),
    ...props
  };

  return (
    <Fragment>
      {!state.loading && <SEO title={props.title} />}
      <SiteMap {...siteMapProps} />
    </Fragment>
  );
};

SiteMapTemplate.propTypes = {
  title: PropTypes.string
};

export default memo(SiteMapTemplate);
