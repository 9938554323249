import React, { useContext, memo } from "react";
//Components
import { Tables } from "@uaveiro/ui";
import { lang } from "../../translations";
//Context
import { StoreContext } from "../../context/StoreContext";

const TablesTemplate = ({ ...props }) => {
  const { state } = useContext(StoreContext);

  const tablesProps = {
    activeColor: state.activeColor,
    lang: lang(),
    ...props
  };

  return <Tables {...tablesProps} />;
};

export default memo(TablesTemplate);
