import React from "react";
//Containers
import RouteSelector from "../containers/RouteSelector";
import DynamicSelector from "../containers/DynamicSelector";
import Map from "../containers/Map";

import Routes from "./Routes";

import { Switch, Route, useLocation } from "react-router-dom";

import { views } from "./../services/constants";
import ErrorTemplate from "../templates/Error";

const Router = () => {
  const location = useLocation();
  //TODO implement fix when location comes undefined
  return (
    <Switch>
      <Route
        exact
        path="/:lang?"
        component={() => (
          <RouteSelector
            activeView={views.index}
            file="home"
            document="home"
            sideNav={false}
          />
        )}
      />

      {Routes.routes.map(({ component: Component, path, ...route }, index) => {
        return (
          <Route
            key={index}
            path={[`/:lang${path[0]}`, `/:lang${path[1]}`]}
            component={() => <Component {...route.props} />}
          />
        );
      })}

      <Route
        exact
        path="/:lang/map"
        component={() => <Map activeView="index" sideNav={false} />}
      />

      <Route
        exact
        path="/:lang/404"
        component={() => <ErrorTemplate errorCode={404} />}
      />
      <Route
        exact
        path="/:lang/error"
        component={() => (
          <ErrorTemplate
            errorCode={
              location.state
                ? location.state.errorCode
                : localStorage.getItem("portalErrorCode")
            }
          />
        )}
      />
      <Route
        exact
        path="/:lang/**/"
        component={props => (
          <DynamicSelector activeView={views.index} {...props} />
        )}
      />

      <Route
        render={({ staticContext }) => {
          if (staticContext) staticContext.status = 404;
          return <ErrorTemplate errorCode={404} />;
        }}
      />
    </Switch>
  );
};

export default Router;
