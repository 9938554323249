export default [
  {
    name: "Services",
    title: "Os nossos serviços",
    demoFavorites: true
    /* data: [
      {
        title: "Geral",
        list: [
          {
            text: "contactos gerias",
            icon: "fal fa-phone",
            href: "/"
          },
          {
            text: "bud",
            icon: "fal fa-sd-card",
            href: "/"
          },
          {
            text: "pesquisar pessoas",
            icon: "fal fa-newspaper",
            href: "/"
          },
          {
            text: "email",
            icon: "fal fa-person-sign",
            href: "/"
          },
          {
            text: "notícias",
            icon: "fal fa-sim-card",
            href: "/"
          }
        ]
      },
      {
        title: "Ensino e investigação",
        list: [
          {
            text: "contactos gerias",
            icon: "fal fa-phone",
            href: "/"
          },
          {
            text: "bud",
            icon: "fal fa-sd-card",
            href: "/"
          },
          {
            text: "pesquisar pessoas",
            icon: "fal fa-newspaper",
            href: "/"
          }
        ]
      }
    ]*/
  }
];
