import React from "react";
import { useHistory } from "react-router-dom";

const Playground = () => {
  const history = useHistory();
  return (
    <div style={{ margin: "30px" }}>
      <h1>Playground</h1>
      <br />
      <br />

      <button onClick={() => history.push("/pt/404")}>Go to 404</button>

      <a href="https://wso2-is.dev.ua.pt/oauth2/authorize?client_id=D2wPaAQ3_dfgJgeStXAfwJRCKu0a&scope=openid%20profile&response_type=id_token%20token&state=af0ifjsldkj&nonce=aaaaaaaa&redirect_uri=http://localhost:8081/pt/login">
        Login User
      </a>
    </div>
  );
};

export default Playground;
