import React, { Fragment, cloneElement } from "react";
import PropTypes from "prop-types";
//components
import { Loading, Error } from "@uaveiro/ui";

function HandleFallback({
  render,
  data,
  validate,
  loadingProps,
  emptyProps,
  hasLoading,
  LoadingComponent
}) {
  if (data.loading) {
    return hasLoading ? (
      cloneElement(LoadingComponent)
    ) : (
      <Loading {...loadingProps} />
    );
  }
  if (data.error) {
    return hasLoading ? (
      cloneElement(LoadingComponent)
    ) : (
      <Loading {...loadingProps} />
    );
    //return <Error {...errorProps} />;
  }
  if (data.data == null || Object.keys(data.data[validate]).length <= 0) {
    return <Error {...emptyProps} />;
  }
  return <Fragment>{render(data)}</Fragment>;
}

HandleFallback.propTypes = {
  render: PropTypes.func,
  validate: PropTypes.string,
  loadingProps: PropTypes.object,
  errorProps: PropTypes.object,
  emptyProps: PropTypes.object,
  hasLoading: PropTypes.bool,
  LoadingComponent: PropTypes.node,
  data: PropTypes.oneOfType([
    PropTypes.object.isRequired,
    PropTypes.array.isRequired
  ])
};

export default HandleFallback;
