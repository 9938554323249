const data = {
  footer: {
    social: {
      links: [
        {
          url: "https://www.facebook.com/universidadedeaveiro/",
          ariaLabel: "Logótipo facebook"
        },
        {
          url: "https://www.instagram.com/universidadedeaveiro/",
          ariaLabel: "Logótipo do instagram"
        },
        {
          url: "https://twitter.com/univaveiro",
          ariaLabel: "Logótipo do twitter"
        },
        {
          url:
            "https://www.linkedin.com/school/universidade-de-aveiro/?originalSubdomain=pt",
          ariaLabel: "Logótipo do LinkedIn"
        },
        {
          url: "https://www.youtube.com/channel/UCJnxPIxMHdxcYhRIIw_ztEg",
          ariaLabel: "Logótipo do YouTube"
        },
        /* {
          url: "https://open.spotify.com/show/4NKCT4hAdpnR1Xc0XsavFj?si=anbaEjsRTPCLXdxMh6T2bg&dl_branch=1&nd=1",
          ariaLabel: "Logótipo do Spotify"
        } */
      ]
    }
  }
};

export default data;
