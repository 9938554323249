import theme from "./theme";

const activeColors = {
  default: "#95CA3D",
  about: "#95CA3D",
  study: "#0EB4BD",
  live: "#F3B21B",
  investigation: "#42D3B8",
  cooperate: "#9878D3",
  international: "#F0592A"
};

const viewsColors = {
  root: activeColors.default,
  aboutua: activeColors.default,
  cooperate: activeColors.cooperate,
  education: activeColors.study,
  ua: activeColors.default,
  public: activeColors.default,
  index: activeColors.default,
  internal: activeColors.default,
  international: activeColors.international,
  live: activeColors.live,
  investigation: activeColors.investigation
};

/*
-- HighContrast
-- Values are null in main theme so it passes forward to other conditions
*/
const highContrast = {
  backgroundColor: null,
  lightBackgroundColor: null,
  opacityBgColor: null,
  lightOpacityBgColor: null,
  text: null,
  link: null,
  linkHover: null,
  action: null,
  borderColor: null,
  border: null
};

const globalStyle = {
  body: {
    backgroundColor: theme.colors.white
  }
};
/*
-- Containers
*/

const accessibilitySelector = {
  whiteLogo: "/imgs/logo.svg",
  mobileWhiteLogo: "/imgs/logo_mobile.svg",
  blackLogo: "/imgs/logo_b.svg",
  mobileBlackLogo: "/imgs/logo_mobile_b.svg",
  borderColor: "#4d4d4d",
  backgroundColor: theme.colors.black,
  color: theme.colors.black,
  isSearchOpenBackgroundColor: theme.colors.white,
  activeLineColor: theme.colors.white,
  dropDownHoverBackgroundColor: "#eeeeee",
  dropDownIconColor: theme.colors.black,
  linksColor: theme.colors.black,
  iconColor: theme.colors.black,
  accessibilityActiveBgColor: theme.colors.black,
  accessibilityIconActiveColor: theme.colors.white,
  accessibilityMenuIconColor: theme.colors.black,
  accessibilityIconHoverColor: null,
  searchFilterColor: theme.colors.black,
  accessibilityOnColor: theme.colors.white
};
/*
-- Atoms
*/
//AnimatedBackground
const animatedBackground = {
  backgroundColor: "#f6f7f8",
  gradientColor: ["#eeeeee", "#dddddd", "#eeeeee"]
};

//AspectRatio
const aspectRatio = {
  backgroundColor: theme.colors.greyBg
};

//Button
const button = {
  //Global
  fontSize: theme.sizes.s,
  fontWeight: String(theme.weight.light),
  padding: "10px 20px",
  lineHeight: "1",
  //Primary
  primaryColor: theme.colors.black,
  primaryBackgroundColor: theme.colors.white,
  primaryBorderColor: theme.colors.blue,
  primaryHoverColor: theme.colors.white,
  primaryHoverBackgroundColor: theme.colors.blue,
  primaryFocus: "rgba(0,178,189,0.4)",
  primaryIconBackgroundColor: theme.colors.white,
  primaryIconColor: theme.colors.blue,
  //Dark
  darkColor: theme.colors.white,
  darkBackgroundColor: theme.colors.black,
  darkBorderColor: theme.colors.white,
  darkHoverColor: theme.colors.black,
  darkHoverBackgroundColor: theme.colors.white,
  darkFocus: "rgba(255,255,255,0.4)",
  darkIconBackgroundColor: theme.colors.black,
  darkIconColor: theme.colors.white,
  //Light
  lightColor: theme.colors.white,
  lightBackgroundColor: theme.colors.blue,
  lightBorderColor: theme.colors.white,
  lightHoverColor: theme.colors.blue,
  lightHoverBackgroundColor: theme.colors.white,
  lightFocus: "rgba(255,255,255,0.4)",
  lightIconBackgroundColor: theme.colors.blue,
  lightIconColor: theme.colors.white,
  //Default
  color: theme.colors.black,
  backgroundColor: theme.colors.white,
  borderColor: theme.colors.black,
  hoverColor: theme.colors.white,
  hoverBackgroundColor: theme.colors.black,
  focus: "rgba(0,0,0,0.4)",
  iconBackgroundColor: theme.colors.white,
  iconColor: theme.colors.black
};

//Icon
const icon = {
  color: theme.colors.black
};

//Input
const input = {
  fontSize: theme.sizes.s,
  color: theme.colors.black,
  height: theme.sizes.xl5,
  focus: "rgba(0, 0, 0, 0.4)"
};

//Line
const line = {
  width: 34,
  height: 5,
  color: theme.colors.white,
  marginTop: 0,
  marginTopMarkdown: 10,
  widthDifference: 8
};

//Link
const link = {
  color: theme.colors.black,
  fontSize: theme.typos.link,
  fontWeight: String(theme.weight.xbase)
};

//Picture
const picture = {
  backgroundColor: "#e0e0e0",
  color: theme.colors.black
};

//Pagination
const pagination = {
  backgroundColor: theme.colors.white,
  linkColor: "#666",
  linkHoverColor: "#94d500",
  disabledHover: "#d9d9d9",
  linkActive: "#94d500",
  jumpNextColor: "#ccc",
  jumpNextHoverColor: "#94d500",
  jumpPrevColor: "#666",
  disabledLinkColor: "#ccc",
  quickJumperInputBgColor: theme.colors.white,
  quickJumperInputHoverColor: "#94d500",
  quickJumperButtonBgColor: theme.colors.white,
  quickJumperButtonColor: "rgba(0, 0, 0, 0.65)",
  quickJumperButtonHAFColor: "#94d500",
  quickJumperButtonHAFBgColor: theme.colors.white,
  simplePagerInputBgColor: theme.colors.white,
  simplePagerInputHoverColor: "#94d500",
  simplePagerButtonColor: "rgba(0, 0, 0, 0.65)",
  simplePagerButtonBgColor: theme.colors.white,
  simplePagerButtonHAFColor: "#94d500",
  simplePagerButtonHAFBgColor: theme.colors.white
};

//ScrollMore
const scrollMore = {
  iconColor: theme.colors.black
};

//Text
const text = {
  fontSize: theme.typos.text,
  color: theme.colors.black,
  colorReverse: theme.colors.white,
  fontWeight: String(theme.weight.xbase)
};

//Title
const title = {
  fontSize: theme.typos.title,
  color: theme.colors.white,
  fontWeight: String(theme.weight.xbase),
  fontFamily: theme.type.base,
  lineHeight: 1.4
};

//Triangle
const triangle = {
  color: theme.colors.white,
  contentColor: theme.colors.black
};

/*
-- Molecules
*/

//AccessibleAccordion
const accessibleAccordion = {
  color: theme.colors.white,
  arrowColor: theme.colors.grey
};

//Accordion
const accordion = {
  color: theme.colors.white
};

//Breadcrumbs
const breadcrumbs = {
  height: "32px",
  link: {
    color: theme.colors.grey,
    fontSize: "15px",
    fontWeight: 300
  },
  button: {
    iconColor: theme.colors.black,
    bgColor: "transparent",
    hoverBgColor: "#E7E8E9"
  },
  anchors: {
    buttonBgColor: "#E7E8E9",
    textColor: theme.colors.black
  }
};

// -- CARDS --

//AgendaCard
const agendaCard = {
  categoryColor: theme.colors.white,
  titleColor: theme.colors.white,
  textColor: theme.colors.white,
  dateColor: theme.colors.black,
  iconColor: theme.colors.white,
  borderColor: theme.colors.white,
  textColorHover: theme.colors.white,
  squareColorHover: theme.colors.white,
  iconColorHover: theme.colors.black
};

//AgendaFeatured
const agendaFeatured = {
  dateContainerTextColor: "rgb(77, 147, 255)",
  buttonBgColor: "rgb(77, 147, 255)",
  buttonTextColor: theme.colors.white,
  textColor: theme.colors.white,
  titleColor: theme.colors.white,
  dateColor: theme.colors.black,
  dateContainerBgColor: theme.colors.white,
  borderColor: theme.colors.white,
  textColorHover: theme.colors.white,
  animationBgColor: "rgb(77, 147, 255)"
};

//BackgroundImageNewsCard
const backgroundImageNewsCard = {
  cardBackgroundColor: theme.colors.white,
  textColor: theme.colors.black,
  contentContainerBackgroundColor: "rgba(255, 255, 255, 0.8)"
};

//BorderedCArd
const borderedCard = {
  borderColor: theme.colors.black
};

//CandidatureCard
const candidatureCard = {
  titleColor: theme.colors.black,
  cardBackgroundColor: theme.colors.white
};

//ColorExtractCard
const colorExtractCard = {
  backgroundColor: theme.colors.greyLight,
  iconColor: theme.colors.black,
  linkColor: theme.colors.black
};

//HorizontalNewsCard
const horizontalNewsCard = {
  backgroundColor: theme.colors.white,
  height: "294px",
  mobileXsHeight: "250px",
  textColor: theme.colors.black,
  contentContainerBgColor: theme.colors.white,
  triangleContentColor: theme.colors.black,
  categoryColor: theme.colors.black
};

//ImageCard
const imageCard = {
  link: {
    color: theme.colors.black,
    textDecorationColor: "#00b2bd"
  }
};

//MiniHorizontalNewsCArd
const miniHorizontalNewsCard = {
  backgroundColor: theme.colors.white,
  height: "75px",
  dateColor: "#5B5C5E",
  borderColor: theme.colors.black,
  triangleBgColor: theme.colors.white,
  triangleContentColor: theme.colors.black
};

//NewsCard
const newsCard = {
  plusButtonTextColor: theme.colors.black,
  plusButtonBgColor: theme.colors.white,
  shareIconColor: theme.colors.black,
  dateColor: theme.colors.black,
  cardBackgroundColor: theme.colors.white,
  categoryColor: theme.colors.black
};

//PersonCard
const personCard = {
  backgroundColor: "#5B5C5E",
  textColor: theme.colors.white,
  imageSize: "115px",
  imageBackgroundColor: theme.colors.greyBg
};

//ShareSocialMedia
const shareSocialMedia = {
  backgroundColor: theme.colors.white,
  copyToClipboardTextColor: theme.colors.white,
  copyToClipboardBgColor: theme.colors.black,
  iconColor: theme.colors.black,
  boxShadow: "0 3px 6px #00000029"
};

//ShowMoreCard
const showMoreCard = {
  hoverBgColor: theme.colors.white,
  hoverTitleColor: theme.colors.subWeb,
  borderColor: theme.colors.white,
  titleColor: theme.colors.white,
  linkColor: "#818386"
};

//StudentCard
const studentCard = {
  borderColor: "#cccccb"
};

//Carousel
const carousel = {
  legendColor: theme.colors.black,
  dotsColor: theme.colors.grey
};

//CustomArrow
const customArrow = {
  customArrowColor: theme.colors.arrow
};

//DatePicker
const datePicker = {
  backgroundColor: "transparent",
  borderColor: theme.colors.black,
  separatorColor: theme.colors.black,
  textColor: theme.colors.black,
  shadowColor: theme.colors.black,
  hoverBorderColor: "rgba(0, 0, 0, 0.3)",
  iconColor: theme.colors.black,
  clearIconHoverColor: "rgba(0, 0, 0, 0.3)"
};

//DropDown
const dropDown = {
  backgroundColor: theme.colors.white,
  minWidth: "210px",
  underlineAnimationColor: theme.colors.white
};

//DropDownList
const dropdownList = {
  color: theme.colors.greyAnchor,
  hoverBackgroundColor: theme.colors.greyAnchor
};

//InjectHtml
const injectHtml = {
  default: {
    color: theme.colors.black
  },
  presentationStyles: {
    color: theme.colors.black,
    before: "rgb(51, 51, 51)"
  },
  lightStyles: {
    color: theme.colors.white
  },
  span: theme.colors.black,
  link: {
    color: theme.colors.black,
    textDecorationColor: "#00b2bd",
    hoverColor: "#00b2bd"
  }
};

//IstituteSearch
const instituteSearch = {
  leftIconColor: "#94D500",
  rightIconColor: "#848484",
  beforeBackgroundColor: "#94d500",
  color: "#848484",
  borderColor: "#cecbcb"
};

const listItem = {
  lineColor: theme.colors.greyBorder,
  spacingBottom: theme.spacing.s,
  spacingLeft: theme.spacing.s
};

//PlusButton
const plusButton = {
  backgroundColor: theme.colors.white
};

//ReactCalendar
const reactCalendar = {
  titleActiveBgColor: "#4d93ff",
  titleActiveColor: theme.colors.white,
  backgroundColor: theme.colors.black,
  buttonFocus: "#4d93ff",
  buttonColor: theme.colors.white,
  buttonHoverBgColor: "#4d93ff",
  buttonHoverTextColor: theme.colors.white,
  monthViewWeekDaysColor: theme.colors.white,
  monthViewWeekDaysNeighboringColor: "rgba(255, 255, 255, 0.5)"
};

//ScrollableTabs
const scrollableTabs = {
  border: "1px solid #eeee",
  button: {
    iconColor: theme.colors.black,
    bgColor: theme.colors.white
  },
  tab: {
    linkColor: theme.colors.black,
    underlineColor: theme.colors.subWeb
  }
};

//Section
const section = {
  paddingTop: theme.spacing.xl4,
  paddingBottom: theme.spacing.xl4,
  backgroundColor: theme.colors.white
};

//SectionHeader
const sectionHeader = {
  backgroundColor: theme.colors.black,
  textColor: theme.colors.white
};

//Select
const select = {
  textColor: theme.colors.white,
  borderColor: theme.colors.white,
  outlineColor: theme.colors.focus,
  primary25: "#efefef",
  primary: "#000",
  primary50: "#EDEDED",
  indicator: theme.colors.black,
  menuListBgColor: theme.colors.white,
  menuListColor: theme.colors.black,
  optionHover: theme.colors.black,
  menuListBorder: "none",
  optionIsSelected: theme.colors.black,
  optionBgColorHover: "#EDEDED",
  optionIsSelectedColor: theme.colors.white,
  clearIndicator: theme.colors.black,
  variantBlack: {
    color: theme.colors.black,
    bgColor: theme.colors.white
  }
};

const tab = {
  color: theme.colors.black,
  backgroundColor: "transparent"
};

const tabContent = {
  marginTop: theme.spacing.xs2,
  backgroundColor: "transparent"
};

//Table
const table = {
  borderColor: "rgb(147, 149, 151)",
  borderLightColor: "rgba(147, 149, 151, 0.5)",
  optionColor: theme.colors.focus,
  minorColor: theme.colors.focus,
  linksTextDecorationColor: theme.colors.focus,
  indentColor: theme.colors.black,
  textColor: theme.colors.black
};

/*
-- Organisms
*/

//About
const about = {
  backgroundColor: "#F1F1F2",
  arrowColor: theme.colors.arrow
};

//Agenda
const agenda = {
  backgroundColor: theme.colors.blue,
  titleColor: theme.colors.white,
  squareColorHover: theme.colors.white,
  agendaCardTextColor: theme.colors.white,
  agendaFeaturedTextColor: theme.colors.white,
  agendaFeaturedDateContainerBgColor: theme.colors.white
};

//Article
const article = {
  backgroundColor: theme.colors.white
};

//Candidature
const candidature = {
  arrowColor: theme.colors.white,
  backgroundColor: theme.colors.white,
  dotsColor: theme.colors.white,
  card: {
    linkColor: theme.colors.black
  }
};

//Cards
const cards = {
  backgroundColor: theme.colors.white,
  titleColor: theme.colors.black,
  dotsColor: theme.colors.white,
  slideBackgroundColor: theme.colors.white,
  arrowColor: theme.colors.white
};

//ContentList
const contentList = {
  backgroundColor: theme.colors.white,
  lineColor: theme.colors.black,
  borderColor: theme.colors.borderColor,
  listTitleColor: theme.colors.black,
  listLinkColor: theme.colors.black
};

//ContentList -- SimpleList
const simpleList = {
  backgroundColor: theme.colors.white,
  borderColor: theme.colors.borderColor,
  linkColor: theme.colors.black
};

//Contents
const contents = {
  nestedRow: {
    linkColor: theme.colors.black,
    textDecorationColor: theme.colors.blue
  },
  oneCol: {
    textColor: theme.colors.white,
    buttonBgColor: "#00AFBB"
  },
  twoCols: {
    textColor: theme.colors.blue,
    linkColor: theme.colors.black,
    textDecorationColor: theme.colors.blue
  }
};

//Courses
const courses = {
  backgroundColor: theme.colors.white,
  lineColor: theme.colors.black,
  borderColor: theme.colors.borderColor,
  textColor: "#adacac",
  listLinkColor: theme.colors.black,
  noteColor: "#adacac"
};

//Curricular
const curricular = {
  backgroundColor: theme.colors.white
};

//CurricularPlan
const curricularPlan = {
  backgroundColor: theme.colors.white,
  lineColor: theme.colors.black,
  linkColor: theme.colors.black
};

//CurricularUnit
const curricularUnit = {
  backgroundColor: theme.colors.white,
  lineColor: theme.colors.black,
  titleColor: theme.colors.blue,
  containerBackgroundColor: "#e7e8e9",
  containerBorderColor: theme.colors.black,
  infoContainerBorderColor: "#E5E3E3",
  linkColor: theme.colors.black,
  linkTextDecorationColor: theme.colors.blue
};

//Departments
const departments = {
  backgroundColor: theme.colors.white,
  titleColor: "#00AFBB",
  borderColor: "#939597",
  linkColor: theme.colors.black
};

//Directory
const directory = {
  linkColor: theme.colors.black,
  linkHoverColor: theme.colors.green,
  alphabetColor: "#656565",
  textColor: "#656565",
  alphabetActiveLetter: theme.colors.green,
  alphabetBoxSelectedShadow:
    "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
};

//Edicts
const edicts = {
  backgroundColor: theme.colors.white,
  iconColor: theme.colors.black,
  emptyIconColor: "#97999C",
  linkColor: theme.colors.black
};

//Events
const events = {
  backgroundColor: theme.colors.white,
  agendaCard: {
    outlineColor: "#616161",
    buttonOutlineColorHover: theme.colors.white,
    iconColorHover: theme.colors.white,
    iconColor: theme.colors.black,
    categoryColor: "#616161",
    titleColor: theme.colors.black,
    textColor: theme.colors.black,
    dateColor: "#616161",
    squareColorHover: theme.colors.black,
    textColorHover: theme.colors.white,
    isLimitHour: theme.colors.black,
    isNotLimitHour: "#616161"
  },
  agendaFeatured: {
    dateContainerBgColor: "#4d93ff",
    borderColor: "#4d93ff",
    dateContainerTextColor: theme.colors.white,
    buttonBgColor: "#4d93ff",
    buttonTextColor: theme.colors.white,
    titleColor: theme.colors.black,
    textColor: "#616161",
    dateColor: theme.colors.black,
    textColorHover: theme.colors.black,
    animationBgColor: theme.colors.white
  },
  calendarCardToday: {
    backgroundColor: "#1755ff",
    titleColor: theme.colors.white,
    textColor: theme.colors.white,
    afterBgColor: "#1755ff",
    bottomBgColor: "#4d93ff",
    plusButtonBgColor: "#4d93ff",
    plusButtonTextColor: theme.colors.white
  },
  calendarCard: {
    backgroundColor: theme.colors.white,
    border: "1px solid #4d93ff",
    hoverBgColor: "#4d93ff",
    textColor: "#4d93ff",
    textColorHover: theme.colors.white,
    activeBgColor: "rgba(77, 147, 255, 0.7)"
  },
  calendarButton: {
    backgroundColor: theme.colors.black,
    color: theme.colors.white,
    iconColor: theme.colors.white,
    hoverBgColor: "rgba(0, 0, 0, 0.7)",
    focusOutline: "2px solid rgba(0, 0, 0, 0.5)"
  }
};

//Facts
const oneFact = {
  backgroundColor: theme.colors.white
};

//Footer
const footer = {
  backgroundColor: theme.colors.black,
  color: theme.colors.white,
  linkColor: theme.colors.white,
  copyColor: "#757575",
  borderColor: "rgba(255,255,255,0.2)",
  socialMediaIconsColor: theme.colors.white,
  logos: {
    compete: "/imgs/logos/logo_compete.jpg",
    eciu: "/imgs/logos/logo_eciu.jpg",
    eche: "/imgs/logos/eche.jpg",
    eps: "/imgs/logos/eps.jpg",
    cgd: "/imgs/logos/logo_cgd.jpg",
    accessibility: "/imgs/logos/logo_acessibilidade.jpg"
  }
};

//Header
const header = {
  height: "calc(100vh - (40px + 105px + 50px))",
  mobileHeight: "calc(100vh - (40px + 85px + 50px))",
  maxHeight: "992px",
  arrowColor: theme.colors.white,
  arrowBackgroundColor: "rgba(0, 0, 0, 0.3)",
  subWeb: {
    height: "calc(100vh - (40px + 40px + 105px + 50px))",
    mobileHeight: "calc(100vh - (40px + 40px + 72px + 50px))",
    titleColor: theme.colors.white,
    bgColor: "rgba(50, 49, 51, 0.8)",
    iconColor: theme.colors.white,
    warning: {
      bgColor: "#e7e8e9",
      textColor: theme.colors.black
    }
  },
  video: {
    bgColor: theme.colors.black,
    imagePlaceholder: {
      iconColor: theme.colors.white
    },
    adButton: {
      iconColor: theme.colors.white,
      toolTipBgColor: "rgba(33, 33, 33, 0.8)",
      toolTipColor: theme.colors.white
    }
  }
};

//Information
const information = {
  lineColor: theme.colors.black,
  titleColor: theme.colors.blue
};

// -- Journal --

//JournalCards
const journalCards = {
  backgroundColor: theme.colors.white,
  titleColor: theme.colors.black
};

//JournalColumns
const journalColumns = {
  backgroundColor: theme.colors.white,
  titleColor: theme.colors.black
};

//JournalDetails
const journalDetails = {
  backgroundColor: theme.colors.white,
  color: theme.colors.black,
  linkColor: theme.colors.black,
  borderedCardLineColor: theme.colors.black,
  socialMediaIconsColor: theme.colors.black,
  printIconColor: theme.colors.black
};

//JournalDossiers
const journalDossiers = {
  backgroundColor: theme.colors.white,
  titleColor: theme.colors.black
};

//JournalFooter
const journalFooter = {
  backgroundColor: "#1A1A1A",
  color: theme.colors.white
};

//JournalHighlights
const journalHighlights = {
  backgroundColor: theme.colors.white,
  highlightCardBgColor: "#e7e8e9"
};

//JournalListing
const journalListing = {
  backgroundColor: theme.colors.greyBg,
  mobileBackgroundColor: theme.colors.white,
  mobileNewsCardBgColor: theme.colors.greyBg,
  titleColor: theme.colors.black
};

//JournalMoreNews
const journalMoreNews = {
  backgroundColor: "#E7E8E9",
  containerRightBgColor: theme.colors.white,
  titleColor: theme.colors.black,
  borderedCardLinkColor: theme.colors.black,
  newsCardBgColor: theme.colors.white
};

//JournalNavigation
const journalNavigation = {
  backgroundColor: theme.colors.white,
  mobileBorderBottomColor: theme.colors.greyBg,
  boxShadow: "0 0 10px  rgba(0, 0, 0, 0.2)",
  menuIconColor: theme.colors.black,
  linksColor: theme.colors.black,
  titleColor: theme.colors.black
};

//JournalNews
const journalNews = {
  backgroundColor: "#E7E8E9",
  mobileBackgroundColor: theme.colors.white,
  mobileNewsCardBgColor: theme.colors.greyBg,
  titleColor: theme.colors.black
};

//JournalRelated
const journalRelated = {
  backgroundColor: "#4d93ff"
};

//JournalSlides
const journalSlides = {
  backgroundColor: theme.colors.white,
  textColor: theme.colors.white,
  cardBackgroundColor: theme.colors.white,
  desktopContentContainerBgColor: "#c7c9cb",
  desktopContentContainerContentColor: "#6d6f72",
  activeCardBgColor: theme.colors.white,
  activeCardContentColor: theme.colors.black,
  mobileCardBackgroundColor: "#E7E8E9",
  cardTextColor: theme.colors.black,
  mobileMdTextColor: theme.colors.black,
  gradientColor: "rgba(8, 8, 8, 0.3)"
};

//LastUpdate
const lastUpdate = {
  bgColor: "#1A1A1A",
  textColor: theme.colors.white
};

//Loading
const loading = {
  backgroundColor: theme.colors.white
};

//MobileNavBar
const mobileNavBar = {
  mobileWhiteLogo: "/imgs/logo_mobile.svg",
  mobileBlackLogo: "/imgs/logo_mobile_b.svg",
  backgroundColor: theme.colors.black,
  isSearchOpenBackgroundColor: theme.colors.white,
  iconColor: theme.colors.white,
  textColor: theme.colors.white,
  dropDownBackgroundColor: theme.colors.black,
  linksColor: theme.colors.white
};

//MobilePublicNavBar
const mobilePublicNavBar = {
  backgroundColor: theme.colors.white,
  linksColor: theme.colors.black,
  textColor: theme.colors.black,
  iconColor: theme.colors.black,
  boxShadow: "0 15px 10px -15px rgba(0, 0, 0, 0.2)"
};

//News
const news = {
  personCard: {
    style2BgColor: "#E7E8E9",
    style2Color: "#000"
  }
};

//errorHandler
const errorHandler = {
  backgroundColor: theme.colors.white,
  color: theme.colors.black,
  borderColor: theme.colors.black,
  hoverColor: "#E7E8E9",
  activeColor: "rgba(231, 232, 233, 0.5)",
  gameFontSize: theme.sizes.xl4,
  gameFontWeight: theme.weight.base,
  gameSquareSize: "110px",
  gameSquareMobileSize: "80px",
  gameSquareXXXSmallMobileSize: "60px",
  gameBorderSize: "1px",
  playButtonContainerBgColor: "rgba(255, 255, 255, 0.85)"
};

//Numbers
const numbers = {
  sectionTitleColor: "#939598"
};

//PeopleArticle
const peopleArticle = {
  backgroundColor: theme.colors.white,
  lineColor: theme.colors.black
};

//Presentation
const presentation = {
  backgroundColor: theme.colors.white,
  lineColor: theme.colors.black,
  linkColor: theme.colors.black,
  linkTextDecorationColor: theme.colors.blue,
  card: {
    border: "3px solid #000"
  }
};

//Profile
const profile = {
  backgroundColor: theme.colors.white,
  linkColor: theme.colors.black,
  iconColor: theme.colors.black
};

//Projects
const projects = {
  projectList: {
    titleColor: theme.colors.black,
    table: {
      linkColor: theme.colors.black
    },
    card: {
      borderColor: "#6D6E70",
      linkColor: theme.colors.black,
      textColor: "#97999C"
    }
  },
  projectDetails: {
    titleColor: theme.colors.black,
    textColor: "#97999C",
    logos: {
      borderColor: "#AFB1B3"
    }
  }
};

//PublicNavBar
const publicNavbar = {
  borderColor: "#e6e6e6",
  linksColor: theme.colors.black,
  backgroundColor: theme.colors.white,
  iconColor: theme.colors.black,
  myUAColor: theme.colors.green,
  avatar: {
    linksColor: "#231f20"
  },
  bentoMenu: {
    moreLinkBgColor: "#e7e8e9",
    moreLinkColor: "#231f20"
  }
};

//SideNav
const sideNav = {
  iconColor: theme.colors.white,
  backgroundColor: theme.colors.white,
  desktopBoxShadow: theme.shadows.base,
  mobileBoxShadow: "0 4px 10px -3px rgba(0, 0, 0, 0.1)"
};

//SiteMap
const siteMap = {
  backgroundColor: theme.colors.white,
  lineColor: theme.colors.black,
  linkColor: theme.colors.black
};

/*
-- SubWebs
*/
//SubWebContacts
const subWebContacts = {
  height: "485px",
  card: {
    bgColor: theme.colors.white,
    iconColor: theme.colors.black
  }
};

//SubWebCourses
const subWebCourses = {
  titleColor: theme.colors.white,
  backgroundColor: theme.colors.subWeb
};

//SubWebContacts
const subWebDepartment = {
  titleColor: theme.colors.black,
  backgroundColor: "#E7E8E9",
  height: "485px"
};

//SubWebDepartmentCourses
const subWebDepartmentCourses = {
  subTitleColor: theme.colors.subWeb,
  card: {
    borderColor: "#AFB1B3",
    titleColor: theme.colors.black,
    subTitle: "#97999C"
  }
};

//SubWebNavigation
const subWebNavigation = {
  backgroundColor: theme.colors.subWeb,
  menuIconColor: theme.colors.white,
  linksColor: theme.colors.white,
  boxShadow: "0 0 10px  rgba(0, 0, 0, 0.2)",
  whiteLogo: "/imgs/logo.svg",
  activeLineColor: theme.colors.white
};

//SubWebPeople
const subWebPeople = {
  titleColor: theme.colors.subWeb,
  personCard: {
    textColor: "#818386",
    linkColor: "#818386"
  }
};

//SubWebWarnings
const subWebWarnings = {
  titleColor: theme.colors.black,
  card: {
    titleColor: theme.colors.subWeb,
    linkColor: theme.colors.black
  }
};

//WebNavBar
const webNavBar = {
  whiteLogo: "/imgs/logo.svg",
  mobileWhiteLogo: "/imgs/logo_mobile.svg",
  blackLogo: "/imgs/logo_b.svg",
  mobileBlackLogo: "/imgs/logo_mobile_b.svg",
  borderColor: "#4d4d4d",
  backgroundColor: theme.colors.black,
  color: theme.colors.black,
  isSearchOpenBackgroundColor: theme.colors.white,
  activeLineColor: theme.colors.white,
  dropDownHoverBackgroundColor: "#eeeeee",
  dropDownIconColor: theme.colors.black,
  linksColor: theme.colors.white,
  iconColor: theme.colors.white,
  accessibilityActiveBgColor: theme.colors.black,
  accessibilityIconActiveColor: theme.colors.white,
  accessibilityMenuIconColor: theme.colors.black,
  accessibilityIconHoverColor: null,
  searchFilterColor: theme.colors.black,
  accessibilityOnColor: theme.colors.white
};

/*
-- Templates
*/

//GlobalSearch
const globalSearch = {
  backgroundColor: theme.colors.white,
  tabLinkColor: theme.colors.black,
  tabsBorder: "1px solid #eeee",
  places: {
    linkColor: theme.colors.black,
    linkHoverColor: "#94D500",
    textColor: "#656565",
    mapLinkColor: "#656565"
  },
  news: {
    linkColor: theme.colors.black,
    linkHoverColor: "#94D500",
    dateColor: theme.colors.black,
    textColor: "#656565"
  }
};

//GoogleSearch
const googleSearch = {
  backgroundColor: theme.colors.white,
  brandingTextNameColor: theme.colors.black,
  brandingTextColor: "#999999",
  searchButtonBgColor: theme.colors.white,
  searchButtonSvgFill: "#94d60a",
  selectOptionBorder: "1px solid black",
  selectOptionColor: theme.colors.black,
  selectOptionBgColor: "transparent",
  selectLabelColor: theme.colors.black,
  linkColor: theme.colors.black,
  linkHoverColor: "#94d60a",
  pageNumberColor: "#999999",
  currentPageColor: theme.colors.black,
  gscResultInfo: "#676767",
  gscResultVisibleUrl: "#999999",
  gsSnippet: "#444444",
  gcscBrandingClickable: "transparent",
  findMoreMagnifier: theme.colors.black,
  optionMenuBgColor: theme.colors.white,
  optionMenuBorder: "none",
  gscOptionMenuBgColor: theme.colors.black,
  gscOptionMenuColor: theme.colors.white,
  gscMenuItemColor: theme.colors.black,
  gscActiveMenuItemHoverBgColor: theme.colors.black,
  gscActiveMenuItemHoverColor: theme.colors.white,
  gscMenuItemHoverBgColor: "#f1f1f1",
  gscMenuItemHoverColor: theme.colors.black
};

/*
-- Others
*/

//GoUpButton
const goToTopButton = {
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  boxShadow: "0 3px 6px #00000029",
  iconColor: theme.colors.white
};

//Loadings
const loadings = {
  mainBgColor: "#dddddd",
  bgColor: theme.colors.white,
  cardBgColor: theme.colors.white,
  cardBorderColor: theme.colors.black,
  lightBorderColor: "#dddddd",
  maskBgColor: theme.colors.white,
  iconColor: "#bdbdbd",
  iconContainerBgColor: "#e0e0e0",

  /*
  -- Molecules
  */

  //Cards
  bgImageNewsCard: {
    maskBgColor: theme.colors.white,
    contentContainerBgColor: theme.colors.white
  },

  agendaCard: {
    miniSliderColor: "#f6f7f8"
  },

  agendaFeaturedCard: {
    dateContainerBgColor: "#f6f7f8",
    borderColor: "#f6f7f8"
  },

  /*
  -- Journal
  */
  //JournalCards
  journalCards: {
    personCard: {
      bgColor: "#f5f5f5"
    }
  },

  //JournalDetails
  journalDetails: {
    maskBgColor: theme.colors.white,
    socialMediaIconsColor: "#eee"
  },

  //JournalHighLights
  journalHighlights: {
    backgroundColor: theme.colors.white,
    horizontalNewsCard: {
      bgColor: "#f9f9f9",
      maskColor: "#f9f9f9"
    }
  },

  //JournalListing
  journalListing: {
    mediumDevices: {
      loadingMaskBgColor: "#fafafa"
    }
  },

  //JournalNavigation
  journalNavigation: {
    iconColor: "#9e9e9e"
  }
};

//React Slick Slider
const reactSlickSlider = {
  buttonBgColor: theme.colors.grey
};

export default {
  ...theme,
  viewsColors,
  highContrast,
  globalStyle,
  loadings,
  containers: {
    accessibilitySelector
  },
  components: {
    //Atoms
    animatedBackground,
    aspectRatio,
    button,
    icon,
    input,
    line,
    link,
    picture,
    pagination,
    scrollMore,
    text,
    title,
    triangle,
    //Molecules
    accessibleAccordion,
    accordion,
    breadcrumbs,
    agendaCard,
    agendaFeatured,
    backgroundImageNewsCard,
    borderedCard,
    candidatureCard,
    colorExtractCard,
    horizontalNewsCard,
    imageCard,
    miniHorizontalNewsCard,
    newsCard,
    personCard,
    shareSocialMedia,
    showMoreCard,
    studentCard,
    carousel,
    customArrow,
    datePicker,
    dropDown,
    dropdownList,
    injectHtml,
    instituteSearch,
    listItem,
    plusButton,
    reactCalendar,
    scrollableTabs,
    section,
    sectionHeader,
    select,
    tab,
    tabContent,
    table,
    //Organisms
    about,
    agenda,
    article,
    candidature,
    cards,
    contentList,
    simpleList,
    contents,
    courses,
    curricular,
    curricularPlan,
    curricularUnit,
    departments,
    directory,
    edicts,
    events,
    oneFact,
    footer,
    header,
    information,
    // - Journal
    journalCards,
    journalColumns,
    journalDetails,
    journalDossiers,
    journalFooter,
    journalHighlights,
    journalListing,
    journalMoreNews,
    journalNavigation,
    journalNews,
    journalRelated,
    journalSlides,
    lastUpdate,
    loading,
    mobileNavBar,
    mobilePublicNavBar,
    news,
    errorHandler,
    numbers,
    peopleArticle,
    presentation,
    profile,
    projects,
    publicNavbar,
    sideNav,
    siteMap,
    // - SubWebs
    subWebContacts,
    subWebDepartment,
    subWebDepartmentCourses,
    subWebCourses,
    subWebNavigation,
    subWebPeople,
    subWebWarnings,
    webNavBar,
    //Templates
    globalSearch,
    googleSearch,
    //Others
    goToTopButton,
    reactSlickSlider
  }
};
