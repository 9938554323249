import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//Components
import { SubWebNavigation } from "../../components/organisms/index";
//API
import { getSubNavigation } from "../../api/requests";
//Context
import { StoreContext } from "../../context/StoreContext";
import { ContentContext } from "../../context/ContentContext";
//Utils
import { getZoom } from "../../services/utilities";
import { changeLanguage, lang } from "../../translations";

const NavigationTemplate = ({ match, searchToggle, history, ...props }) => {
  const context = useContext(StoreContext);
  const isFirefox = typeof InstallTrigger !== "undefined";
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [state, setState] = useState({
    loading: true,
    hasError: false,
    data: []
  });
  const {
    state: { disableLocalStorage }
  } = useContext(ContentContext);

  const { id: path, activeColor, activeColorBar } = context.state.subWebNav;

  const onChangeAccessibilityFilter = e => {
    e.preventDefault();
    e.stopPropagation();
    const value = Number(context.state.accessibilityValue) === 1 ? 0 : 1;
    context.updateAccessibility(value, disableLocalStorage);
    setDropdownOpen(false);
  };

  const onChangeZoom = e => {
    e.preventDefault();
    e.stopPropagation();
    const value =
      context.state.zoomValue === "100%"
        ? "125%"
        : getZoom() === "125%"
        ? "150%"
        : "100%";
    context.updateZoom(value, disableLocalStorage);
  };

  const onChangeLang = e => {
    e.preventDefault();
    e.stopPropagation();
    const language = lang() === "pt" ? "en" : "pt";
    const path = history.location.pathname;

    const regex = /agenda[^\w]/gi;
    const isAgenda = regex.test(path);
    //const isAgenda = path.includes("agenda");

    let url = isAgenda ? `/${language}/agenda` : path.replace(lang(), language);
    const isHome = disableLocalStorage && url === "/";
    history.push(isHome ? `/${language}/` : url);

    changeLanguage(language);
  };
  const onHandleClear = e => {
    setSearchType("a");
    setSearchValue("");
    searchToggle(e);
  };
  const getData = async () => {
    try {
      const data = await getSubNavigation({
        path,
        lang: lang(),
        history: props.history
      });

      setState(prevState => {
        return {
          ...prevState,
          data: data.tabs,
          loading: false
        };
      });
    } catch (error) {
      setState(prevState => {
        return { ...prevState, loading: false, hasError: true };
      });
    }
  };

  useEffect(() => {
    getData();
  }, [path]);
  const navProps = {
    isFirefox,
    content: context.state.subWebNav,
    activeColor: activeColorBar || activeColor || "#0EB4BD",
    isWhite: activeColorBar.includes("#fff") || activeColor.includes("#fff"),
    isDropdownOpen,
    onHandleClear,
    setDropdownOpen,
    onChangeZoom,
    zoomValue: context.state.zoomValue,
    lang: lang(),
    isExternal: context.state.isExternal,
    accessibilityValue: Number(context.state.accessibilityValue),
    onChangeAccessibilityFilter,
    onChangeLang,
    ...state,
    ...props
  };

  return <SubWebNavigation {...navProps} />;
};

NavigationTemplate.propTypes = {
  match: PropTypes.object,
  institute: PropTypes.object,
  searchToggle: PropTypes.funct
};

export default withRouter(NavigationTemplate);
