import React, { useEffect, useMemo, useContext } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
//Components
import { ErrorHandler } from "@uaveiro/ui";
import { baseUrl, portalAPI } from "../../services/constants";
//Context
import { ContentContext } from "../../context/ContentContext";

const ErrorTemplate = ({ errorCode, ...props }) => {
  const {
    state: { disableLocalStorage }
  } = useContext(ContentContext);

  const history = useHistory();

  const hasHistory = useMemo(
    () => history !== undefined && history.location.state !== undefined,
    [history]
  );
  const historyProps = useMemo(
    () => (hasHistory ? { ...history.location.state } : {}),
    [hasHistory, history]
  );

  let included;

  if (typeof window !== "undefined") {
    const domainUrl = window.location.href;
    const lastLocation = ["login", "error", "404"];
    included = lastLocation.some(location => domainUrl.includes(location));
  }

  const url = useMemo(
    () =>
      hasHistory && !included
        ? `${baseUrl}${history.location.state.location}`
        : typeof window !== "undefined"
        ? `${window.location.protocol}//${window.location.hostname}${
            !disableLocalStorage
              ? localStorage.getItem("portalLastLocationPath")
              : ""
          }`
        : null,
    [hasHistory, baseUrl, disableLocalStorage]
  );

  useEffect(() => {
    const onSendToTeams = async () => {
      const page = {
        404: "Ups! Página não encontrada",
        default: "Algo Correu mal!"
      };
      const info = `url: ${url}`;

      await fetch(
        `${portalAPI}/teams/message?page=${
          page[errorCode || "default"]
        }&info=${info}&code=${errorCode}`,
        {
          method: "POST"
        }
      );
    };
    onSendToTeams();
  }, [errorCode]);

  const onRenderComponent = () => {
    const errorProps = {
      description: {
        url,
        ...historyProps
      },
      errorCode,
      ...props
    };

    return <ErrorHandler errorCode={errorCode} {...errorProps} />;
  };

  return onRenderComponent();
};

ErrorTemplate.propTypes = {
  errorCode: PropTypes.number
};

export default ErrorTemplate;
