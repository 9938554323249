import React, {
  useReducer,
  createContext,
  useEffect,
  useCallback
} from "react";
import PropTypes from "prop-types";
import { getCookie } from "../services/utilities";

let ContentContext = createContext();

let initialState = {
  coursesList: {},
  breadcrumbs: [],
  dynamicBreadcrumbs: [],
  journalList: {
    id: -1,
    loading: true,
    error: false,
    currentPage: 1,
    totalPages: null,
    data: {
      categoryTitle: "",
      listNews: []
    }
  },
  disableLocalStorage: false
};

let reducer = (state, action) => {
  switch (action.type) {
    case "update-courses-list":
      return { ...state, coursesList: action.payload };
    case "update-journal-list":
      return { ...state, journalList: action.payload };
    case "update-dynamic-breadcrumbs-list":
      const { path } = action.payload;

      //Filters if already contains a url
      const prevState = state.dynamicBreadcrumbs.filter(e => e.path !== path);
      //Reorders
      const dynamicBreadcrumbs = [...prevState, { path }];
      //Remove first item
      if (dynamicBreadcrumbs.length >= 5) {
        dynamicBreadcrumbs.shift();
      }

      return { ...state, dynamicBreadcrumbs };
    case "update-breadcrumbs-list":
      return { ...state, breadcrumbs: action.payload };
    case "set-disable-local-storage":
      return { ...state, disableLocalStorage: action.payload };
    default:
      return state;
  }
};

function ContentProvider({ children }) {
  let [state, dispatch] = useReducer(reducer, initialState);
  let value = { state, dispatch };

  const setLocalStorageConfig = useCallback(() => {
    const oneTrustCookie = getCookie("OptanonConsent");

    if (oneTrustCookie) {
      try {
        const getGroups = oneTrustCookie
          .split("&")
          .find(item => item.includes("groups="))
          .replace("groups=", "")
          .split(",");

        const disableLocalStorage = getGroups.find(item =>
          item.includes("C0003:0")
        );

        dispatch({
          type: "set-disable-local-storage",
          payload: !!disableLocalStorage
        });

        if (!!disableLocalStorage && window) {
          window.localStorage.clear();
        }
      } catch (e) {}
    }
  }, []);

  useEffect(() => {
    setLocalStorageConfig();
    let timer = setTimeout(() => {
      if (window && window.OneTrust) {
        window.OneTrust.OnConsentChanged(() => {
          setLocalStorageConfig();
        });
      }
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <ContentContext.Provider value={value}>{children}</ContentContext.Provider>
  );
}

ContentProvider.propTypes = {
  children: PropTypes.node
};

let ContentConsumer = ContentContext.Consumer;

export { ContentContext, ContentProvider, ContentConsumer };
