import moment from "moment";
import { extendMoment } from "moment-range";
import "moment/min/locales";

//trans
import messages_pt from "./pt.json";
import messages_en from "./en.json";

extendMoment(moment);

const messages = {
  pt: messages_pt,
  en: messages_en
};

// language without region code
const BrowserLanguage =
  typeof window !== "undefined" ? navigator.language.split(/[-_]/)[0] : "pt"; 

function changeLanguage(language) {
  try {
    if (typeof window !== "undefined") { 
      window.sessionStorage.checkLang = language;
      localStorage.setItem("portalUALang", language);
      window.location.reload();       
    }
  } catch (error) {}
}

function lang() {
  try {
    if (typeof window !== "undefined") {
      if (localStorage.getItem("portalUALang") === null) {
        return window.sessionStorage.checkLang.includes(null)
          ? "pt"
          : window.sessionStorage.checkLang;
      }
      return localStorage.getItem("portalUALang");
    }
  } catch (error) {
    return "pt";
  }
}

if (lang() === "pt") {
  moment.locale("pt");
} else {
  moment.locale("en");
}

export { messages, BrowserLanguage, lang, changeLanguage };
