import React from "react";
//Data
import data from "../../data/navigation/search";
//Engine
import ComponentSelector from "../ComponentSelector";

function Search(props) {
  return data.map((component, index) => {
    const { name, ...rest } = component;
    return (
      <ComponentSelector
        key={index}
        name={name}
        index={index}
        length={Object.keys(data).length}
        {...rest}
        {...props}
      />
    );
  });
}

export default Search;
