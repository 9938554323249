export default [
  {
    name: "SEO",
    title: {
      pt: "Notícias",
      en: "News"
    },
    description: "Jornal da Universidade de Aveiro. Um jornal que pensa.",
    keywords: ["jornal", "journal", "notícias", "news"]
  },
  {
    name: "JournalHighlights"
  },
  {
    name: "JournalNews",
    sectionTitle: "Investigação",
    categoryId: 9,
    resource: "app.journal.news.investigation.title",
    hasShareIcon: true
  },
  {
    name: "JournalCards",
    sectionTitle: "Entrevistas",
    resource: "app.journal.news.interviews.title",
    smPaddingTop: "0",
    categoryId: 12,
    isTag: false,
    isSlider: true
  },
  {
    name: "JournalNews",
    sectionTitle: "Campus",
    resource: "app.journal.news.campus.title",
    smPaddingTop: "0",
    categoryId: 11,
    hasShareIcon: true
  },
  {
    name: "JournalCards",
    sectionTitle: "Opinião",
    resource: "app.journal.news.opinion.title",
    cardBackgroundColor: "#E7E8E9",
    cardTextColor: "#000",
    smPaddingTop: "0",
    categoryId: 13,
    isTag: false,
    isSlider: true
  },
  {
    name: "JournalNews",
    sectionTitle: "Cultura e Desporto",
    resource: "app.journal.news.sport.title",
    backgroundColor: "#C7C9CB",
    arrowColor: "#000",
    smPaddingTop: "0",
    categoryId: 1,
    hasShareIcon: true
  },
  {
    name: "JournalMoreNews",
    sectionTitle: "Mais lidas",
    resource: "app.journal.news.mostRead.title",
    rightSectionTitle: "Últimas",
    rightResource: "app.journal.news.latest.title"
  },
  {
    name: "JournalSlides",
    title: "Dossiê: COVID-19",
    resource: "app.journal.news.dossier.title",
    linkTitle: "Ver todos os dossiês",
    linkResource: "app.journal.news.dossier.all.title",
    showHeader: true,
    titleProps: {
      hasLine: false,
      hasLink: true,
      href: "/pt/noticias/41?dossier=true?n=COVID-19"
    },
    cardStyles: {
      textColor: "#000",
      backgroundColor: "#fff"
    },
    id: 41,
    // idEn: 42,
    endpoint:
      "/news?featured=true&recordsPerPage=6&categoriesToIgnore=10&categoriesToIgnore=3&categoriesToIgnore=8&categoriesToIgnore=14"
  },
  {
    name: "JournalColumns",
    leftSectionTitle: "Provas académicas",
    leftResource: "app.journal.news.academicExams.title",
    centerSectionTitle: "Bolsas e Empregos",
    centerResource: "app.journal.news.scholarshipsJobs.title",
    rightSectionTitle: "Concursos e prémios",
    rightResource: "app.journal.news.contestsAwards.title",
    leftCategoryId: [10],
    centerCategoryId: [3],
    rightCategoryId: [5]
  }
];
