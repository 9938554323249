import React, { memo, useState, useEffect, useMemo } from "react";
//Components
import { News } from "@uaveiro/ui";
//Utils
import { hasHash, baseUrl } from "../../services/constants";
import { facebookUrl, twitterUrl, linkedinUrl } from "../../services/utilities";
//API
import { getNews } from "../../api/requests";
import { withRouter } from "react-router-dom";
import { lang } from "../../translations";

const NewsTemplate = memo(function NewsTemplate({ ...props }) {
  const [state, setState] = useState({
    loading: true,
    error: false,
    data: {}
  });

  const route = useMemo(() => (lang() === "pt" ? "noticias" : "news"), [lang]);

  const openInNewTab = (e, url) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(url, "_blank");
  };

  const onHandleEnter = (e, url) => {
    if (e.key === "Enter") {
      openInNewTab(e, url);
    }
  };

  const onAddUrl = data => {
    let items = [];
    data.listNews.map(item => {
      let url = `${lang()}/${route}/${item.categoryID}/${item.id}`;
      items.push({
        ...item,
        url: `/${url}`,
        categoryUrl: `${lang()}/${route}/${item.categoryID}`,
        share: {
          facebook: facebookUrl(`${baseUrl}/${url}`),
          twitter: twitterUrl(`${baseUrl}/${url}`, item.title),
          linkedin: linkedinUrl(`${baseUrl}/${url}`),
          url: `${baseUrl}/${url}`
        }
      });
      return true;
    });

    return {
      listNews: items,
      pagination: data.pagination
    };
  };

  const onFetchData = async (
    channel = 57,
    recordsPerPage = 6,
    featured = true
  ) => {
    try {
      const multiCategories =
        props.categoriesToIgnore !== undefined &&
        props.categoriesToIgnore.length > 0;

      const multiCategoriesIDs = multiCategories
        ? `&categoriesToIgnore=${props.categoriesToIgnore.join(
            "&categoriesToIgnore="
          )}`
        : "";

      const isTag =
        props.tag !== undefined && props.tag !== null && props.tag.length > 0;
      const id = isTag ? `tag=${props.tag.join("&tag=")}` : null;

      const res = await getNews({
        lang: lang(),
        isTag,
        channel,
        id,
        recordsPerPage,
        featured,
        multiCategories,
        multiCategoriesIDs,
        history: props.history
      });

      const data = onAddUrl(res.data);

      setState(prevState => {
        return { ...prevState, data, loading: false };
      });
    } catch (error) {
      setState(prevState => {
        return { ...prevState, loading: false, error: true };
      });
    }
  };

  useEffect(() => {
    onFetchData(props.channel, props.recordsPerPage, props.featured);
  }, [props.channel, props.recordsPerPage, props.featured]);

  const NewsProps = {
    onAction: openInNewTab,
    onEnter: onHandleEnter,
    categoryUrl: `${hasHash}/${lang()}/${route}`,
    ...props
  };

  return (
    <News
      data={state.data}
      loading={state.loading}
      hasError={state.error}
      {...NewsProps}
    />
  );
});

export default withRouter(NewsTemplate);
