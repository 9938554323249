/**
 * SECTION - RESEARCH
 */

import RouteSelector from "../containers/RouteSelector";
import LocalSelector from "../containers/LocalSelector";
import { views } from "./../services/constants";

const { investigation } = views;

const ResearchRoutes = {
  routes: [
    {
      path: ["/sigef-projects/:id", "/projetos-sigef/:id"],
      component: LocalSelector,
      props: {
        activeView: investigation,
        document: "projectRedirect"
      }
    },
    {
      path: ["/id-projects/:id", "/projetos-id/:id"],
      component: LocalSelector,
      props: {
        activeView: investigation,
        document: "projectDetails"
      }
    },
    {
      path: ["/id-projects", "/projetos-id"],
      component: LocalSelector,
      props: {
        activeView: investigation,
        document: "projects"
      }
    }
  ]
};

export default ResearchRoutes;
