import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

function SmartContainer({ children, className }) {
  const [innerWidth, setInnerWidth] = useState(null);

  const onChangeInnerWidth = () => {
    setInnerWidth(window.innerWidth);
  };

  useEffect(() => {
    onChangeInnerWidth();
    window.addEventListener("resize", onChangeInnerWidth);
    return () => {
      window.removeEventListener("resize", onChangeInnerWidth);
    };
  }, []);

  return (
    <div className={`${innerWidth >= 992 ? "container" : ""} ${className}`}>
      {children}
    </div>
  );
}

SmartContainer.propTypes = {
  mdMarginLeft: PropTypes.string,
  mdMarginRight: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default styled(SmartContainer)`
  height: 0;
`;
