import React, { memo, Fragment, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//Components
import { PeopleArticle } from "../../components/organisms";
import SEO from "../SEO";
//Utils
import { compose } from "../../services/utilities";
import { StoreContext } from "../../context/StoreContext";
import Breadcrumbs from "../Breadcrumbs";

const PeopleArticleTemplate = memo(function PeopleArticleTemplate({
  data,
  match,
  ...props
}) {
  const { state } = useContext(StoreContext);
  const { lang } = match.params;
  const content = useMemo(() => data[lang] || data, [data, lang]);
  //Gets the breadcrumbs from that person
  const [person] = content.filter(item => item.name === match.params.name);
  const breadcrumbs =
    person.breadcrumbs !== undefined ? person.breadcrumbs : null;

  return (
    <Fragment>
      {props.hasSEO && <SEO title={content.title} />}
      <Breadcrumbs data={breadcrumbs} lang={lang} isLocal={true} />

      <PeopleArticle
        data={content}
        name={match.params.name}
        activeColor={content.activeColor || state.activeColor}
      />
    </Fragment>
  );
});

PeopleArticleTemplate.propTypes = {
  data: PropTypes.array,
  match: PropTypes.object,
  hasSEO: PropTypes.bool
};

export default compose(withRouter)(PeopleArticleTemplate);
