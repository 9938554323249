import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//components
import { MobilePublicNavbar } from "../../components/organisms";
//Hooks
import { useAuthentication } from "../../hooks";

const MobilePublicNavbarTemplate = props => {
  const authState = useAuthentication(props.location);

  const publicNavBarProps = {
    servicesUrl: `/${props.lang}/${
      props.lang === "pt" ? "servicos" : "services"
    }`,
    ...authState,
    ...props
  };

  return <MobilePublicNavbar {...publicNavBarProps} />;
};

MobilePublicNavbarTemplate.propTypes = {
  history: PropTypes.object
};

export default withRouter(MobilePublicNavbarTemplate);
