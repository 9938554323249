import React, { useContext } from "react";
//Styles
import { ThemeProvider, Theme, ThemeContrast } from "@uaveiro/ui";

//Context
import { StoreContext } from "./context/StoreContext";

function ThemeRegister({ children }) {
  const { state } = useContext(StoreContext);
  return (
    <ThemeProvider
      theme={Number(state.accessibilityValue) === 0 ? Theme : ThemeContrast}
    >
      {children}
    </ThemeProvider>
  );
}

export default ThemeRegister;
