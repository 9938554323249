import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//Components
import { JournalNews } from "@uaveiro/ui";
//Hocs
import {
  compose,
  facebookUrl,
  twitterUrl,
  linkedinUrl
} from "../../services/utilities";
import { hasHash, baseUrl } from "../../services/constants";
//API
import { getNews } from "../../api/requests";

function JournalNewsTemplate({ match, categoryId, ...props }) {
  const [state, setState] = useState({
    loading: true,
    error: false,
    data: {}
  });
  const { lang, categoryId: paramCategoryId } = match.params;
  const route = lang === "pt" ? "noticias" : "news";

  const onAddUrl = data => {
    let items = data.listNews.map(item => {
      let url = `${lang}/${route}/${item.categoryID}/${item.id}`;
      return {
        ...item,
        url: `/${url}`,
        share: {
          facebook: facebookUrl(`${baseUrl}/${url}`),
          twitter: twitterUrl(`${baseUrl}/${url}`, item.title),
          linkedin: linkedinUrl(`${baseUrl}/${url}`),
          url: `${baseUrl}/${url}`
        }
      };
    });

    return {
      listNews: items,
      pagination: data.pagination
    };
  };

  const onFetchData = async () => {
    try {
      const id =
        paramCategoryId !== undefined ? Number(paramCategoryId) : categoryId;

      const res = await getNews({
        lang,
        id,
        notInTopFeatured: true,
        history: props.history
      });
      const data = onAddUrl(res.data);

      setState(prevState => {
        return { ...prevState, data, loading: false };
      });
    } catch (error) {
      setState(prevState => {
        return { ...prevState, loading: false, error: true };
      });
    }
  };

  useEffect(() => {
    onFetchData();
  }, []);

  const openInNewTab = (e, url) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(url, "_blank");
  };

  const onHandleEnter = (e, url) => {
    if (e.key === "Enter") {
      props.history.push(url);
    }
  };

  const NewsProps = {
    onAction: openInNewTab,
    onEnter: onHandleEnter,
    categoryUrl: `${hasHash}/${lang}/${route}/${categoryId}`,
    categoryId,
    hasHash,
    ...props
  };

  return (
    <JournalNews
      data={state.data}
      loading={state.loading}
      hasError={state.error}
      {...NewsProps}
    />
  );
}

JournalNewsTemplate.propTypes = {
  match: PropTypes.object.isRequired,
  categoryId: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired
};

export default compose(withRouter)(JournalNewsTemplate);
