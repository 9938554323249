import { lang } from "../../translations";
import { views } from "../../services/constants";

const {
  root,
  education,
  cooperate,
  live,
  investigation,
  international
} = views;

const data = {
  topNavbar: {
    institute: {
      mobileStyles: {
        list: {
          marginBottom: "0"
        },
        link: {
          fontSize: "12px",
          fontWeight: "300",
          fontDecoration: "none"
        }
      },
      links: [
        {
          title: "sobre a ua",
          activeView: root,
          activeColor: "#95CA3D",
          resource: "app.nav.about",
          baseUrl: lang() === "pt" ? `universidade` : `university`,
          enableDropdown: true,
          hasMobileLinks: false,
          styles: {
            color: "#fff",
            fontSize: "14px",
            fontWeight: "400",
            fontDecoration: "none",
            uppercase: true
          },
          list: [
            {
              title: "sobre nós",
              resource: "app.nav.about.item.aboutUs",
              url: lang() === "pt" ? `sobre-nos` : `about-us`,
              styles: {
                fontSize: "14px",
                fontDecoration: "none"
              }
            },
            {
              title: "história",
              resource: "app.nav.about.item.history",
              url: lang() === "pt" ? `historia` : `history`,
              styles: {
                fontSize: "14px",
                fontDecoration: "none"
              }
            },
            {
              title: "organização",
              resource: "app.nav.about.item.organization",
              url: lang() === "pt" ? `organizacao` : `structure`,
              styles: {
                fontSize: "14px",
                fontDecoration: "none"
              }
            },
            {
              title: "os campi",
              resource: "app.nav.about.item.campus",
              url: lang() === "pt" ? `campi` : `campus`,
              styles: {
                fontSize: "14px",
                fontDecoration: "none"
              }
            },
            {
              title: "comunidade",
              resource: "app.nav.about.item.community",
              url: lang() === "pt" ? `comunidade` : `community`,
              styles: {
                fontSize: "14px",
                fontDecoration: "none"
              }
            },
            {
              title: "qualidade",
              resource: "app.nav.about.item.quality",
              url: lang() === "pt" ? `qualidade` : `quality`,
              styles: {
                fontSize: "14px",
                fontDecoration: "none"
              }
            },
            {
              title: "factos e números",
              resource: "app.nav.about.item.numbers",
              url: lang() === "pt" ? `factos-numeros` : `facts-figures`,
              styles: {
                fontSize: "14px",
                fontDecoration: "none"
              }
            }
          ]
        },
        {
          title: "estudar",
          activeView: education,
          activeColor: "#00AFBB",
          resource: "app.nav.education",
          baseUrl: lang() === "pt" ? `estudar` : `study`,
          enableDropdown: true,
          hasMobileLinks: false,
          styles: {
            color: "#fff",
            fontSize: "14px",
            fontWeight: "400",
            fontDecoration: "none",
            uppercase: true
          },
          list: [
            {
              title: "cursos",
              resource: "app.nav.education.item.courses",
              url: lang() === "pt" ? `tipo-curso` : `course-types`,
              styles: {
                fontSize: "14px",
                fontDecoration: "none"
              }
            },
            {
              title: "candidaturas",
              resource: "app.nav.education.item.aplication",
              url: lang() === "pt" ? `candidaturas` : `applications`,
              styles: {
                fontSize: "14px",
                fontDecoration: "none"
              }
            },
            // {
            //   title: "matrículas",
            //   resource: "app.nav.education.item.registration",
            //   url: lang() === "pt" ? `matriculas` : `registration`,
            //   styles: {
            //     fontSize: "14px",
            //     fontDecoration: "none"
            //   }
            // },
            {
              title: "apoio ao ensino",
              resource: "app.nav.education.item.teachingSupport",
              url: lang() === "pt" ? `apoio-ensino` : `teaching-support`,
              styles: {
                fontSize: "14px",
                fontDecoration: "none"
              }
            },
            {
              title: "apoio ao estudo",
              resource: "app.nav.education.item.studySupport",
              url: lang() === "pt" ? `apoio-estudo` : `study-support`,
              styles: {
                fontSize: "14px",
                fontDecoration: "none"
              }
            },
            {
              title: "estágios e emprego",
              resource: "app.nav.education.item.aftercourse",
              url:
                lang() === "pt"
                  ? `estagios-emprego`
                  : `professional-integration`,
              styles: {
                fontSize: "14px",
                fontDecoration: "none"
              }
            },
            {
              title: "departamentos e escolas",
              resource: "app.nav.education.item.departments",
              url:
                lang() === "pt"
                  ? `departamentos-escolas`
                  : `departments-schools`,
              styles: {
                fontSize: "14px",
                fontDecoration: "none"
              }
            }
          ]
        },
        {
          title: "viver",
          activeView: live,
          activeColor: "#F3B21B",
          resource: "app.nav.live",
          baseUrl: lang() === "pt" ? `viver` : `living`,
          enableDropdown: true,
          hasMobileLinks: false,
          styles: {
            color: "#fff",
            fontSize: "14px",
            fontWeight: "400",
            fontDecoration: "none",
            uppercase: true
          },
          list: [
            {
              title: "viver na UA",
              resource: "app.nav.live.item.study",
              url: lang() === "pt" ? `viver-ua` : `living-ua`,
              styles: {
                fontSize: "14px",
                fontDecoration: "none"
              }
            },
            {
              title: "alimentação",
              resource: "app.nav.live.item.eating",
              url: lang() === "pt" ? `alimentacao` : `eating`,
              styles: {
                fontSize: "14px",
                fontDecoration: "none"
              }
            },
            {
              title: "alojamento",
              resource: "app.nav.live.item.sleeping",
              url: lang() === "pt" ? `alojamento` : `sleeping`,
              styles: {
                fontSize: "14px",
                fontDecoration: "none"
              }
            },
            {
              title: "desporto",
              resource: "app.nav.live.item.sports",
              url: lang() === "pt" ? `desporto-na-ua` : `sport-in-ua`,
              styles: {
                fontSize: "14px",
                fontDecoration: "none"
              }
            },
            {
              title: "saúde e bem-estar",
              resource: "app.nav.live.item.health",
              url: lang() === "pt" ? `saude-bem-estar` : `health-wellness`,
              styles: {
                fontSize: "14px",
                fontDecoration: "none"
              }
            },
            {
              title: "cultura e tempos livres",
              resource: "app.nav.live.item.culture",
              url: lang() === "pt" ? `cultura-tempo-livre` : `culture-leisure`,
              styles: {
                fontSize: "14px",
                fontDecoration: "none"
              }
            },
            {
              title: "vida académica",
              resource: "app.nav.live.item.live",
              url: lang() === "pt" ? `vida-academica` : `academic-life`,
              styles: {
                fontSize: "14px",
                fontDecoration: "none"
              }
            }
          ]
        },
        {
          title: "investigar",
          activeColor: "#42D3B8",
          activeView: investigation,
          resource: "app.nav.investigation",
          baseUrl: lang() === "pt" ? `investigacao` : `uaresearch`,
          enableDropdown: true,
          hasMobileLinks: false,
          styles: {
            color: "#fff",
            fontSize: "14px",
            fontWeight: "400",
            fontDecoration: "none",
            uppercase: true
          },
          list: [
            {
              title: "unidades de investigação",
              resource: "app.nav.investigation.item.research.units",
              url:
                lang() === "pt"
                  ? `unidades-investigacao`
                  : `unidades-investigacao`,
              styles: {
                fontSize: "14px",
                fontDecoration: "none"
              }
            },
            {
              title: "atividade científica",
              resource: "app.nav.investigation.item.activity",
              url:
                lang() === "pt"
                  ? `atividade-cientifica`
                  : `scientific-activity`,
              styles: {
                fontSize: "14px",
                fontDecoration: "none"
              }
            },
            {
              title: "Centro académico clínico",
              resource: "app.nav.investigation.item.activity.academicCenter",
              url:
                lang() === "pt"
                  ? `cacemha`
                  : `cacemha`,
              styles: {
                fontSize: "14px",
                fontDecoration: "none"
              }
            },
            {
              title: "apoios e financiamento",
              resource: "app.nav.investigation.item.support",
              url: lang() === "pt" ? `financiamento` : `funding`,
              styles: {
                fontSize: "14px",
                fontDecoration: "none"
              }
            },
            {
              title: "escola doutoral",
              resource: "app.nav.investigation.item.school",
              url: lang() === "pt" ? `escola-doutoral` : `doctoral-school`,
              styles: {
                fontSize: "14px",
                fontDecoration: "none"
              }
            },
            {
              title: "apoio à investigação",
              resource: "app.nav.investigation.item.supportresearch",
              url: lang() === "pt" ? `apoio-investigacao` : `research-support`,
              styles: {
                fontSize: "14px",
                fontDecoration: "none"
              }
            }
          ]
        },
        {
          title: "cooperar",
          activeView: cooperate,
          activeColor: "#9878D3",
          resource: "app.nav.innovation",
          baseUrl: lang() === "pt" ? `cooperacao` : `cooperation`,
          enableDropdown: true,
          hasMobileLinks: false,
          styles: {
            color: "#fff",
            fontSize: "14px",
            fontWeight: "400",
            fontDecoration: "none",
            uppercase: true
          },
          list: [
            {
              title: "áreas de cooperação",
              resource: "app.nav.innovation.item.development",
              url: lang() === "pt" ? `areas-cooperacao` : `cooperation-areas`,
              styles: {
                fontSize: "14px",
                fontDecoration: "none"
              }
            },
            {
              title: "empreendedorismo",
              resource: "app.nav.innovation.item.entrepreneurship",
              url: lang() === "pt" ? `empreendedorismo` : `entrepreneurship`,
              styles: {
                fontSize: "14px",
                fontDecoration: "none"
              }
            },
            {
              title: "impacto na região",
              resource: "app.nav.innovation.item.pci",
              url: lang() === "pt" ? `impacto-regiao` : `regional-influence`,
              styles: {
                fontSize: "14px",
                fontDecoration: "none"
              }
            },
            {
              title: "balcão de cooperação",
              resource: "app.nav.innovation.item.cooperate",
              url:
                lang() === "pt" ? `balcao-cooperacao` : `cooperation-support`,
              styles: {
                fontSize: "14px",
                fontDecoration: "none"
              }
            }
          ]
        },
        {
          title: "internacional",
          activeView: international,
          activeColor: "#F0592A",
          resource: "app.nav.International",
          baseUrl: lang() === "pt" ? `internacional` : `international`,
          enableDropdown: true,
          hasMobileLinks: false,
          styles: {
            color: "#fff",
            fontSize: "14px",
            fontWeight: "400",
            fontDecoration: "none",
            uppercase: true
          },
          list: [
            {
              title: "porquê Portugal?",
              resource: "app.nav.International.item.whyPortugal",
              url: lang() === "pt" ? `porque-portugal` : `why-portugal`,
              styles: {
                fontSize: "14px",
                fontDecoration: "none"
              }
            },
            {
              title: "estudar e investigar",
              resource: "app.nav.International.item.study",
              url:
                lang() === "pt"
                  ? `internacional-estudar-na-ua`
                  : `international-study-at-ua`,
              styles: {
                fontSize: "14px",
                fontDecoration: "none"
              }
            },
            {
              title: "viver em Aveiro",
              resource: "app.nav.International.item.livingAveiro",
              url: lang() === "pt" ? `viver-aveiro` : `living-aveiro`,
              styles: {
                fontSize: "14px",
                fontDecoration: "none"
              }
            },
            {
              title: "cooperação internacional",
              resource: "app.nav.International.item.international.cooperation",
              url:
                lang() === "pt"
                  ? `internacional-cooperacao`
                  : `international-cooperation`,
              styles: {
                fontSize: "14px",
                fontDecoration: "none"
              }
            },
            {
              title: "mobilidade",
              resource: "app.nav.International.item.mobility",
              url: lang() === "pt" ? `mobilidade` : `mobility`,
              styles: {
                fontSize: "14px",
                fontDecoration: "none"
              }
            }
          ]
        }
      ],
      accessibility: {
        name: "fal fa-universal-access",
        cursor: "pointer",
        color: "#fff",
        darken: true,
        link: {
          href: lang() === "pt" ? `acessibilidade` : `accessibility`,
          fontSize: "14px"
        }
      },
      contacts: {
        name: "fal fa-phone",
        cursor: "pointer",
        color: "#fff",
        darken: true,
        link: {
          href: lang() === "pt" ? `contactos` : `contacts`,
          fontSize: "14px"
        }
      },
      language: {
        title: "EN",
        alt: "alterar idioma para EN",
        fontDecoration: "none",
        fontWeight: "100",
        color: "#fff",
        fontSize: "14px",
        lighten: true
      },
      search: {
        name: "fal fa-search",
        cursor: "pointer",
        color: "#fff",
        darken: true,
        link: {
          fontSize: "14px"
        },
        box: {
          fontSize: "18px",
          fontDecoration: "none"
        }
      }
    }
  },
  publicNavbar: {
    links: [
      {
        title: "Futuros Estudantes",
        resource: "app.nav.public.students.future",
        url:
          lang() === "pt"
            ? `publico-futuros-estudantes`
            : `public-prospective-students`
      },
      {
        title: "Estudantes UA",
        resource: "app.nav.public.students.ua",
        url: lang() === "pt" ? `publico-estudantes` : `public-students`
      },
      {
        title: "Estudantes Internacionais",
        resource: "app.nav.public.students.international",
        url:
          lang() === "pt"
            ? `publico-estudantes-internacionais`
            : `public-internacional-student`
      },
      {
        title: "Alumni",
        resource: "app.nav.public.alumni",
        url: lang() === "pt" ? `publico-alumni` : `public-alumni`
      },
      {
        title: "Pessoas UA",
        resource: "app.nav.public.ua.people",
        url: lang() === "pt" ? `publico-pessoas` : `public-people`
      },
      {
        title: "Sociedade",
        resource: "app.nav.public.society",
        url: lang() === "pt" ? `publico-sociedade` : `public-society`
      }
    ],
    social: [
      {
        title: "Notícias",
        ariaLabel: "Notícias",
        resource: "app.nav.public.social.news",
        url: lang() === "pt" ? `noticias` : `news`,
        target: "_self",
        onlyIcon: false,
        styles: {
          fontWeight: "400",
          fontDecoration: "none"
        }
      },
      {
        title: "Agenda",
        ariaLabel: "Agenda",
        resource: "app.nav.public.social.agenda",
        url: lang() === "pt" ? `agenda` : `agenda`,
        target: "_self",
        onlyIcon: false,
        styles: {
          fontWeight: "400",
          fontDecoration: "none"
        }
      },
      {
        url: "https://www.facebook.com/universidadedeaveiro/",
        target: "_blank",
        ariaLabel: "Facebook",
        onlyIcon: true,
        styles: {
          fontWeight: "400",
          fontDecoration: "none"
        }
      },
      {
        url: "https://www.instagram.com/universidadedeaveiro/",
        target: "_blank",
        ariaLabel: "Instagram",
        onlyIcon: true,
        styles: {
          fontWeight: "400",
          fontDecoration: "none"
        }
      }
    ],
    auth: {
      styles: {
        fontWeight: "400"
      },
      iconLogOut: {
        name: "fas fa-user",
        cursor: "cursor",
        fontSize: "12px",
        marginLeft: "12px"
      },
      iconLogIn: {
        name: "fas fa-user",
        cursor: "cursor",
        fontSize: "12px",
        marginLeft: "12px"
      }
    }
  }
};

export default data;
