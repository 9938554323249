import React from "react";
//Engine
import styled from "styled-components";

function Map({ className }) {
  return (
    <div className={className}>
      <div className="">
        <div className="col-lg-12 iframe-container">
          <iframe
            title="google-maps"
            src="https://www.google.com/maps/d/embed?mid=1T5C-L79Kh_IKDSWUDELzyPqrqyIIKep5&z=16&ll=40.632543, -8.658140"
          />
        </div>
      </div>
    </div>
  );
}

export default styled(Map)`
  .iframe-container {
    overflow: hidden;
    // Calculated from the aspect ration of the content (in case of 16:9 it is 9/16= 0.5625)
    height: calc(100vh - 216px);
    position: relative;
  }

  @media screen and (max-width: 768px) {
    .iframe-container {
      height: calc(100vh - 125px);
    }
  }

  .iframe-container iframe {
    border: 0;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
  }
`;
