import React from "react";
import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
//Styles
import styled, { css } from "styled-components";
import { lighten, darken } from "polished";
import theme from "../../../services/theme";

const sizing = {
  xSmall: `
    font-size: ${theme.sizes.xs}; //12px
  `,
  small: `
    font-size: ${theme.sizes.s}; //14px
  `,
  article: `
    font-size: ${theme.sizes.sm};; //15px
  `,
  mediumSmall: `
    font-size: ${theme.sizes.m}; //16px
  `,
  medium: `
    font-size: ${theme.sizes.l}; //18px
  `,
  large: `
    font-size: ${theme.sizes.xl2}; //22px
  `,
  xLarge: `
    font-size: ${theme.sizes.xl3m}; //28px
  `,
  xxLarge: `
    font-size: ${theme.sizes.xl4}; //32px
  `,
  xxxLarge: `
    font-size: ${theme.sizes.xl6}; //48px
  `
};

const sharedStyle = css`
  pointer-events: ${props => (props.disabled ? "none" : "auto")};
  color: ${props => props.color};
  ${props =>
    props.fontSize
      ? `font-size: ${props.fontSize}`
      : sizing[props.size || "small"]};
  line-height: ${props => props.lineHeight};
  font-weight: ${props => props.fontWeight || "400"};
  text-decoration: ${props => props.textDecoration || "none"};
  background: ${props => props.background};
  transform: ${props => props.transform};
  text-transform: ${props => props.textTransform};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  padding-bottom: ${props => props.paddingBottom};
  cursor: ${props => props.cursor || "pointer"};
  position: ${props => props.position};
  right: ${props => props.right};
  padding-right: ${props => props.paddingRight};
  border-right: ${props => props.borderRight};
  margin-bottom: ${props => props.marginBottom};
  margin-top: ${props => props.marginTop};
  display: ${props => props.display};
  height: ${props => props.height};
  width: ${props => props.width};
  text-decoration-color: ${props => props.textDecorationColor};
  -webkit-text-decoration-color: ${props => props.textDecorationColor};
  ${props => props.uppercase && "text-transform: uppercase"};
  ${props => props.opacity && "opacity: 0.8"};

  &:focus {
    outline: 2px solid inherit;
    outline-offset: ${props => props.outlineOffset};
  }

  //Alt styles
  img {
    color: #000;
    font-weight: 300;
  }

  ${props =>
    props.hasHover
      ? `&:hover {
    text-decoration: ${props.fontDecoration};
    color: ${
      props.default ? props.textDecorationColor : lighten(0.1, props.color)
    };
    ${props.lighten && `opacity: 0.5;`};
    text-decoration-color: ${props.textDecorationColor};
    -webkit-text-decoration-color: ${props.textDecorationColor};
    /* 
    -- Icon Styles when hover a link
    */
    ${props.hasIconHover &&
      `
      i,
      p {
        ${
          props.darken
            ? `color: ${darken(0.5, props.color)}`
            : `color: ${lighten(0.5, props.color)}`
        };
      }
    `};
  }`
      : ""};

  &:hover {
    color: ${props => props.hoverColor};
  }

  /* 
  -- Responsive
  */
  // Medium devices (tablets, less than 992px)
  @media (max-width: 992px) {
    margin-bottom: ${props => props.mdMarginBottom};
  }

  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767px) {
    font-size: ${props => props.mobileSmFontSize};
    padding: ${props => props.mobileSmPadding};
  }

  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575px) {
    font-size: ${props => props.mobileXsFontSize};
    line-height: ${props => props.mobileXsLineHeight};
  }
`;

const StateLink = styled(RouterLink).attrs(({ text, children }) => ({
  children: text || children
}))`
  ${sharedStyle}
`;

const NativeLink = styled.a.attrs(({ text, children }) => ({
  children: text || children
}))`
  ${sharedStyle}
`;

const Link = ({ opacity, native, href, ...rest }) =>
  native ? (
    <NativeLink href={href} {...rest} />
  ) : (
    <StateLink to={href} {...rest} />
  );

Link.defaultProps = {
  href: "#",
  fontDecoration: "underline",
  color: "#000",
  uppercase: false,
  lighten: false,
  opacity: false,
  disabled: false,
  hasSameBackground: false,
  native: false,
  hasHover: true,
  hasIconHover: true
};

Link.propTypes = {
  color: PropTypes.string,
  href: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fontDecoration: PropTypes.string,
  uppercase: PropTypes.bool,
  lighten: PropTypes.bool,
  opacity: PropTypes.bool,
  cursor: PropTypes.string,
  disabled: PropTypes.bool,
  mobileSmFontSize: PropTypes.string,
  mobileXsFontSize: PropTypes.string,
  display: PropTypes.string,
  textDecoration: PropTypes.string,
  textDecorationColor: PropTypes.string,
  lineHeight: PropTypes.string,
  background: PropTypes.string,
  mobileXsLineHeight: PropTypes.string,
  native: PropTypes.bool
};

export default Link;
