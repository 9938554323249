import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
//Components
import { Link } from "../../../atoms";
import {
  Section,
  SectionHeader,
  VerticalList,
  ListItem,
  Error,
  ContentContainer,
  ContentLoading,
  Text,
  Picture,
  MarkdownRenderer
} from "@uaveiro/ui";
import { withTheme } from "styled-components";

const ProjectList = ({ className, theme, loading, hasError, data }) => {
  const [showLogos, setShowLogos] = useState(true);

  const onHandleLogosError = e => {
    setShowLogos(false);
  };
  return (
    <Section
      className={className}
      paddingTop="70px"
      paddingBottom={showLogos ? "0" : "70px"}
      hasHighContrastBorderTop={false}
    >
      {loading ? (
        <div className="container">
          <div className="row no-gutters">
            <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-4 col-12 offset-0">
              <ContentLoading />
            </div>
          </div>
        </div>
      ) : hasError ? (
        <Error />
      ) : (
        <Fragment>
          <div className="container">
            <div className="row no-gutters">
              <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-4 col-12 offset-0">
                {showLogos && (
                  <ContentContainer marginBottom="50px">
                    <Picture
                      src={data.logos}
                      isLocal={false}
                      maxHeight="40px"
                      maxWidth="100%"
                      width="unset"
                      backgroundSize="contain"
                      imagePosition="left"
                      hasImageBgColor={false}
                      onCatchError={onHandleLogosError}
                    />
                  </ContentContainer>
                )}

                <SectionHeader
                  hasContainer={false}
                  hasLink={false}
                  hasLine={false}
                  textColor={
                    theme.components.projects.projectDetails.titleColor
                  }
                >
                  {data.title.value}
                </SectionHeader>

                <VerticalList>
                  {data.fields.map((item, i) => {
                    let isMoney = item.type === "money";
                    let isLink = item.type === "link";
                    let isDate = item.type === "date";
                    let isMarkdown = item.type === "markdown";
                    let isExternalLink = item.extra!== null && item.extra.includes("http");
                    const moneyValue =
                      isMoney &&
                      Number(item.info.value)
                        .toFixed(2)
                        .replace(/\d(?=(\d{3})+\.)/g, "$&,");

                    return (
                      item.info.value && (
                        <ListItem key={i} marginBottom="30px">
                          <Text
                            size="article"
                            color={
                              theme.components.projects.projectDetails.textColor
                            }
                          >
                            {item.info.label}
                          </Text>
                          {isLink ? (
                            <Link
                              native={isExternalLink}
                              href={item.extra}
                              fontWeight="300"
                              textDecoration="underline"
                              fontSize="15px"
                            >
                              {item.info.value}
                            </Link>
                          ) : isMarkdown ? (
                            <MarkdownRenderer content={item.info.value} />
                          ) : (
                            <Text size="article">
                              {isDate
                                ? item.info.value.split(" ")[0]
                                : isMoney
                                ? moneyValue
                                : item.info.value}
                              {isMoney && "€"}
                            </Text>
                          )}
                        </ListItem>
                      )
                    );
                  })}
                </VerticalList>
                <ContentContainer marginBottom="50px">
                  {/* added to force a margin in the bottom of the page after removing the logo */}
                </ContentContainer>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Section>
  );
};

ProjectList.propTypes = {
  className: PropTypes.string
};

export default withTheme(ProjectList);
