import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  getAccessibilityFilter,
  changeAccessibilityFilter,
  getZoom,
  changeZoom,
  getCookieBarLocalStorage,
  setCookieBarLocalStorage
} from "../services/utilities";
import { views } from "./../services/constants";
import theme from "./../services/theme.semantic";

const StoreContext = React.createContext();

class StoreProvider extends Component {
  static propTypes = {
    children: PropTypes.any
  };

  state = {
    showSideNav: false,
    activeView: views.public,
    activeColor: theme.viewsColors.root,
    searchFocus: false,
    zoomValue: getZoom() || "100%",
    accessibilityValue: getAccessibilityFilter() || 0,
    cookiesConsent: getCookieBarLocalStorage(),
    isNews: false,
    isSubWeb: false,
    subWebNav: {
      id: "cidtff/leip/page/11764"
    },
    sideNav: {
      id: null
    },
    pageLastModified: null,
    isExternal: false,
    services: {
      all: [],
      favorites: [],
      original: []
    }
  };
  componentDidMount() {
    if (typeof window !== "undefined") {
      if (window.location.search.includes("highContrast=true")) {
        this.setState({
          accessibilityValue: 1
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isSubWeb !== prevState.isSubWeb) {
      this.setState({ isExternal: false });
    }
  }

  updateSideNav = showSideNav => {
    this.setState({ showSideNav });
  };

  setSideNav = (sideNav, activeView = "index", showSideNav = true) => {
    this.setState({
      showSideNav: true,
      activeView,
      sideNavId: sideNav.id,
      sideNav
    });
  };

  updateActiveView = (activeView = views.root) => {
    this.setState(() => ({
      activeView,
      activeColor: theme.viewsColors[activeView]
    }));
  };
  updateSubWebActiveView = (activeView = views.root) => {
    this.setState(() => ({
      activeView
    }));
  };
  toggleSearchFocus = () => {
    this.setState(prevState => ({
      searchFocus: !prevState.searchFocus
    }));
  };
  updateZoom = (zoomValue, disableLocalStorage = false) => {
    this.setState({ zoomValue });
    if (!disableLocalStorage) {
      changeZoom(zoomValue);
    }
  };
  updateAccessibility = (accessibilityValue, disableLocalStorage = false) => {
    this.setState({ accessibilityValue });
    if (!disableLocalStorage) {
      changeAccessibilityFilter(accessibilityValue);
    }
  };
  updateCookieBarConsent = cookiesConsent => {
    this.setState({ cookiesConsent });
    setCookieBarLocalStorage(cookiesConsent);
  };
  updateJournalNav = isNews => {
    this.setState({ isNews });
  };
  updateSubWeb = isSubWeb => {
    this.setState({ isSubWeb });
  };
  updatePageLastModified = pageLastModified => {
    this.setState({ pageLastModified });
  };

  setSubWebNavigation = (subWebNav, isSubWeb = true) => {
    this.setState({
      isSubWeb,
      subWebNav
    });
  };
  updateActiveColor = activeColor => {
    this.setState({ activeColor });
  };

  updateExternalPage = (isExternal = false) => {
    this.setState({ isExternal });
  };

  updateServices = (
    services = {
      all: [],
      favorites: [],
      original: []
    }
  ) => {
    this.setState({ services });
  };

  render() {
    return (
      <StoreContext.Provider
        value={{
          state: this.state,
          updateSideNav: this.updateSideNav,
          updateActiveView: this.updateActiveView,
          toggleSearchFocus: this.toggleSearchFocus,
          updateZoom: this.updateZoom,
          updateAccessibility: this.updateAccessibility,
          updateCookieBarConsent: this.updateCookieBarConsent,
          updateJournalNav: this.updateJournalNav,
          updateSubWeb: this.updateSubWeb,
          updatePageLastModified: this.updatePageLastModified,
          setSubWebNavigation: this.setSubWebNavigation,
          updateActiveColor: this.updateActiveColor,
          setSideNav: this.setSideNav,
          updateExternalPage: this.updateExternalPage,
          updateServices: this.updateServices,
          updateSubWebActiveView: this.updateSubWebActiveView
        }}
      >
        {this.props.children}
      </StoreContext.Provider>
    );
  }
}

export { StoreContext, StoreProvider };
