import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//components
import { Contents } from "@uaveiro/ui";
//Utils
import { compose } from "../../services/utilities";
//API
import {
  getCourseGraduateProfilesById,
  getCoursePlanSummaryById,
  getCourseAccreditationsById
} from "../../api/requests";

const fetchModifier = {
  TwoCols: getCourseGraduateProfilesById,
  OneCol: getCoursePlanSummaryById,
  NestedRow: getCourseAccreditationsById
};

const ContentsTemplate = ({ match, variant, history, ...props }) => {
  const [state, setState] = useState({
    loading: true,
    error: false,
    content: {}
  });

  const onFetchContent = async () => {
    try {
      const { lang, id } = match.params;
      const data = await fetchModifier[variant]({
        id,
        lang,
        hasDelay: true,
        history
      });

      setState(prevState => {
        return { ...prevState, content: { ...data }, loading: false };
      });
    } catch (error) {
      setState(prevState => {
        return { ...prevState, loading: false, error: true };
      });
    }
  };

  //If the user changes the url update the content
  useEffect(() => {
    onFetchContent();
  }, [history.location]);

  const oneColAction = () => {
    history.push(`/${match.params.lang}/c/${match.params.id}/p`);
  };

  const contentsProps = {
    lang: match.params.lang,
    variant,
    history,
    match,
    oneColAction,
    ...props
  };
  if (!state.loading && state.content.hideModule) return <Fragment></Fragment>;
  return (
    <Contents
      content={state.content}
      loading={state.loading}
      hasError={state.error}
      {...contentsProps}
    />
  );
};

ContentsTemplate.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  variant: PropTypes.string
};

export default compose(withRouter)(ContentsTemplate);
