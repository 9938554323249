import React, { memo, useState, useEffect, useContext } from "react";
//Components
import { Contests } from "@uaveiro/ui";
//API
import { getContests as queryContests } from "../../api/requests";
import { withRouter } from "react-router-dom";
import cleanDeep from 'clean-deep';
import queryString from 'query-string';
import { StoreContext } from "../../context/StoreContext";

const ContestsTemplate = memo(function ContestsTemplate({
  contestType,
  states,
  tabs,
  match,
   ...props 
}) {
  const { state } = useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const [contests, setContests] = useState([]);
  const [contestsFiltered, setContestsFiltered] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const [error, setError] = useState(null);
  const { lang } = match.params;

  const getContests = async () => {
    try {
      const dirty = {
        type: contestType, state: states, language: lang
      };
      const params = cleanDeep(dirty);
      const query = queryString.stringify(params);
      setError(null);
      setLoading(true);
      const response = await queryContests({
        lang: lang, 
        query,
        ...props
      });
      const filteredContests = response.data.filter(contest => {
        const hasOpenApplications = contest.openApplications;
        const isAdmissionState = contest.contestStateId === 2;

        const [day, month, year] = contest.submissionDeadlineFormattedDate.split('-');
        const submissionDeadline = new Date(year, month - 1, day);
        
        const isSubmissionDeadlineExpired = submissionDeadline < new Date().setHours(0, 0, 0, 0);

        // Não mostra concursos em estado admissão que estejam expirados ou que não tenham candidaturas abertas
        return !(isAdmissionState && (!hasOpenApplications || isSubmissionDeadlineExpired));
      });
      setContests(filteredContests);
      setLoading(false)
    } catch (err) {
      setContests([])
      setLoading(false);
      setError(err);
    }
  }

  useEffect(() => {
    if (tabs.length > 0) {
      setActiveTab(tabs[0].id)
    }
    getContests();
  }, [contestType, JSON.stringify(states), JSON.stringify(tabs), lang]);

  useEffect(() => {
    if (activeTab !== null && states.length > 0) {
      setContestsFiltered(contests.filter((contest) => contest.contestStateId === activeTab));
    } else {
      setContestsFiltered(contests);
    }
  }, [contests, activeTab]);

  const onChangeTab = id => {
    setActiveTab(id);
  };

  return (
    <Contests
      {...props}
      data={{contests: contestsFiltered}}
      loading={loading}
      hasError={error}
      onChangeTab={onChangeTab}
      activeTab={activeTab}
      tabs={tabs}
      activeColor={state.activeColor}
    />
  );
});

export default withRouter(ContestsTemplate);
