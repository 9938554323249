import { lang } from "./../translations";
import { hasHash } from "./constants";

/**
 * USER OPERATIONS
 */

//Saves the user token to remember the user
export const _storeUser = token => {
  // window.localStorage.setItem("portalWebToken", token);
};

//Checks if the user is loggedin
export const _isLoggedIn = () => {
  // return window.localStorage.getItem("portalWebToken");
};
//Removes the token and logs out the user
export const logout = () => {
  try {
    if (typeof window !== "undefined") {
      localStorage.removeItem("portalUAToken");
    }
  } catch (error) {}
  /* */
};

export const parseJwt = token => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function(c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const getAuthUserInformation = () => {
  try {
    if (typeof window !== "undefined") {
      const token = localStorage.getItem("sb-ua-auth-token");
      const defaultResponse = {
        token: "",
        authenticated: false
      };

      if (token !== "undefined" && token !== null) {
        const tokenData = parseJwt(token);

        if (Date.now().valueOf() / 1000 < tokenData.exp) {
          return {
            authenticated: true,
            token,
            photo: "https://via.placeholder.com/150",
            ...tokenData
          };
        } else {
          return defaultResponse;
        }
      } else {
        return defaultResponse;
      }
    }
  } catch (error) {
    return {
      token: "",
      authenticated: false
    };
  }
};

export const convertBlobImage = src => {
  if (typeof window !== "undefined") {
    const urlCreator = window.URL || window.webkitURL;
    return urlCreator.createObjectURL(src);
  }
};

/**
 * UTILS OPERATIONS
 */
export const generateToken = () => {
  return guid();
};

export const guid = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + "" + s4() + "" + s4() + "" + s4();
};

export const _refreshPage = () => {
  window.location.reload();
};

export const compose = (...funcs) =>
  funcs.reduce(
    (a, b) => (...args) => a(b(...args)),
    arg => arg
  );

export const validURL = str => {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

export const is_touch_device = () => {
  let query = ["(", prefixes.join("touch-enabled),("), "heartz", ")"].join("");
  return mq(query);
};

// strip html tags
export const stripHtml = html => {
  /*const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";*/
  return "";
};

// clear hash route
export const clearHashRoute = hashRoute => {
  const chars = hashRoute.split("");
  let cleanRoute = hashRoute;
  if (chars[0] === "#") cleanRoute = hashRoute.substr(1);
  return cleanRoute;
};

export function changeAccessibilityFilter(value) {
  if (typeof window !== "undefined") {
    localStorage.setItem("portalUAAccessibilityFilter", value);
  }
}

//gets the default or current value for de accessibility filter
export function getAccessibilityFilter() {
  try {
    if (typeof window !== "undefined") {
      if (localStorage.getItem("portalUAAccessibilityFilter") === null) {
        changeAccessibilityFilter(0);
        return 0;
      }
      return localStorage.getItem("portalUAAccessibilityFilter");
    }
  } catch (error) {
    return 0;
  }
  return 0;
}

export function changeZoom(value) {
  try {
    if (typeof window !== "undefined") {
      localStorage.setItem("portalUAZoomFilter", value);
    }
  } catch (error) {}
}

export function getZoom() {
  try {
    if (typeof window !== "undefined") {
      if (localStorage.getItem("portalUAZoomFilter") === null) {
        changeZoom("100%");
        return "100%";
      }
      return localStorage.getItem("portalUAZoomFilter");
    }
  } catch (error) {
    return "100%";
  }
}

//gets the cookies bar state
export function setCookieBarLocalStorage(hasConsent = true) {
  try {
    if (typeof window !== "undefined") {
      localStorage.setItem("portalUACookiesBarConsent", hasConsent);
      return hasConsent;
    }
  } catch (error) {}
}

export function getCookieBarLocalStorage() {
  try {
    if (typeof window !== "undefined") {
      const cookieBarConsent = localStorage.getItem(
        "portalUACookiesBarConsent"
      );
      if (cookieBarConsent === null) return false;
      return JSON.parse(cookieBarConsent);
    }
  } catch (error) {}
}

export const internalLink = path => `${hasHash}/${lang()}/${path}`;

export const isIE = () => {
  if (typeof window !== "undefined") {
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    // eslint-disable-next-line
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      // If Internet Explorer, return version number
      // alert(parseInt(ua.substring(msie + 5, ua.indexOf(".", msie))));
      return true;
    } // If another browser, return 0
  }

  return false;
};

//TODO: Melhorar para ser mais dinamico e colocar mum ficheiro separado
export function truncateOnWord(
  str,
  limit = 0,
  collapse = true,
  ellipsis = "..."
) {
  if (!collapse || str === null) {
    return str;
  }
  let trimmable =
    "\u0009\u000A\u000B\u000C\u000D\u0020\u00A0\u1680\u180E\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u202F\u205F\u2028\u2029\u3000\uFEFF";
  let reg = new RegExp("(?=[" + trimmable + "])");
  let words = str.split(reg);
  let count = 0;
  let content =
    words
      .filter(word => {
        count += word.length;
        return count <= limit;
      })
      .join("") + ellipsis;

  return content;
}

export function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  // eslint-disable-next-line
  name = name.replace(/[\[\]]/g, "\\$&");
  let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

// eslint-disable-next-line
Array.prototype.chunk = function(chunk_size) {
  if (!this.length) {
    return [];
  }

  return [this.slice(0, chunk_size)].concat(
    this.slice(chunk_size).chunk(chunk_size)
  );
};

//SHARE URLS

export const facebookUrl = (url = "/") =>
  `https://www.facebook.com/sharer/sharer.php?u=${url}`;
export const twitterUrl = (url = "/", title = "Title") =>
  `http://twitter.com/share?url=${encodeURIComponent(
    url
  )}&text=${encodeURIComponent(title)}`;

export const linkedinUrl = (url = "/") =>
  `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
    url
  )}`;

export function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  document.body.removeChild(textArea);
}
export async function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }

  try {
    await navigator.clipboard.writeText(text);
    return true;
  } catch (e) {
    throw e;
  }
}

export function formatMoney(
  amount,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(
      (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    return e;
  }
}

export function groupArrayOfObjects(list, key) {
  return list.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const insert = (arr, index, ...newItems) => [
  ...arr.slice(0, index),
  ...newItems,
  ...arr.slice(index)
];

export function isEmpty(str) {
  return !str || 0 === str.length;
}

export const getCookie = cname => {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};
