import React, { memo, useContext, useMemo, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//Components
import { Article } from "@uaveiro/ui";
import SEO from "../SEO";
//Utils
import { compose } from "../../services/utilities";
import { StoreContext } from "../../context/StoreContext";
import { withSize } from "react-sizeme";

const ArticleTemplate = memo(function ArticleTemplate({
  hasSEO,
  shouldStretch,
  data,
  match,
  size,
  ...props
}) {
  const { state } = useContext(StoreContext);
  const { lang } = match.params;

  const content = data[lang] || data;
  const hasContent =
    content && content.content && content.content.trim() !== "";

  const checkSubWebContentProps = useMemo(
    () =>
      state.isSubWeb
        ? {
            hasContent: state.subWebNav.language === lang,
            showLangMessage: state.subWebNav.language !== lang
          }
        : {
            hasContent
          },
    [hasContent, state.isSubWeb, state.subWebNav.language]
  );

  const hasTitle = !!(content && content.title && content.title.trim() !== "");
  const defaultContent = data["pt"] || data;
  const hasTitleDefault = !!(
    defaultContent &&
    defaultContent.title &&
    defaultContent.title.trim() !== ""
  );

  const gridClasses = useMemo(
    () =>
      shouldStretch
        ? "col-12"
        : "col-xl-6 offset-xl-3 col-lg-8 offset-lg-4 col-12 offset-0",
    [shouldStretch]
  );

  const seoProps = useMemo(() => {
    const markdown = hasContent ? content.content : defaultContent.content;
    const title = hasTitle ? content.title : defaultContent.title;
    return {
      content: markdown,
      title
    };
  }, [data, hasSEO]);

  const articleProps = {
    hasTitleDefault,
    defaultContent,
    gridClasses,
    hasTitle,
    lang,
    activeColor: state.activeColor || content.activeColor,
    isMobile: size.width <= 991,
    ...checkSubWebContentProps,
    ...props
  };

  return (
    <Fragment>
      {hasSEO && !state.isSubWeb && <SEO {...seoProps} />}
      <Article data={content} {...articleProps} />
    </Fragment>
  );
});

ArticleTemplate.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    activeColor: PropTypes.string,
    content: PropTypes.string
  }).isRequired,
  match: PropTypes.object,
  hasSEO: PropTypes.bool,
  shouldStretch: PropTypes.bool
};

export default compose(withRouter, withSize())(ArticleTemplate);
