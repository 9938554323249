export default [
  {
    name: "SEO",
    title: {
      pt: "Cursos",
      en: "Courses"
    },
    description: "Lista de Cursos",
    keywords: []
  },
  {
    name: "Courses",
    title: "Técnicos superiores profissionais",
    titleColor: "#000",
    titleSize: "20px",
    titleWeight: "300",
    textColor: "#000",
    textWeight: "400",
    textSize: "14px",
    textLineHeight: "1.6",
    hasLine: false,
    endpoint: "/courses"
  }
];
