//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@//
//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@//
//@@@@@@@@@@@@@@///@@@@@@@@@@@@@@@//
//@@@@@@///#@@@@////@@@@////@@@@@@//
//@@@@@@////@@@/////@@@/////@@@@@@//
//@@@@@@////////////////////@@@@@@//
//@@@@@@////////////////////@@@@@@//
//@@@@@@///@@@@@////@@@@@///@@@@@@//
//@@@@@@//(@@@@@@@@@@@@@@///@@@@@@//
//@@@@@@//(@@@@@@@@@@@@@@///@@@@@@//
//@@@@@@//(@@@@@@@@@@@@@@///@@@@@@//
//@@@@@@/////////@@/////////@@@@@@//
//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@//
//@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@//
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import { hydrate } from "react-dom";

//Routing
import { BrowserRouter as Router, Switch } from "react-router-dom";
//Containers
import App from "./containers/App";
//Components
import { ScrollToTop } from "./components/molecules";
import Link from "./components/atoms/Link";
//Theme
import ThemeRegister from "./ThemeRegister";
//Locales
import { IntlProvider, FormattedMessage } from "react-intl";
import { lang, messages } from "./translations";
//Store Context
import { StoreProvider } from "./context/StoreContext";
import { ContentProvider } from "./context/ContentContext";
import * as serviceWorker from "./serviceWorker";
//Providers
import {
  ConfigProvider,
  InjectIntlContext,
  useFormatMessage
} from "@uaveiro/ui";
//Utils
import { imageAPI, portalWWW, portalAPI } from "./services/constants";
import { isIE } from "./services/utilities";
//Static file
import "../public/styles/bootstrap-grid.min.css";
import "../public/fontawesome/css/all.css";
import "../public/styles/slick.min.css";
import "../public/styles/slick-theme.min.css";
import "../public/styles/Calendar.css";
import "../public/styles/system-bar.css";

//removed the console log in prod
if (process.env.NODE_ENV !== "development") {
  console.error = () => null;
  console.log = () => null;
  console.warn = () => null;
}

hydrate(
  <IntlProvider locale={lang() || "pt"} messages={messages[lang() || "pt"]}>
    <InjectIntlContext>
      <StoreProvider>
        <ContentProvider>
          <ConfigProvider
            config={{
              imageAPI,
              portalWWW,
              portalAPI
            }}
            components={{
              link: Link,
              formattedMessage: FormattedMessage,
              isIE: isIE
            }}
            hooks={{
              useIntl: useFormatMessage
            }}
          >
            <ThemeRegister>
              <Router>
                <Switch>
                  <ScrollToTop>
                    <App />
                  </ScrollToTop>
                </Switch>
              </Router>
            </ThemeRegister>
          </ConfigProvider>
        </ContentProvider>
      </StoreProvider>
    </InjectIntlContext>
  </IntlProvider>,
  document.getElementById("root")
);

if (module.hot) {
  module.hot.accept();
}

serviceWorker.register();
