/**
 * SECTION - DEPARTMENTS
 */

import LocalSelector from "../containers/LocalSelector";
import { views } from "./../services/constants";

const { education } = views;

const DepartmentRoutes = {
  routes: [
    {
      path: ["/departments-schools", "/departamentos-escolas"],
      component: LocalSelector,
      props: {
        activeView: education,
        document: "departmentList"
      }
    }
  ]
};

export default DepartmentRoutes;
