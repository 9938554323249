import React, { useEffect, useState, useContext, useMemo } from "react";
//Utils
import { getAuthUserInformation, logout } from "../services/utilities";
import { authUrl, portalAPI, authLogoutUrl } from "../services/constants";
//API
import {
  getAuthStatus,
  getServices,
  setServiceFavorite,
  removeServiceFavorite,
  getAuthUserPhoto
} from "../api/requests";
//Utils
import moment from "moment";
//Context
import { StoreContext } from "../context/StoreContext";

const useAuthentication = location => {
  const context = useContext(StoreContext);
  const [user, setUser] = useState({
    token: "",
    authenticated: false,
    photo: "https://via.placeholder.com/150"
  });

  const [status, setStatus] = useState(true);
  const [state, setState] = useState({
    loading: true,
    error: false
  });

  const auth = getAuthUserInformation();
  const tokenDate = useMemo(() => moment(auth.exp * 1000).format(), [auth]);
  const currentDate = useMemo(() => moment().format(), []);

  const onLogout = async () => {
    setUser(prevState => {
      return {
        ...prevState,
        token: "",
        authenticated: false,
        photo: ""
      };
    });
    //clears the token from localStorage
    logout();
    window.location.href = authLogoutUrl;
  };

  const onSetFavorite = async id => {
    try {
      await setServiceFavorite({ id });
      onFetchServices();
    } catch (error) {
      setState(prevState => {
        return {
          ...prevState,
          error: true
        };
      });
    }
  };

  const onRemoveFavorite = async id => {
    try {
      await removeServiceFavorite({ id });
      onFetchServices();
    } catch (error) {
      setState(prevState => {
        return {
          ...prevState,
          error: true
        };
      });
    }
  };

  const checkAuthStatus = async token => {
    try {
      const res = await getAuthStatus({ token });

      if (res.status !== "valid") {
        setUser(prevState => {
          return {
            ...prevState,
            token: "",
            authenticated: false,
            photo: ""
          };
        });
        setStatus(true);
      }

      return res;
    } catch (e) {
      setStatus(false);
    }
  };

  const servicesModel = service => {
    return {
      id: service.id,
      favorite: service.isFavourite,
      text: service.shortName,
      icon: service.image,
      href: service.url
    };
  };

  const onFetchServices = async token => {
    try {
      const res = await getServices({ lang: "pt", token });

      const list = {
        favorites: res
          .filter((item, index) => index < 12 && item.isFavourite)
          .map(service => servicesModel(service)),
        all: res.map(service => servicesModel(service)),
        original: res
      };

      context.updateServices(list);

      setState(prevState => {
        return {
          ...prevState,
          loading: false
        };
      });
    } catch (error) {
      setState(prevState => {
        return {
          ...prevState,
          loading: false,
          error: true
        };
      });
    }
  };

  useEffect(() => {
    /*  const auth = getAuthUserInformation();
    const tokenDate = moment(auth.exp * 1000).format();
    const currentDate = moment().format();*/

    if (auth.authenticated && status && tokenDate > currentDate) {
      //Check if the user has a valid token
      checkAuthStatus(auth.token);
      onFetchServices(auth.token);

      setUser(prevState => {
        return {
          ...prevState,
          // photo: `${portalAPI}/person/${auth.id}/photo?language=pt&getdefault=true`,
          ...auth
        };
      });
    } else {
      setUser(prevState => {
        return {
          ...prevState,
          token: "",
          authenticated: false,
          photo: ""
        };
      });
    }
  }, [location.pathname, status]);

  useEffect(() => {
    const fetchUserPhoto = async () => {
      try {
        if (auth.authenticated && status && tokenDate > currentDate) {
          const photo = await getAuthUserPhoto({
            id: auth.id,
            token: auth.token
          });

          setUser(prevState => {
            return {
              ...prevState,
              photo
            };
          });
        }
      } catch (error) {
        setUser(prevState => {
          return {
            ...prevState,
            photo: ""
          };
        });
      }
    };
    fetchUserPhoto();
  }, [status]);

  return {
    user,
    logout: onLogout,
    setFavorite: onSetFavorite,
    removeFavorite: onRemoveFavorite,
    authUrl,
    authLogoutUrl,
    portalAPI,
    services: context.state.services,
    ...state
  };
};

export default useAuthentication;
