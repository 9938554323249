import PropTypes from "prop-types";
//Styles
import styled from "styled-components";
import theme from "../../../../services/theme.semantic";
import { withExtendedClass } from "../../../../services/utilities.style";

const TabContent = styled.div.attrs(({ className }) =>
  withExtendedClass({
    className,
    extend: "tab-content "
  })
)`
  margin-top: ${props => props.marginTop};
  max-height: 0;
  overflow: hidden;
  background-color: ${props => props.backgroundColor};
  -webkit-transition: max-height 0.2s;
  -o-transition: max-height 0.2s;
  transition: max-height 0.2s;
`;

TabContent.propTypes = {
  marginTop: PropTypes.string,
  backgroundColor: PropTypes.string
};

TabContent.defaultProps = {
  ...theme.components.tabContent
};

export default TabContent;
