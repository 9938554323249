import React from "react";
import { withRouter } from "react-router-dom";
import { withTheme } from "styled-components";
import { injectIntl } from "react-intl";
import { Timely } from '@uaveiro/ui';

const TimelyTemplate = ({ match, ...props }) => {
  const { lang } = match.params;

  return (
    <Timely {...props} lang={lang}></Timely>
  );
};


export default withRouter(withTheme(injectIntl(TimelyTemplate)));
