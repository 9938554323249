const data = [
  {
    name: "SEO",
    title: {
      pt: "Página Inicial",
      en: "Homepage"
    },
    description: "Sítio da Universidade de Aveiro. Um campus que pensa.",
    keywords: ["universidade de aveiro", "ua", "aveiro", "universidade"],
    lastModified: "02/01/20 15:34:01",
    dynamicSEO: false
  },
  {
    name: "Slider",
    styles: {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      textColor: "#fff"
    },
    endpoint: "/news/top",
    data: {
      pt: {
        data: {
          payload: null,
          list: [
            {
              description: "Balanço do ano que passou",
              image: {
                id: 3,
                alt:
                  "Estudantes na relva, com toalhas de piquenique. Ao longe um ecrã do palco onde está a haver um concerto. ",
                title: "Piquenique de acolhimento 2019",
                url:
                  "https://api-assets.ua.pt/files/imgs/000/000/003/original.jpg",
                isLocal: false
              },
              summary: "",
              title: "2019: um ano em resumo na Universidade de Aveiro",
              url: "https://www.ua.pt/pt/noticias/11/62196",
              id: "Slide-23_1577970669150"
            },
            {
              type: "leftColumns",
              description: "Balanço do ano que passou",
              image: {
                id: 3,
                alt:
                  "Estudantes na relva, com toalhas de piquenique. Ao longe um ecrã do palco onde está a haver um concerto. ",
                title: "Piquenique de acolhimento 2019",
                url:
                  "https://api-assets.ua.pt/files/imgs/000/000/003/original.jpg",
                isLocal: false
              },
              summary: "",
              title: "2019: um ano em resumo na Universidade de Aveiro",
              url: "https://www.ua.pt/pt/noticias/11/62196",
              id: "Slide-23_1577970669150"
            },
            {
              type: "video",
              default: "https://youtu.be/QbIDx8Hbif0",
              accessible: "https://youtu.be/CQ7ng1ARMwI",
              placeholder:
                "https://www.datacar.com/wp-content/uploads/2015/03/video1.png"
            }
          ]
        }
      },
      en: {
        data: {
          payload: null,
          list: [
            {
              type: "leftColumns",
              description: "Events that marked the history of the university",
              image: {
                id: 3,
                alt:
                  "Estudantes na relva, com toalhas de piquenique. Ao longe um ecrã do palco onde está a haver um concerto. ",
                title: "Piquenique de acolhimento 2019",
                url:
                  "https://api-assets.ua.pt/files/imgs/000/000/003/original.jpg",
                isLocal: false
              },
              summary: "",
              title: "2019: a year-in-review at the University of Aveiro",
              url: "https://www.ua.pt/en/news/11/62208",
              id: "Slide-24_1577972258575"
            },
            {
              description: "Events that marked the history of the university",
              image: {
                id: 3,
                alt:
                  "Estudantes na relva, com toalhas de piquenique. Ao longe um ecrã do palco onde está a haver um concerto. ",
                title: "Piquenique de acolhimento 2019",
                url:
                  "https://api-assets.ua.pt/files/imgs/000/000/003/original.jpg",
                isLocal: false
              },
              summary: "",
              title: "2019: a year-in-review at the University of Aveiro",
              url: "https://www.ua.pt/en/news/11/62208",
              id: "Slide-24_1577972258575"
            },
            {
              type: "video",
              default: "https://youtu.be/4nTfqQfC-EE",
              accessible: "https://youtu.be/wHsG545Ovew"
            }
          ]
        }
      }
    }
  },
  {
    name: "News",
    backgroundColor: "#F1F1F2",
    textColor: "#000",
    title: "Notícias",
    resource: "app.organism.news.title",
    showHeader: true,
    titleProps: {
      hasLine: false,
      hasLink: true,
      href: "https://uaonline.ua.pt"
    },
    cardStyles: {
      textColor: "#000",
      backgroundColor: "#fff"
    },
    endpoint:
      "/news?featured=true&channel=57&recordsPerPage=6&categoriesToIgnore=10&categoriesToIgnore=3&categoriesToIgnore=8&categoriesToIgnore=14"
  },
  {
    name: "Campus",
    backgroundColor: "#fff",
    textColor: "#000",
    title: "Destaques",
    resource: "app.organism.campus.title",
    showHeader: false,
    data: {
      pt: [
        {
          backgroundColor: "#00AFBB",
          backgroundImage: "contents/imgs/study/estudantes_7.jpg",
          position: "left",
          textColor: "#fff",
          text: "Estudar",
          resource: "app.nav.education",
          alt:
            "Alunos a consultar os folhetos da campanha: Ua a formar talentos desde 1973.",
          href: "education",
          isInternalLink: true,
          col: [6, 6],
          list: [
            {
              color: "#fff",
              text: "cursos",
              resource: "app.nav.education.item.courses",
              href: "degrees"
            },
            {
              color: "#fff",
              text: "candidaturas",
              resource: "app.nav.education.item.candidature",
              href: "aplications"
            },
            {
              color: "#fff",
              text: "departamentos e escolas",
              resource: "app.nav.education.item.departments",
              href: "departmentList"
            }
          ]
        },
        {
          backgroundColor: "#F3B21B",
          backgroundImage: "contents/imgs/living/viver_aveiro.jpg",
          position: "right",
          textColor: "#fff",
          text: "Viver",
          resource: "app.nav.live",
          alt:
            "Casas tradicionais da Costa Nova. Pintadas as riscas. Uma amarela outra azul",
          href: "liveUA",
          isInternalLink: true,
          col: [8, 4],
          list: [
            {
              color: "#fff",
              text: "alimentação",
              resource: "app.nav.live.item.eating",
              href: "eating"
            },
            {
              color: "#fff",
              text: "alojamento",
              resource: "app.nav.live.item.sleeping",
              href: "sleeping"
            },
            {
              color: "#fff",
              text: "desporto",
              resource: "app.nav.live.item.sports",
              href: "sports"
            }
          ]
        },
        {
          backgroundColor: "#42D3B8",
          backgroundImage:
            "contents/imgs/investigacao_cooperacao/investigacao_cooperacao_2.jpg",
          position: "left",
          textColor: "#fff",
          text: "Investigar",
          resource: "app.nav.investigation",
          alt: "Investigadora a olhar para um microscópio. Bata branca.",
          href: "research",
          isInternalLink: true,
          col: [5, 7],
          list: [
            {
              color: "#fff",
              text: "atividade científica",
              resource: "app.nav.investigation.item.activity",
              href: "scientificActivity"
            },
            {
              color: "#fff",
              text: "apoios e financiamento",
              resource: "app.nav.investigation.item.support",
              href: "funding"
            },
            {
              color: "#fff",
              text: "apoio à investigação",
              resource: "app.nav.investigation.item.supportreseacrh",
              href: "researchSupport"
            }
          ]
        },
        {
          backgroundColor: "#9878D3",
          backgroundImage:
            "contents/imgs/investigacao_cooperacao/investigacao_cooperacao_36.jpg",
          position: "right",
          textColor: "#fff",
          text: "Cooperar",
          resource: "app.nav.innovation",
          alt:
            "seis balões de erlenmayer com líquidos coloridos. Liquidos e luz com cor lilás",
          href: "cooperation",
          isInternalLink: true,
          col: [5, 7],
          list: [
            {
              color: "#fff",
              text: "áreas de cooperação",
              resource: "app.nav.innovation.item.development",
              href: "cooperationAreas"
            },
            {
              color: "#fff",
              text: "portefólio",
              resource: "app.nav.innovation.item.programs",
              href: "entrepreneurship"
            },
            {
              color: "#fff",
              text: "impacto na região",
              resource: "app.nav.innovation.item.pci",
              href: "regionalInfluence"
            }
          ]
        },
        {
          backgroundColor: "#F0592A",
          backgroundImage: "contents/imgs/living/viver_ua_6.jpg",
          position: "left",
          textColor: "#fff",
          text: "Internacional",
          resource: "app.nav.International",
          alt: "Pessoas atrás de um vidro. Vidro com o mapa mundo.",
          href: "internationalStudyInPortugal",
          col: [8, 4],
          list: [
            {
              color: "#fff",
              text: "estudar em Portugal",
              resource: "app.nav.International.item.students",
              href: "whyPortugal"
            },
            {
              color: "#fff",
              text: "estudar e investigar na UA",
              resource: "app.nav.International.item.study",
              href: "internationalStudyAtUA"
            },
            {
              color: "#fff",
              text: "mobilidade",
              resource: "app.nav.International.item.mobility",
              href: "mobility"
            }
          ]
        }
      ],
      en: [
        {
          backgroundColor: "#00AFBB",
          backgroundImage: "contents/imgs/study/estudantes_7.jpg",
          position: "left",
          textColor: "#fff",
          text: "Estudar",
          resource: "app.nav.education",
          alt:
            "Alunos a consultar os folhetos da campanha: Ua a formar talentos desde 1973.",
          href: "education",
          isInternalLink: true,
          col: [6, 6],
          list: [
            {
              color: "#fff",
              text: "cursos",
              resource: "app.nav.education.item.courses",
              href: "degrees"
            },
            {
              color: "#fff",
              text: "candidaturas",
              resource: "app.nav.education.item.candidature",
              href: "aplications"
            },
            {
              color: "#fff",
              text: "departamentos e escolas",
              resource: "app.nav.education.item.departments",
              href: "departmentList"
            }
          ]
        },
        {
          backgroundColor: "#F3B21B",
          backgroundImage: "contents/imgs/living/viver_aveiro.jpg",
          position: "right",
          textColor: "#fff",
          text: "Viver",
          resource: "app.nav.live",
          alt:
            "Casas tradicionais da Costa Nova. Pintadas as riscas. Uma amarela outra azul",
          href: "liveUA",
          isInternalLink: true,
          col: [8, 4],
          list: [
            {
              color: "#fff",
              text: "alimentação",
              resource: "app.nav.live.item.eating",
              href: "eating"
            },
            {
              color: "#fff",
              text: "alojamento",
              resource: "app.nav.live.item.sleeping",
              href: "sleeping"
            },
            {
              color: "#fff",
              text: "desporto",
              resource: "app.nav.live.item.sports",
              href: "sports"
            }
          ]
        },
        {
          backgroundColor: "#42D3B8",
          backgroundImage:
            "contents/imgs/investigacao_cooperacao/investigacao_cooperacao_2.jpg",
          position: "left",
          textColor: "#fff",
          text: "Investigar",
          resource: "app.nav.investigation",
          alt: "Investigadora a olhar para um microscópio. Bata branca.",
          href: "research",
          isInternalLink: true,
          col: [5, 7],
          list: [
            {
              color: "#fff",
              text: "atividade científica",
              resource: "app.nav.investigation.item.activity",
              href: "scientificActivity"
            },
            {
              color: "#fff",
              text: "apoios e financiamento",
              resource: "app.nav.investigation.item.support",
              href: "funding"
            },
            {
              color: "#fff",
              text: "apoio à investigação",
              resource: "app.nav.investigation.item.supportreseacrh",
              href: "researchSupport"
            }
          ]
        },
        {
          backgroundColor: "#9878D3",
          backgroundImage:
            "contents/imgs/investigacao_cooperacao/investigacao_cooperacao_36.jpg",
          position: "right",
          textColor: "#fff",
          text: "Cooperar",
          resource: "app.nav.innovation",
          alt:
            "seis balões de erlenmayer com líquidos coloridos. Liquidos e luz com cor lilás",
          href: "cooperation",
          isInternalLink: true,
          col: [5, 7],
          list: [
            {
              color: "#fff",
              text: "áreas de cooperação",
              resource: "app.nav.innovation.item.development",
              href: "cooperationAreas"
            },
            {
              color: "#fff",
              text: "portefólio",
              resource: "app.nav.innovation.item.programs",
              href: "entrepreneurship"
            },
            {
              color: "#fff",
              text: "impacto na região",
              resource: "app.nav.innovation.item.pci",
              href: "regionalInfluence"
            }
          ]
        },
        {
          backgroundColor: "#F0592A",
          backgroundImage: "contents/imgs/living/viver_ua_6.jpg",
          position: "left",
          textColor: "#fff",
          text: "Internacional",
          resource: "app.nav.International",
          alt: "Pessoas atrás de um vidro. Vidro com o mapa mundo.",
          href: "internationalStudyInPortugal",
          col: [8, 4],
          list: [
            {
              color: "#fff",
              text: "estudar em Portugal",
              resource: "app.nav.International.item.students",
              href: "whyPortugal"
            },
            {
              color: "#fff",
              text: "estudar e investigar na UA",
              resource: "app.nav.International.item.study",
              href: "internationalStudyAtUA"
            },
            {
              color: "#fff",
              text: "mobilidade",
              resource: "app.nav.International.item.mobility",
              href: "mobility"
            }
          ]
        }
      ]
    }
  },
  {
    name: "Testimonials",
    title: "Os nossos estudantes",
    resource: "app.organism.testimonials.title",
    backgroundColor: "#F1F1F2",
    textColor: "#000",
    showHeader: true,
    titleProps: {
      hasLine: false,
      hasLink: false
    },
    data: {
      en: [
        {
          textColor: "#000",
          backgroundColor: "#F1F1F2",
          text:
            "Enrolled in the Environmental Studies MSc, Nicole Muchangos aspires to be a recognized marine environment professional. She began her studies in the Sea Sciences 1st cycle degree and she returned to UA to follow her studies.",
          person: {
            image: "contents/imgs/covers/nicole_muchangos.jpg",
            name: "Nicole Muchangos",
            about: "UA Student",
            alt: "Fotografia de Nicole Muchangos a sorrir"
          }
        },
        {
          textColor: "#000",
          backgroundColor: "#F1F1F2",
          text:
            "Farfetch is one of the world's most successful startups and Antero Ferreira was a key person in the tranformation of this luxury article online platform in a global phenomenon.",
          person: {
            image: "contents/imgs/geral/pessoas_antero_ferreira.jpg",
            name: "Antero Ferreira",
            about: "Alumni",
            alt: "Fotografia de Antero Ferreira a falar numa conferência "
          }
        },
        {
          textColor: "#000",
          backgroundColor: "#F1F1F2",
          text:
            "She has always claimed to have had a huge appeal for Behavioral Ecology of wildlife. Bárbara Matos, graduated in Biology, decided to return to the master's degree in Applied Ecology.",
          person: {
            image: "contents/imgs/geral/pessoas_barbara_matos.jpg",
            name: "Bárbara Matos",
            about: "Alumni",
            alt: "Fotografia de Bárbara Matos a sorrir, num bar. Oceano."
          }
        }
      ],
      pt: [
        {
          textColor: "#000",
          backgroundColor: "#F1F1F2",
          text:
            "A frequentar o Mestrado em Estudos Ambientais, Nicole Muchangos sonha ser uma profissional de ambiente marinho reconhecida. Começou por se licenciar em Ciências do Mar, na UA, onde regressou para continuar os estudos.",
          person: {
            image: "contents/imgs/covers/nicole_muchangos.jpg",
            name: "Nicole Muchangos",
            about: "Estudante da UA",
            alt: "Fotografia de Nicole Muchangos a sorrir"
          }
        },
        {
          textColor: "#000",
          backgroundColor: "#F1F1F2",
          text:
            "Farfetch é uma das startups mais bem-sucedidas do mundo e tem em Antero Ferreira uma das chaves que transformaram num fenómeno global a plataforma online de artigos de luxo.",
          person: {
            image: "contents/imgs/geral/pessoas_antero_ferreira.jpg",
            name: "Antero Ferreira",
            about: "Alumni",
            alt: "Fotografia de Antero Ferreira a falar numa conferência "
          }
        },
        {
          textColor: "#000",
          backgroundColor: "#F1F1F2",
          text:
            "Diz sempre ter tido uma enorme atração pela Ecologia Comportamental de animais selvagens. Bárbara Matos, licenciada em Biologia, decidiu voltar para realizar o mestrado em Ecologia Aplicada.",
          person: {
            image: "contents/imgs/geral/pessoas_barbara_matos.jpg",
            name: "Bárbara Matos",
            about: "Alumni",
            alt: "Fotografia de Bárbara Matos a sorrir, num bar. Oceano."
          }
        }
      ]
    }
  },
  {
    name: "Facts",
    backgroundColor: "#fff",
    title:
      "19 dos 20 centros de investigação científica da UA receberam classificação de excelente e muito bom ",
    resource: "app.organism.facts.title",
    source: "Fundação para a Ciência e a Tecnologia",
    text: "2017/2018",
    titleStyles: {
      fontSize: "70px",
      color: "#000",
      mobileSmFontSize: "42px"
    }
  },
  {
    name: "Agenda",
    backgroundColor: "#4d93ff",
    textColor: "#000",
    title: "Agenda",
    resource: "app.organism.agenda.title",
    href: "agenda",
    isInternalLink: true,
    showHeader: true,
    eventStyles: {
      titleColor: "#fff",
      descriptionColor: "#fff",
      date: {
        textColor: "#fff"
      }
    },
    featured: {
      backgroundColor: "#4d93ff",
      titleColoar: "#fff",
      textColor: "#fff"
    },
    categoriesStyles: {
      activeColor: "#000",
      textColor: "#000"
    },
    endpoint: "/calendar?channel=57&recordsPerPage=4"
  }
];

export default data;
