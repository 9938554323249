import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Testimonials } from "@uaveiro/ui";
//Utils
import { lang } from "../../translations";

const TestimonialsWithLink = ({ data, isEditor, ...props }) => {
  const content = useMemo(() => data[lang()] || data, [data]);
  return <Testimonials data={content} isEditor={false} {...props} />;
};

TestimonialsWithLink.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export default TestimonialsWithLink;
