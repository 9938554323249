import React, { useContext } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//Components
import { SubWebSchedule } from "@uaveiro/ui";
import { StoreContext } from "../../context/StoreContext";

const ScheduleTemplate = ({ match, ...props }) => {
  const context = useContext(StoreContext);
  const {
    params: { lang }
  } = match;

  const scheduleProps = {
    lang,
    activeColor: context.state.activeColor,
    ...props
  };

  return <SubWebSchedule {...scheduleProps} />;
};

ScheduleTemplate.propTypes = {
  match: PropTypes.object
};

export default withRouter(ScheduleTemplate);
