import React, { memo, useContext } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//components
import { Cards } from "@uaveiro/ui";
import { StoreContext } from "../../context/StoreContext";

const CardsTemplate = memo(function CardsTemplate({
  data,
  title,
  match,
  ...props
}) {
  const { state } = useContext(StoreContext);
  const { lang } = match.params;

  const cardProps = {
    content: data[lang] || data,
    lang,
    activeColor: state.activeColor,
    title: title[lang] || title,
    ...props
  };

  return <Cards {...cardProps} />;
});

CardsTemplate.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string
      })
    ),
    PropTypes.object
  ]),
  match: PropTypes.object
};

export default withRouter(CardsTemplate);
