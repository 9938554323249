import React, { useEffect, useState, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import DOMPurify from "dompurify";
//API
import { getMarkdownDemo } from "../../api/requests";
import { MarkdownRenderer } from "@uaveiro/ui";
import { Link } from "../../components/atoms";

const DemoMarkTemplate = ({ match, className, ...props }) => {
  const [state, setState] = useState({
    isLoading: true,
    hasError: false,
    data: {}
  });
  const [showHtml, setShowHtml] = useState(false);
  const [showMark, setShowMark] = useState(false);
  const { lang, id } = match.params;

  const getData = async () => {
    try {
      const res = await getMarkdownDemo({
        lang,
        id,
        history: props.history
      });

      setState(prevState => {
        return {
          ...prevState,
          data: res,
          isLoading: false
        };
      });
    } catch (error) {
      setState(prevState => {
        return { ...prevState, isLoading: false, hasError: true };
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={className}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="actions">
              <Link href={`${state.data.previousPageID}`}>Anterior</Link>
              <Link href={`${state.data.nextPageID}`}>Proximo</Link> {" | "}
              <Link
                href={`https://www.ua.pt/stic/page/${id}`}
                target="_blank"
                native
              >
                Página original
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h2>Markdown</h2>
            {state.isLoading ? (
              <div>A carregar...</div>
            ) : state.hasError ? (
              <div>Aconteceu um erro</div>
            ) : (
              <Fragment>
                <div className="content">
                  <MarkdownRenderer content={state.data.markdown} />
                </div>

                <div className="code">
                  <button onClick={() => setShowMark(!showMark)}>
                    {showMark ? "Hide" : "Show"} Raw
                  </button>
                  <br />
                  <br />
                  {showMark && <code>{state.data.markdown}</code>}
                </div>
              </Fragment>
            )}
          </div>
          <div className="col-md-6">
            <h2>Html</h2>
            {state.isLoading ? (
              <div>A carregar...</div>
            ) : state.hasError ? (
              <div>Aconteceu um erro</div>
            ) : (
              <Fragment>
                <div className="content">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(state.data.html)
                    }}
                  />
                </div>

                <div className="code">
                  <button onClick={() => setShowHtml(!showHtml)}>
                    {showHtml ? "Hide" : "Show"} Raw
                  </button>
                  <br />
                  <br />
                  {showHtml && <code> {state.data.html}</code>}
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

DemoMarkTemplate.propTypes = {
  match: PropTypes.object
};

const DemoMarkTemplateWithStyles = styled(DemoMarkTemplate)`
  padding: 60px 0;
  .content,
  .code {
    margin-top: 20px;
  }
  .actions {
    padding: 25px 0;
    a {
      margin-right: 10px;
    }
  }
`;

export default withRouter(DemoMarkTemplateWithStyles);
