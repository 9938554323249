import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import data from "../../data/navigation/navigation";

import { Link } from "../../components/atoms";
import { ListItem, Dropdown, DropdownList, Icon } from "@uaveiro/ui";

const {
  topNavbar: { institute }
} = data;

const AccessibilitySelector = ({
  accessibilityValue,
  isDropdownOpen,
  isFirefox,
  onChangeAccessibilityFilter,
  onChangeZoom,
  onChangeLang,
  zoomValue,
  setDropdownOpen,
  lang,
  theme,
  hasEmbeddedSearch,
  searchToggle,
  isSearchable,
  isInternal
}) => {
  return (
    <>
      <ListItem className="accessibility" marginRight="12px">
        <Dropdown
          aria-label="menu da acessibilidade"
          padding="10px 0"
          minContentWidth="60px"
          right="-11px"
          onMouseEnter={() => setDropdownOpen(true)}
          onMouseLeave={() => setDropdownOpen(false)}
          onFocus={() => setDropdownOpen(true)}
        >
          <div className="acessibility-icon-dropdown">
            <Link
              aria-label="acessibilidade"
              background={
                accessibilityValue
                  ? theme.containers.accessibilitySelector.accessibilityOnColor
                  : "inherit"
              }
              href={`/${lang}/${institute.accessibility.link.href}`}
              fontSize={institute.accessibility.link.fontSize}
              onClick={() => setDropdownOpen(false)}
            >
              <Icon
                {...institute.accessibility}
                color={
                  !isInternal
                    ? theme.containers.accessibilitySelector.backgroundColor
                    : theme.containers.accessibilitySelector
                        .accessibilityIconActiveColor
                }
              />
            </Link>
          </div>
          {isDropdownOpen && (
            <DropdownList fontWeight="400">
              <Link
                aria-label="Mudar o contraste"
                className={`acessibility-icon  ${
                  accessibilityValue ? "active" : ""
                }`}
                onClick={onChangeAccessibilityFilter}
              >
                <Icon
                  name="fas fa-adjust"
                  color={
                    !isInternal
                      ? theme.containers.accessibilitySelector
                          .accessibilityMenuIconColor
                      : theme.containers.accessibilitySelector.searchFilterColor
                  }
                />
              </Link>

              {!isFirefox && (
                <Link
                  aria-label="Fazer zoom na página"
                  className="acessibility-icon"
                  onClick={onChangeZoom}
                >
                  <Icon
                    name={`fal fa-search-${
                      zoomValue === "150%" ? "minus" : "plus"
                    }`}
                    color={
                      !isInternal
                        ? theme.containers.accessibilitySelector
                            .accessibilityMenuIconColor
                        : theme.containers.accessibilitySelector
                            .searchFilterColor
                    }
                  />
                </Link>
              )}
            </DropdownList>
          )}
        </Dropdown>
      </ListItem>

      <ListItem
        paddingRight="12px"
        paddingLeft="12px"
        borderRight={isSearchable ? `1px solid #757575` : null}
        borderLeft={`1px solid #757575`}
      >
        <Link
          {...institute.language}
          aria-label={institute.language.alt}
          onClick={onChangeLang}
          color={
            !isInternal
              ? theme.containers.accessibilitySelector
                  .accessibilityMenuIconColor
              : theme.containers.accessibilitySelector.accessibilityOnColor
          }
        >
          {lang === "pt" ? "EN" : "PT"}
        </Link>
      </ListItem>

      <ListItem marginLeft="12px">
        {isSearchable ? (
          hasEmbeddedSearch ? (
            <Link
              onClick={e => searchToggle(e)}
              aria-label="Pesquisar"
              {...institute.search.link}
            >
              <Icon
                {...institute.search}
                color={
                  !isInternal
                    ? theme.containers.accessibilitySelector
                        .accessibilityMenuIconColor
                    : theme.containers.accessibilitySelector
                        .accessibilityOnColor
                }
              />
            </Link>
          ) : (
            <Link
              //onClick={}
              aria-label="Pesquisar"
              {...institute.search.link}
            >
              <Icon
                {...institute.search}
                color={
                  !isInternal
                    ? theme.containers.accessibilitySelector
                        .accessibilityMenuIconColor
                    : theme.containers.accessibilitySelector
                        .accessibilityOnColor
                }
              />
            </Link>
          )
        ) : null}
      </ListItem>
    </>
  );
};

AccessibilitySelector.defaultProps = {
  isSearchable: true,
  isInternal: true
};

const AccessibilitySelectorWithStyles = styled(AccessibilitySelector)`
  @media (max-width: 991px) {
    //Acessibility icon dropdown
    .accessibility {
      position: relative;

      :first-child {
        margin-right: 0;
      }
      .acessibility-icon-dropdown {
        display: inline-block;
        padding: 10px 8px 11px 10px;
        a {
          padding: 4px;
        }
      }

      .dropdown-content {
        .acessibility-icon {
          padding: 8px 0;
          text-align: center;

          :hover {
            background-color: ${props =>
              props.theme.components.webNavBar.dropDownHoverBackgroundColor};
            cursor: pointer;
            i {
              color: ${props =>
                props.theme.components.webNavBar.accessibilityIconHoverColor};
            }
          }
        }
        .acessibility-icon.active {
          background-color: ${props =>
            props.theme.components.webNavBar.accessibilityActiveBgColor};
          i {
            color: ${props =>
              props.theme.components.webNavBar.accessibilityIconActiveColor};
          }
        }
      }
    }
  }
`;
export default withTheme(AccessibilitySelectorWithStyles);
