/* eslint-disable no-script-url*/
import React, { useState, useEffect, useContext, memo } from "react";
import PropTypes from "prop-types";
//components
import { WebNavbar } from "../../components/organisms";
//Context
import { StoreContext } from "../../context/StoreContext";
//Locales
import { changeLanguage, lang } from "../../translations";
import { hasHash } from "../../services/constants";
import { getZoom, isIE } from "../../services/utilities";
// Context
import { ContentContext } from "../../context/ContentContext";

const WebNavbarTemplate = ({
  isSearchOpen,
  searchToggle,
  history,
  ...props
}) => {
  const isFirefox = typeof InstallTrigger !== "undefined";
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const GlobalStore = useContext(StoreContext);
  const [isFocus, setFocus] = useState(false);
  const [searchType, setSearchType] = useState("a");
  const [searchValue, setSearchValue] = useState("");
  const {
    state: { disableLocalStorage }
  } = useContext(ContentContext);

  const searchSwitcher = value => {
    return {
      a: {
        route:
          lang() === "pt" ? `/${lang()}/pesquisa/a/` : `/${lang()}/search/a/`,
        query: `?#gsc.tab=0&gsc.q=${value}&gsc.sort=`
      },
      p: {
        route:
          lang() === "pt" ? `/${lang()}/pesquisa/p/` : `/${lang()}/search/p/`,
        query: `?q=${value}`
      },
      n: {
        route:
          lang() === "pt" ? `/${lang()}/pesquisa/n/` : `/${lang()}/search/n/`,
        query: `?q=${value}`
      },
      l: {
        route:
          lang() === "pt" ? `/${lang()}/pesquisa/l/` : `/${lang()}/search/l/`,
        query: `?q=${value}`
      }
    };
  };

  const onNavigate = (value, type = "a") => {
    const option = searchSwitcher(value)[type];
    history.push(`${option.route}${option.query}`);
  };

  const onHandleSearch = e => {
    if (e.key === "Enter") {
      GlobalStore.updateJournalNav(false);
      onNavigate(e.target.value, searchType);
    }
  };

  const onHandleSearchType = (e, type) => {
    e.preventDefault();
    setSearchType(type);
    if (searchValue) {
      GlobalStore.updateJournalNav(false);
      onNavigate(searchValue, type);
    }
  };

  const onHandleClear = e => {
    setSearchType("a");
    setSearchValue("");
    searchToggle(e);
  };

  const windowSize = typeof window !== "undefined" ? window.innerWidth : 1200;

  // focus search input
  useEffect(() => {
    setSearchType("a");
    setSearchValue("");
    if (isSearchOpen) setFocus(true);
  }, [isSearchOpen]);

  const onChangeLang = e => {
    e.preventDefault();
    e.stopPropagation();
    const language = lang() === "pt" ? "en" : "pt";
    const path = history.location.pathname;

    const regex = /agenda[^\w]/gi;
    const isAgenda = regex.test(path);
    //const isAgenda = path.includes("agenda");

    let url = isAgenda ? `/${language}/agenda` : path.replace(lang(), language);
    const isHome = disableLocalStorage && url === "/";

    if (isHome || isAgenda) {
      history.push(isHome ? `/${language}/` : url);
    } else {
      if (typeof window !== "undefined") {
        window.history.pushState({}, null, url);
      }
    }

    changeLanguage(language);
  };

  const onChangeAccessibilityFilter = e => {
    e.preventDefault();
    e.stopPropagation();
    const value = Number(GlobalStore.state.accessibilityValue) === 1 ? 0 : 1;
    GlobalStore.updateAccessibility(value, disableLocalStorage);
    setDropdownOpen(false);
  };

  const onChangeZoom = e => {
    e.preventDefault();
    e.stopPropagation();
    const value =
      GlobalStore.state.zoomValue === "100%"
        ? "125%"
        : getZoom() === "125%"
        ? "150%"
        : "100%";
    GlobalStore.updateZoom(value, disableLocalStorage);
  };

  const webNavbarProps = {
    isFirefox,
    isSearchOpen,
    isDropdownOpen,
    setDropdownOpen,
    isFocus,
    onChangeZoom,
    onChangeAccessibilityFilter,
    onChangeLang,
    windowSize,
    onHandleClear,
    onHandleSearchType,
    onHandleSearch,
    hasHash,
    isIE,
    lang: lang(),
    activeView: GlobalStore.state.activeView,
    accessibilityValue: Number(GlobalStore.state.accessibilityValue),
    zoomValue: GlobalStore.state.zoomValue,
    setSearchValue,
    searchType,
    history,
    searchToggle,
    GlobalStore,
    ...props
  };

  return <WebNavbar {...webNavbarProps} />;
};

WebNavbarTemplate.propTypes = {
  isSearchOpen: PropTypes.bool,
  searchToggle: PropTypes.func,
  pathname: PropTypes.string,
  institute: PropTypes.object,
  history: PropTypes.object.isRequired,
  height: PropTypes.number
};

export default memo(WebNavbarTemplate);
