export default [
  {
    name: "Presentation",
    title: "departamento de comunicação e arte",
    subTitle: "licenciatura em design",
    hasSubTitle: false,
    hasLine: false,
    showMore: true,
    showImage: false,

    data: [],
    endpoint: "presentation"
  },
  {
    name: "Curricular",
    backgroundColor: "#F3F3F4",
    styles: {
      title: {
        color: "#000",
        fontSize: "24px",
        fontWeight: "500",
        lineHeight: "30px"
      },
      text: {
        color: "#7F8284",
        fontSize: "16px"
      }
    },
    endpoint: "short-info",
    hasDelay: true,
    data: []
  },
  {
    name: "Candidature",
    title: "Candidaturas",
    backgroundColor: "#00AFBB",
    textColor: "#fff",
    hasLine: false,
    card: {
      title: "Concurso Geral"
    },
    endpoint: "admission",
    hasDelay: true
  },
  {
    name: "Image",
    hasEndpoint: false,
    isVector: false,
    alt:
      "Jovens a estudar na biblioteca. Luzes no teto. Duas colunas na horizontal entre os estudantes. Secretárias.",
    imagePosition: "center 66%",
    data: {
      url: "contents/imgs/spaces/espacos_bibliotecas_9.jpg"
    },
    height: "400px",
    backgroundAttachment: "fixed",
    hasDelay: true
  },
  {
    name: "Contents",
    variant: "TwoCols",
    hasLine: false,
    title: {
      pt: "Saídas Profissionais",
      en: "Professional outcomes"
    },
    backgroundColor: "#fff",
    textColor: "#000",
    endpoint: "graduate-profiles",
    hasDelay: true
  },
  {
    name: "Contents",
    variant: "OneCol",
    hasLine: false,
    title: {
      pt: "Plano Curricular",
      en: "Curricular plan"
    },
    backgroundColor: "#00AFBB",
    textColor: "#fff",
    endpoint: "plan-summary",
    hasDelay: true
  },
  {
    name: "Contents",
    variant: "NestedRow",
    hasLine: false,
    title: "Registos e Acreditações",
    backgroundColor: "#F3F3F4",
    textColor: "#000",
    endpoint: "accreditations",
    hasDelay: true,
    hasTitle: false
  }
];
