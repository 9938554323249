/**
 * SECTION - EDUCATION
 */

import LocalSelector from "../containers/LocalSelector";
import { views } from "./../services/constants";

const { education, internal } = views;

const EducationRoutes = {
  routes: [
    {
      path: ["/course-types", "/tipo-curso"],
      component: LocalSelector,
      props: {
        activeView: education,
        document: "degrees"
      }
    },
    {
      path: ["/edicts/:degree?", "/editais/:degree?"],
      component: LocalSelector,
      props: {
        activeView: education,
        document: "edicts"
      }
    }
  ]
};

export default EducationRoutes;
