import React, { useContext } from "react";
import PropTypes from "prop-types";
//Styles
import styled, { withTheme } from "styled-components";
//Components
import { Link } from "../../../atoms";
import { HorizontalList, ListItem, Text, ScreenReaderText } from "@uaveiro/ui";
//Locales
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import { compose } from "../../../../services/utilities";
import { StoreContext } from "../../../../context/StoreContext";
import moment from "moment";

const Footer = ({ className, match, date, theme, lang }) => {
  const { state } = useContext(StoreContext);

  const pageDate = moment(state.pageLastModified);
  const isPageDateValid = pageDate.isValid();

  // const {
  //   params: { lang }
  // } = match;
  return (
    <div className={`${className} journal-footer d-flex align-items-center`}>
      <ScreenReaderText>
        <Text as="h2" text="Footer" />
      </ScreenReaderText>
      <div className="container">
        <div className="row no-gutters">
          <div className="col-auto offset-xl-3">
            <HorizontalList
              display="inline-block"
              className="d-flex align-items-center"
            >
              <ListItem
                paddingRight="15px"
                borderRight={`1px solid ${theme.components.journalFooter.color}`}
              >
                <Link
                  color={theme.components.journalFooter.color}
                  lineHeight="20px"
                  fontWeight="300"
                  display="inline-block"
                  href={lang === "pt" ? `/pt/noticias/sobre` : `/en/news/about`}
                >
                  <FormattedMessage
                    id="app.journal.news.footer.about"
                    defaultMessage="Sobre as notícias"
                  />
                </Link>
              </ListItem>
              <ListItem paddingLeft="15px">
                <Link
                  color={theme.components.journalFooter.color}
                  fontWeight="300"
                  display="inline-block"
                  lineHeight="20px"
                  href={
                    lang === "pt"
                      ? `/pt/noticias/contactos`
                      : `/en/news/contacts`
                  }
                >
                  <FormattedMessage
                    id="app.journal.news.footer.contacts"
                    defaultMessage="Contactos"
                  />
                </Link>
              </ListItem>
            </HorizontalList>
          </div>

          {state.pageLastModified && isPageDateValid && (
            <div className="col-auto">
              <Text
                color={theme.components.lastUpdate.textColor}
                size="small"
                marginLeft="30px"
                lineHeight="20px"
              >
                <FormattedMessage
                  id="app.lastUpdate.text"
                  defaultMessage="última atualização a"
                />{" "}
                {pageDate.format("DD MMMM YYYY")}
              </Text>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  sectionTitle: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  backgroundColor: PropTypes.string,
  titleProps: PropTypes.object,
  date: PropTypes.string
};

Footer.defaultProps = {
  date: "dd/mm/yyyy"
};

const FooterWithStyles = styled(Footer)`
  //highContrast only border
  border-top: ${props => props.theme.highContrast.border};
  height: 40px;
  background-color: ${props =>
    props.theme.components.journalFooter.backgroundColor};
`;

export default compose(withTheme, withRouter)(FooterWithStyles);
