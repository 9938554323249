/**
 * SECTION - COURSES
 */

import LocalSelector from "../containers/LocalSelector";
import CourseInfoSelector from "../templates/CourseInfo";
import { views } from "./../services/constants";

const { education } = views;

const CourseRoutes = {
  routes: [
    {
      path: ["/courses/:type/:id", "/cursos/:type/:id"],
      component: LocalSelector,
      props: {
        activeView: education,
        document: "courses"
      }
    },
    {
      path: ["/course/:id", "/curso/:id"],
      component: LocalSelector,
      props: {
        activeView: education,
        document: "course"
      }
    },
    {
      path: ["/c/:id/p", "/c/:id/p"],
      component: LocalSelector,
      props: {
        activeView: education,
        document: "coursePlan"
      }
    },
    {
      path: ["/c/:id/info", "/c/:id/info"],
      component: CourseInfoSelector,
      props: {
        activeView: education,
        document: "courseInfo"
      }
    },
    {
      path: ["/uc/:id", "/uc/:id"],
      component: LocalSelector,
      props: {
        activeView: education,
        document: "curricularUnit"
      }
    }
  ]
};

const FixedCourseRoutes = {
  routes: [
    {
      path: ["/courses/g/10", "/cursos/g/10"],
      component: LocalSelector,
      props: {
        activeView: education,
        document: "g.10"
      }
    },
    {
      path: ["/courses/g/9", "/cursos/g/9", "/programas-doutorais"],
      component: LocalSelector,
      props: {
        activeView: education,
        document: "g.9"
      }
    },
    {
      path: ["/courses/g/4", "/cursos/g/4"],
      component: LocalSelector,
      props: {
        activeView: education,
        document: "g.4"
      }
    },
    {
      path: ["/courses/c/1", "/cursos/c/1"],
      component: LocalSelector,
      props: {
        activeView: education,
        document: "c.1"
      }
    }
  ]
};

export { FixedCourseRoutes };

export default CourseRoutes;
