import { useContext, useEffect } from "react";
import { StoreContext } from "../context/StoreContext";

function useActiveNavigation(
  activeView,
  showSideNav = true,
  isNews = false,
  isSubWeb = false
) {
  const context = useContext(StoreContext);
  useEffect(() => {
    context.updateActiveView(activeView);
    context.updateSideNav(showSideNav);
    context.updateJournalNav(isNews);
    context.updateSubWeb(isSubWeb);
  }, []);
  return [activeView, context.updateActiveView];
}

export default useActiveNavigation;
