const data = [
  {
    name: "Slider",
    styles: {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      textColor: "#fff"
    },
    endpoint: "/news/top",
    data: {
      pt: {
        data: {
          payload: null,
          list: [
            {
              type: "leftColumns",
              id: "61973",
              title:
                "46º aniversário da UA com homenagem a Sophia e intervenção de Daniel Innerarity",
              description: "16 de dezembro",
              summary: "Heyeyeye",
              image: {
                title:
                  "46º aniversário da UA com homenagem a Sophia e intervenção de Daniel Innerarity",
                url: "contents/imgs/living/46anos_ua.png",
                alt:
                  "Fundo branco. No centro o logótipo da UA o número 46 colorido."
              },
              url: "/0/61973"
            },
            {
              type: "leftColumns",
              id: "62062",
              title:
                "Investigadores distinguidos com bolsas europeias no valor de quatro milhões de euros",
              description: "Investigação na UA",
              summary: "",
              image: {
                title:
                  "Investigadores distinguidos com bolsas europeias no valor de quatro milhões de euros",
                url:
                  "contents/imgs/investigacao_cooperacao/investigadores_bolsaseuropeias2.jpg",
                alt: "Dois investigadores a sorrir no Campus de Santiago."
              },
              url: "/0/62062"
            },
            {
              type: "leftColumns",
              id: "62048",
              title:
                "UA considerada uma das universidades mais sustentáveis do mundo",
              description: " Ranking GreenMetric World University 2019",
              summary: "",
              image: {
                title:
                  "UA considerada uma das universidades mais sustentáveis do mundo",
                url: "contents/imgs/living/sustentabilidade.jpg",
                alt:
                  "Flor cor-de-rosa desabrocha do interior de um tubo de ensaio. Ao fundo o campus desfocado."
              },
              url: "/0/62048"
            }
          ]
        }
      },
      en: {
        data: {
          payload: null,
          list: [
            {
              type: "leftColumns",
              id: "61973",
              title:
                "UA celebrates its 46th anniversary with Daniel Innerarity and a tribute to Sophia",
              description: "December 16th ",
              summary: "",
              image: {
                title:
                  "UA celebrates its 46th anniversary with Daniel Innerarity and a tribute to Sophia",
                url: "contents/imgs/living/46anos_ua.png",
                alt:
                  "White background. In the centre is the UA logo  and the number 46."
              },
              url: "/0/61973"
            },
            {
              type: "leftColumns",
              id: "62065",
              title:
                "UA researchers awarded with European grants of €4 million",
              description: "Research at the UA",
              summary: "",
              image: {
                title:
                  "UA researchers awarded with European grants of €4 million",
                url:
                  "contents/imgs/investigacao_cooperacao/investigadores_bolsaseuropeias2.jpg",
                alt: "Two researchers smiling at Campus de Santiago."
              },
              url: "/16/62065"
            },
            {
              type: "leftColumns",
              id: "62048",
              title:
                "UA is one of the most sustainable universities in the world",
              description: "GreenMetric World University 2019 Ranking",
              summary: "",
              image: {
                title:
                  "UA is one of the most sustainable universities in the world",
                url: "contents/imgs/living/sustentabilidade.jpg",
                alt:
                  "Pink flower blooms from inside a test tube. In the background, the campus is blurred."
              },
              url: "/0/62048"
            }
          ]
        }
      }
    }
  },
  {
    name: "Agenda",
    backgroundColor: "#0EB4BD",
    textColor: "#fff",
    title: "Agenda",
    resource: "app.organism.agenda.title",
    href: "agenda",
    isInternalLink: true,
    showHeader: true,
    eventStyles: {
      titleColor: "#fff",
      descriptionColor: "#fff",
      date: {
        textColor: "#fff"
      }
    },
    featured: {
      backgroundColor: "#4d93ff",
      titleColor: "#fff",
      textColor: "#fff"
    },
    categoriesStyles: {
      activeColor: "#000",
      textColor: "#000"
    },
    endpoint: "/calendar?channel=57&recordsPerPage=4"
  },
  {
    name: "News",
    backgroundColor: "#F1F1F2",
    textColor: "#000",
    title: "Notícias",
    resource: "app.organism.news.title",
    showHeader: true,
    titleProps: {
      hasLine: false,
      hasLink: true,
      href: "https://uaonline.ua.pt"
    },
    cardStyles: {
      textColor: "#000",
      backgroundColor: "#fff"
    },
    endpoint:
      "/news?featured=true&channel=57&recordsPerPage=6&categoriesToIgnore=10&categoriesToIgnore=3&categoriesToIgnore=8&categoriesToIgnore=14"
  },
  {
    name: "SubWebCourses"
  },
  {
    name: "SubWebWarnings",
    title: "Avisos",
    tabs: [
      {
        text: "dgeit"
      },
      {
        text: "economia"
      },
      {
        text: "gestão"
      },
      {
        text: "turismo"
      },
      {
        text: "engenharia industrial"
      }
    ],
    data: [
      {
        title: "Licenciatura em Lorem ipsum",
        subTitle: "Lorem ipsum dolor sit amet.",
        text:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium consectetur corporis culpa deleniti dolor fficia optio quam quasi qui sapiente sint tenetur voluptatem voluptatibus...",
        hasMoreLink: true
      },
      {
        title: "Licenciatura em Lorem ipsum",
        subTitle: "Lorem ipsum dolor sit amet.",
        text:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium consectetur corporis culpa deleniti dolor fficia optio quam quasi qui sapiente sint tenetur voluptatem voluptatibus..."
      },
      {
        title: "Licenciatura em Lorem ipsum",
        subTitle: "Lorem ipsum dolor sit amet.",
        text:
          "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium consectetur corporis culpa deleniti dolor fficia optio quam quasi qui sapiente sint tenetur voluptatem voluptatibus...",
        hasTitle: false
      }
    ]
  },
  {
    name: "SubWebDepartment"
  },
  {
    name: "SubWebContacts",
    title: "Contactos do departamento",
    mapUrl:
      "https://maps.google.com/maps?q=university%20of%20aveiro&t=&z=13&ie=UTF8&iwloc=&output=embed",
    main: [
      {
        text: "Telefone: (+351) 234 567 890 (Extensão interna: 22700)"
      },
      {
        text: "Fax: (+351) 234 567 890 (Extensão interna: 22700)"
      }
    ],
    data: [
      {
        text: "Emails:"
      },
      {
        text: "Geral: dec.geral@ua.pt"
      },
      {
        text: "Secretaria: dec.geral@ua.pt"
      },
      {
        text: "Direção: dec.geral@ua.pt"
      }
    ],
    social: [
      {
        type: "instagram",
        url: ""
      },
      {
        type: "facebook",
        url: ""
      },
      {
        type: "twitter",
        url: ""
      },
      {
        type: "linkedIn",
        url: ""
      }
    ]
  }
];

export default data;
