import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//Components
import { Departments } from "@uaveiro/ui";

const DepartmentsTemplate = ({ politec, departments, match, ...props }) => {
  const { lang } = match.params;
  const departmentsContent = useMemo(() => departments[lang] || departments, [
    departments,
    lang
  ]);
  const politecContent = useMemo(() => politec[lang] || politec, [
    politec,
    lang
  ]);

  const data = {
    departments: departmentsContent,
    politec: politecContent
  };

  return <Departments data={data} {...props} />;
};

DepartmentsTemplate.propTypes = {
  politec: PropTypes.object,
  departments: PropTypes.array,
  match: PropTypes.object
};

export default withRouter(DepartmentsTemplate);
