import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { withRouter } from "react-router-dom";
import { stripHtml } from "../../services/utilities";
import { hasHash } from "../../services/constants";
import removeMd from "remove-markdown";
import { lang as computeLang } from "../../translations";

export const computeDescription = (description, content) => {
  const descriptionLengthMax = 180;

  let metaDescription = stripHtml(description);
  let shortDescription =
    metaDescription.split("").length > descriptionLengthMax
      ? `${metaDescription.substring(0, descriptionLengthMax)}`
      : metaDescription;

  if (!description && content) {
    // clean up markdown syntax from content
    const contentClean = removeMd(content).trim();

    // get paragraphs
    const paragraphs = contentClean.split("\n");

    // remove empty strings from paragraphs
    let paragraphsClean = paragraphs.filter(line => line !== "");
    paragraphsClean = paragraphsClean.map(line => line.trim());

    const [firstP, secondP] = paragraphsClean;
    const isFirstParagraphBig = firstP && firstP.length > descriptionLengthMax;
    const hasMultipleParagraphs = paragraphsClean.length > 0;
    if (hasMultipleParagraphs) {
      metaDescription = isFirstParagraphBig
        ? `${firstP}`
        : `${firstP} ${secondP}`;
      shortDescription = isFirstParagraphBig
        ? `${firstP.substring(0, descriptionLengthMax)}...`
        : `${firstP}`;
    }
    if (!hasMultipleParagraphs) {
      metaDescription = contentClean;
      shortDescription =
        contentClean.length > descriptionLengthMax
          ? `${contentClean.trim().substring(0, descriptionLengthMax)}...`
          : contentClean;
    }
  }
  if (description && description.lenght > descriptionLengthMax) {
    metaDescription = `${description
      .trim()
      .substring(0, descriptionLengthMax)}...`;
  }

  if (metaDescription === "undefined" && shortDescription === "undefined") {
    metaDescription = false;
    shortDescription = false;
  }

  return { metaDescription, shortDescription };
};

function SEO({
  title: titleProp,
  description,
  meta,
  lang: langProp,
  keywords,
  location,
  content
}) {
  const ogSite = `https://www.ua.pt`;
  const ogUrl = useMemo(() => {
    return location.pathname === "/"
      ? ogSite
      : `${ogSite}/${hasHash}${location.pathname}`;
  }, [location.pathname]);

  const lang = useMemo(() => langProp || computeLang(), [
    langProp,
    location.pathname
  ]);
  const title = useMemo(() => titleProp && (titleProp[lang] || titleProp), [
    titleProp,
    langProp,
    location.pathname
  ]);

  const metaTitle =
    title && typeof title !== "object"
      ? `${title} - Universidade de Aveiro`
      : `Universidade de Aveiro`;

  const { metaDescription, shortDescription } = useMemo(
    () => computeDescription(description, content),
    [description, content]
  );

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={metaTitle}
      meta={[
        {
          property: `og:site_name`,
          content: `Universidade de Aveiro`
        },
        {
          property: `og:site`,
          content: ogSite
        },
        {
          property: `og:image`,
          content: `https://static.ua.pt/images/ua/sm-campus.png`
        },
        {
          property: `og:url`,
          content: ogUrl
        },
        {
          property: `fb:page_id`,
          content: `114882798568553`
        },
        {
          property: `og:title`,
          content: metaTitle
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:site:id`,
          content: `22531295`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          name: `twitter:site`,
          content: `@UnivAveiro`
        },
        {
          name: `twitter:title`,
          content: metaTitle
        },
        {
          name: `theme-color`,
          content: `#ffffff`
        }
      ]
        .concat(
          description || (metaDescription && shortDescription)
            ? [
                {
                  name: `twitter:description`,
                  content: shortDescription
                },
                {
                  name: `description`,
                  content: metaDescription
                },
                {
                  property: `og:description`,
                  content: shortDescription
                }
              ]
            : []
        )
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `)
              }
            : []
        )
        .concat(meta)}
      link={[
        {
          rel: "canonical",
          href: ogUrl
        },
        {
          rel: "icon",
          type: "image/png",
          href: "/favicon/favicon.ico"
        }
      ]}
    />
  );
}

SEO.defaultProps = {
  lang: null,
  meta: [],
  keywords: [],
  title: null,
  content: ``
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      pt: PropTypes.string,
      en: PropTypes.string
    })
  ]),
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired,
  content: PropTypes.string
};

export default withRouter(SEO);
