import React from "react";
//Engine
import ComponentSelector from "../ComponentSelector";
import { Loading } from "@uaveiro/ui";
//Hooks
import { useActiveNavigation, usePageContent } from "../../hooks";

function Page(props) {
  const path = `${props.activeView}/${props.document}`;
  const { data, loading } = usePageContent(path);
  useActiveNavigation(props.activeView, props.sideNav, props.isNews);
  if (loading) return <Loading hasDelay />;
  if (data === null || data === undefined) return null;

  return data.map((component, index) => {
    const { name, id, ...rest } = component;

    return (
      <ComponentSelector
        key={index}
        name={name || id}
        index={index}
        length={Object.keys(data).length}
        {...rest}
        {...props}
      />
    );
  });
}

export default Page;
