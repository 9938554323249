import React, { memo, useEffect, Fragment, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//components
import { CurricularUnit } from "@uaveiro/ui";
//Utils
import { portalAPI, hasHash } from "../../services/constants";
import { compose } from "../../services/utilities";

const CurricularUnitTemplate = memo(function CurricularUnitTemplate({
  match,
  history,
  ...props
}) {
  const [state, setState] = useState({
    loading: true,
    error: false,
    data: {}
  });

  const { lang, id } = match.params;

  const onFetchData = async () => {
    try {
      const url = `${portalAPI}${props.endpoint}${id}?language=${lang}`;
      const res = await fetch(url);
      const { data } = await res.json();

      setState(prevState => {
        return { ...prevState, data, loading: false };
      });
    } catch (e) {
      setState(prevState => {
        return { ...prevState, loading: false, error: true };
      });
    }
  };

  useEffect(() => {
    onFetchData();
  }, [history.location]);

  const curricularUnitProps = {
    hasHash,
    lang,
    ...props
  };

  return (
    <Fragment>
      <CurricularUnit
        data={state.data}
        loading={state.loading}
        hasError={state.error}
        {...curricularUnitProps}
      />
    </Fragment>
  );
});

CurricularUnitTemplate.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default compose(withRouter)(CurricularUnitTemplate);
