import React, { useState, useEffect, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { Icon, Text } from "@uaveiro/ui";
import { withContext } from "./../../../hocs";
//Locales
import { injectIntl } from "react-intl";
import { compose } from "../../../services/utilities";

const animationClasses = {
  IN_ANIMATION: "in-animation",
  ANIMATION: "animation",
  OUT_ANIMATION: "out-animation"
};

function placeholderMsg(intl) {
  return {
    a: intl.formatMessage({
      id: "app.search.placeholder.a",
      defaultMessage: "Pesquisa todo o portal"
    }),
    p: intl.formatMessage({
      id: "app.search.placeholder.p",
      defaultMessage: "Pesquisa por pessoas"
    }),
    n: intl.formatMessage({
      id: "app.search.placeholder.n",
      defaultMessage: "Pesquisa por notícias e agenda"
    }),
    l: intl.formatMessage({
      id: "app.search.placeholder.l",
      defaultMessage: "Pesquisa por departamentos e outros locais"
    }),
    projects: intl.formatMessage({
      id: "app.projects.projectsList.search.placeholder",
      defaultMessage: "Procurar um projeto"
    }),
    default: intl.formatMessage({
      id: "app.search.placeholder.default",
      defaultMessage:
        "Pesquisa em páginas, ficheiros, pessoas, notícias e locais"
    })
  };
}

const InstituteSearch = ({
  className,
  onChange,
  value,
  onKeyPress,
  clear,
  showClear,
  removeLeftIcon,
  type,
  isFocus,
  context,
  onClick,
  intl,
  theme
}) => {
  const [animationClass, setAnimationClass] = useState(``);

  const placeholder = useMemo(() => {
    if (type) return placeholderMsg(intl)[type];
    return placeholderMsg(intl).default;
  }, [type]);

  const searchInput = useRef();

  // focus handling from parent
  useEffect(() => {
    if (isFocus) {
      searchInput.current.focus();
    }
  }, [onChange, context.state.searchFocus]);

  const onFocus = () => {
    const { ANIMATION, IN_ANIMATION } = animationClasses;
    setAnimationClass(`${ANIMATION} ${IN_ANIMATION}`);
  };

  const onBlur = () => {
    const { ANIMATION, OUT_ANIMATION } = animationClasses;
    setAnimationClass(`${ANIMATION} ${OUT_ANIMATION}`);
  };

  return (
    <div className={className}>
      <div className={`institute-search ${animationClass}`}>
        {removeLeftIcon ? null : (
          <div className="institute-search-left">
            <Icon
              name={theme.icons.search}
              size="small"
              color={theme.components.instituteSearch.leftIconColor}
              onClick={onClick}
            />
          </div>
        )}
        <input
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onKeyPress={onKeyPress}
          onFocus={onFocus}
          onBlur={onBlur}
          ref={searchInput}
        />

        {showClear && (
          <button className="institute-search-right">
            <Text
              size="medium"
              color={theme.components.instituteSearch.rightIconColor}
              onClick={clear}
            >
              {theme.icons.times_icon}
            </Text>
          </button>
        )}
      </div>
    </div>
  );
};

InstituteSearch.defaultProps = {
  showClear: false,
  isFocus: true,
  type: "a",
  setFocus: () => null
};

InstituteSearch.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  onKeyPress: PropTypes.func,
  clear: PropTypes.func,
  showClear: PropTypes.bool,
  removeLeftIcon: PropTypes.bool,
  type: PropTypes.string,
  isFocus: PropTypes.bool,
  setFocus: PropTypes.func,
  context: PropTypes.shape({
    state: PropTypes.shape({
      focusSearch: PropTypes.bool
    }).isRequired
  }).isRequired
};

const InstituteSearchWithStyles = styled(InstituteSearch)`
  margin: ${props => props.margin};
  .animation {
    :before {
      content: "";
      position: absolute;
      bottom: 0;
      z-index: 2;
      height: 2px;
      background-color: ${props =>
        props.underlineAnimationColor ||
        props.theme.components.instituteSearch.beforeBackgroundColor};
    }
  }
  //From left to right
  .in-animation {
    :before {
      left: 0;
      width: 100%;
      -webkit-animation: animate-in 0.5s linear;
      -moz-animation: animate-in 0.5s linear;
      animation: animate-in 0.5s linear;
    }
  }
  @keyframes animate-in {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }
  //From right to left
  .out-animation {
    :before {
      right: 0;
      width: 0;
      -webkit-animation: animate-out 0.5s linear;
      -moz-animation: animate-out 0.5s linear;
      animation: animate-out 0.5s linear;
    }
  }
  @keyframes animate-out {
    0% {
      width: 100%;
    }
    100% {
      width: 0;
    }
  }
  .institute-search {
    position: relative;
    width: ${props => props.width};

    .institute-search-right {
      position: absolute;
      right: 0;
      top: 0;
      visibility: ${props => props.clearVisibility};
      cursor: pointer;
      background-color: transparent;
      border: none;
    }
    .institute-search-left {
      position: absolute;
      left: 0;
      top: 0;
    }
    input {
      font-weight: 300;
      color: ${props => props.theme.components.instituteSearch.color};
      width: 100%;
      border: none;
      padding: ${props =>
        props.removeLeftIcon ? "0 28px 10px 0" : "0 28px 10px 28px"};
      outline: none;
      font-size: 14px;
      border-bottom: 2px solid
        ${props => props.theme.components.instituteSearch.borderColor};
      background-color: transparent;

      ::placeholder {
        color: ${props => props.theme.components.instituteSearch.color};
      }
    }
  }
`;

InstituteSearchWithStyles.defaultProps = {
  width: "100%"
};

export default compose(
  withContext,
  injectIntl,
  withTheme
)(InstituteSearchWithStyles);
