import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//Components
import { JournalHighlights } from "@uaveiro/ui";
//Hocs
import {
  compose,
  facebookUrl,
  twitterUrl,
  linkedinUrl
} from "../../services/utilities";
import { hasHash, baseUrl } from "../../services/constants";
//API
import { getNewsFeatured } from "../../api/requests";
import { withSize } from "react-sizeme";

function JournalHighlightsTemplate({ match, size, ...props }) {
  const [state, setState] = useState({
    loading: true,
    error: false,
    data: {}
  });

  const { lang } = match.params;

  const onAddUrl = data => {
    const route = lang === "pt" ? "noticias" : "news";
    let items = data.list.map(item => {
      let url = `${lang}/${route}/${item.categoryId}/${item.id}`;
      return {
        ...item,
        url: `/${url}`,
        categoryUrl: `/${lang}/${route}/${item.categoryId}`,
        share: {
          facebook: facebookUrl(`${baseUrl}${hasHash}/${url}`),
          twitter: twitterUrl(`${baseUrl}${hasHash}/${url}`, item.title),
          linkedin: linkedinUrl(`${baseUrl}${hasHash}/${url}`),
          url: `${baseUrl}${hasHash}/${url}`
        }
      };
    });

    return {
      list: items
    };
  };

  const onFetchData = async () => {
    try {
      const res = await getNewsFeatured({ lang, history: props.history });
      const data = onAddUrl(res.data);

      setState(prevState => {
        return { ...prevState, data, loading: false };
      });
    } catch (error) {
      setState(prevState => {
        return { ...prevState, loading: false, error: true };
      });
    }
  };

  useEffect(() => {
    onFetchData();
  }, []);

  const journalHighlightsProps = {
    size,
    ...props
  };

  return (
    <JournalHighlights
      data={state.data}
      loading={state.loading}
      hasError={state.error}
      {...journalHighlightsProps}
    />
  );
}

JournalHighlightsTemplate.propTypes = {
  match: PropTypes.object.isRequired,
  size: PropTypes.object.isRequired
};

export default compose(withRouter, withSize())(JournalHighlightsTemplate);
