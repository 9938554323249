import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//Components
import { SubWebDepartment } from "@uaveiro/ui";

const DepartmentTemplate = ({ match, data, ...props }) => {
  const {
    params: { lang }
  } = match;

  const departmentProps = {
    lang,
    data,
    isLoading: false,
    hasError: false,
    ...props
  };

  return <SubWebDepartment {...departmentProps} />;
};

DepartmentTemplate.propTypes = {
  match: PropTypes.object,
  data: PropTypes.object
};

export default withRouter(DepartmentTemplate);
