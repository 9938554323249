import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//Components
import { JournalColumns } from "@uaveiro/ui";
//Hocs
import { compose } from "../../services/utilities";
import { hasHash } from "../../services/constants";
//API
import { getNews } from "../../api/requests";

function JournalColumnsTemplate({
  match,
  leftCategoryId,
  centerCategoryId,
  rightCategoryId,
  ...props
}) {
  const { lang } = match.params;
  const route = lang === "pt" ? "noticias" : "news";

  const [state, setState] = useState({
    error: false,
    leftSectionData: {
      loading: true,
      error: false,
      data: {}
    },
    centerSectionData: {
      loading: true,
      error: false,
      data: {}
    },
    rightSectionData: {
      loading: true,
      error: false,
      data: {}
    }
  });

  const onAddUrl = data => {
    let items = data.listNews.map(item => {
      return {
        ...item,
        url: `/${lang}/${route}/${item.categoryID}/${item.id}`
      };
    });

    return {
      listNews: items,
      pagination: data.pagination
    };
  };

  const onFetchData = async (categoryId, section) => {
    const multiCategoriesIDs = categoryId
      .map(id => `categories=${id}`)
      .join("&");

    try {
      const res = await getNews({
        lang,
        id: null,
        recordsPerPage: 4,
        multiCategories: true,
        multiCategoriesIDs,
        notInTopFeatured: true,
        history: props.history
      });

      const data = onAddUrl(res.data);

      setState(prevState => {
        return {
          ...prevState,
          [section]: {
            loading: false,
            error: false,
            data
          }
        };
      });
    } catch (error) {
      setState(prevState => {
        return {
          ...prevState,
          [section]: {
            loading: false,
            error: true
          }
        };
      });
    }
  };

  useEffect(() => {
    onFetchData(leftCategoryId, "leftSectionData");
    onFetchData(centerCategoryId, "centerSectionData");
    onFetchData(rightCategoryId, "rightSectionData");
  }, []);

  const openInNewTab = (e, url) => {
    e.preventDefault();
    e.stopPropagation();
    window.open(url, "_blank");
  };

  const onHandleEnter = (e, url) => {
    if (e.key === "Enter") {
      props.history.push(url);
    }
  };

  const NewsProps = {
    onAction: openInNewTab,
    onEnter: onHandleEnter,
    leftSectionData: state.leftSectionData,
    centerSectionData: state.centerSectionData,
    rightSectionData: state.rightSectionData,
    categoryUrl: {
      left: `${hasHash}/${lang}/${route}/${leftCategoryId}`,
      center: `${hasHash}/${lang}/${route}/${centerCategoryId}`,
      right: `${hasHash}/${lang}/${route}/${rightCategoryId}`
    },
    ...props
  };

  return <JournalColumns {...NewsProps} />;
}

JournalColumnsTemplate.propTypes = {
  match: PropTypes.object.isRequired,
  leftCategoryId: PropTypes.array.isRequired,
  centerCategoryId: PropTypes.array.isRequired,
  rightCategoryId: PropTypes.array.isRequired,
  history: PropTypes.object
};

export default compose(withRouter)(JournalColumnsTemplate);
