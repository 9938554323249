import { useEffect, useContext } from "react";
import PropTypes from "prop-types";
//Routing
import { withRouter } from "react-router-dom";
import { StoreContext } from "../../../context/StoreContext";
//Context
import { ContentContext } from "../../../context/ContentContext";

function ScrollToTop({ children, location, history }) {
  const context = useContext(StoreContext);
  const contentContext = useContext(ContentContext);

  useEffect(() => {
    //Navigation dynamic breadcrumbs
    contentContext.dispatch({
      type: "update-dynamic-breadcrumbs-list",
      payload: { path: location.pathname }
    });

    if (contentContext.state.disableLocalStorage && window) {
      window.localStorage.clear();
    }

    if (history.action !== "POP") {
      window.scrollTo(0, 0);
    }

    const isNews =
      location.pathname.search(/\bnoticias\b/) >= 0 ||
      location.pathname.search(/\bnews\b/) >= 0;

    context.updateJournalNav(isNews);
  }, [location]);

  return children;
}

ScrollToTop.propTypes = {
  children: PropTypes.node.isRequired
};

export default withRouter(ScrollToTop);
