import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//Components
import { Facts } from "@uaveiro/ui";

const FactsTemplate = ({ match, title, ...props }) => {
  const { lang } = match.params;
  const content = useMemo(() => title[lang] || title, [title, lang]);

  return <Facts title={content} {...props} />;
};

FactsTemplate.propTypes = {
  title: PropTypes.string,
  match: PropTypes.object
};

export default withRouter(FactsTemplate);
