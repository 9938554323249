const data = [
  {
    name: "Breadcrumbs",
    hasLargerMdPaddingTop: false,
    hasSideNavColumnOffset: false,
    customColumn: "offset-lg-3 col",
    hasHighContrastBorderTop: true,
    isLocal: true,
    data: {
      pt: [
        { text: "UA", url: "/" },
        { text: "pessoas", url: "/pessoas" }
      ],
      en: [
        { text: "UA", url: "/" },
        { text: "deca", url: "/deca" },
        { text: "people", url: "/people" }
      ]
    }
  },
  {
    name: "SubWebPeople"
  }
];

export default data;
