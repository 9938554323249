import { useState, useEffect } from "react";
import queryString from "query-string";

const useQueryBuilder = (baseUrl, locationUrl) => {
  const searchParams = queryString.parse(locationUrl);
  const [urlParams, setUrlParams] = useState(searchParams);

  useEffect(() => {
    const esc = encodeURIComponent;
    const query = Object.keys(urlParams)
      .map(k => esc(k) + "=" + esc(urlParams[k]))
      .join("&");

    window.history.pushState(null, null, `${baseUrl}${query && "?"}${query}`);
  }, [urlParams]);

  const remoteUrlParam = key => {
    const params = { ...urlParams };

    if (Array.isArray(key)) {
      key.map(item => delete params[item]);
    } else {
      delete params[key];
    }

    setUrlParams(params);
  };

  return [urlParams, setUrlParams, searchParams, remoteUrlParam];
};

export default useQueryBuilder;
