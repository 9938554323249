import { rem } from "polished";
const colors = {
  white: "#fff",
  whiteDark: "#F3F3F4",
  black: "#000",
  blackLight: "#333333",
  grey: "#666666",
  greyAnchor: "#6f6f6f",
  greyLight: "#E5E5E5",
  greyBorder: "#e6e6e6",
  greyDark: "#40494e",
  greyBg: "#E6E7E8",
  green: "#95CA3D",
  blue: "#00b2bd",
  blueLight: "#00d1ca",
  focus: "#91d300",
  yellow: "#f7b609",
  orange: "#f05a24",
  red: "#FF6666",
  arrow: "rgb(204, 204, 203)",
  borderColor: "#939597",
  subWeb: "#0EB4BD"
};

const type = {
  base: `'Roboto', sans-serif`,
  serif: `'Playfair Display', 'Roboto', sans-serif`,
  icons: `'FontAwesome'`
};

const weight = {
  xxlight: 100,
  xlight: 200,
  light: 300,
  xbase: 400,
  base: 500,
  bold: 600
};

const sizes = {
  xs3: rem("3px"),
  xs2: rem("8px"),
  xs: rem("12px"),
  s: rem("14px"),
  sm: rem("15px"),
  m: rem("16px"),
  l: rem("18px"),
  xl: rem("20px"),
  xl2: rem("22px"),
  xl3: rem("26px"),
  xl3m: rem("28px"),
  xl4: rem("32px"),
  xl5: rem("36px"),
  xl6: rem("48px"),
  xl7: rem("56px"),
  xll: rem("168px")
};

const typos = {
  input: sizes.xs,
  button: sizes.xs,
  link: sizes.xs,
  text: sizes.l,
  title: sizes.xl6
};

const spacing = {
  xs3: rem("5px"),
  xs2: rem("10px"),
  xs: rem("12px"),
  s: rem("15px"),
  m: rem("20px"),
  l: rem("24px"),
  xl: rem("30px"),
  xl1: rem("32px"),
  xl2: rem("40px"),
  xl3: rem("50px"),
  xl4: rem("70px"),
  xl5: rem("100px"),
  xl6: rem("120px")
};

const values = {
  borderSize: "1px",
  headerLine: {
    width: "34px",
    height: "5px"
  },
  letterSpacing: "normal"
};

const shadows = {
  base: "0px 8px 16px 0px rgba(0, 0, 0, 0.1)",
  card: "0 .125rem .25rem rgba(0,0,0,.075)"
};

const mixins = {
  focus: () => `
    outline: 2px solid ${theme.colors.focus};
  `
};

const icons = {
  //Brand
  facebook: "fab fa-facebook-f",
  instagram: "fab fa-instagram",
  twitter: "fab fa-twitter",
  linkedIn: "fab fa-linkedin-in",
  youtube: "fab fa-youtube",
  // spotify: "fab fa-spotify",
  //Computers
  link: "fal fa-link",
  fa_print: "fal fa-print",
  //Files
  file: "fal fa-file",
  //Navigation
  arrow_up: "fal fa-arrow-up",
  chevron_up: "fal fa-chevron-up",
  chevron_down: "fal fa-chevron-down",
  chevron_left: "fal fa-chevron-left",
  chevron_right: "fal fa-chevron-right",
  //Mathematics
  plus: "+",
  times_icon: "×",
  fas_times: "fas fa-times",
  //Status
  calendar: "fal fa-calendar-alt",
  //Social
  share_alt: "fal fa-share-alt",
  image: "fas fa-image",
  //Interfaces
  search: "fas fa-search",
  //Audio & Video
  audio_description: "fal fa-audio-description",
  audio_description_active: "fas fa-audio-description",
  youtube_play: "fab fa-youtube"
};

const theme = {
  colors,
  type,
  sizes,
  typos,
  weight,
  values,
  spacing,
  shadows,
  mixins,
  icons
};
export default theme;
