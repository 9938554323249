import React, {
  useEffect,
  useState,
  useMemo,
  memo,
  Fragment,
  useContext
} from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { withRouter } from "react-router-dom";
//Components
import { Link } from "../../components/atoms";
import { InstituteSearch } from "../../components/molecules";

import {
  ScreenReaderText,
  Title,
  ContentContainer,
  Section
} from "@uaveiro/ui";

import Search from "../GoogleSearch";
import Directory from "../Directory";
import News from "./News";
import Places from "./Places";
//Utils
import { compose, getParameterByName } from "../../services/utilities";
import { hasHash } from "../../services/constants";
//Locales
import { injectIntl } from "react-intl";
//Context
import { StoreContext } from "../../context/StoreContext";

const GlobalSearch = memo(function GlobalSearch({
  className,
  match,
  history,
  intl,
  theme
}) {
  const context = useContext(StoreContext);
  const [searchValue, setSearchValue] = useState("");
  const [showClear, setShowClear] = useState(false);

  const { type, lang } = match.params;

  const searchList = useMemo(() => {
    const initial = [
      {
        type: "a",
        text: intl.formatMessage({
          id: "app.search.all.title",
          defaultMessage: "Todo o Portal"
        }),
        href: {
          pt: `${hasHash}/${lang}/pesquisa/a/?#gsc.tab=0&gsc.q=${searchValue}&gsc.sort=`,
          en: `${hasHash}/${lang}/search/a/?#gsc.tab=0&gsc.q=${searchValue}&gsc.sort=`
        }
      },
      {
        type: "p",
        text: intl.formatMessage({
          id: "app.search.people.title",
          defaultMessage: "Pessoas"
        }),
        href: {
          pt: `${hasHash}/${lang}/pesquisa/p/?q=${searchValue}`,
          en: `${hasHash}/${lang}/search/p/?q=${searchValue}`
        }
      },
      {
        type: "n",
        text: intl.formatMessage({
          id: "app.search.news.title",
          defaultMessage: "Notícias"
        }),
        href: {
          pt: `${hasHash}/${lang}/pesquisa/n/?q=${searchValue}`,
          en: `${hasHash}/${lang}/search/n/?q=${searchValue}`
        }
      },
      {
        type: "l",
        text: intl.formatMessage({
          id: "app.search.locals.title",
          defaultMessage: "Locais"
        }),
        href: {
          pt: `${hasHash}/${lang}/pesquisa/l/?q=${searchValue}`,
          en: `${hasHash}/${lang}/search/l/?q=${searchValue}`
        }
      }
    ];

    return initial.map(({ href, type: searchType, ...rest }) => ({
      href: href[lang],
      isActive: searchType === type,
      ...rest
    }));
  }, [lang, searchValue, type]);

  const onHandleSearch = e => {
    //empty search
    if (!searchValue) return;
    if (e.key === "Enter") {
      searchSwitcher(searchValue);
    }
  };

  const onHandleIconSearch = () => {
    if (!searchValue) return;
    searchSwitcher(searchValue);
  };

  const searchSwitcher = value => {
    switch (type) {
      case "a":
        onGlobalSearch(value);
        break;
      case "p":
        onDirectorySearch(value);
        break;
      case "n":
        onNewsSearch(value);
        break;
      case "l":
        onPlacesSearch(value);
        break;
      default:
        onGlobalSearch(value);
        break;
    }
  };

  const onGlobalSearch = value => {
    const route = lang === "pt" ? `/${lang}/pesquisa/a/` : `/${lang}/search/a/`;
    const query = `?#gsc.tab=0&gsc.q=${value}&gsc.sort=`;
    history.push(`${route}${query}`);
  };

  const onDirectorySearch = value => {
    const route = lang === "pt" ? `/${lang}/pesquisa/p/` : `/${lang}/search/p/`;
    history.push(`${route}?q=${value}`);
  };

  const onNewsSearch = value => {
    const route = lang === "pt" ? `/${lang}/pesquisa/n/` : `/${lang}/search/n/`;
    history.push(`${route}?q=${value}`);
  };

  const onPlacesSearch = value => {
    const route = lang === "pt" ? `/${lang}/pesquisa/l/` : `/${lang}/search/l/`;
    history.push(`${route}?q=${value}`);
  };

  const onChangeSearchValue = e => {
    //empty search
    if (!e.target.value) {
      searchSwitcher("");
    }

    setShowClear(!e.target.value ? false : true);
    setSearchValue(e.target.value);
  };
  const onClearSearch = () => {
    //If is empty return nothing
    if (!searchValue) return;
    setShowClear(false);
    setSearchValue("");
    searchSwitcher("");
  };

  useEffect(() => {
    //Clears the SubWeb Navigation
    context.setSubWebNavigation({}, false);
    if (type === "a") {
      if (history.location.hash) {
        const url = decodeURIComponent(history.location.hash);
        const search = url.split("&")[1].split("=")[1];
        setSearchValue(search);
        setShowClear(true);
      }
    } else {
      const value = getParameterByName("q");
      setSearchValue(value);
    }
  }, [history, match]);

  return (
    <Section
      className={className}
      backgroundColor={theme.components.globalSearch.backgroundColor}
      hasHighContrastBorderTop={false}
    >
      <ScreenReaderText>
        <Title text="Área de Pesquisa" />
      </ScreenReaderText>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <ContentContainer>
              <InstituteSearch
                type={type}
                value={searchValue}
                onChange={onChangeSearchValue}
                onKeyPress={onHandleSearch}
                onClick={onHandleIconSearch}
                clear={onClearSearch}
                showClear={showClear}
                clearVisibility="visible"
              />
            </ContentContainer>
          </div>
        </div>
      </div>

      {/*Tabs*/}
      <ContentContainer
        marginTop="24px"
        marginBottom="12px"
        borderBottom={theme.components.globalSearch.tabsBorder}
      >
        <div className="container">
          <div className="row no-gutters">
            {searchList.map(({ text, href, isActive }, index) => (
              <Tab className="col-auto" key={index} isActive={isActive}>
                <ContentContainer
                  position="relative"
                  active={isActive}
                  underlineWithAnimation
                  underlineAnimationColor={
                    theme.components.globalSearch.tabLinkColor
                  }
                >
                  <Link
                    text={text}
                    href={href}
                    color={theme.components.globalSearch.tabLinkColor}
                    fontSize="14px"
                    fontWeight="300"
                    display="block"
                    fontDecoration="none"
                  />
                </ContentContainer>
              </Tab>
            ))}
          </div>
        </div>
      </ContentContainer>

      <Fragment>
        {type === "a" && <Search />}
        {type === "p" && <Directory endpoint="/person" />}
        {type === "n" && <News endpoint="/news/search" />}
        {type === "l" && <Places endpoint="/locations" />}
      </Fragment>
    </Section>
  );
});

const Tab = styled.div`
  margin-right: 30px;
  a {
    cursor: pointer;
    padding: ${props => props.theme.spacing.xs2};
    font-weight: ${props =>
      props.isActive ? props.theme.weight.xbase : props.theme.weight.light};
  }

  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767px) {
    margin-right: 15px;
    a {
      padding: ${props => props.theme.spacing.xs2}
        ${props => props.theme.spacing.xs3};
    }
  }
`;

GlobalSearch.propTypes = {
  className: PropTypes.string,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default compose(withTheme, withRouter, injectIntl)(GlobalSearch);
