import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
//Components
import { Files } from "@uaveiro/ui";
//utils
import { baseUrl } from "../../services/constants";

const FilesTemplate = ({ match, ...props }) => {
  const {
    params: { lang }
  } = match;

  const onDownloadAll = () => {
    if (typeof window !== "undefined") {
      const filterIds = props.data.map(file => file.id).join("&id=");
      window.location.href = `${baseUrl}/${lang}/files?id=${filterIds}`;
    }
  };

  const onDownload = file => {
    if (typeof window !== "undefined") {
      window.location.href = `${baseUrl}/${lang}/file/${file.id}`;
    }
  };

  const filesProps = {
    onDownload,
    onDownloadAll,
    lang,
    ...props
  };

  return <Files {...filesProps} />;
};

FilesTemplate.propTypes = {
  match: PropTypes.object
};

export default withRouter(FilesTemplate);
