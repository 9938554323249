  export default [
    {
      name: "SEO",
      title: {
        pt: "Jornal",
        en: "Journal"
      },
      description: "Jornal da Universidade de Aveiro. Um jornal que pensa.",
      keywords: ["jornal", "journal", "notícias", "news"]
    },
    {
      name: "JournalDossiersDetails",
      sectionTitle: "Dossiês"
    }
  ];