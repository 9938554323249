import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//Components
import { SubWebDepartmentCourses } from "@uaveiro/ui";
//API
import { getCourseTypesList } from "../../api/requests";
//Context
import { StoreContext } from "../../context/StoreContext";
//Hooks
import { useQueryBuilder } from "../../hooks";

const DepartmentCoursesTemplate = ({ match, ...props }) => {
  const context = useContext(StoreContext);
  const [state, setState] = useState({
    isLoading: true,
    hasError: false,
    filters: [],
    data: [],
    staticData: []
  });

  const [
    urlParams,
    setUrlParams,
    searchParams,
    remoteUrlParam
  ] = useQueryBuilder(match.url, props.history.location.search);

  const { id: department } = context.state.subWebNav;

  const {
    params: { lang }
  } = match;

  const getFilterDefaultValues = data => {
    //Checks for Query params
    const hasParams = Object.keys(searchParams).length > 0;

    //Gets the Degree param if exists
    const degreeOption =
      hasParams && searchParams.degree !== undefined
        ? {
            label: searchParams.degree,
            value: searchParams.degree
          }
        : null;

    //Gets the Degree param value and filters the label data if exists
    const scientificAreasValue =
      hasParams && searchParams.scientificAreas !== undefined
        ? searchParams.scientificAreas
        : "";

    const [scientificAreasLabel] = data
      .filter(res => res.jsonField === "scientificAreas")
      .map(item => {
        let [result] = item.options.filter(
          option => option.value === scientificAreasValue
        );
        return result;
      });

    const scientificAreasOption =
      searchParams.scientificAreas !== undefined
        ? {
            label:
              scientificAreasLabel !== undefined
                ? scientificAreasLabel.label
                : searchParams.scientificAreas,
            value: searchParams.scientificAreas
          }
        : null;

    //Updates the filters with default values
    return data.map(item => {
      return {
        ...item,
        defaultValues:
          item.jsonField === "degree" ? degreeOption : scientificAreasOption
      };
    });
  };

  const getData = async () => {
    try {
      const res = await getCourseTypesList({
        lang,
        history: props.history,
        department
      });

      const filters = getFilterDefaultValues(res.data.filters);

      setState(prevState => {
        return {
          ...prevState,
          data: res.data.list,
          staticData: res.data.list,
          filters,
          isLoading: false
        };
      });
    } catch (error) {
      setState(prevState => {
        return { ...prevState, isLoading: false, hasError: true };
      });
    }
  };

  useEffect(() => {
    getData();
  }, [department]);

  useEffect(() => {
    if (!state.isLoading) {
      if (Object.keys(searchParams).length > 0) {
        const { degree, scientificAreas } = searchParams;

        //Check and set degree param from url and filter data
        degree !== undefined && onChangeFilter({ value: degree }, "degree");
        //Check and set scientificAreas param from url and filter data
        scientificAreas !== undefined &&
          onChangeFilter({ value: scientificAreas }, "scientificAreas");
      }
    }
  }, [state.isLoading]);

  const onChangeFilter = (option, field) => {
    const content = state.staticData;

    if (option === null) {
      //Removes the field param from the url
      remoteUrlParam(field);

      setState(prevState => {
        return {
          ...prevState,
          data: prevState.staticData
        };
      });
      return;
    }

    setUrlParams({
      ...urlParams,
      [field]: option.value
    });

    if (field === "degree") {
      const data = content.filter(res => res.title === option.value);

      setState(prevState => {
        return {
          ...prevState,
          data
        };
      });
    }

    if (field === "scientificAreas") {
      const data = content.map(res => {
        const list = res.list.filter(
          item => item.scientificAreas.indexOf(option.value) > -1
        );

        return { ...res, list: [...list] };
      });

      setState(prevState => {
        return {
          ...prevState,
          data
        };
      });
    }
  };

  const departmentCoursesProps = {
    lang,
    onChangeFilter,
    ...state,
    ...props
  };

  return <SubWebDepartmentCourses {...departmentCoursesProps} />;
};

DepartmentCoursesTemplate.propTypes = {
  title: PropTypes.string
};

export default withRouter(DepartmentCoursesTemplate);
