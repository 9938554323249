import React from "react";
//Engine
import ComponentSelector from "../ComponentSelector";
//Hooks
import { useActiveNavigation } from "../../hooks";
/**
 * Journal
 */
import journal from "../../data/internal/journal/journal";
import journalAbout from "../../data/internal/journal/journalAbout";
import journalContacts from "../../data/internal/journal/journalContacts";
import journalDetails from "../../data/internal/journal/journalDetails";
import journalDossiers from "../../data/internal/journal/journalDossiers";
import journalDossiersDetails from "../../data/internal/journal/journalDossiersDetails";
import journalList from "../../data/internal/journal/journalList";
/**
 * Projects
 */
import projects from "../../data/internal/projects/projects";
import projectDetails from "../../data/internal/projects/projectDetails";
import projectRedirect from "../../data/internal/projects/projectRedirect";
/**
 * App
 */
import footer from "../../data/footer";
import agenda from "../../data/internal/app/agenda";
import edicts from "../../data/internal/app/edicts";
import profile from "../../data/internal/app/profile";
import ourServices from "../../data/internal/app/ourServices";
/**
 * Courses
 */
import courses from "../../data/internal/courses/courses";
import course from "../../data/internal/courses/course";
import courseInfo from "../../data/internal/courses/courseInfo";
import coursePlan from "../../data/internal/courses/coursePlan";
import curricularUnit from "../../data/internal/courses/curricularUnit";
import degrees from "../../data/internal/courses/degrees";
import department from "../../data/internal/courses/department";
import departmentList from "../../data/internal/courses/departmentList";
/**
 * Auth
 */
import authenticationCallback from "../../data/internal/authentication/callback";

/**
 * DEMOS
 */
import demoArticle from "../../data/demos/demoArticle";
import demoPeople from "../../data/demos/demoPeople";
import demoHome from "../../data/demos/demoHome";
import demoCourses from "../../data/demos/demoCourses";
import demoMark from "../../data/demos/demoMark";
import playground from "../../data/demos/playground";
import cookiesPolicy from "../../data/demos/cookiesPolicy";

const documents = {
  journal,
  journalAbout,
  journalContacts,
  journalDetails,
  journalDossiers,
  journalDossiersDetails,
  journalList,
  projects,
  projectDetails,
  projectRedirect,
  agenda,
  edicts,
  profile,
  courses,
  course,
  coursePlan,
  curricularUnit,
  degrees,
  department,
  departmentList,
  footer,
  demoArticle,
  demoPeople,
  demoHome,
  demoCourses,
  demoMark,
  playground,
  authenticationCallback,
  ourServices,
  courseInfo,
  cookiesPolicy
};

function Page(props) {
  const data = documents[props.document];
  useActiveNavigation(
    props.activeView,
    props.sideNav,
    props.isNews,
    props.isSubWeb
  );
  if (data === null || data === undefined) return null;

  return data.map((component, index) => {
    const { name, ...rest } = component;
    return (
      <ComponentSelector
        key={index}
        name={name}
        index={index}
        length={Object.keys(data).length}
        {...rest}
        {...props}
      />
    );
  });
}

export default Page;
