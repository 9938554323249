import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//Components
import { JournalDossiers } from "@uaveiro/ui";
//Hocs
import { compose } from "../../services/utilities";
import { hasHash } from "../../services/constants";
//API
import { getDossiersList } from "../../api/requests";

function JournalDossiersTemplate({ match, ...props }) {
  const [state, setState] = useState({
    loading: true,
    error: false,
    data: {}
  });
  const { lang } = match.params;

  const onAddUrl = data => {
    const route = lang === "pt" ? "noticias" : "news";
    const routeDossier = lang === "pt" ? "dossie" : "dossier";
    let items = data.map(item => {
      return {
        ...item,
        url: `${hasHash}/${lang}/${route}/${routeDossier}/${item.id}`
        // url: `${hasHash}/${lang}/${route}/${item.id}?dossier=true?n=${item.title}`
      };
    });

    return {
      list: items
    };
  };

  const onFetchData = async () => {
    try {
      const res = await getDossiersList({
        lang,
        history: props.history
      });

      const data = onAddUrl(res.data);

      setState(prevState => {
        return { ...prevState, data, loading: false };
      });
    } catch (error) {
      setState(prevState => {
        return { ...prevState, loading: false, error: true };
      });
    }
  };

  useEffect(() => {
    onFetchData();
  }, []);

  return (
    <JournalDossiers
      data={state.data}
      loading={state.loading}
      hasError={state.error}
      {...props}
    />
  );
}

JournalDossiersTemplate.propTypes = {
  match: PropTypes.object.isRequired
};

export default compose(withRouter)(JournalDossiersTemplate);
