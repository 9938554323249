export default [
  {
    name: "SEO",
    title: {
      pt: "Estudar",
      en: "Study"
    },
    description: "",
    keywords: []
  },
  {
    name: "Slider",
    styles: {
      backgroundColor: "rgba(230, 230, 230, 0.8)",
      textColor: "#000"
    },
    endpoint: "/news/top",
    data: {
      pt: {
        data: {
          payload: null,
          list: [
            {
              id: 57584,
              title:
                "UA abre 3ª fase de candidaturas para estudantes internacionais",
              description: "Candidaturas de 5 a 19 de junho",
              summary: "",
              image: {
                title:
                  "UA abre 3ª fase de candidaturas para estudantes internacionais",
                url: "contents/imgs/study/estudantes_15.jpg",
                alt:
                  "Grupo de estudantes a caminhar pelo campus. Jardins. Céu limpo."
              },
              url: "/pt/acesso-ingresso-internacionais"
            },
            {
              id: 57584,
              title: "10 mil visitantes no XPERiMENTA Universidade de Aveiro",
              description: "1ª edição excede expectativas",
              summary: "",
              image: {
                title: "Espaço Bicicleta",
                url: "contents/imgs/study/estudantes_6.jpg",
                alt: "Espaço Bicicleta"
              },
              url: "https://uaonline.ua.pt/pub/detail.asp?c=57947&lg=pt"
            }
          ]
        }
      },
      en: {
        data: {
          payload: null,
          list: [
            {
              id: 57584,
              title: "Third call for applications for international students",
              description: "Open from the 5th to 19th of june",
              summary: "",
              image: {
                title: "Third call for applications for international students",
                url: "contents/imgs/study/estudantes_15.jpg",
                alt:
                  "Group of students walking at the campus. Gardens. Clear sky."
              },
              url: "/en/international-access-admission"
            },
            {
              id: 57584,
              title: "10000 visitors in UA’s XPERIMENTA",
              description: "1st edition surpasses expectations",
              summary: "",
              image: {
                title: "Espaço Bicicleta",
                url: "contents/imgs/study/estudantes_6.jpg",
                alt: "Espaço Bicicleta"
              },
              url: "https://uaonline.ua.pt/pub/detail.asp?c=57947&lg=pt"
            }
          ]
        }
      }
    }
  },
  {
    name: "MultiColumns",
    backgroundColor: "#00b2bd",
    isThemeReverse: true,
    title: {
      pt: "Cursos",
      en: "Courses"
    },
    href: "tipo-curso",
    isInternalLink: true,
    data: {
      pt: [
        {
          content:
            "\n  A Universidade de Aveiro tem o curso certo para ti!\n\n  A nossa oferta formativa é vasta e estende-se a várias áreas do saber e ciclos de estudos, por isso podes ficar connosco durante todo o teu percurso académico. De entre os 16 departamentos e 4 escolas politécnicas, descobre o curso que melhor se adequa à tua vocação."
        },
        {
          content:
            "\n  * [Cursos técnicos superiores profissionais](/pt/cursos/g/10)\n  * [Licenciaturas e mestrados integrados](/pt/cursos/c/1)\n  * [Mestrados](/pt/cursos/g/4)\n  * [Programas doutorais](/pt/cursos/g/9)\n  "
        }
      ],
      en: [
        {
          content:
            "\n University of Aveiro has the right course for you!\n\n Our programmes are vast and extend to several areas of knowledge and study cycles, so you can stay with us during your entire academic journey. Among the 16 departments and 4 polytechnic schools, find out the course that best suits your vocation.  "
        },
        {
          content:
            "\n  * [Higher Professional Training](/en/courses/g/10)\n  * [1st cycle degrees and integrated masters](/en/courses/c/1)\n  * [Master's Degree](/en/courses/g/4)\n  * [Doctoral Programmes](/en/courses/g/9)\n\n  "
        }
      ]
    }
  },
  {
    name: "Numbers",
    backgroundColor: "#bdbdbd",
    titleColor: "#fff",
    textColor: "#fff",
    lineColor: "#fff",
    hasDate: false,
    data: {
      pt: [
        {
          title: "departamentos",
          number: "16"
        },
        {
          title: "licenciaturas",
          number: "52"
        },
        {
          title: "estudantes graduação",
          number: "+13k"
        },
        {
          title: "bolsas de mérito",
          number: "1500"
        }
      ],
      en: [
        {
          title: "departments",
          number: "16"
        },
        {
          title: "1st cycle degrees",
          number: "52"
        },
        {
          title: "undergraduate students",
          number: "+13k"
        },
        {
          title: "merit scholarships",
          number: "1500"
        }
      ]
    }
  },
  {
    name: "Campus",
    backgroundColor: "#fff",
    textColor: "#000",
    title: "Destaques",
    resource: "app.organism.campus.title",
    showHeader: false,
    titleProps: {
      hasLine: false,
      hasLink: false
    },
    isInternalLink: true,
    data: {
      pt: [
        {
          backgroundColor: "#fff",
          backgroundImage: "contents/imgs/study/estudantes_7.jpg",
          position: "right",
          textColor: "#000",
          lineColor: "#000",
          text: "Candidaturas",
          alt: "",
          col: [6, 6],
          hasText: true,
          href: "candidaturas",
          description:
            "Podes candidatar-te a um curso da Universidade de Aveiro através de diferentes vias de acesso que variam consoante o teu percurso escolar ou académico no momento de candidatura."
        },
        {
          backgroundColor: "#fff",
          backgroundImage: "contents/imgs/spaces/espacos_complexo.jpg",
          position: "left",
          textColor: "#000",
          lineColor: "#000",
          text: "Outras formas de estudar",
          alt:
            "Jovem a estudar no computador. Várias secretárias. Complexo pedagógico. Pequena planta. Luz branca.",
          col: [6, 6],
          hasText: true,
          href: "estudar-mais",
          description:
            "Se queres complementar as tuas aprendizagens ou competências, a UA disponibiliza um conjunto de alternativas formativas que vão desde a possibilidade de frequência de unidades curriculares isoladas, até à possibilidade de frequentar cursos livres de línguas."
        },
        {
          backgroundColor: "#fff",
          backgroundImage: "contents/imgs/spaces/espacos_biblioteca_3.jpg",
          position: "right",
          textColor: "#000",
          lineColor: "#000",
          text: "Apoio aos estudantes",
          alt:
            "Biblioteca da Universidade de Aveiro. Jovens a estudar nas secretárias.",
          col: [6, 6],
          href: "apoio-estudo",
          hasText: true,
          description:
            "Entrar no Ensino Superior implica mudança na vida de um estudante e, por isso, toda a ajuda é bem-vinda nesta fase. Porque a Universidade de Aveiro quer que te sintas parte da comunidade, dispõe de alguns serviços de apoio ao estudo para que nunca te sintas sem rumo."
        },
        {
          backgroundColor: "#fff",
          backgroundImage: "contents/imgs/living/viver_alameda.jpg",
          position: "left",
          textColor: "#000",
          lineColor: "#000",
          text: "Estágios e emprego",
          alt: "Duas pessoas a caminhar na Alameda.",
          col: [6, 6],
          href: "estagios-emprego",
          hasText: true,
          description:
            "E depois do curso, o que vais fazer? E no momento de entrar no mercado de trabalho, a Universidade de Aveiro também está com os seus diplomados, quer no apoio à preparação do currículo, quer na disponibilização de inúmeras oportunidades de estágio e emprego."
        }
      ],
      en: [
        {
          backgroundColor: "#fff",
          backgroundImage: "contents/imgs/study/estudantes_7.jpg",
          position: "right",
          textColor: "#000",
          lineColor: "#000",
          text: "Applications",
          alt: "",
          col: [6, 6],
          hasText: true,
          href: "applications",
          description:
            "You can apply to a course of the University of Aveiro through different access routes that vary according your school or academic path at the moment of application."
        },
        {
          backgroundColor: "#fff",
          backgroundImage: "contents/imgs/spaces/espacos_complexo.jpg",
          position: "left",
          textColor: "#000",
          lineColor: "#000",
          text: "Other ways to study",
          alt:
            "Jovem a estudar no computador. Várias secretárias. Complexo pedagógico. Pequena planta. Luz branca.",
          col: [6, 6],
          hasText: true,
          href: "more-learning",
          description:
            "If you want to complement your learning or competencies, UA provides a set of training alternatives that go from the possibility of attending isolated curricular unities, to the possibility of attending language open courses."
        },
        {
          backgroundColor: "#fff",
          backgroundImage: "contents/imgs/spaces/espacos_biblioteca_3.jpg",
          position: "right",
          textColor: "#000",
          lineColor: "#000",
          text: "Support to students",
          alt:
            "Biblioteca da Universidade de Aveiro. Jovens a estudar nas secretárias.",
          col: [6, 6],
          href: "study-support",
          hasText: true,
          description:
            "Entering Higher Education implies a change in the student’s life, and therefore all help is welcome at this stage. As the University of Aveiro wants you to feel part of the community, it has several study support services, so you never feel without purpose."
        },
        {
          backgroundColor: "#fff",
          backgroundImage: "contents/imgs/living/viver_alameda.jpg",
          position: "left",
          textColor: "#000",
          lineColor: "#000",
          text: "Internships and employment",
          alt: "Duas pessoas a caminhar na Alameda.",
          col: [6, 6],
          href: "professional-integration",
          hasText: true,
          description:
            "And after graduating, what will you do? At the moment of entering the labour market, the University of Aveiro is side by side with its graduates, either in supporting them in the preparation of their curriculum, as well as in providing several internships and employment opportunities."
        }
      ]
    }
  },
  {
    name: "Agenda",
    href: "/pt/agenda",
    backgroundColor: "#00b2bd",
    textColor: "#fff",
    title: "Agenda",
    resource: "app.organism.agenda.title",
    showHeader: true,
    eventStyles: {
      titleColor: "#000",
      descriptionColor: "#fff",
      date: {
        textColor: "#fff"
      }
    },
    featured: {
      backgroundColor: "#00b2bd",
      titleColor: "#000",
      textColor: "#fff"
    },
    categoriesStyles: {
      activeColor: "#000",
      textColor: "#000"
    },
    endpoint: "/calendar?channel=57&recordsPerPage=4"
  }
];
