export default [
  {
    name: "Breadcrumbs",
    data: {
      pt: [
        { text: "UA", url: "/" },
        { text: "investigar", url: "/investigacao" },
        { text: "atividade científica", url: "/atividade-cientifica" },
        { text: "projetos i&d", url: "/projetos-id" }
      ],
      en: [
        { text: "UA", url: "/" },
        { text: "research", url: "/uaresearch" },
        { text: "scientific activity", url: "/scientific-activity" },
        { text: "r&d projects", url: "/id-projects" }
      ]
    }
  },
  { name: "ProjectDetails" }
];
