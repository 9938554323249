import React, { memo } from "react";
import PropTypes from "prop-types";
//Components
import {
  Section,
  ContentContainer,
  Title,
  Text,
  Line,
  Theme as theme
} from "@uaveiro/ui";

const Information = memo(function Information({
  className,
  hasSubTitle,
  hasLine,
  data
}) {
  return (
    <Section className={className}>
      <div className="container">
        <div className="row no-gutters">
          <div className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-4 col-12 offset-0">
            {hasSubTitle && (
              <Title
                tag="h3"
                text={data.subTitle}
                fontWeight="300"
                marginBottom="5px"
                fontSize="22px"
                firstLetter="capitalize"
              />
            )}

            <Title
              text={data.title}
              fontWeight="500"
              marginTop="5px"
              marginBottom="15px"
              fontSize="28px"
              lineHeight="1"
              firstLetter="capitalize"
            />
            {hasLine && (
              <Line
                color={theme.components.information.lineColor}
                marginBottom="25px"
              />
            )}

            {data.content.map((item, index) => {
              return (
                <ContentContainer key={index} marginTop="40px">
                  <Title
                    tag="h3"
                    text={item.label}
                    color={
                      data.activeColor ||
                      theme.components.information.titleColor
                    }
                    fontWeight="400"
                    marginBottom="5px"
                    fontSize="16px"
                  />
                  <Text fontSize="14px" fontWeight="300">
                    {item.value}
                  </Text>
                </ContentContainer>
              );
            })}
          </div>
        </div>
      </div>
    </Section>
  );
});

Information.defaultProps = {
  hasSubTitle: false,
  hasLine: false
};

Information.propTypes = {
  data: PropTypes.array,
  className: PropTypes.string,
  hasSubTitle: PropTypes.bool,
  hasLine: PropTypes.bool
};

export default Information;
