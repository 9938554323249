import React from "react";
import PropTypes from "prop-types";
import { AnimatedBackground, ContentContainer } from "@uaveiro/ui";

const Mask = props => {
  return (
    <ContentContainer
      backgroundColor="#4d93ff"
      position="absolute"
      {...props}
    />
  );
};

const LoadingCard = ({ ...props }) => {
  return (
    <ContentContainer
      height="139px"
      borderTop="1px solid #000"
      borderBottom="1px solid #000"
      padding="20px 0"
    >
      <div className="row">
        <div className="col-auto">
          <ContentContainer padding="10px 0" width="93px">
            <div className="row no-gutters">
              <div className="col-auto">
                <ContentContainer
                  width="24px"
                  height="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <ContentContainer
                    height="16px"
                    width="8px"
                    backgroundColor="#f6f7f8"
                  />
                </ContentContainer>
              </div>
              <div className="col">
                <ContentContainer
                  display="flex"
                  alignItems="center"
                  flexDirection="column"
                >
                  <ContentContainer
                    height="15px"
                    width="30px"
                    backgroundColor="#f6f7f8"
                  />
                  <ContentContainer
                    height="15px"
                    width="30px"
                    backgroundColor="#f6f7f8"
                    marginTop="5px"
                  />
                </ContentContainer>
              </div>
              <div className="col-auto">
                <ContentContainer
                  width="24px"
                  height="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <ContentContainer
                    height="16px"
                    width="8px"
                    backgroundColor="#f6f7f8"
                  />
                </ContentContainer>
              </div>
            </div>
          </ContentContainer>
        </div>
        <div className="col">
          <AnimatedBackground height="97px">
            <Mask {...props} height="15px" right="0" width="50%" />
            <Mask {...props} height="6px" top="15px" width="100%" />
            <Mask {...props} height="15px" top="21px" right="0" width="40%" />
            <Mask {...props} height="25px" top="36px" width="100%" />

            <Mask {...props} height="15px" top="61px" right="0" width="50%" />
            <Mask {...props} height="6px" top="76px" width="100%" />
            <Mask {...props} height="15px" top="82px" right="0" width="60%" />
          </AnimatedBackground>
        </div>
      </div>
    </ContentContainer>
  );
};

const Loading = ({ className }) => {
  return (
    <div className={className}>
      <div className="row">
        <div className="col-xl-6 col-lg-6 col-md-12 col-12">
          <LoadingCard />
        </div>
      </div>
    </div>
  );
};

Loading.propTypes = {
  className: PropTypes.string
};

export default Loading;
