//Containers

import RouteSelector from "../containers/RouteSelector";
import { views } from "./../services/constants";

const { root } = views;

const InternalRoutes = {
  routes: [
    {
      path: ["/h/:name", "/h/:name"],
      component: RouteSelector,
      props: {
        activeView: root,
        document: "people"
      }
    }
  ]
};

export default InternalRoutes;
