import React, { useMemo } from "react";
import PropTypes from "prop-types";
//Route
import { Link as RouterLink } from "react-router-dom";
//Styles
import styled, { withTheme } from "styled-components";
//components
import { Link } from "../../atoms";
import {
  IconCard,
  HorizontalList,
  ListItem,
  DropdownList,
  Dropdown,
  VerticalList,
  ContentContainer,
  Icon,
  ScreenReaderText,
  Title,
  Picture,
  Image
} from "@uaveiro/ui";
//Locales
import { FormattedMessage } from "react-intl";

//Container for Avatar
const AvatarContainer = styled.a`
  height: 20px;
  width: 20px;
  display: flex;
`;

//Container for Bento Icon
const MenuIconContainer = styled(RouterLink)`
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MoreLink = styled(RouterLink)`
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props =>
    props.theme.components.publicNavbar.bentoMenu.moreLinkBgColor};
  color: ${props =>
    props.theme.components.publicNavbar.bentoMenu.moreLinkColor};
  font-size: 12px;
  font-weight: 300;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const PublicNavbar = ({
  className,
  isLogin,
  loginClick,
  links,
  social,
  theme,
  lang,
  user,
  authUrl,
  portalAPI,
  logout,
  servicesUrl,
  services,
  setFavorite,
  removeFavorite,
  ...props
}) => {
  const iconNames = useMemo(() => {
    return { 2: theme.icons.facebook, 3: theme.icons.instagram };
  }, []);

  return (
    <nav className={className} {...props}>
      <ScreenReaderText>
        <Title text="Navegação de Públicos" />
      </ScreenReaderText>
      <div className="container">
        <div className="row no-gutters align-items-center">
          <div className="col-xl-3">
            <HorizontalList
              display="inline-block"
              spacing="0"
              className="public-social-links"
            >
              {social.map((item, index) => {
                return (
                  <ListItem lineHeight="20px" height="20px" key={index}>
                    <Link
                      target={item.target}
                      tabIndex={0}
                      aria-label={item.ariaLabel}
                      href={item.onlyIcon ? item.url : `/${lang}/${item.url}`}
                      fontSize="12px"
                      native={item.onlyIcon}
                      color={theme.components.publicNavbar.linksColor}
                    >
                      {item.onlyIcon ? (
                        <Icon
                          aria-label={item.title}
                          name={iconNames[index]}
                          cursor="pointer"
                          color={theme.components.publicNavbar.iconColor}
                        />
                      ) : (
                        <FormattedMessage
                          id={item.resource}
                          defaultMessage={item.title}
                        />
                      )}
                    </Link>
                  </ListItem>
                );
              })}
            </HorizontalList>
          </div>

          <div className="col">
            <HorizontalList display="inline-block">
              {links.map((item, index) => {
                return (
                  <ListItem
                    marginRight="15px"
                    lineHeight="20px"
                    height="20px"
                    key={index}
                  >
                    <Link
                      tabIndex={0}
                      fontWeight="300"
                      // href={item.url}
                      href={`/${lang}/${item.url}`}
                      target={item.target}
                      aria-label={item.title}
                      fontSize="12px"
                      color={theme.components.publicNavbar.linksColor}
                    >
                      <FormattedMessage
                        id={item.resource}
                        defaultMessage={item.title}
                      />
                    </Link>
                  </ListItem>
                );
              })}
            </HorizontalList>
          </div>

          <div className="col-auto d-flex">
            {user.authenticated ? (
              <HorizontalList
                display="inline-flex"
                className="d-flex"
                spacing="0"
              >
                <ListItem marginRight="10px">
                  <Dropdown
                    display="flex"
                    top="40px"
                    right="-10px"
                    zIndex="130"
                  >
                    <AvatarContainer href="#">
                      <Image
                        src={user.photo}
                        alt="Imagem sem alt"
                        height="100%"
                      />
                    </AvatarContainer>
                    <DropdownList
                      color={theme.components.publicNavbar.avatar.linksColor}
                      fontWeight="300"
                    >
                      <VerticalList>
                        <ListItem>
                          <Link
                            size="small"
                            fontDecoration="none"
                            href="https://my.ua.pt/"
                            target="_blank"
                            native
                          >
                            My.ua
                          </Link>
                        </ListItem>
                        <ListItem>
                          <Link
                            size="small"
                            fontDecoration="none"
                            href="https://id.ua.pt/"
                            target="_blank"
                            native
                          >
                            ID.ua
                          </Link>
                        </ListItem>
                        <ListItem>
                          <Link
                            size="small"
                            fontDecoration="none"
                            href="https://www.ua.pt/"
                            target="_blank"
                            native
                          >
                            Portal UA
                          </Link>
                        </ListItem>
                        <ListItem>
                          <Link
                            as="button"
                            size="small"
                            fontDecoration="none"
                            onClick={() => logout()}
                          >
                            Sair
                          </Link>
                        </ListItem>
                      </VerticalList>
                    </DropdownList>
                  </Dropdown>
                </ListItem>
                <ListItem>
                  <Dropdown
                    display="flex"
                    top="40px"
                    right="-10px"
                    zIndex="130"
                    padding="0"
                    minContentWidth="360px"
                    maxContentWidth="360px"
                  >
                    <MenuIconContainer to={servicesUrl}>
                      <Image
                        height="20px"
                        width="20px"
                        objectFit="initial"
                        src={theme.components.publicNavbar.bentoMenu.icon}
                      />
                    </MenuIconContainer>
                    <div className="dropdown-menu">
                      <ContentContainer
                        className="d-flex flex-wrap"
                        padding="10px 4px"
                      >
                        {services.favorites.map((item, index) => {
                          return (
                            <IconCard
                              key={index}
                              linkText={item.text}
                              iconName={item.icon}
                              margin="6px 12px"
                              linkProps={{
                                href: item.href,
                                native: true,
                                target: "_blank"
                              }}
                            />
                          );
                        })}
                      </ContentContainer>
                      <MoreLink to={servicesUrl}>ver mais</MoreLink>
                    </div>
                  </Dropdown>
                </ListItem>
              </HorizontalList>
            ) : (
              <Link
                tabIndex={0}
                href="https://my.ua.pt/"
                aria-label="Iniciar sessão"
                fontSize="12px"
                native
                hasIconHover={false}
                {...props.auth.styles}
                color={theme.components.publicNavbar.myUAColor}
              >
                my UA
                <Icon
                  aria-label="Ícone de iniciar sessão"
                  {...props.auth.iconLogIn}
                  color="inherit"
                />
              </Link>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
};

PublicNavbar.propTypes = {
  className: PropTypes.string,
  isLogin: PropTypes.bool.isRequired,
  loginClick: PropTypes.func.isRequired,
  links: PropTypes.array.isRequired,
  social: PropTypes.array.isRequired,
  auth: PropTypes.object,
  lang: PropTypes.string,
  setFavorite: PropTypes.func.isRequired,
  removeFavorite: PropTypes.func.isRequired,
  services: PropTypes.shape({
    favorites: PropTypes.array.isRequired,
    all: PropTypes.array.isRequired
  })
};

PublicNavbar.defaultProps = {
  name: "fa-info",
  cursor: "default",
  lang: "pt",
  setFavorite: () => null,
  removeFavorite: () => null,
  services: {
    favorites: [],
    all: []
  }
};

const PublicNavBarWithStyles = styled(PublicNavbar)`
  width: 100%;
  height: 40px;
  background: ${props => props.theme.components.publicNavbar.backgroundColor};
  display: flex;
  align-items: center;

  .public-social-links {
    li {
      padding-right: 12px;
      padding-left: 12px;
      border-left: 1px solid
        ${props => props.theme.components.publicNavbar.borderColor};
      &:first-child {
        padding-left: 0;
        border-left: none;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
  /*
    DropDown
  */
  .dropdown {
    height: 40px;
    align-items: center;
    //Styles Avatar List
    .dropdown-content {
      li {
        display: block;
      }
    }
  }
`;

export default withTheme(PublicNavBarWithStyles);
