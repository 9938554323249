import React from "react";
import PropTypes from "prop-types";
//Components
import { Link } from "../../atoms";
import {
  Section,
  ContentContainer,
  VerticalList,
  ListItem,
  Text
} from "@uaveiro/ui";
import { withTheme } from "styled-components";

const SimpleList = ({ title, data, className, isLinks, theme }) => {
  return (
    <section>
      <Section
        className={className}
        backgroundColor={theme.components.simpleList.backgroundColor}
        hasHighContrastBorderTop={false}
      >
        <div className="container">
          <div className="row no-gutters">
            <div className="offset-xl-3 col-xl-7 offset-lg-4 col-lg col-12">
              <Text
                as="h2"
                size="xLarge"
                text={title}
                fontWeight="500"
                marginBottom="10px"
                firstLetter="capitalize"
              />
              <ContentContainer
                marginTop="40px"
                marginRight="0"
                marginBottom="80px"
                mobileXsMarginRight="0"
              >
                {/*Lista de Departamentos*/}
                <VerticalList>
                  {data.map((item, index) => {
                    return (
                      <ListItem
                        key={index}
                        paddingTop="10px"
                        paddingBottom="10px"
                        borderBottom={`1px solid ${theme.components.simpleList.borderColor}`}
                        borderTop={
                          index === 0
                            ? `1px solid ${theme.components.simpleList.borderColor}`
                            : "none"
                        }
                      >
                        {isLinks ? (
                          <Link
                            href={item.url}
                            aria-label={item.text}
                            fontSize="14px"
                            fontWeight="300"
                            color={theme.components.simpleList.linkColor}
                          >
                            {item.text}
                          </Link>
                        ) : (
                          <Text fontSize="14px" fontWeight="300">
                            {item.text}
                          </Text>
                        )}
                      </ListItem>
                    );
                  })}
                </VerticalList>
              </ContentContainer>
            </div>
          </div>
        </div>
      </Section>
    </section>
  );
};

SimpleList.defaultProps = {
  title: "Title",
  data: [],
  isLinks: false
};

SimpleList.propTypes = {
  title: PropTypes.string,
  titleColor: PropTypes.string,
  data: PropTypes.array,
  className: PropTypes.string,
  isLinks: PropTypes.bool
};

export default withTheme(SimpleList);
