import React, { useState, useEffect, useContext } from "react";
//Engine
import ComponentSelector from "../ComponentSelector";
import { Loading } from "@uaveiro/ui";
//API
import { getDynamicPage, sendToTeams } from "../../api/requests";
//Context
import { StoreContext } from "../../context/StoreContext";
//utils
import { insert } from "../../services/utilities";


function Page(props) {
  const context = useContext(StoreContext);
  const [state, setState] = useState({
    loading: true,
    error: false,
    data: []
  });

  const { params } = props.match;
  const path = params[0];
  const lang = params.lang;

  const onSetNavigation = data => {
    //Validates if the contents has a top navigation
    const navigation = data.filter(nav => nav.name === "Navigation");
    const sideNav = data.filter(nav => nav.name === "SideNav");
    const jornal = data.filter(nav => nav.id === "jornal");

    //Sets and renders the top navigation
    if (navigation.length > 0) {
      const [nav] = navigation;

      if (
        context.state.subWebNav.id !== nav.id ||
        props.history.action === "POP"
      ) {
        const show = nav.menu !== "hidden";

        context.setSubWebNavigation(show ? nav : {}, show);
        context.updateActiveColor(nav.activeColor);
        context.updateSubWebActiveView(props.activeView);

        //This is a external page witch removes all the portal navs
        if (nav.serviceType === 10) {
          context.updateExternalPage(true);
        }
      }
    } else {
      context.updateExternalPage(false);
      context.setSubWebNavigation({}, false);
    }

    //Sets and renders the SideNav
    if (sideNav.length > 0 && sideNav.some(item => item.menu !== "hidden")) {
      const [side] = sideNav;

      if (
        context.state.sideNavId !== side.id ||
        props.history.action === "POP"
      ) {
        context.setSideNav(side, side.id);
        context.updateActiveColor(side.activeColor);
      }
    } else {
      context.updateSideNav(false);
    }
    //if there is a jornal id being rendered, then the journalNav is
    //also rendered
    if (jornal.length) {
      context.updateJournalNav(true);
    }
  };

  const setManualBreadcrumbs = res => {
    const navigation = res.filter(nav => nav.name === "Navigation");
    const hasNav = navigation.length > 0;

    const [nav] = navigation;

    const setBreadCrumbs =
      !res.some(e => e.name === "Breadcrumbs") &&
      res.some(e => e.name === "Article") &&
      !res.some(e => e.name === "Slider") &&
      hasNav;

    return setBreadCrumbs
      ? insert(res, 2, {
          name: "Breadcrumbs",
          data: hasNav
            ? [
                { text: "UA", url: "/" },
                { text: nav.id, url: `/${nav.id}` }
              ]
            : [{ text: "UA", url: "/" }]
        })
      : res;
  };

  const onFetchPage = async () => {
    try {
      const res = await getDynamicPage({
        path,
        lang
      });

      const data = setManualBreadcrumbs(res);

      //Checks and sets Navigation and SideNav
      onSetNavigation(data);
      //Updates the state and builds the page
      setState(prevState => {
        return {
          ...prevState,
          data,
          loading: false
        };
      });
    } catch (error) {
      await sendToTeams({ page: props.location.pathname, info: error });
      switch (error.message) {
        case "404":
          props.history.push(`/pt/404`);
          break;
        default:
          props.history.push(`/pt/error`);
      }
    }
  };

  useEffect(() => {
    //Gets the page content
      let SplitPath = path.split("/");
      let flag = true;
      for(var i = 0; i < SplitPath.length; i++){
        if(SplitPath[i] === "noticias" || SplitPath[i] === "news"){
          // quando a página tem 3 argumentos o refresh não deve ser invocado
          if(SplitPath.length > 2)
            flag = false;
        }
      } 
      
      // se for uma página de notícias não chama a função dinâmica
      if(flag){
        onFetchPage();
      } 
       
  }, [path]);

  if (state.loading) return <Loading hasDelay />;
  if (state.data === null || state.data === undefined) return null;

  return state.data.map((component, index) => {
    const { name, ...rest } = component;

    return (
      <ComponentSelector
        key={index}
        name={name}
        index={index}
        length={Object.keys(state.data).length}
        {...rest}
        {...props}
      />
    );
  });
}

export default Page;
