import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
//Components
import { Numbers } from "@uaveiro/ui";

const NumbersTemplate = ({ data, match, ...props }) => {
  const { lang } = match.params;
  const content = useMemo(() => data[lang] || data, [data, lang]);
  return <Numbers data={content} {...props} />;
};

NumbersTemplate.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  match: PropTypes.object
};

export default withRouter(NumbersTemplate);
