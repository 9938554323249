import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { lang } from "../../../translations";
//Components
import { Link } from "../../atoms";
import {
  Section,
  ContentContainer,
  VerticalList,
  ListItem,
  Text,
  Line,
  Loading
} from "@uaveiro/ui";
import { withTheme } from "styled-components";
import { FormattedMessage } from "react-intl";

const SiteMap = ({ title, className, hasLine, loading, data, theme }) => {
  return (
    <Section
      className={className}
      paddingTop="70px"
      paddingBottom="70px"
      backgroundColor={theme.components.siteMap.backgroundColor}
      hasHighContrastBorderTop={false}
    >
      <div className="container">
        <div className="row no-gutters">
          {loading ? (
            <Loading hasDelay />
          ) : (
            <div className="offset-xl-3 col-xl-7 offset-lg-2 col-lg col-12">
              <Text
                as="h2"
                size="xLarge"
                fontWeight="500"
                firstLetter="capitalize"
              >
                {title}
              </Text>
              {hasLine && (
                <Line
                  color={theme.components.siteMap.lineColor}
                  marginBottom="25px"
                />
              )}

              {data
                .filter(
                  res =>
                    res.name === "SideNav" ||
                    (res.name === "sidenav" && res.tabs.length > 0)
                )
                .map((res, index) => {
                  return (
                    <ContentContainer
                      marginTop={index === 0 ? "25px" : "50px"}
                      key={index}
                    >
                      <Text fontWeight="500" as="h3" size="medium">
                        {res.title}
                      </Text>
                      <Line color={res.activeColor} marginBottom="15px" />
                      <VerticalList
                        listPaddingTop="5px"
                        listPaddingBottom="5px"
                      >
                        {res.tabs.map((item, index) => {
                          return (
                            <Fragment key={index}>
                              <ListItem>
                                <Link
                                  href={item.url}
                                  fontWeight="300"
                                  color={theme.components.siteMap.linkColor}
                                >
                                  {item.title}
                                </Link>
                              </ListItem>
                              {item.links !== undefined &&
                                item.links.length > 0 &&
                                item.links.map((child, index) => {
                                  return (
                                    <ListItem key={index} paddingLeft="10px">
                                      <Link
                                        href={`/${lang()}${child.url}`}
                                        fontWeight="300"
                                        color={
                                          theme.components.siteMap.linkColor
                                        }
                                      >
                                        {child.text || child.title}
                                      </Link>
                                    </ListItem>
                                  );
                                })}
                            </Fragment>
                          );
                        })}
                      </VerticalList>
                    </ContentContainer>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </Section>
  );
};

SiteMap.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  hasLine: PropTypes.bool
};

export default withTheme(SiteMap);
